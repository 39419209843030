import { Component, OnInit, ViewChild } from '@angular/core';
import { LoggerService } from 'src/app/_services/logger/logger.service';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


import { format } from 'date-fns';
import { ROLES } from '../../constants';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication/authentication.service';

@Component({
    moduleId: module.id,
    selector: 'logger',
    templateUrl: 'logger.component.html',
    styleUrls: ['logger.component.css']
})
export class LoggerComponent implements OnInit {

    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @ViewChild(MatSort) public sort: MatSort;

    userConnected: User;
    selectedTab = 'logger';
    authorizedStoreRoles = [ROLES.SUPERADMIN, ROLES.NATIONAL_MAG];
    storesLocked : number;
    totalStores = 393;
    essenceLocked : number;
    totalEss = 385;
    public logs: MatTableDataSource<any> = new MatTableDataSource();
    public displayedColumns: string[] = ['id', 'date', 'user', 'role', 'method', 'path', 'content'];

    constructor(
        private loggerService: LoggerService,
        private authenticationService: AuthenticationService
    ) {
        this.userConnected = this.authenticationService.getCurrentUser();
    }

    ngOnInit() {
        this.logs.sort = this.sort;
        this.logs.paginator = this.paginator;
        this.loggerService.getLogs().subscribe(r => {
            r.forEach(n => { n.date = format(new Date(n.date), 'dd/MM/yyyy HH:mm') });
            this.logs.data = r;
        });
        this.loggerService.getNumberOfStoreLocked().subscribe(r => {
            this.storesLocked = r[0].count;
        });
        this.loggerService.getNumberOfEssLocked().subscribe(r => {
            this.essenceLocked = r[0].count;
        });

        this.loggerService.getNumberOfStore().subscribe(r => {
            this.totalStores = r[0].count;
        });
        this.loggerService.getNumberOfEss().subscribe(r => {
            this.totalEss = r[0].count;
        });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.logs.filter = filterValue.trim().toLowerCase();
        if (this.logs.paginator) {
            this.logs.paginator.firstPage();
        }
    }

    updateSelectedTab(tab: string) {
        this.selectedTab = tab;
    }

    calculatePercentStore() {
        return ((this.storesLocked / this.totalStores)*100).toFixed(1).toString();
    }

    calculatePercentEssence() {
        return ((this.essenceLocked / this.totalEss)*100).toFixed(1).toString();
    }
}
