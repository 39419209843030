import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import * as Rollbar from 'rollbar';
import { EntryLog } from '../components/logistic/_models/entry-log';

// Rollbar
const rollbarConfig = {
    accessToken: '5aacdab0d91c4c528a81d987015c08d5',
    captureUncaught: true,
    captureUnhandledRejections: true,
};

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

    handleError(err: any): void {
        this.rollbar.error(err.originalError || err);
    }
}

/**
 * Demande à l'instance de rollbar en paramètre de log la nouvelle entrée logistique.
 */
export function logNewEntryLog(rollbar: Rollbar, entry: EntryLog) {
    rollbar.info('new entry log: ' + JSON.stringify(entry));
}
