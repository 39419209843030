import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {

    constructor(
        private http: HttpClient,
        private toastService: ToastrService,
    ) { }

    public getLogs = (): Observable<any> => {
        return this.http.get<any>(environment.OP_API_URI + '/logger');
    }
    public getNumberOfStoreLocked = (): Observable<any> => {
        return this.http.get<any>(environment.OP_API_URI + '/logger/storeLocked');
    }

    public getNumberOfEssLocked = (): Observable<any> => {
        return this.http.get<any>(environment.OP_API_URI + '/logger/essenceLocked');
    }
    public getNumberOfStore = (): Observable<any> => {
        return this.http.get<any>(environment.OP_API_URI + '/logger/storeNumber');
    }

    public getNumberOfEss = (): Observable<any> => {
        return this.http.get<any>(environment.OP_API_URI + '/logger/essenceNumber');
    }
}
