import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { AnneeReference } from '../../_models/anneeReference';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<AnneeReference[]> {
    return this.http.get<AnneeReference[]>(`${environment.OP_API_URI}/yearReference`);
  }

}
