import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { Pattern } from 'src/app/_models/pattern';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PatternService {
    constructor(private http: HttpClient) {}

    getAll(): Observable<Pattern[]> {
        return this.http.get<Pattern[]>(`${environment.OP_API_URI}/pattern`);
    }
    deleteCreateOrUpdatePatterns(category: string, patterns: Pattern[]): Observable<any> {
        return this.http.post(`${environment.OP_API_URI}/pattern/${category}`, { patterns });
    }

    getByCategory(category: string): Observable<Pattern[][]> {
        return this.http.get<Pattern[][]>(`${environment.OP_API_URI}/pattern/${category}/groupBy`);
    }
}
