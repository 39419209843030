import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from 'src/app/_models/store';
import { User } from 'src/app/_models/user';
import { AdminService } from 'src/app/_services/admin/admin.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { ModalUserComponent } from '../modal-user/modal-user.component';
import { isNullOrUndefined } from 'util';
import { ModalUserEditionComponent } from '../modal-user-edition/modal-user-edition.component';
import { ROLES } from '../../../constants';

@Component({
    selector: 'app-view-users',
    templateUrl: './view-users.component.html',
    styleUrls: ['./view-users.component.css'],
})
export class ViewUsersComponent implements OnInit {
    @ViewChild('filterIdentifiant') filterIdentifiant: ElementRef;
    @ViewChild('filterRole') filterRole: ElementRef;
    @ViewChild('filterMagasin') filterMagasin: ElementRef;

    @Input() roles: any[];

    allUsers: User[];

    usersToDisplay: User[] = [];

    stores: Store[];

    roleName: String;

    loading = false;

    identifiantFilter: string = '';

    roleFilter: string = '';

    storeFilter: string = '';

    isFiltered: boolean = false;

    constructor(
        private modalUser: NgbModal,
        private adminService: AdminService,
        private authenticationService: AuthenticationService,
    ) {
        this.adminService.listen().subscribe((m: any) => {
            this.loading = true;
            this.resetResearch();
            this.getUsers();
        })
    }

    ngOnInit() {
        this.loading = true;
        this.isFiltered = false;
        // TODO Adapter les noms des fonctions -> + explicit
        this.getUsers();
        this.getStores();
    }

    getUsers() {
        this.adminService.getUsersManaged(this.authenticationService.getCurrentUser().id).subscribe(
            users => {
             this.allUsers = users;
                ROLES.LIST.NON_ADMINS.includes(this.authenticationService.getCurrentUser().role)?
                this.usersToDisplay = this.allUsers.filter(s=>s.role === this.authenticationService.getCurrentUser().role):this.usersToDisplay = this.allUsers;
/*                this.allUsers = users;
                this.usersToDisplay = this.allUsers;*/
                this.loading = false;
            },
            () => {
                this.loading = false;
            }
        );
    }

    getStores() {
        this.adminService.getStores(this.authenticationService.getCurrentUser().login).subscribe(
            stores => {
                this.stores = stores;
            },
            () => { }
        );
    }
    // TODO On peut sans doute refactorer ça
    setIdentifiantFilter(event) {
        this.identifiantFilter = event.target.value;
        this.applyFilter();
    }

    setRoleFilter(event) {
        this.roleFilter = event.target.value;
        this.applyFilter();
    }

    setStoreFilter(event) {
        this.storeFilter = event.target.value;
        this.applyFilter();
    }

    /**
     * Applique les trois filtres sur les users, ca permet de les combiner.
     */
    // TODO Ajouter un loader (des fois le filtre peut prendre + de 2s)
    applyFilter() {
        this.usersToDisplay = this.allUsers.filter(user => {
            if (!user.login.toLowerCase().includes(this.identifiantFilter.toLowerCase())) {
                return false;
            } else if (
                !isNullOrUndefined(this.changeRoleName(user.role)) &&
                !this.changeRoleName(user.role)
                    .toLowerCase()
                    .includes(this.roleFilter.toLowerCase())
            ) {
                return false;
            } else if (
                !user.stores.find(store => store.name.toLowerCase().includes(this.storeFilter.toLowerCase())) &&
                user.stores.length !== 0
            ) {
                return false;
            }
            return true;
        });
    }

    changeRoleName = input => {
        return this.adminService.changeRoleName(input);
    };

    resetResearch() {
        this.filterIdentifiant.nativeElement.value = '';
        this.filterRole.nativeElement.value = '';
        this.filterMagasin.nativeElement.value = '';
        this.identifiantFilter = '';
        this.roleFilter = '';
        this.storeFilter = '';
        this.applyFilter();
    }

    openCreateUserModal() {
        const userModal = this.modalUser.open(ModalUserComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
        });
        userModal.componentInstance.stores = this.stores;
        userModal.componentInstance.roles = this.roles;
        userModal.result.then(res => {
            if (res && res.login) {
                this.allUsers.push(res);
            }
        });
    }

    openEditUserModal(user) {
        const userEditModal = this.modalUser.open(ModalUserEditionComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
        });
        userEditModal.componentInstance.user = user;
        userEditModal.componentInstance.roles = this.roles;
        userEditModal.componentInstance.stores = this.stores;
        userEditModal.result.then(res => {
            if (res && res.login) {
                let previousUser = this.allUsers.find(u => u.id === res.id);
                this.allUsers.splice(this.allUsers.indexOf(previousUser), 1);
                this.allUsers.push(res);
            }
        });
    }


}
