import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { Designation } from '../../_models/designation';
import { DESIGNATION_URI } from '../../constants';
import { isNullOrUndefined } from 'util';

@Injectable({
    providedIn: 'root',
})
export class DesignationService {
    public designations: Designation[];

    constructor(private http: HttpClient) {}

    getDesignationById(id: number): Designation {
        if (this.designations == null || this.designations.length === 0) {
            this.getAllDesignation().subscribe(designations => {
                this.designations = designations;
                return this.designations.find(designation => designation.id === id);
            });
        } else {
            return this.designations.find(designation => designation.id === id);
        }
    }

    getDesignationByCode(code, type?): Designation {
        if (this.designations == null || this.designations.length === 0) {
            this.getAllDesignation().subscribe(designations => {
                this.designations = designations;
                return isNullOrUndefined(type)
                    ? this.designations.find(designation => designation.code === code && designation.type === null)
                    : this.designations.find(designation => designation.code === code && designation.type === 'LOG');
            });
        } else {
            return isNullOrUndefined(type)
                ? this.designations.find(designation => designation.code === code && designation.type === null)
                : this.designations.find(designation => designation.code === code && designation.type === 'LOG');
        }
    }

    getAllDesignation(): Observable<Designation[]> {
        if (this.designations == null || this.designations.length === 0) {
            return this.http.get<Designation[]>(environment.OP_API_URI + DESIGNATION_URI);
        } else {
            return new Observable<Designation[]>(observer => observer.next(this.designations));
        }
    }
}
