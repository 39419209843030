import { Pipe, PipeTransform } from '@angular/core';
import { AdminService } from '../_services/admin/admin.service';

/**
 * Permet de filtrer les designations généralement (sur tout leurs attributs)
 */
@Pipe({ name: 'filterDesignation' })
export class DesignationPipe implements PipeTransform {
    constructor(private adminService: AdminService) {}

    transform(array: any[], value: string): any[] {
        array = array.filter(d => {
            return (
                d.value.libelle
                    .toLowerCase()
                    .trim()
                    .includes(value.toLowerCase().trim()) ||
                d.value.code
                    .toLowerCase()
                    .trim()
                    .includes(value.toLowerCase().trim()) ||
                (d.value.toolTipText &&
                    d.value.toolTipText
                        .toLowerCase()
                        .trim()
                        .includes(value.toLowerCase().trim())) ||
                (d.value.formula &&
                    d.value.formula
                        .toLowerCase()
                        .trim()
                        .includes(value.toLowerCase().trim())) ||
                (d.value.authorizedRoles &&
                    d.value.authorizedRoles.find(role => {
                        return (
                            this.changeRoleName(role) &&
                            this.changeRoleName(role)
                                .toLowerCase()
                                .trim()
                                .includes(value.toLowerCase().trim())
                        );
                    }))
            );
        });
        return array;
    }
    changeRoleName = input => {
        return this.adminService.changeRoleName(input);
    };
}
