import { Component, Inject, OnInit } from '@angular/core';
import { StoreService } from 'src/app/_services/store/store.service';
import { RubriqueService } from 'src/app/_services/rubrique/rubrique.service';
import { forkJoin } from 'rxjs';
import { Store } from 'src/app/_models/store';
import { Rubrique } from 'src/app/_models/rubrique';
import { Account } from 'src/app/_models/account';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { capitalize } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AccountService } from '../../../_services/account/account.service';
import { DailyService } from '../../../_services/daily/daily.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
interface CEX {
  value: string;
  viewValue: string;
}

interface Month {
  value: number;
  viewValue: string;
}
@Component({
  selector: 'app-repart-daily',
  templateUrl: './repart-daily.component.html',
  styleUrls: ['./repart-daily.component.css']
})
export class RepartDailyComponent implements OnInit {
  constructor(
    private storeService: StoreService,
    private rubriqueService: RubriqueService,
    private fb: FormBuilder,
    private dailyService: DailyService,
    private toastService: ToastrService,
    public dialog: MatDialog,
    private accountService: AccountService
  ) { }

  stores: {
    hyper: Store[],
    super: Store[],
    ultra: Store[],
  };
  periods: Month[];
  accountsRay: Account[];
  accounts: Account[];
  accountsDrive: Account[];
  entrantForm: FormGroup;

  get hyper(): FormArray {
    return this.entrantForm.get('hyperList') as FormArray;
  };

  get super(): FormArray {
    return this.entrantForm.get('superList') as FormArray;
  };

  get ultra(): FormArray {
    return this.entrantForm.get('ultraList') as FormArray;
  };

  get period(): FormArray {
    return this.entrantForm.get('periodList') as FormArray;
  };

  get rubriqueDrive(): FormArray {
    return this.entrantForm.get('rubriqueDriveList') as FormArray;
  };

  get accountRay(): FormArray {
    return this.entrantForm.get('accountRayList') as FormArray;
  };

  get account(): FormArray {
    return this.entrantForm.get('accountList') as FormArray;
  };

  get accountDrive(): FormArray {
    return this.entrantForm.get('accountDriveList') as FormArray;
  };

  list = ['hyper', 'super', 'ultra'];

  listCEX: CEX[] = [
    { value: 'MAG', viewValue: 'Compte d\'exploitation magasin' },
    { value: 'ESS', viewValue: 'Compte d\'exploitation essence' },
    { value: 'MAR', viewValue: 'Compte d\'exploitation marché' },
    { value: 'DRIVE', viewValue: 'Compte d\'exploitation drive' }
  ];

  monthPeriods: Month[] = [
    { value: 1, viewValue: 'Janvier' },
    { value: 2, viewValue: 'Février' },
    { value: 3, viewValue: 'Mars' },
    { value: 4, viewValue: 'Avril' },
    { value: 5, viewValue: 'Mai' },
    { value: 6, viewValue: 'Juin' },
    { value: 8, viewValue: 'Juillet' },
    { value: 9, viewValue: 'Août' },
    { value: 10, viewValue: 'Septembre' },
    { value: 11, viewValue: 'Octobre' },
    { value: 12, viewValue: 'Novembre' },
    { value: 13, viewValue: 'Décembre' }
  ];

  spinner = false;
  progress = '';


  ngOnInit() {

    this.stores = { hyper: [], super: [], ultra: [] };

    this.entrantForm = this.fb.group({
      hyper: this.fb.control(false),
      super: this.fb.control(false),
      ultra: this.fb.control(false),

      hyperList: this.fb.array([]),
      superList: this.fb.array([]),
      ultraList: this.fb.array([]),

      period: this.fb.control(false),
      periodList: this.fb.array([]),

      account: this.fb.control(false),
      accountList: this.fb.array([]),

      accountRay: this.fb.control(false),
      accountRayList: this.fb.array([]),

      accountDrive: this.fb.control(false),
      accountDriveList: this.fb.array([]),

      repartitionMonth: this.fb.control(1),
    })

    forkJoin(
      this.storeService.getAll(),
          this.accountService.getAccountsByTypes(['magEss']),
            this.accountService.getAccountsByTypes(['MAR']),
              this.accountService.getAccountsByTypes(['DRIVE']),
                this.rubriqueService.getAllByType('DRIVE'),

    ).subscribe(data => {
      this.stores.hyper = data[0].filter(store => store.category === 'HYP').sort((a, b) => a.hyperionId < b.hyperionId ? -1 : 1);
      const hypControls = this.stores.hyper.map(() => this.fb.control(false));
      hypControls.forEach(c => this.hyper.push(c));

      this.stores.super = data[0].filter(store => store.category === 'SUP').sort((a, b) => a.hyperionId < b.hyperionId ? -1 : 1);
      const supControls = this.stores.super.map(() => this.fb.control(false));
      supControls.forEach(c => this.super.push(c));

      this.stores.ultra = data[0].filter(store => store.category === 'ULT').sort((a, b) => a.hyperionId < b.hyperionId ? -1 : 1);
      const ultControls = this.stores.ultra.map(() => this.fb.control(false));
      ultControls.forEach(c => this.ultra.push(c));

      this.periods = this.monthPeriods;
      console.log('*********'+ this.periods[0].viewValue);
      const periodControls = this.periods.map(() => this.fb.control(false));
      periodControls.forEach(c => this.period.push(c));

      this.accounts = data[1];
      const accControls = this.accounts.map(() => this.fb.control(false));
      accControls.forEach(c => this.account.push(c));

      this.accountsRay = data[2];
      const accRayControls = this.accountsRay.map(() => this.fb.control(false));
      accRayControls.forEach(c => this.accountRay.push(c));

      this.accountsDrive = data[3];
      const accDriveControls = this.accountsDrive.map(() => this.fb.control(false));
      accDriveControls.forEach(c => this.accountDrive.push(c));

      this.entrantForm.valueChanges.subscribe(() => {
        this.refreshGlobalValue('hyper');
        this.refreshGlobalValue('super');
        this.refreshGlobalValue('ultra');
        this.refreshGlobalValue('period');
        this.refreshGlobalValue('account');
        this.refreshGlobalValue('accountRay');
        this.refreshGlobalValue('accountDrive');
      })
    })

  }

  refreshGlobalValue = (controlName: string) => {
    const values: boolean[] = this[controlName].value;
    if (values == null) { return }
    if (values.every(v => v === true)) {
      this.entrantForm.get(controlName).setValue(true, { emitEvent: false });
      return
    }
    if (values.every(v => v === false)) {
      this.entrantForm.get(controlName).setValue(false, { emitEvent: false });
      return
    }
    this.entrantForm.get(controlName).setValue(null, { emitEvent: false });
  }

  setGlobalValue = (controlName: string) => {
    const value = this.entrantForm.value[controlName];
    if (value == null || value === false) {
      this[controlName].controls.forEach(c => c.setValue(true, { emitEvent: false }))
    } else if (value === true) {
      this[controlName].controls.forEach(c => c.setValue(false, { emitEvent: false }))
    }
    this.refreshGlobalValue(controlName)
  }

  public capitalized = (text: string) => {
    return capitalize(text);
  }

  public isDisabled = () => {
    const values = this.entrantForm.value
    const predicates = [values.hyper, values.super, values.ultra]
    return predicates.every(p => p === false)
  }

  public getRecapStores = () => {
    const hypers = this.entrantForm.value.hyperList.filter(v => v === true).length
    const supers = this.entrantForm.value.superList.filter(v => v === true).length
    const ultras = this.entrantForm.value.ultraList.filter(v => v === true).length

    return hypers + supers + ultras;
  }

  public getRecapPeriods = () => {
    const periods = this.entrantForm.value.periodList.filter(v => v === true).length
    return periods ;
  }

  public getRecapAccounts = () => {
    const accountsRay = this.entrantForm.value.accountRayList.filter(v => v === true).length
    const accounts = this.entrantForm.value.accountList.filter(v => v === true).length
    const accountsDrive = this.entrantForm.value.accountDriveList.filter(v => v === true).length
    return accountsRay + accounts + accountsDrive;
  }

  public validate = () => {
    const dialogRef = this.dialog.open(DialogContentDailyComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'OK') { return };

      const hypers = this.hyper.value
        .map((h, index) => {
          if (h === true) {
            return this.stores.hyper[index].id
          } else {
            return null;
          }
        })
        .filter(id => id != null)

      const supers = this.super.value
        .map((h, index) => {
          if (h === true) {
            return this.stores.super[index].id
          } else {
            return null;
          }
        })
        .filter(id => id != null)

      const ultras = this.ultra.value
        .map((h, index) => {
          if (h === true) {
            return this.stores.ultra[index].id
          } else {
            return null;
          }
        })
        .filter(id => id != null)

      const storeIdsAll = hypers.concat(supers.concat(ultras));

      const rayonIds = this.accountRay.value
          .map((h, index) => {
            if (h === true) {
              return this.accountsRay[index].rayonId
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      const accountMagEssIds = this.account.value
          .map((h, index) => {
            if (h === true) {
              return this.accounts[index].rayonId
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      const driveIds = this.accountDrive.value
          .map((h, index) => {
            if (h === true) {
              return this.accountsDrive[index].rayonId
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      const periodId = this.entrantForm.value.repartitionMonth

      const allRayonIds = (rayonIds.concat(accountMagEssIds)).concat(driveIds);
      let storeIds = [];

 /*     this.dailyService.repartitionMonthlyToDaily({ storeIds , allRayonIds, periodId}).subscribe(
          () => this.toastService.success(`La répartition mensuel en jour/jour est terminée`, 'Succès'),
          () => this.toastService.error(`Une erreur s'est produite, contacter un administrateur`, 'Erreur')

      )*/

      storeIdsAll.forEach(storeId => {
        storeIds.push(storeId);
        if(storeIds.length >7 ) {
            this.dailyService.repartitionMonthlyToDaily({ storeIds , allRayonIds, periodId}).subscribe(
             data => {
                  setTimeout(() => {
                  }, 5000000);
                }
            )


          storeIds = [];
        }

      })

      if(storeIds.length >0 ) {
        this.dailyService.repartitionMonthlyToDaily({ storeIds , allRayonIds, periodId}).subscribe(
        data => {
              setTimeout(() => {
              }, 5000000);
            }
        )
        storeIds = [];
      }
      this.toastService.success(`La répartition mensuel en jour/jour est programmée, elle peuvent prendre plusieurs minutes selon la volumétrie de la sélection`, 'Succès'),
      this.spinner = true;
      if(storeIdsAll.length>7) {
        setTimeout(() => {
          this.toastService.success(`La répartition mensuel en jour/jour est terminée`, 'Succès');
        }, 100000);
      } else {
        setTimeout(() => {
          this.toastService.success(`La répartition mensuel en jour/jour est terminée`, 'Succès');
        }, 3000);
      }
    });
  }

  getRecap(type: string) {
    if (type === 'MAG' ){
      return (this.entrantForm.value.accountRayList.filter(v => v === true).length
          + this.entrantForm.value.accountList.filter(v => v === true).length) === 0;
    }else {
      return this.entrantForm.value.accountDriveList.filter(v => v === true).length === 0;
    }
  }

  public getRecapItems = (type) => {
    //const hypers = this.entrantForm.value.hyperList.filter(v => v === true).length
    //const supers = this.entrantForm.value.superList.filter(v => v === true).length
    //const ultras = this.entrantForm.value.ultraList.filter(v => v === true).length

    if (type === 'store') {
      const hypers = this.hyper.value
          .map((h, index) => {
            if (h === true) {
              return this.stores.hyper[index].id_transco + ' ' + this.stores.hyper[index].name
            } else {
              return null;
            }
          })
          .filter(id => id != null);

      const supers = this.super.value
          .map((h, index) => {
            if (h === true) {
              return this.stores.super[index].id_transco + ' ' + this.stores.super[index].name
            } else {
              return null;
            }
          })
          .filter(id => id != null);

      const ultras = this.ultra.value
          .map((h, index) => {
            if (h === true) {
              return this.stores.ultra[index].id_transco + ' ' + this.stores.ultra[index].name
            } else {
              return null;
            }
          })
          .filter(id => id != null);

      return hypers.concat(supers.concat(ultras));

    } else if (type === 'account') {

      const rayonIds = this.accountRay.value
          .map((h, index) => {
            if (h === true) {
              return this.accountsRay[index].rayonId + ' ' + this.accountsRay[index].name
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      const accountMagEssIds = this.account.value
          .map((h, index) => {
            if (h === true) {
              return this.accounts[index].rayonId + ' ' + this.accounts[index].name
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      const driveIds = this.accountDrive.value
          .map((h, index) => {
            if (h === true) {
              return this.accountsDrive[index].rayonId + ' ' + this.accountsDrive[index].name
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      return rayonIds.concat(accountMagEssIds.concat(driveIds));
    }
  }
  openDialog(type) {

    this.dialog.open(DialogDataInfoDailyDialog, {
      data: this.getRecapItems(type)
    });
  }

}

@Component({
  selector: 'dialog-content-daily',
  template: `
  <mat-card>
  <mat-card-header>
    <div mat-card-avatar><mat-icon>warning</mat-icon></div>
    <mat-card-title>Confirmation</mat-card-title>
    <mat-card-subtitle>Risque d'écrasement</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
      Cette action provoque l'écrasement des données sélectionnées:
    </p>
    <p>
      Etes-vous sûr de vouloir continuer ?
    </p>
  </mat-card-content>
  <mat-card-actions style="display: flex; justify-content: space-evenly">
    <button mat-raised-button color="warn" mat-dialog-close>Annuler</button>
    <button mat-raised-button (click)="this.ref.close('OK')">Confirmer</button>
  </mat-card-actions>
</mat-card>
  `,
})
export class DialogContentDailyComponent {
  constructor(public ref: MatDialogRef<DialogContentDailyComponent>) { }
}


@Component({
  selector: 'dialog-content',
  template: `
    <mat-card >
      <mat-card-content style="height: 400px ; overflow-x : auto">
        <ul>
          <li *ngFor="let item of data"> {{item}} </li>
        </ul>
      </mat-card-content>
      <mat-card-actions style="display: flex; justify-content: space-evenly">
        <button mat-raised-button color="warn" mat-dialog-close>Fermer</button>
      </mat-card-actions>
    </mat-card>
  `,
})
export class DialogDataInfoDailyDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data : []) {}
}
