import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pattern } from 'src/app/_models/pattern';



@Component({
    selector: 'app-designations-controller-logistic',
    templateUrl: './designations-controller-logistic.component.html',
    styleUrls: ['./designations-controller-logistic.component.css'],
})
export class DesignationsControllerLogisticComponent implements OnInit {
    /** Sélecteur permettant d'afficher les tableaux des périodes journalières, mensuelles ou annuelles */
    @Input('tabSelected') tabSelected: string;
    /** Liste des designations */
    @Input() motif: Pattern[][];
    /** La ligne selectionnée */
    @Input('lineSelected') lineSelected: string;

    /** Event pour indiquer la ligne selectionnée */
    @Output() newLineSelected: EventEmitter<string> = new EventEmitter();
    /** Event pour indiquer les element à cacher */
    @Output() hiddenList: EventEmitter<string[]> = new EventEmitter();
    /** tabSelector */
    @Output() tabSelector: EventEmitter<string> = new EventEmitter();

    hidden: string[];

    constructor() { }

    ngOnInit() {
        this.hidden = [];
    }

    isHidden(designation: Pattern): boolean {
        if (designation.parentCode == null || this.hidden.length <= 0) {
            return false;
        }
        if (this.hidden.some(code => code === designation.parentCode)) {
            return true;
        }
    }

    setHide(code: string) {
        if (this.isParentHide(code)) {
            const i = this.hidden.findIndex(cd => cd === code);
            this.hidden.splice(i, 1);
        } else {
            this.hidden.push(code);
        }
        this.hiddenList.emit(this.hidden);
    }

    setAllHidden(isTrue: boolean) {
        this.hidden = [];
        if (isTrue) {
            this.motif.forEach(group => {
                group.forEach(des => {
                    if (des.parentCode === '0') {
                        this.hidden.push(des.rubrique.code);
                    }
                });
            });
        }
        this.hiddenList.emit(this.hidden);
    }

    isParentHide(code: string): boolean {
        return this.hidden.findIndex(cd => cd === code) > -1;
    }

    fireNewLineSelected(designationCode) {
        this.lineSelected = this.lineSelected !== designationCode ? designationCode : '';
        this.newLineSelected.emit(designationCode);
    }

    isAccentLine(code) {
        return code === 'ECO056' || code === 'ECOX19';
    }

    changeSelector(type: string) {
        if (this.tabSelected !== type) {
            this.tabSelected = type;
            this.tabSelector.emit(this.tabSelected);
        }
    }

    hasChildren = (code: string) => {
        return this.motif.some(g => {
            return g.some(p => {
                return p.parentCode === code;
            })
        })
    }
}
