import { Injectable } from '@angular/core';
import { EntryLog } from '../../components/logistic/_models/entry-log';
import { forkJoin, merge, Observable } from 'rxjs';
import { EntryService } from '../entry/entry.service';
import { Entry } from '../../_models/entry';
import { DailyService } from '../daily/daily.service';
import { EntryJourna } from '../../_models/entryJourna';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { CONFIG_KEY, TITLE } from 'src/app/constants';
import { CoefficientService } from '../coefficient/coefficient.service';
import { Coefficient } from '../../_models/coefficient';
import { ReferenceService } from '../anneeReference/reference.service';



@Injectable({
    providedIn: 'root',
})
export class DistributionService {

    constructor(
        private entryService: EntryService,
        private dailyService: DailyService,
        private authenticationService: AuthenticationService,
        private referenceService: ReferenceService,
    ) { }

    private dispatchAnnualEntry(
        entry: Entry,
        account,
        periods: any[],
        accountId: number,
        titles,
        designations
    ): Observable<any> {
        const entries = [];
        const periodValue = Math.round((entry.value / periods.length) * 100) / 100;
        periods.forEach(period => {
            const periodEntry = period.entryMatrix[entry.designation.code][entry.title.code];

            periodEntry.value = periodValue;
            const newEntry = {
                titleId: titles.find(t => t.code === entry.title.code).id,
                periodId: period.id,
                accountId,
                designationId: designations.find(d => d.code === entry.designation.code).id,
                value: periodValue,
                yearId: 3,
                role: this.authenticationService.getCurrentUser().role,
                byUser: this.authenticationService.getCurrentUser().login,
            };
            entries.push(newEntry);
        });
        return this.entryService.saveAll(entries);
    }

    dispatchAnnualEntry14(
        entry: EntryLog,
        account,
        periods: any[],
        accountId: number,
        titles,
        designations,
        year,
        MV_PILOT_CODE,
        VA_PILOT_CODE
    ): Observable<any> {
        periods = periods.filter(
            period => period.code !== 'CUM6' && period.code !== 'CUM12' && period.code !== 'DELTA'
        );
        // remove locked periods and their result
        const entryCopy = JSON.parse(JSON.stringify(entry));
        const lockedPeriods = periods.filter(period => { return period.isLocked || period.locks.some(l => titles.find(t => t.id === l.titleId).code === entry.title.code); }
        );
        const lockedValue = this.calculateSumEntries(entry.title.code, entry.designation.code, lockedPeriods);
        entryCopy.value = entry.value - lockedValue;
        periods = periods.filter(period => { return !(period.isLocked || period.locks.some(l => titles.find(t => t.id === l.titleId).code === entry.title.code)); }
        );
        return this.dispatchAnnualEntry(entryCopy, account, periods, accountId, titles, designations);
    }

    dispatchAnnualEntry12(
        entry: Entry,
        account,
        periods: any[],
        accountId: number,
        titles,
        designations,
        year,
        MV_PILOT_CODE,
        VA_PILOT_CODE
    ): Observable<any> {
        periods = periods.filter(
            period =>
                period.code !== 'SUPP06' &&
                period.code !== 'SUPP12' &&
                period.code !== 'CUM6' &&
                period.code !== 'CUM12' &&
                period.code !== 'DELTA'
        );
        // remove locked periods and their result
        const entryCopy = JSON.parse(JSON.stringify(entry));
        // add period locked by locks to lockedperiods

        const lockedPeriods = periods.filter(period => { return period.locks.some(l => titles.find(t => t.id === l.titleId).code === entry.title.code && l.isLocked) ; });
        // Filter via locks
        const lockedValue = this.calculateSumEntries(entry.title.code, entry.designation.code, lockedPeriods);
        console.log("lockedPeriods : "+ lockedPeriods.length)
        entryCopy.value = entry.value - lockedValue;
        // remove periods locked by locks in periods
        //periods = periods.filter(period => { return (period.locks.some(l => titles.find(t => t.id === l.titleId).code === entry.title.code && !l.isLocked )); });
        console.log("periods : "+ periods.length)


        const supp6Entry = {
            titleId: titles.find(t => t.code === entry.title.code).id,
            periodId: 7, //SUPP06
            accountId,
            designationId: designations.find(d => d.code === entry.designation.code).id,
            value: 0,
            yearId: 3,
            role: this.authenticationService.getCurrentUser().role,
        };
        const supp12Entry = {
            titleId: titles.find(t => t.code === entry.title.code).id,
            periodId: 14, //SUPP12
            accountId,
            designationId: designations.find(d => d.code === entry.designation.code).id,
            value: 0,
            yearId: 3,
            role: this.authenticationService.getCurrentUser().role,
            byUser: this.authenticationService.getCurrentUser().login,
        };

        const suppsResult = this.entryService.saveAll([supp6Entry, supp12Entry]);
        const mensuelResult = this.dispatchAnnualEntry(entryCopy, account, periods, accountId, titles, designations);
        return merge(suppsResult, mensuelResult);
    }

    /**
     * Distribue la valeur annuelle entre les différents mois en fonction du poids de la valeur de l'estimé
     * @param entry
     * @param periods
     * @param accountId
     * @param titles
     * @param designations
     */
    dispatchAnnualEntryLastWeight(
        entry: EntryLog,
        account,
        periods: any[],
        accountId: number,
        titles,
        designations,
        coefficients: any[],
        referencesYear: any[],
        year,
        MV_PILOT_CODE,
        VA_PILOT_CODE
    ): Observable<any> {
        const entries = [];
        let yearReference = [];
        let titleWitness = referencesYear.find(e => e.category === account.type).code;
        let codeTitle;
        // remove locked periods and their result
        periods = periods.filter(
            period => period.code !== 'CUM6' && period.code !== 'CUM12' && period.code !== 'DELTA'
        );
        const lockedPeriods = periods.filter(period => { return period.locks.some(l => titles.find(t => t.id === l.titleId).code === entry.title.code && l.isLocked) ; });
        const lockedValue = this.calculateSumEntries(entry.title.code, entry.designation.code, lockedPeriods);
        let entryValue = entry.value - lockedValue;
        //periods = periods.filter(period => { return (period.locks.some(l => titles.find(t => t.id === l.titleId).code === entry.title.code && !l.isLocked )); });
        const sumEntries = this.calculateSumEntries(titleWitness, entry.designation.code, periods);
        let sumPeriodEntry = 0;
        periods.forEach(period => {
            let periodEntry = period.entryMatrix[entry.designation.code][entry.title.code];
            let weightEntry = period.entryMatrix[entry.designation.code][titleWitness];
            let weightDecimalEntry = ( weightEntry.value / sumEntries );
            let coefficientId = designations.find(d => d.code === entry.designation.code).coefficientId;
            let coefficientValue = this.getCoefficientValueByIdAndPeriod(coefficients,coefficientId,period.code);
            if (periodEntry && periodEntry.id !== null && (!weightEntry || weightEntry.id === null)) {
                // TODO: Breaking risk here
                periodEntry.value = 0;
                entries.push({ id: periodEntry.id, value: periodEntry.value });
            } else {
                periodEntry = {
                    titleId: titles.find(t => t.code === entry.title.code).id,
                    periodId: period.id,
                    accountId,
                    designationId: designations.find(d => d.code === entry.designation.code).id,
                    yearId: 3,
                    role: this.authenticationService.getCurrentUser().role,
                    byUser: this.authenticationService.getCurrentUser().login,
                };
                periodEntry.value = (Number(weightDecimalEntry) * Number(coefficientValue)) * Number(entryValue);
                console.log("(Number(weightDecimalEntry) * Number(coefficientValue)) => " + Number(weightDecimalEntry) * Number(coefficientValue) );
                sumPeriodEntry += periodEntry.value;
                //periodEntry.value = ((Number(weightEntry.value) / sumEntries) * Number(entryValue)).toFixed(2);
                entries.push(periodEntry);
            }
        });

        const coefficientDerive = Number(entryValue) / Number(sumPeriodEntry);
        entries.forEach(
            entry => {
                entry.value = (Number(entry.value) * Number(coefficientDerive));
            }
        );
        return this.entryService.saveAll(entries);
    }

    private calculateSumEntries(titleCode, designationCode, periods: any[]): number {
        let sumEntries = 0;
        periods.forEach(period => {
            const periodEntry = period.entryMatrix[designationCode][titleCode];
            console.log("Period : "+ period.code + " - valeur : " + periodEntry.value + " - Title : " + titleCode + " - DesignationCode : " + designationCode);
            if (periodEntry) {
                sumEntries += Number(periodEntry.value);
            }
        });
        return sumEntries;
    }

    /**
     * Repartition annuelle pilot
     * @param entry
     * @param account
     * @param periods
     * @param accountId
     * @param titles
     * @param designations
     */
    dispatchAnnualEntryPilot(
        entry: Entry,
        account,
        periods: any[],
        accountId: number,
        titles,
        designations,
        year,
        MV_PILOT_CODE,
        VA_PILOT_CODE
    ): Observable<any> {
        periods = periods.filter(
            period =>
                period.code !== 'SUPP06' &&
                period.code !== 'SUPP12' &&
                period.code !== 'CUM6' &&
                period.code !== 'CUM12' &&
                period.code !== 'DELTA'
        );
        // remove locked periods and their result
        const lockedPeriods = periods.filter(period => period.isPilotLocked);
        const lockedValue = this.calculateSumEntries(entry.title.code, entry.designation.code, lockedPeriods);
        let entryValue = entry.value - lockedValue;
        periods = periods.filter(period => !period.isPilotLocked);
        const pilotEntries = [];
        const periodValue = Math.round((entryValue / periods.length) * 100) / 100;
        const observables = [];
        periods.forEach(period => {
            const deltaDailyEntry: EntryJourna = {
                annee: year + 1,
                mois: this.getMonthOfPeriodById(account, period.id),
                jour: 0,
                accountId: account.id,
            };
            if (entry.designation.code === VA_PILOT_CODE) {
                deltaDailyEntry.volumeAffaire = periodValue;
            } else if (entry.designation.code === MV_PILOT_CODE) {
                deltaDailyEntry.margeVente = periodValue;
            }
            observables.push(
                this.dailyService.getAllByAccountAndPeriod(
                    account.id,
                    this.getMonthOfPeriodById(account, period.id),
                    year + 1
                )
            );
            pilotEntries.push(deltaDailyEntry);
        });
        return forkJoin(observables).pipe(
            switchMap(results => {
                results.forEach((result, index) => {
                    const calendarDays = result as any[];
                    let sum = 0;
                    calendarDays.forEach(calendarDay => {
                        if (calendarDay.presentYearEntry) {
                            if (entry.designation.code === VA_PILOT_CODE) {
                                sum += calendarDay.presentYearEntry.volumeAffaire;
                            } else {
                                sum += calendarDay.presentYearEntry.margeVente;
                            }
                        }
                    });
                    if (entry.designation.code === VA_PILOT_CODE) {
                        pilotEntries[index].volumeAffaire -= sum;
                    } else if (entry.designation.code === MV_PILOT_CODE) {
                        pilotEntries[index].margeVente -= sum;
                    }
                });
                return this.dailyService.saveAll(pilotEntries);
            })
        );
    }

    /**
     * Distribue la valeur annuelle entre les différents mois en fonction du poids de la valeur de l'estimé pour pilot
     * @param entry
     * @param periods
     * @param accountId
     * @param titles
     * @param designations
     */
    dispatchAnnualEntryLastWeightPilot(
        entry: EntryLog,
        account,
        periods: any[],
        accountId: number,
        titles,
        designations,
        year,
        MV_PILOT_CODE,
        VA_PILOT_CODE
    ): Observable<any> {
        periods = periods.filter(
            period =>
                period.code !== 'SUPP06' &&
                period.code !== 'SUPP12' &&
                period.code !== 'CUM6' &&
                period.code !== 'CUM12' &&
                period.code !== 'DELTA'
        );

        const pilotEntries = [];
        const observables = [];
        let titleWitness;
        // remove locked periods and their result
        periods = periods.filter(
            period => period.code !== 'CUM6' && period.code !== 'CUM12' && period.code !== 'DELTA'
        );
        const lockedPeriods = periods.filter(period => period.isPilotLocked);
        const lockedValue = this.calculateSumEntries(entry.title.code, entry.designation.code, lockedPeriods);
        let entryValue = entry.value - lockedValue;
        periods = periods.filter(period => !period.isPilotLocked);
        titleWitness = 'REAL';
        const sumEntries = this.calculateSumEntries(titleWitness, entry.designation.code, periods);
        periods.forEach(period => {
            let weightEntry = period.entryMatrix[entry.designation.code][titleWitness];
            if (weightEntry && weightEntry.value) {
                const deltaDailyEntry: EntryJourna = {
                    annee: year + 1,
                    mois: this.getMonthOfPeriodById(account, period.id),
                    jour: 0,
                    accountId: account.id,
                };
                if (entry.designation.code === VA_PILOT_CODE) {
                    deltaDailyEntry.volumeAffaire = (Number(weightEntry.value) / sumEntries) * Number(entryValue);
                } else if (entry.designation.code === MV_PILOT_CODE) {
                    deltaDailyEntry.margeVente = (Number(weightEntry.value) / sumEntries) * Number(entryValue);
                }
                observables.push(
                    this.dailyService.getAllByAccountAndPeriod(
                        account.id,
                        this.getMonthOfPeriodById(account, period.id),
                        year + 1
                    )
                );
                pilotEntries.push(deltaDailyEntry);
            } else {
                const deltaDailyEntry: EntryJourna = {
                    annee: year + 1,
                    mois: this.getMonthOfPeriodById(account, period.id),
                    jour: 0,
                    accountId: account.id,
                };
                if (entry.designation.code === VA_PILOT_CODE) {
                    deltaDailyEntry.volumeAffaire = 0;
                } else if (entry.designation.code === MV_PILOT_CODE) {
                    deltaDailyEntry.margeVente = 0;
                }
                observables.push(
                    this.dailyService.getAllByAccountAndPeriod(
                        account.id,
                        this.getMonthOfPeriodById(account, period.id),
                        year + 1
                    )
                );
                pilotEntries.push(deltaDailyEntry);
            }
        });
        return forkJoin(observables).pipe(
            switchMap(results => {
                results.forEach((result, index) => {
                    const calendarDays = result as any[];
                    let sum = 0;
                    calendarDays.forEach(calendarDay => {
                        if (calendarDay.presentYearEntry) {
                            if (entry.designation.code === VA_PILOT_CODE) {
                                sum += calendarDay.presentYearEntry.volumeAffaire;
                            } else {
                                sum += calendarDay.presentYearEntry.margeVente;
                            }
                        }
                    });
                    if (entry.designation.code === VA_PILOT_CODE) {
                        pilotEntries[index].volumeAffaire -= sum;
                    } else if (entry.designation.code === MV_PILOT_CODE) {
                        pilotEntries[index].margeVente -= sum;
                    }
                });
                return this.dailyService.saveAll(pilotEntries);
            })
        );
    }

    getMonthOfPeriodById(account, periodId): number {
        const periods = account.periods.filter(period => period.code.startsWith('M'));
        return periods.findIndex(period => period.id === periodId) + 1;
    }

    getCoefficientValueByIdAndPeriod(coefficients, coefficientId, periodValue) {
        switch (periodValue) {
            case 'M01':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].jan;
            case 'M02':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].fev;
            case 'M03':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].mar;
            case 'M04':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].avr;
            case 'M05':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].mai;
            case 'M06':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].jui;
            case 'SUPP06':
                return 1;
            case 'M07':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].jul;
            case 'M08':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].aou;
            case 'M09':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].sep;
            case 'M10':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].oct;
            case 'M11':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].nov;
            case 'M12':
                return coefficients.filter(coefficient => coefficient.id === coefficientId)[0].dec;
            case 'SUPP12':
                return 1;
        }
    }
}
