import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EntryLog } from '../../components/logistic/_models/entry-log';

@Injectable({
    providedIn: 'root',
})
export class EntryLogService {
    url: string = environment.OP_API_URI + '/entriesLog';

    constructor(private http: HttpClient) { }

    public createEntryLog(entryLog: EntryLog): Observable<any> {
        return this.http.post(this.url, entryLog);
    }

    public updateEntryLog(id: number, value: string): Observable<any> {
        const body = {
            value,
        };
        return this.http.put(this.url + '/' + id, body);
    }

    public createOrUpdateEntryLog(create: any[], update: any[]): Observable<any> {
        const body = {
            create,
            update,
        };
        return this.http.post(this.url + '/createOrUpdate/', body);
    }

    public createOrUpdateEntryLogV2(entries: EntryLog[]): Observable<any> {
        return this.http.post(this.url + '/createOrUpdateV2/', entries);
    }
}
