import { Component, Inject, OnInit } from '@angular/core';
import { StoreService } from 'src/app/_services/store/store.service';
import { RubriqueService } from 'src/app/_services/rubrique/rubrique.service';
import { forkJoin } from 'rxjs';
import { Store } from 'src/app/_models/store';
import { Rubrique } from 'src/app/_models/rubrique';
import { Account } from 'src/app/_models/account';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { capitalize } from 'lodash';
import { EntryService } from 'src/app/_services/entry/entry.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AccountService } from '../../../_services/account/account.service';
import { PeriodService } from '../../../_services/period/period.service';
import { Period } from '../../../_models/period';
import { DialogDataInfoDailyDialog } from '../repart-daily/repart-daily.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
interface CEX {
  value: string;
  viewValue: string;
}

interface Periods {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-lock-cex',
  templateUrl: './lock-cex.component.html',
  styleUrls: ['./lock-cex.component.css']
})
export class LockCexComponent implements OnInit {
  constructor(
    private storeService: StoreService,
    private rubriqueService: RubriqueService,
    private fb: FormBuilder,
    private entryService: EntryService,
    private toastService: ToastrService,
    public dialog: MatDialog,
    private accountService: AccountService,
    private periodService: PeriodService
  ) { }

 stores: {
    hyper: Store[],
    super: Store[],
    ultra: Store[],
    otherType: Store[],
  };
  rubriques: Rubrique[];
  rubriquesDrive: Rubrique[]
  accountsRay: Account[];
  accounts: Account[];
  accountsDrive: Account[];
  entrantForm: FormGroup;
  periods : Period[];
  accountsOther: Account[];

  get hyper(): FormArray {
    return this.entrantForm.get('hyperList') as FormArray;
  };

  get super(): FormArray {
    return this.entrantForm.get('superList') as FormArray;
  };

  get ultra(): FormArray {
    return this.entrantForm.get('ultraList') as FormArray;
  };

    get otherType(): FormArray {
        return this.entrantForm.get('otherTypeList') as FormArray;
    };

  get rubrique(): FormArray {
    return this.entrantForm.get('rubriqueList') as FormArray;
  };

  get rubriqueDrive(): FormArray {
    return this.entrantForm.get('rubriqueDriveList') as FormArray;
  };

  get accountRay(): FormArray {
    return this.entrantForm.get('accountRayList') as FormArray;
  };

  get account(): FormArray {
    return this.entrantForm.get('accountList') as FormArray;
  };

  get accountDrive(): FormArray {
    return this.entrantForm.get('accountDriveList') as FormArray;
  };

  get period(): FormArray {
    return this.entrantForm.get('periodList') as FormArray;
  };
    get accountOherType(): FormArray {
        return this.entrantForm.get('accountOtherList') as FormArray;
    };
  list = ['hyper', 'super', 'ultra'];

  listCEX: CEX[] = [
    { value: 'MAG', viewValue: 'Compte d\'exploitation magasin' },
    { value: 'ESS', viewValue: 'Compte d\'exploitation essence' },
    { value: 'MAR', viewValue: 'Compte d\'exploitation marché' },
    { value: 'DRIVE', viewValue: 'Compte d\'exploitation drive' },
  ];

  listPeriod : Periods[] = [

      { value: '1', viewValue: 'Janvier' },
      { value: '2', viewValue: 'Février' },
      { value: '3', viewValue: 'Mars' },
      { value: '4', viewValue: 'Avril' },
      { value: '5', viewValue: 'Mai' },
      { value: '6', viewValue: 'Juin' },
      { value: '7', viewValue: 'SUPP6' },
      { value: '8', viewValue: 'Juillet' },
      { value: '9', viewValue: 'Août' },
      { value: '10', viewValue: 'Septembre' },
      { value: '11', viewValue: 'Octobre' },
      { value: '12', viewValue: 'Novembre' },
      { value: '13', viewValue: 'Décembre' },
      { value: '14', viewValue: 'SUPP12' },
      { value: '15', viewValue: 'Annual' },

    ];

  spinner = false;
  progress = '';
  selectedValue: string;
  clotureCEX: CEX[] = [
        { value: 'MAG', viewValue: 'Compte d\'exploitation magasin' },
        { value: 'SAV', viewValue: 'Compte d\'exploitation SAV' },
        { value: 'ARA', viewValue: 'Compte d\'exploitation ARA' },
        { value: 'ENER', viewValue: 'Compte d\'exploitation ENER' },
        { value: 'AUCHFR', viewValue: 'Compte d\'exploitation AUCHFR' },
        { value: 'AUCHPRO', viewValue: 'Compte d\'exploitation AUCHPRO' },
        { value: 'DIR', viewValue: 'Compte d\'exploitation DIR' },
        { value: 'APP', viewValue: 'Compte d\'exploitation APP' },
        { value: 'FRN', viewValue: 'Compte d\'exploitation FRN' }
    ];

  ngOnInit() {

    this.stores = { hyper: [], super: [], ultra: [], otherType: []};

    this.entrantForm = this.fb.group({
      hyper: this.fb.control(false),
      super: this.fb.control(false),
      ultra: this.fb.control(false),

      hyperList: this.fb.array([]),
      superList: this.fb.array([]),
      ultraList: this.fb.array([]),
      rubrique: this.fb.control(false),
      rubriqueList: this.fb.array([]),

      rubriqueDrive: this.fb.control(false),
      rubriqueDriveList: this.fb.array([]),

      account: this.fb.control(false),
      accountList: this.fb.array([]),

      accountRay: this.fb.control(false),
      accountRayList: this.fb.array([]),

      accountDrive: this.fb.control(false),
      accountDriveList: this.fb.array([]),

      period: this.fb.control(false),
      periodList: this.fb.array([]),

      repartition: this.fb.control('REP_POIDS'),

      column: this.fb.control('4'),

      cloture: this.fb.control('0'),
      type: this.fb.control('MAG'),

    })

    forkJoin(
      this.storeService.getAll(),
        this.rubriqueService.getAllByType('MAG'),
          this.accountService.getAccountsByTypes(['magEss']),
            this.accountService.getAccountsByTypes(['MAR']),
              this.accountService.getAccountsByTypes(['DRIVE']),
                this.rubriqueService.getAllByType('DRIVE'),
                  this.accountService.getPeriodsExcluded(),
    ).subscribe(data => {
      this.stores.hyper = data[0].filter(store => store.category === 'HYP').sort((a, b) => a.hyperionId < b.hyperionId ? -1 : 1);
      const hypControls = this.stores.hyper.map(() => this.fb.control(false));
      hypControls.forEach(c => this.hyper.push(c));


      this.stores.super = data[0].filter(store => store.category === 'SUP').sort((a, b) => a.hyperionId < b.hyperionId ? -1 : 1);
      const supControls = this.stores.super.map(() => this.fb.control(false));
      supControls.forEach(c => this.super.push(c));

      this.stores.ultra = data[0].filter(store => store.category === 'ULT').sort((a, b) => a.hyperionId < b.hyperionId ? -1 : 1);
      const ultControls = this.stores.ultra.map(() => this.fb.control(false));
      ultControls.forEach(c => this.ultra.push(c));

      this.rubriques = data[1].filter(r => !r.calculated);
      const rubControls = this.rubriques.map(() => this.fb.control(false));
      rubControls.forEach(c => this.rubrique.push(c));

      this.accounts = data[2];
      const accControls = this.accounts.map(() => this.fb.control(false));
      accControls.forEach(c => this.account.push(c));

      this.accountsRay = data[3];
      const accRayControls = this.accountsRay.map(() => this.fb.control(false));
      accRayControls.forEach(c => this.accountRay.push(c));

      this.accountsDrive = data[4];
      const accDriveControls = this.accountsDrive.map(() => this.fb.control(false));
      accDriveControls.forEach(c => this.accountDrive.push(c));

      this.rubriquesDrive = data[5].filter(r => !r.calculated);
      const rubDriveControls = this.rubriquesDrive.map(() => this.fb.control(false));
      rubDriveControls.forEach(c => this.rubriqueDrive.push(c));

      this.periods = data[6].filter(r => !r.calculated);
      const periodControls = this.periods.map(() => this.fb.control(false));
      periodControls.forEach(c => this.period.push(c));

      this.entrantForm.valueChanges.subscribe(() => {
        this.refreshGlobalValue('hyper');
        this.refreshGlobalValue('super');
        this.refreshGlobalValue('ultra');
        this.refreshGlobalValue('rubrique');
        this.refreshGlobalValue('account');
        this.refreshGlobalValue('accountRay');
        this.refreshGlobalValue('accountDrive');
        this.refreshGlobalValue('rubriqueDrive');
        this.refreshGlobalValue('period');
      })
    })

  }

  refreshGlobalValue = (controlName: string) => {
    const values: boolean[] = this[controlName].value;
    if (values == null) { return }
    if (values.every(v => v === true)) {
      this.entrantForm.get(controlName).setValue(true, { emitEvent: false });
      return
    }
    if (values.every(v => v === false)) {
      this.entrantForm.get(controlName).setValue(false, { emitEvent: false });
      return
    }
    this.entrantForm.get(controlName).setValue(null, { emitEvent: false });
  }

  setGlobalValue = (controlName: string) => {
    const value = this.entrantForm.value[controlName];
    if (value == null || value === false) {
      this[controlName].controls.forEach(c => c.setValue(true, { emitEvent: false }))
    } else if (value === true) {
      this[controlName].controls.forEach(c => c.setValue(false, { emitEvent: false }))
    }
    this.refreshGlobalValue(controlName)
  }

  public capitalized = (text: string) => {

      if(this.entrantForm.value.type === 'MAG') {
          return capitalize(text);
      }
      return this.entrantForm.value.type

  }

  public isDisabled = () => {
    const values = this.entrantForm.value
      let predicates = null
      if(this.entrantForm.value.type === 'MAG') {
           predicates = [values.hyper, values.super, values.ultra]
      } else {
           predicates = [values.otherType]
      }
    return predicates.every(p => p === false)
  }

  public getRecapStores = () => {
    const hypers = this.entrantForm.value.hyperList.filter(v => v === true).length
    const supers = this.entrantForm.value.superList.filter(v => v === true).length
    const ultras = this.entrantForm.value.ultraList.filter(v => v === true).length

    return hypers + supers + ultras;
  }


  public getRecapAccounts = () => {
    const accountsRay = this.entrantForm.value.accountRayList.filter(v => v === true).length
    const accounts = this.entrantForm.value.accountList.filter(v => v === true).length
    const accountsDrive = this.entrantForm.value.accountDriveList.filter(v => v === true).length
    return accountsRay + accounts + accountsDrive;
  }

  public getRecapPeriods = () => {
    const periods = this.entrantForm.value.periodList.filter(v => v === true).length
    return periods;
  }

  public validate = () => {
    const dialogRef = this.dialog.open(DialogContentLockComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'OK') { return };

      const hypers = this.hyper.value
        .map((h, index) => {
          if (h === true) {
            return this.stores.hyper[index].id
          } else {
            return null;
          }
        })
        .filter(id => id != null)

      const supers = this.super.value
        .map((h, index) => {
          if (h === true) {
            return this.stores.super[index].id
          } else {
            return null;
          }
        })
        .filter(id => id != null)

      const ultras = this.ultra.value
        .map((h, index) => {
          if (h === true) {
            return this.stores.ultra[index].id
          } else {
            return null;
          }
        })
        .filter(id => id != null)



      const rayonIds = this.accountRay.value
          .map((h, index) => {
            if (h === true) {
              return this.accountsRay[index].rayonId
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      const accountMagEssIds = this.account.value
          .map((h, index) => {
            if (h === true) {
              return this.accounts[index].rayonId
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      const driveIds = this.accountDrive.value
          .map((h, index) => {
            if (h === true) {
              return this.accountsDrive[index].rayonId
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      const periodIds = this.period.value
          .map((h, index) => {
            if (h === true) {
              return this.periods[index].id
            } else {
              return null;
            }
          })
          .filter(id => id != null)

        let othersStores
        let accountOthersType
        if(this.entrantForm.value.type !== 'MAG') {

             othersStores = this.otherType.value
                .map((h, index) => {
                    if (h === true) {
                        return this.stores.otherType[index].id
                    } else {
                        return null;
                    }
                })
                .filter(id => id != null)

             accountOthersType = this.accountOherType.value
                .map((h, index) => {
                    if (h === true) {
                        return this.accountsOther[index].rayonId
                    } else {
                        return null;
                    }
                })
                .filter(id => id != null)
        }


        const storeIds = this.entrantForm.value.type !== 'MAG' ? othersStores : hypers.concat(supers.concat(ultras));
        const allRayonIds = this.entrantForm.value.type !== 'MAG' ?  accountOthersType : (rayonIds.concat(accountMagEssIds)).concat(driveIds)

      const repartition = this.entrantForm.value.repartition

      const title = this.entrantForm.value.column

      const cloture = this.entrantForm.value.cloture

      this.toastService.success(`L'opération est en cours`, 'Succès');

      this.entryService.lockCEX({ storeIds, allRayonIds, periodIds , title, cloture}).subscribe(
        () => {this.toastService.clear();this.toastService.success(`L'opération est terminée`, 'Succès')},
        () => this.toastService.error(`Une erreur s'est produite, contacter un administrateur`, 'Erreur')
      )

      this.spinner = true;
    });
  }

  getRecap(type: string) {
    if (type === 'MAG' ){
      return (this.entrantForm.value.accountRayList.filter(v => v === true).length
          + this.entrantForm.value.accountList.filter(v => v === true).length) === 0;
    }else {
      return this.entrantForm.value.accountDriveList.filter(v => v === true).length === 0;
    }
  }

  public getRecapItems = (type) => {

    if (type === 'store') {
      const hypers = this.hyper.value
          .map((h, index) => {
            if (h === true) {
              return this.stores.hyper[index].id_transco + ' - ' + this.stores.hyper[index].name + ' (Hyper)'
            } else {
              return null;
            }
          })
          .filter(id => id != null);

      const supers = this.super.value
          .map((h, index) => {
            if (h === true) {
              return this.stores.super[index].id_transco + ' - ' + this.stores.super[index].name + ' (Super)'
            } else {
              return null;
            }
          })
          .filter(id => id != null);

      const ultras = this.ultra.value
          .map((h, index) => {
            if (h === true) {
              return this.stores.ultra[index].id_transco + ' - ' + this.stores.ultra[index].name + ' (Ultra)'
            } else {
              return null;
            }
          })
          .filter(id => id != null);
        if(this.entrantForm.value.type !== 'MAG') {
            const others = this.otherType.value
                .map((h, index) => {
                    if (h === true) {
                        return this.stores.otherType[index].id_transco + ' - ' + this.stores.otherType[index].name
                    } else {
                        return null;
                    }
                })
                .filter(id => id != null);
            return others;
        }

      return hypers.concat(supers.concat(ultras));

    } else if (type === 'account') {

      const rayonIds = this.accountRay.value
          .map((h, index) => {
            if (h === true) {
              return this.accountsRay[index].rayonId + ' - ' + this.accountsRay[index].name
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      const accountMagEssIds = this.account.value
          .map((h, index) => {
            if (h === true) {
              return this.accounts[index].rayonId + ' - ' + this.accounts[index].name
            } else {
              return null;
            }
          })
          .filter(id => id != null)

      const driveIds = this.accountDrive.value
          .map((h, index) => {
            if (h === true) {
              return this.accountsDrive[index].rayonId + ' - ' + this.accountsDrive[index].name
            } else {
              return null;
            }
          })
          .filter(id => id != null)
        if(this.entrantForm.value.type !== 'MAG') {

            const others = this.accountOherType.value
                .map((h, index) => {
                    if (h === true) {
                        return this.accountsOther[index].rayonId + ' - ' + this.accountsOther[index].name
                    } else {
                        return null;
                    }
                })
                .filter(id => id != null)

            return others;
        }
      return rayonIds.concat(accountMagEssIds.concat(driveIds));
    } else if (type === 'periode') {
      const periodIds = this.period.value
        .map((h, index) => {
          if (h === true) {
            return this.listPeriod[index].viewValue
          } else {
            return null;
          }
        })
        .filter(id => id != null)

      return periodIds;
    }
  }
  openDialog(type) {

    this.dialog.open(DialogDataInfoLockDialog, {
      data: this.getRecapItems(type)
    });
  }
    getAccountsByType = (controlName: string) => {
        const value = this.entrantForm.value[controlName];
        this.stores = { hyper: [], super: [], ultra: [], otherType: [] };
        if(this.entrantForm.value.type === 'MAG') {
            this.list = ['hyper', 'super', 'ultra'];
        } else {
            this.list = ['otherType'];
        }

        this.entrantForm = this.fb.group({
            hyper: this.fb.control(false),
            super: this.fb.control(false),
            ultra: this.fb.control(false),
            otherType: this.fb.control(false),

            hyperList: this.fb.array([]),
            superList: this.fb.array([]),
            ultraList: this.fb.array([]),
            otherTypeList: this.fb.array([]),
            rubrique: this.fb.control(false),
            rubriqueList: this.fb.array([]),

            rubriqueDrive: this.fb.control(false),
            rubriqueDriveList: this.fb.array([]),

            account: this.fb.control(false),
            accountList: this.fb.array([]),

            accountRay: this.fb.control(false),
            accountRayList: this.fb.array([]),

            accountDrive: this.fb.control(false),
            accountDriveList: this.fb.array([]),

            period: this.fb.control(false),
            periodList: this.fb.array([]),

            repartition: this.fb.control('REP_POIDS'),

            column: this.fb.control('4'),

            cloture: this.fb.control('0'),
            type: this.fb.control(value),

            accountOther: this.fb.control(false),
            accountOtherList: this.fb.array([]),
            accountOherType: this.fb.control(false),

        })
        let searchAccount = this.entrantForm.value.type ==='AUCHPRO' ? 'getAccountsByTypesAndStore' : 'getAccountsByTypes'
        let typeAccount = this.entrantForm.value.type ==='AUCHPRO' ? 'SAV' : this.entrantForm.value.type ==='AUCHFR' ? 'MAR_FR' : this.entrantForm.value.type
        forkJoin(
            this.storeService.getAll(),
            this.rubriqueService.getAllByType('MAG'),
            this.accountService.getAccountsByTypes(['magEss']),
            this.accountService.getAccountsByTypes(['MAR']),
            this.accountService.getAccountsByTypes(['DRIVE']),
            this.rubriqueService.getAllByType('DRIVE'),
            this.accountService.getPeriodsExcluded(),
            this.accountService[searchAccount]([typeAccount],this.entrantForm.value.type),
        ).subscribe(data => {
            this.stores.hyper = data[0].filter(store => store.category === 'HYP').sort((a, b) => a.hyperionId < b.hyperionId ? -1 : 1);
            const hypControls = this.stores.hyper.map(() => this.fb.control(false));
            hypControls.forEach(c => this.hyper.push(c));


            this.stores.super = data[0].filter(store => store.category === 'SUP').sort((a, b) => a.hyperionId < b.hyperionId ? -1 : 1);
            const supControls = this.stores.super.map(() => this.fb.control(false));
            supControls.forEach(c => this.super.push(c));

            this.stores.ultra = data[0].filter(store => store.category === 'ULT').sort((a, b) => a.hyperionId < b.hyperionId ? -1 : 1);
            const ultControls = this.stores.ultra.map(() => this.fb.control(false));
            ultControls.forEach(c => this.ultra.push(c));

            this.stores.otherType = data[0].filter(store => store.category === value).sort((a, b) => a.hyperionId < b.hyperionId ? -1 : 1);
            const araControls = this.stores.otherType.map(() => this.fb.control(false));
            araControls.forEach(c => this.otherType.push(c));

            this.rubriques = data[1].filter(r => !r.calculated);
            const rubControls = this.rubriques.map(() => this.fb.control(false));
            rubControls.forEach(c => this.rubrique.push(c));

            this.accounts = data[2];
            const accControls = this.accounts.map(() => this.fb.control(false));
            accControls.forEach(c => this.account.push(c));

            this.accountsRay = data[3];
            const accRayControls = this.accountsRay.map(() => this.fb.control(false));
            accRayControls.forEach(c => this.accountRay.push(c));

            this.accountsDrive = data[4];
            const accDriveControls = this.accountsDrive.map(() => this.fb.control(false));
            accDriveControls.forEach(c => this.accountDrive.push(c));

            this.rubriquesDrive = data[5].filter(r => !r.calculated);
            const rubDriveControls = this.rubriquesDrive.map(() => this.fb.control(false));
            rubDriveControls.forEach(c => this.rubriqueDrive.push(c));

            this.periods = data[6].filter(r => !r.calculated);
            const periodControls = this.periods.map(() => this.fb.control(false));
            periodControls.forEach(c => this.period.push(c));

            this.accountsOther = data[7];
            const accOtherControls = this.accountsOther.map(() => this.fb.control(false));
            accOtherControls.forEach(c => this.accountOherType.push(c));
            this.entrantForm.valueChanges.subscribe(() => {
                this.refreshGlobalValue('hyper');
                this.refreshGlobalValue('super');
                this.refreshGlobalValue('ultra');
                this.refreshGlobalValue('rubrique');
                this.refreshGlobalValue('account');
                this.refreshGlobalValue('accountRay');
                this.refreshGlobalValue('accountDrive');
                this.refreshGlobalValue('rubriqueDrive');
                this.refreshGlobalValue('period');
              /*  this.refreshGlobalValue('accountOherType');
                this.refreshGlobalValue('otherType');*/
            })
        })

    }
}

@Component({
  selector: 'dialog-content',
  template: `
  <mat-card>
  <mat-card-header>
    <div mat-card-avatar><mat-icon>warning</mat-icon></div>
    <mat-card-title>Confirmation</mat-card-title>
    <mat-card-subtitle>Confirmation de l'opération</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <p>
      Etes-vous sûr de vouloir continuer ?
    </p>
  </mat-card-content>
  <mat-card-actions style="display: flex; justify-content: space-evenly">
    <button mat-raised-button color="warn" mat-dialog-close>Annuler</button>
    <button mat-raised-button (click)="this.ref.close('OK')">Confirmer</button>
  </mat-card-actions>
</mat-card>
  `,
})
export class DialogContentLockComponent {
  constructor(public ref: MatDialogRef<DialogContentLockComponent>) { }
}

@Component({
  selector: 'dialog-content',
  template: `
    <mat-card >
      <mat-card-content style="height: 400px ; overflow-x : auto">
        <ul>
          <li *ngFor="let item of data"> {{item}} </li>
        </ul>
      </mat-card-content>
      <mat-card-actions style="display: flex; justify-content: space-evenly">
        <button mat-raised-button color="warn" mat-dialog-close>Fermer</button>
      </mat-card-actions>
    </mat-card>
  `,
})
export class DialogDataInfoLockDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data : []) {}
}
