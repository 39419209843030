import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface EntryEdition {
    account: { id: number };
    period: { code: string };
    designation: { code: string; name: string };
    title: { code: string; name: string };
    previousValue: string;
    currentValue: string;
    date: Date;
}

@Injectable({
    providedIn: 'root',
})
export class UndoService {
    private historySubject: BehaviorSubject<EntryEdition[]>;
    public history$: Observable<EntryEdition[]>;

    constructor() {
        this.historySubject = new BehaviorSubject<EntryEdition[]>(JSON.parse(localStorage.getItem('history')) || []);
        this.history$ = this.historySubject.asObservable();
    }

    public get historyValues(): EntryEdition[] {
        return this.historySubject.value;
    }

    addNewEdition(edition: EntryEdition) {
        this.historyValues.reverse();
        this.historyValues.push(edition);
        this.historyValues.reverse();
        this.historySubject.next(this.historyValues);
        localStorage.setItem('history', JSON.stringify(this.historyValues));
    }
}
