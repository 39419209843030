import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
@Injectable({
    providedIn: 'root',
})
export class ExportService {
    url: string = environment.OP_API_URI + '/export';
    urlImport: string = environment.OP_API_URI + '/import';

    constructor(private http: HttpClient) { }

    export(code: string): Observable<any> {
        let body = {
            titleLogCode: code,
        };
        return this.http.post(this.url, body);
    }

    exportRay(storeId: number, rubriquesIds: number[]): Observable<any> {
        return this.http.post(`${this.url}/stores/${storeId}`, { rubriquesIds });
    }

    validateStoreEntriesImport(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('excel', file);
        return this.http.post(`${this.urlImport}/validation`, formData);
    }

    updateStoreEntriesImport(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('excel', file);
        return this.http.post(`${this.urlImport}/entrants`, formData).pipe(share());
    }
    exportStores(): Observable<any> {
        return this.http.get(`${this.url}/stores`);
    }

    getNbBlock(): Observable<any> {
        return this.http.get<any>(`${this.url}/rayons/blocks`);
    }
    exportRayons(type: string, block: number): Observable<any> {
        return this.http.get(`${this.url}/rayons/${type}/${block}`);
    }


    validateStoreReperesImport(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('excel', file);
        return this.http.post(`${this.urlImport}/validationMonth`, formData);
    }
    validateStoreEstimeImport(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('excel', file);
        return this.http.post(`${this.urlImport}/validationEstimeMonth`, formData);
    }
    updateStoreReperesImport(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('excel', file);
        return this.http.post(`${this.urlImport}/reperesMonth`, formData).pipe(share());
    }
    exportStoresByMonth(cat:string,block?: number ): Observable<any> {
        return this.http.get(`${this.url}/storesByMonth/${cat}/${block}`);
    }
    exportStoresByCategory(category:string): Observable<any> {
        return this.http.get(`${this.url}/stores/${category}`);
    }
    exportEstimerByMonth(cat:string,block?: number ): Observable<any> {
        return this.http.get(`${this.url}/estimerByMonth/${cat}/${block}`);
    }
    updateStoreEstimeImport(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('excel', file);
        return this.http.post(`${this.urlImport}/estimeMonth`, formData).pipe(share());
    }

    exportConso(accountType: any, storeId: number, rubriquesIds: number[]): Observable<any> {
        return this.http.post(`${this.url}/storesConso/${accountType}`, { storeId, rubriquesIds });
    }
}
