import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../_models/user';
import { AdminService } from '../../../_services/admin/admin.service';
import { Store } from '../../../_models/store';
import { Account } from '../../../_models/account';
import { StoreService } from '../../../_services/store/store.service';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from "util";
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-modal-user-edition',
    templateUrl: './modal-user-edition.component.html',
    styleUrls: ['./modal-user-edition.component.css'],
    providers: [DatePipe],
})
export class ModalUserEditionComponent implements OnInit {
    @Input() user: User;
    @Input() roles: string[];
    @Input() stores: Store[];

    userUpdated: any;
    storeSelected: string;
    DateNow = new Date();
    /**
     * Indique si on est en train d'éditer les rayons d'un magasin logistique pour changer la vue.
     */
    isRayonsLogInEdition: boolean;
    storeInEdition: Store;
    // Utile pour afficher en vert les nouveaux rayons
    storeInEditionPreviousAccounts: Account[];
    // Les rayons qu'on ajoute au magasin en édition
    storeInEditionAccounts: Account[];
    editRayonsLoader = 0;

    dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };

    constructor(
        public activeModal: NgbActiveModal,
        private adminService: AdminService,
        private storeService: StoreService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private datePipe: DatePipe

    ) { }

    ngOnInit() {
        // On crée une copie de l'utilisateur
        this.userUpdated = JSON.parse(JSON.stringify(this.user));
        this.userUpdated.stores.forEach(store => {
            this.adminService.getAccountByUserAndStore(this.user.id, store.id).subscribe(accounts => {
                store.accounts = accounts;
            });
        });
        this.stores.sort((a, b) => {
            if (a.hyperionId.slice(-4) < b.hyperionId.slice(-4)) { return -1; }
            if (a.hyperionId.slice(-4) > b.hyperionId.slice(-4)) { return 1; }
            return 0;
        }
        );
    }

    updateUser() {
        if (this.userUpdated.stores && this.userUpdated.stores.length > 0) {
            this.userUpdated.accounts = [];
            this.userUpdated.stores.forEach(store => {
                if (store.accounts && store.accounts.length > 0) {
                    this.userUpdated.accounts = this.userUpdated.accounts.concat(store.accounts.map(a => a.id));
                }
            });
            this.userUpdated.stores = this.userUpdated.stores.map(s => s.id);
        }
        this.adminService.updateUser(this.userUpdated).subscribe(
            res => {
                this.toastr.success('Utilisateur mis à jour');
                this.activeModal.close(res);
            },
            () => {
                this.toastr.error("Erreur lors de la mise à jour de l'utilisateur, réessayez ultérieurement");
                this.activeModal.close(null);
            }
        );
    }

    changeRoleName(input): string {
        return this.adminService.changeRoleName(input);
    }

    addStore(hyperionId): void {
        const newStore = this.stores.find(s => s.hyperionId === hyperionId);
        if (newStore && this.userUpdated.stores.indexOf(newStore) < 0) {
            this.userUpdated.stores.push(newStore);
        }
    }

    removeStore(store: Store): void {
        this.userUpdated.stores.splice(this.userUpdated.stores.indexOf(store), 1);
    }

    isNewStore(store: Store): boolean {
        return !this.user.stores.find(s => s.id == store.id);
    }

    isNewAccount(account: Account): boolean {
        return !this.storeInEditionPreviousAccounts.find(a => a.id === account.id);
    }

    isStoreLogistic(store: Store): boolean {
        const plainStore = this.stores.find(s => s.id === store.id);
        return plainStore && plainStore.category === 'LOG';
    }

    editStoreLogistic(store: Store) {
        this.editRayonsLoader = store.id;
        // On récupère les comptes du user pour le magasin puis tous les comptes du magasins
        this.adminService.getAccountByUserAndStore(this.user.id, store.id).subscribe(
            accounts => {
                this.storeInEdition = JSON.parse(JSON.stringify(store));
                this.storeInEdition.accounts = accounts;
                this.storeInEditionPreviousAccounts = accounts;
                this.storeService.getStoreById(store.id).subscribe(
                    plainStore => {
                        this.editRayonsLoader = 0;
                        this.storeInEditionAccounts = plainStore.accounts;
                        this.isRayonsLogInEdition = true;
                    },
                    () => {
                        this.editRayonsLoader = 0;
                        this.toastr.error(
                            'Une erreur est survenue lors de la récupération du magasin, veuillez réessayer ultérieurement'
                        );
                    }
                );
            },
            () => {
                this.toastr.error(
                    "Un erreur est survenue lors de la récupération des rayons de l'utilisateur, veuillez réessayer ultérieurement"
                );
            }
        );
    }

    validRayons() {
        const storeUpdated = this.userUpdated.stores.find(s => s.id === this.storeInEdition.id);
        storeUpdated.accounts = this.storeInEdition.accounts;
        this.backFromRaySelection();
    }

    backFromRaySelection() {
        this.isRayonsLogInEdition = false;
        this.storeInEdition = null;
    }

    removeAccount(account) {
        this.storeInEdition.accounts = this.storeInEdition.accounts.filter(a => a !== account);
    }

    /**
     * Indique si l'objet user est valide pour une requete http post.
     */
    isEditUserValid() {
        return (
            this.userUpdated.stores.length === 0
        );
    }

    open(content) {
        this.modalService.open(content);
    }

    disableUser() {
        this.userUpdated.disabled = "true";
        this.userUpdated.disabledAt = this.datePipe.transform(this.DateNow, 'yyyy-MM-dd HH:MM:SS');
        this.userUpdated.stores = [];
        this.userUpdated.accounts = [];

        this.adminService.updateUser(this.userUpdated).subscribe(
            res => {
                this.toastr.success('Utilisateur est supprimé');
                this.activeModal.close(res);
            },
            () => {
                this.toastr.error("Erreur lors de la suppression de l'utilisateur, réessayez ultérieurement");
                this.activeModal.close(null);
            }
        );
    }
    onClose() {
        this.disableUser();
        this.activeModal.close(null);
        this.adminService.filter('Register click');
    }
}
