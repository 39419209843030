import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '../../../_models/store';
import { Account } from '../../../_models/account';
import { isNullOrUndefined } from 'util';
import { StoreService } from '../../../_services/store/store.service';
import { AdminService } from '../../../_services/admin/admin.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../_models/user';
import { ROLES } from '../../../constants';
import { AuthenticationService } from '../../../_services/authentication/authentication.service';

@Component({
    selector: 'app-modal-user',
    templateUrl: './modal-user.component.html',
    styleUrls: ['./modal-user.component.css'],
})
export class ModalUserComponent implements OnInit {
    newUser: any;
    @Input() stores: Store[];
    @Input() roles: any[];
    // Selon le rôle choisit on autorise ou non le choix des magasins (ergonomie).
    isStoreSelectionEnabled: boolean = false;
    storesToSelect: Store[];
    storeSelected: string;
    /**
     * L'objet store en cours d'ajout.
     */
    newStore: Store;
    /**
     * Traitement spécial lorsque l'utilisateur selectionne un magasin logistique.
     * Il peut attribuer des rayons en particulier.
     */
    isStoreSelectedLogistic: boolean;
    accountsList: Account[] = [];
    accountsSelected: Account[] = [];

    userManaged: User;

    dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 1,
        allowSearchFilter: true,
    };

    loading = false;

    constructor(
        public activeModal: NgbActiveModal,
        private storeService: StoreService,
        private adminService: AdminService,
        private toastrService: ToastrService,
        private authenticationService: AuthenticationService,
    ) {}

    ngOnInit() {
        this.newUser = {
            login: '',
            stores: [],
            accounts: [],
            disabled: 'false'
        };
        this.stores.sort((a, b) => a.id - b.id);
    }

    /**
     * Ajoute un nouveau magasin pour l'utilisateur.
     */
    addNewStore() {
        if(this.storeSelected === "all") {
           this.storesToSelect.forEach(s=>this.newUser.stores.push(s));
            return;
        }
        if (isNullOrUndefined(this.storeSelected)) return;

        this.newStore = this.stores.find(s => s.hyperionId === this.storeSelected);
        const alreadyAdded = this.newUser.stores.indexOf(this.newStore) >= 0;

        /*
         * Si le store est logistique, on doit récupérer ses rayons pour laisser le choix à l'utlisateur de les ajouter
         * manuellement.
         */
        if (this.newStore.category === 'LOG') {
            if (alreadyAdded) {
                return;
            }
            this.storeService.getStoreById(this.newStore.id).subscribe(plainStore => {
                this.accountsList = plainStore.accounts;
                this.isStoreSelectedLogistic = true;
            });
        } else {
            if (!alreadyAdded) {
                this.newUser.stores.push(this.newStore);
            }
        }
    }

    removeStoreFromUser(store: Store) {
        const storeToRemove = this.newUser.stores.find(s => s.hyperionId === store.hyperionId);
        const index = this.newUser.stores.indexOf(storeToRemove);
        if (index >= 0) {
            this.newUser.stores.splice(index, 1);
        }
    }

    /**
     * Appelé lors de la selection du rôle.
     * On autorise ou non la selection du magasin selon celui-ci et on filtre les magasins proposés.
     */
    selectRole(role: string) {
        console.log("select All Stores "+role)
        console.log(this.stores)
        // Ce sont les 3 rôles qui necessitent des ajouts de magasins manuels
        this.isStoreSelectionEnabled = ['HRPRORAYON', 'HRPROSIT', 'HRPROSITULT', 'HRPROSITSUP', 'USERLOG', 'APP','SAV','DIR','ARA','ENER','AUCHFR','FRN','DRIVE'].includes(
            role
        );

        if (role === 'USERLOG') {
            this.storesToSelect = this.stores.filter(s => s.category === 'LOG');
            // }  else if(ROLES.LIST.NON_ADMINS.includes(role)&& !ROLES.LIST.ADMINS.includes(this.authenticationService.getCurrentUser().role)) {
        }  else if(ROLES.LIST.CONNECT_NON_ADMINS.includes(this.authenticationService.getCurrentUser().role)) {
            this.storesToSelect =this.stores;
            return;
        }else if(ROLES.LIST.NON_ADMINS.includes(role)) {
            this.storesToSelect =this.stores.filter(s => s.category === role);
            return;
        }
        else if(ROLES.LIST.ADD_USERS_ADMIN.includes(role)) {
            this.storesToSelect = this.stores.filter(s => (ROLES.LIST.RAYON.includes(s.category)));
            return;
        }
        /*else if(role === 'APP') {
            this.storesToSelect = this.stores.filter(s => s.category === 'APP');
        } else if(role === 'SAV') {
            this.storesToSelect = this.stores.filter(s => s.category === 'SAV');
        } else if(role === 'DIR') {
            this.storesToSelect = this.stores.filter(s => s.category === 'DIR');
        } else if(role === 'ARA') {
            this.storesToSelect = this.stores.filter(s => s.category === 'ARA');
        } else if(role === 'ENER') {
            this.storesToSelect = this.stores.filter(s => s.category === 'ENER');
        } else if(role === 'FRN') {
            this.storesToSelect = this.stores.filter(s => s.category === 'FRN');
        } else if(role === 'AUCHFR') {
            this.storesToSelect = this.stores.filter(s => s.category === 'AUCHFR');
        }*/ else {
            this.storesToSelect = this.stores.filter(s => s.category !== 'LOG');
        }

/*        console.log("after filter")
        console.log(this.storesToSelect)*/
    }

    /**
     * Appelé lorsque l'utilisateur valide les rayons qu'il attribut pour un magasin logistique.
     */
    validAccounts() {
        this.newStore.accounts = this.accountsSelected;
        this.accountsList = [];
        this.accountsSelected = [];
        this.isStoreSelectedLogistic = false;
        this.newUser.stores.push(this.newStore);
    }

    saveNewUser() {
        this.loading = true;
        this.newUser.login = this.newUser.login.toUpperCase();

        // On attribue les magasins si le rôle l'autorise
        if (this.isStoreSelectionEnabled) {
            this.newUser.stores.forEach(store => {
                if (!isNullOrUndefined(store.accounts) && store.accounts.length > 0) {
                    this.newUser.accounts = this.newUser.accounts.concat(store.accounts.map(a => a.id));
                }
            });
            // exemple : stores => [2, 56, 78]
            this.newUser.stores = this.newUser.stores.map(s => s.id);
        } else {
            this.newUser.stores = [];
            this.newUser.accounts = [];
        }

        this.adminService.getUser(this.newUser.login.toUpperCase()).subscribe(
            res => {
                this.userManaged = res;
                if(this.userManaged){
                    if(this.userManaged.disabled != "false") {
                        this.userManaged.stores = this.newUser.stores;
                        this.userManaged.accounts = this.newUser.accounts;
                        this.userManaged.role = this.newUser.role;
                        this.userManaged.disabled = "false";
                        this.updateUser(this.userManaged);
                    }else{
                        this.toastrService.error("Utilisateur existe déjà");
                        this.activeModal.close(null);
                    }
                } else {
                    this.createUser();
                }
            },
            () => {
                this.toastrService.error("Erreur lors de la création l'utilisateur, réessayez ultérieurement");
                this.activeModal.close(null);
            }
        );
    }

    changeRoleName = input => {
        return this.adminService.changeRoleName(input);
    };

    backFromRaySelection() {
        this.isStoreSelectedLogistic = false;
        this.accountsList = [];
        this.accountsSelected = [];
    }

    /**
     * Indique si l'objet user est valide pour une requete http post.
     */
    isNewUserInvalid() {
        return (
            isNullOrUndefined(this.newUser.login) ||
            this.newUser.login.trim().length === 0 ||
            isNullOrUndefined(this.newUser.role) ||
            (this.isStoreSelectionEnabled ? this.newUser.stores.length === 0 : false)
        );
    }

    createUser(){
    this.adminService.createUser(this.newUser).subscribe(
        response => {
            this.loading = false;
            this.toastrService.success('Utilisateur créé avec succès');
            this.activeModal.close(response);
        },
        error => {
            this.loading = false;
            this.toastrService.error(error.error);
            this.activeModal.close(null);
        });
    }

    updateUser(updatedUser: User){
        this.adminService.updateUser(updatedUser).subscribe(
            res => {
                this.toastrService.success('Utilisateur créé avec succès');
                this.activeModal.close(res);
            },
            () => {
                this.toastrService.error("Erreur lors de la création l'utilisateur, réessayez ultérieurement");
                this.activeModal.close(null);
            }
        );
    }
}
