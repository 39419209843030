import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { CalendarDay } from '../../_models/calendar-day';
import { EntryJourna } from '../../_models/entryJourna';
import { DailyService } from '../../_services/daily/daily.service';
import { MONTHS } from '../../constants';
import { AccountComponent } from '../account/account.component';
import { ExcelService } from '../../_services/excel/excel.service';

@Component({
    selector: 'app-journa',
    templateUrl: './journa.component.html',
    styleUrls: ['./journa.component.css'],
})
export class JournaComponent implements OnInit, OnChanges {
    @Input('calendarDays') calendarDays: CalendarDay[];
    @Input('period') period: any;
    @Input('accountId') accountId: number;
    @Input('daySelected') daySelected: number;
    @Input() pilotYear: number;
    /** Type de compte : (ex: HYP, ESS, RAY)*/
    @Input() accountType: string;

    month: string;
    matrix: any;
    cellOnEdit: any;
    // La valeur de la cellule en édition
    cellOnEditValue: string;
    cellOnEditDirty: boolean;
    delta: any;
    pilotTotalYear: any[];

    constructor(
        private dailyService: DailyService,
        @Inject(AccountComponent) private parent: AccountComponent,
        private excelService: ExcelService
    ) {
    }

    ngOnInit() {
        this.cellOnEdit = {};
        this.cellOnEditDirty = false;
        console.log("type jour " + this.accountType)
        this.dailyService.getAllByAccountAndYear(this.accountId).subscribe(
            data => {
                this.pilotTotalYear = data;
            }
        );

    }

    ngOnChanges(changes: SimpleChanges): void {
        // On initialise la matrice quand on récupère les jour calendar
        if (
            !isNullOrUndefined(changes.calendarDays) &&
            changes.calendarDays.currentValue !== changes.calendarDays.previousValue
        ) {
            this.cellOnEdit = {};
            this.month = MONTHS[this.period.number - 1].toUpperCase();
            this.initMatrix(this.calendarDays);
        }
    }

    /**
     * Initialise la matrice de calcul à partir des jours calendrier
     * @param {CalendarDay[]} calendarDays les jours calendrier
     * @returns {any} la matrice
     */
    initMatrix(calendarDays: CalendarDay[]): void {
        this.matrix = [];
        this.matrix['delta'] = {};
        this.matrix['delta']['PRESVA'] = -this.period.VA;
        this.matrix['delta']['PRESMV'] = -this.period.MV;

        calendarDays.forEach(calDay => {
            this.matrix[calDay.day.id] = [];
            if (calDay.lastYearEntry === undefined) {
                calDay.lastYearEntry = {
                    jour: calDay.day.numReal,
                    mois: calDay.day.moisReal,
                    annee: this.pilotYear,
                    margeVente: 0,
                    volumeAffaire: 0,
                    accountId: this.accountId,
                };
            }
            if (calDay.presentYearEntry === undefined) {
                calDay.presentYearEntry = {
                    jour: calDay.day.numObjectif,
                    mois: calDay.day.moisObjectif,
                    annee: this.pilotYear + 1,
                    margeVente: 0,
                    volumeAffaire: 0,
                    accountId: this.accountId,
                };
            }

            // Volume d'affaire TTC
            this.matrix[calDay.day.id]['LASTVA'] = calDay.lastYearEntry.volumeAffaire;
            this.matrix[calDay.day.id]['PRESVA'] = calDay.presentYearEntry.volumeAffaire;
            this.matrix[calDay.day.id]['COMMENTVA'] = calDay.presentYearEntry.commentary;
            this.matrix['delta']['PRESVA'] += Number(this.matrix[calDay.day.id]['PRESVA']);

            // Marge sur vente
            this.matrix[calDay.day.id]['LASTMV'] = calDay.lastYearEntry.margeVente;
            this.matrix[calDay.day.id]['PRESMV'] = calDay.presentYearEntry.margeVente;
            this.matrix['delta']['PRESMV'] += Number(this.matrix[calDay.day.id]['PRESMV']);

            this.dispatchLine(calDay.day.id);
        });
    }

    handleEntry($event) {

        if (($event.key !== 'Enter' && $event.code !== 'Enter') ) {
            return;
        }
        const calendarDay = this.calendarDays.find(cd => cd.day.id === this.cellOnEdit.idDay);
        let col = this.cellOnEdit.col;
        let diff = 0;
        switch (col) {
            case 'LASTVA':
                diff = Number(this.cellOnEditValue) - Number(calendarDay.lastYearEntry.volumeAffaire);
                calendarDay.lastYearEntry.volumeAffaire = Number(this.cellOnEditValue);
                break;
            case 'PRESVA':
                diff = Number(this.cellOnEditValue) - Number(calendarDay.presentYearEntry.volumeAffaire);
                calendarDay.presentYearEntry.volumeAffaire = Number(this.cellOnEditValue);
                break;
            case 'LASTMV':
                diff = Number(this.cellOnEditValue) - Number(calendarDay.lastYearEntry.margeVente);
                calendarDay.lastYearEntry.margeVente = Number(this.cellOnEditValue);
                break;
            case 'PRESMV':
                diff = Number(this.cellOnEditValue) - Number(calendarDay.presentYearEntry.margeVente);
                calendarDay.presentYearEntry.margeVente = Number(this.cellOnEditValue);
                break;
            case 'PROGVA':
                diff =
                    Number(calendarDay.lastYearEntry.volumeAffaire) -
                    Number(calendarDay.presentYearEntry.volumeAffaire) +
                    Number(this.cellOnEditValue);
                calendarDay.presentYearEntry.volumeAffaire =
                    Number(calendarDay.lastYearEntry.volumeAffaire) + Number(this.cellOnEditValue);
                break;
            case 'PROGMV':
                diff = Number(this.cellOnEditValue);
                calendarDay.presentYearEntry.margeVente =
                    Number(calendarDay.lastYearEntry.margeVente) + Number(this.cellOnEditValue);
                break;
            case 'PROGVA%':
                const newVA =
                    Number(calendarDay.lastYearEntry.volumeAffaire) * (1 + Number(this.cellOnEditValue) / 100);
                diff = newVA - Number(calendarDay.presentYearEntry.volumeAffaire);
                calendarDay.presentYearEntry.volumeAffaire = newVA;
                break;
            case 'PROGMV%':
                const newVM = Number(calendarDay.lastYearEntry.margeVente) * (1 + Number(this.cellOnEditValue) / 100);
                diff = newVM - Number(calendarDay.presentYearEntry.margeVente);
                calendarDay.presentYearEntry.margeVente = newVM;
                break;
            case 'COMMENTVA':
                calendarDay.presentYearEntry.commentary = this.cellOnEditValue;
                break;
            default:
                break;
        }

        let entryToSend: EntryJourna;
        if (col.match('PROGVA')) {
            col = 'PRESVA';
            entryToSend = calendarDay.presentYearEntry;
        } else if (col.match('PROGMV')) {
            col = 'PRESMV';
            entryToSend = calendarDay.presentYearEntry;
        } else if (col.match('LAST')) {
            entryToSend = calendarDay.lastYearEntry;
        } else if (col.match('PRES')) {
            entryToSend = calendarDay.presentYearEntry;
        } else if (col.match('COMMENTVA')) {
            entryToSend = calendarDay.presentYearEntry;
        }

        entryToSend.volumeAffaire = Math.round(entryToSend.volumeAffaire);
        entryToSend.margeVente = Math.round(entryToSend.margeVente);
        this.dailyService.save(entryToSend).subscribe(res => {
           this.matrix[calendarDay.day.id][col] = col.match('VA') ? entryToSend.volumeAffaire : entryToSend.margeVente;

            if(col.match('COMMENTVA')) {
                this.matrix[calendarDay.day.id][col] = entryToSend.commentary
            }
            this.editCell(this.cellOnEdit.idDay + 1, this.cellOnEdit.col);
            this.dispatchLine(calendarDay.day.id);
            this.dispatchColumn(col, diff);
            this.initTotalYear();
        });

    }

    handleKeyUp() {
     this.cellOnEditDirty = isNaN(Number(this.cellOnEditValue));
    }

    /**
     * Dispatch une ligne en fonction de la VA et de la MV
     * On recalcule la progression et l'objectif en fonction
     * @param {number} idDay l'id du calendar day
     */
    dispatchLine(idDay: any) {
        // Calcul via Progressions en %

        // Calcul des progressions numériques
        this.matrix[idDay]['PROGVA'] = this.matrix[idDay]['PRESVA'] - this.matrix[idDay]['LASTVA'];
        this.matrix[idDay]['PROGMV'] = this.matrix[idDay]['PRESMV'] - this.matrix[idDay]['LASTMV'];

        // Calcul des progressions en %
        this.matrix[idDay]['PROGVA%'] = this.calcProgression(
            this.matrix[idDay]['LASTVA'],
            this.matrix[idDay]['PRESVA']
        );
        this.matrix[idDay]['PROGMV%'] = this.calcProgression(
            this.matrix[idDay]['LASTMV'],
            this.matrix[idDay]['PRESMV']
        );

        // Empêche les 'Infiny'
        if (this.matrix[idDay]['PROGVA%'] === Infinity) {
            this.matrix[idDay]['PROGVA%'] = 0;
        }
        if (this.matrix[idDay]['PROGMV%'] === Infinity) {
            this.matrix[idDay]['PROGMV%'] = 0;
        }

        // Recalcule des marges sur vente / volumes d'affaire
        this.matrix[idDay]['PRESMVVA'] = this.calcMargeByVolume(
            this.matrix[idDay]['PRESMV'],
            this.matrix[idDay]['PRESVA']
        );
        this.matrix[idDay]['LASTMVVA'] = this.calcMargeByVolume(
            this.matrix[idDay]['LASTMV'],
            this.matrix[idDay]['LASTVA']
        );
    }

    dispatchColumn(col: string, diff: number): void {
        const deltaDailyEntry: EntryJourna = {
            jour: 0,
            mois: this.period.number,
            annee: col.match('PRES') ? new Date().getUTCFullYear() : new Date().getUTCFullYear() - 1,
            accountId: this.accountId,
        };
        if (col.match('VA')) {
            deltaDailyEntry.volumeAffaire = -this.matrix['delta'][col] - diff;
        } else {
            deltaDailyEntry.margeVente = -this.matrix['delta'][col] - diff;
        }
        this.dailyService.save(deltaDailyEntry).subscribe(res => {
            this.matrix['delta'][col] = col.match('VA') ? -res.volumeAffaire : -res.margeVente;
        });
    }

    editCell(idDay: number, col: string) {
        if (col.startsWith('LAST') && this.period.isRealLocked) {
            return;
        } else if (this.period.isPilotLocked) {
            return;
        }
        this.cellOnEdit = {};
        this.cellOnEdit.idDay = idDay;
        this.cellOnEdit.col = col;
        try {
            this.cellOnEditValue = this.matrix[idDay][col];
            // this.cellOnEditDirty = isNaN(Number(this.cellOnEditValue));
            if (!Number.isInteger(Number(this.cellOnEditValue)) && col!='COMMENTVA') {
                this.cellOnEditValue = Number(this.cellOnEditValue).toFixed(2);
            }
        } catch (err) {
            this.cellOnEdit = {};
        }
    }

    isCellOnEdit(idDay: number, col: string) {
        if (this.calendarDays.map(e => e.locked).find(d => d === true)) {
            return false;
        }
        return this.cellOnEdit.idDay === idDay && this.cellOnEdit.col === col;

    }

    isCellOnEditDirty() {
        return this.cellOnEditDirty;
    }

    /**
     * Calcul la progression de deux valeurs
     * @param {number} last l'ancienne donnée
     * @param {number} current la nouvelle donnée
     * @returns {number} la progression en pourcentage arrondie
     */
    calcProgression(last: number, current: number) {
        if (last === 0 && current !== 0) {
            return '-';
        }
        return ((current - last) / last) * 100 || 0;
    }

    /**
     * Calcul la marge sur vente sur le volume d'affaire
     * @param {number} marge la marge sur vente
     * @param {number} volume le volume d'affaire
     * @returns {number} le pourcentage arrondie
     */
    calcMargeByVolume(marge: number, volume: number) {
        const result = Math.round((marge / volume) * 100);
        return result === Infinity ? '-' : result || 0;
    }

    /**
     * Calcul la somme d'une colonne pour le total du mois
     * @param col la colonne
     * @returns {number} la somme
     */
    calcSumOfCol(col): number {
        let sum = 0;
        this.calendarDays.forEach(day => {
            sum += Number(this.matrix[day.day.id][col]);
        });
        return sum;
    }

    /**
     * Tri les jours du calendrier par leur numéro dans le mois
     * @param {CalendarDay[]} calendarDays le calendrier
     * @returns {CalendarDay[]} le calendrier filtré
     */
    sortCalendarDaysByDay(calendarDays: CalendarDay[]): CalendarDay[] {
        return calendarDays.sort((d1, d2) => {
            if (d1.day.numObjectif < d2.day.numObjectif) {
                return -1;
            } else if (d1.day.numObjectif > d2.day.numObjectif) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    navigateToPeriod(periodNumber: number) {
        if (periodNumber <= 0) {
            periodNumber = 12;
        } else if (periodNumber >= 13) {
            periodNumber = 1;
        }
        this.parent.goToDailyPeriod(periodNumber);
    }

    isNaN(number) {
        return isNaN(number);
    }

    extractDay() {
        this.excelService.generateExcelDay(this.calendarDays, this.matrix, this.month, this.pilotYear);
    }

    isShowMarge() {
        return this.accountType === 'DIR' ? false : true;
    }

    /**
     * Affiche total Annee
     * @param col la colonne
     * Annee
     * @returns {number} la valeur
     */
    calcSumOfColYear(col, year): number {

        let sum = 0;
        if(this.pilotTotalYear!== undefined) {
            this.pilotTotalYear.forEach(p => {
                if (p.annee === year) {
                    if (col === 'LASTVA' || col === 'PRESVA') {
                        sum = p.volumeAffaire
                    } else {
                        sum = p.margeVente
                    }
                }

            });
        }

        return sum;
    }

    initTotalYear() {
        this.dailyService.getAllByAccountAndYear(this.accountId).subscribe(
            data => {
                    this.pilotTotalYear = data;

            }
        );
    }
}