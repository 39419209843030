import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TitleLog } from 'src/app/components/logistic/_models/title-log';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TitlesLogService {
    url: string = environment.OP_API_URI + '/titlesLog';
    constructor(private http: HttpClient) {}

    getAllTitlesLog(): Observable<TitleLog[]> {
        return this.http.get<TitleLog[]>(this.url);
    }

    getAllTitlesLogByType(type: number): Observable<TitleLog[]> {
        return this.http.get<TitleLog[]>(this.url + '/' + type);
    }

    /**
     * Retourne le type de la colonne en fonction de son id.
     * 1 - nombre entier
     * 2 - pourcentage
     * @param id
     */
    getTitleLogTypeById(id: number): number {
        return [1, 2, 3, 6, 10, 12, 13, 15, 17, 19, 22].includes(id) ? 1 : 2;
    }
}
