import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { APPLICATION_IN_MAINTENANCE } from '../../../constants';

@Component({
    selector: 'app-unauthorized-access',
    templateUrl: './unauthorized-access.component.html',
    styleUrls: ['./unauthorized-access.component.css'],
    animations: [
        trigger('fadeInAnimation', [
            transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class UnauthorizedAccessComponent implements OnInit {

    maintenance: boolean;

    constructor() {
        this.maintenance = APPLICATION_IN_MAINTENANCE;
    }

    ngOnInit() {}
}
