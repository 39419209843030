import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { AdminService } from 'src/app/_services/admin/admin.service';
import { ROLES } from 'src/app/constants';
@Component({
    selector: 'app-admin-page',
    templateUrl: './admin-page.component.html',
    styleUrls: ['./admin-page.component.css'],
})
export class AdminPageComponent implements OnInit {
    userConnected: User;
    selectedTab = 'user';
    roles: any[];
    authorizedRoles = [ROLES.SUPERADMIN, ROLES.NATIONAL_MAG, ROLES.NATIONAL_LOG];
    authorizedStoreRoles = [ROLES.SUPERADMIN, ROLES.NATIONAL_MAG];

    constructor(private authenticationService: AuthenticationService, private adminService: AdminService) {
        this.userConnected = this.authenticationService.getCurrentUser();
    }

    ngOnInit() {
        this.getRoles();
    }

    updateSelectedTab(tab: string) {
        this.selectedTab = tab;
    }

    getRoles() {
        this.adminService.getRoles(this.authenticationService.getCurrentUser().login).subscribe(
            roles => {
                this.roles = roles.sort((a, b) => {
                    if (a.firstname < b.firstname) { return -1; }
                    if (a.firstname > b.firstname) { return 1; }
                    return 0;
                });
            },
            () => { }
        );
    }
}
