import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormArray } from '@angular/forms';

import { RubriqueService } from 'src/app/_services/rubrique/rubrique.service';
import { ConfigService } from 'src/app/_services/config/config.service';
import { ToastrService } from 'ngx-toastr';

import { Rubrique } from 'src/app/_models/rubrique';
import { Config } from 'src/app/_models/config';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-divers-config',
  templateUrl: './divers-config.component.html',
  styleUrls: ['./divers-config.component.css']
})
export class DiversConfigComponent implements OnInit {

  constructor(
    private rubriqueService: RubriqueService,
    private configService: ConfigService,
    private toastService: ToastrService,
    private fb: FormBuilder,
  ) { }

  public configForm: FormArray;

  public configs: Config[];
  public rubriques: Rubrique[];
  public rubriquesRaw: Rubrique[];

  public emptyRubrique: Rubrique = {
    id: null,
    libelle: 'Pas de rubrique -',
    code: '',
    category: null,
    calculated: null,
    coefficientId: null
  }

  async ngOnInit() {

    this.configForm = this.fb.array([
      this.fb.group({
        key: this.fb.control('PILOT_RUBRIQUE_VASE'),
        value: this.fb.control(null),
        category: this.fb.control('RUBRIQUE_ID'),
      }),
      this.fb.group({
        key: this.fb.control('PILOT_RUBRIQUE_MSV'),
        value: this.fb.control(null),
        category: this.fb.control('RUBRIQUE_ID'),
      }),
      this.fb.group({
        key: this.fb.control('RHT_RUBRIQUE_REFERENCE'),
        value: this.fb.control(null),
        category: this.fb.control('RUBRIQUE_ID'),
      }),
      this.fb.group({
        key: this.fb.control('SUPP6_SUPP12_RUBRIQUES_ALLOWED'),
        value: this.fb.control(null),
        category: this.fb.control('RUBRIQUE_IDS'),
      }),
      this.fb.group({
        key: this.fb.control('EXCEPTION_CALCUL_TOTAL_CA_NET_TTC'),
        value: this.fb.control(null),
        category: this.fb.control('RUBRIQUE_ID'),
      }),
    ])

    forkJoin([
      this.configService.getAll().pipe(map(configs => {
        return configs.map(conf => {
          // Trick to use an array stored as a string
          if (conf.category === 'RUBRIQUE_IDS') {
            conf.value = JSON.parse(conf.value)
          }
          return conf;
        })
      })),
      this.rubriqueService.getAllByType('MAG'),
    ]).subscribe(data => {
      this.configs = data[0];
      this.rubriques = [this.emptyRubrique].concat(data[1]);
      this.rubriquesRaw = data[1].filter(r => !r.calculated);
      this.initConfigsControls(this.configs, this.rubriques);
      this.configForm.valueChanges.subscribe(this.onFormValueChange)
    })

  }

  private initConfigsControls = (configs: Config[], rubriques: Rubrique[]) => {

    configs.forEach(config => {
      const group = this.configForm.controls.find(group => group.get('key').value === config.key);
      switch (config.category) {
        case 'RUBRIQUE_ID':
          const rubrique = rubriques.find(r => r.code === config.value);
          if (group == null || rubrique == null) { return };
          group.get('value').setValue(rubrique.code, { emitEvent: false });
          break;
        case 'RUBRIQUE_IDS':
          if (group == null) { return };
          group.get('value').setValue(config.value, { emitEvent: false });
          break;
      }
    })

  }

  private onFormValueChange = (newValue) => {

    const adaptedValue = JSON.parse(JSON.stringify(newValue));
    adaptedValue[3].value = JSON.stringify(adaptedValue[3].value) // Trick to write Array in string

    this.configService.saveAll(adaptedValue).subscribe(
      () => {
        this.toastService.success('Configuration sauvegardée', 'Succès');
      },
      () => {
        this.toastService.error('La sauvegarde a échoué', 'Erreur');
      }
    )
  }

}
