import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class YearSettingsService {
    yearSettingsUri = 'yearSettings';

    constructor(private http: HttpClient) {}

    getYearByType(type: string): Observable<any> {
        return this.http.get<any>(`${environment.OP_API_URI}/${this.yearSettingsUri}`, { params: { type } });
    }
}
