import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Account } from '../../_models/account';
import { ACCOUNT_URI } from '../../constants';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    url: string = `${environment.OP_API_URI}${ACCOUNT_URI}/`;

    constructor(private http: HttpClient) {}

    getAccountById(accountId): Observable<Account> {
        return this.http.get<Account>(this.url + accountId);
    }

    getAccountsByTypes(types): Observable<Account[]> {
        return this.http.get<Account[]>(this.url +'types/'+ types);
    }
    getAccountRayExistsDaily(): Observable<Account[]> {
        return this.http.get<Account[]>(this.url +'rayons/daily');
    }
    getPeriodsExcluded(): Observable<Account[]> {
        return this.http.get<Account[]>(this.url +'period/excluded');
    }
    getAccountsByTypesAndStore(types,typeStore): Observable<Account[]> {
        return this.http.get<Account[]>(this.url +'types/account/store/'+ types+'/'+typeStore);
    }
}
