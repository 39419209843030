import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AdminService } from 'src/app/_services/admin/admin.service';
import { ModalConfirmationComponent } from '../../modal-confirmation/modal-confirmation.component';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { RubriqueService } from 'src/app/_services/rubrique/rubrique.service';
import { Rubrique } from 'src/app/_models/rubrique';
import { ToastrService } from 'ngx-toastr';
import * as math from 'mathjs';
import { Coefficient } from '../../../_models/coefficient';
import { CoefficientService } from '../../../_services/coefficient/coefficient.service';
import { ROLES } from 'src/app/constants';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { User } from 'src/app/_models/user';

@Component({
    selector: 'app-edition-libelle-designation',
    templateUrl: './edition-libelle-designation.component.html',
    styleUrls: ['./edition-libelle-designation.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class EditionLibelleDesignationComponent implements OnInit {
    @ViewChild('overFlowContent') private myScrollContainer: ElementRef;
    @Input() roles: any[];
    @Input() userRole: string;

    userConnected: User;

    rubriqueType = [{ name: 'Magasin',   code: 'MAG' },
                    { name: 'Logistique',   code: 'LOG' },
                    { name: 'Franchisé / Partenaire',   code: 'FRN' },
                    { name: 'Auchan.fr',   code: 'AUCHFR' },
                    { name: 'Drive', code: 'DRIVE'} ,
                    { name: 'Service après-vente', code: 'SAV'},
                    //{ name: 'Service après-vente PRO', code: 'SAV_PRO'},
                    { name: 'Service Appui', code: 'APP'},
                    { name: 'Auchan Direct', code: 'DIR'},
                    { name: 'ARA', code: 'ARA'},
                    { name: 'Energie', code: 'ENER'},
                    //{ name: 'Export', code: 'EXP'}
    ];

    selectedType = this.rubriqueType[0];

    filterValue = '';
    filterValueRole = '';

    filteredRoles;
    allDesignations: Rubrique[];

    coefficients: Coefficient[];

    error = null;
    loading = false;

    formGroup: FormGroup;
    items: FormArray;
    formToDisplay: FormArray;

    parser: math.Parser;

    constructor(
        private rubriqueService: RubriqueService,
        private adminService: AdminService,
        public modal: MatDialog,
        private fb: FormBuilder,
        private toastService: ToastrService,
        private coefficientService: CoefficientService,
        private authenticationService: AuthenticationService,
    ) {
        this.userConnected = this.authenticationService.getCurrentUser();

        if (this.userConnected.role === ROLES.NATIONAL_MAG) {
            this.rubriqueType = [{ name: 'Magasin',   code: 'MAG' },
                                    { name: 'Drive', code: 'DRIVE'}];
        }
        if (this.userConnected.role === ROLES.NATIONAL_LOG) {
            this.rubriqueType = [{ name: 'Log', code: 'LOG' }];
        }
    }

    ngOnInit() {
        this.filteredRoles = [...this.roles.filter(r => r !== 'HYP')];
        this.formGroup = new FormGroup({
            items: new FormArray([], [this.codeUniqueValidateur, this.formulaValidateur]),
        });
        this.items = this.formGroup.get('items') as FormArray;
        this.formToDisplay = this.formGroup.get('items').value;
        this.getCoefficients();
        this.getDesignations();
    }

    updateSelectedTab(type) {
        this.selectedType = type;
        this.resetForm();
        this.getDesignations();
    }
    getDesignations() {
        this.loading = true;
        this.rubriqueService.getAllByType(this.selectedType.code).subscribe(
            data => {
                this.allDesignations = data;
                this.allDesignations.map(d => {
                    if (d.authorizedRoles) {
                        d.authorizedRoles = d.authorizedRoles.replace(/ /g, '').split(',');
                    }
                });

                this.allDesignations.forEach(d => {
                    this.addRow(d);
                });

                this.loading = false;
            },
            () => {
                this.loading = false;
            }
        );
    }

    addRow(item?) {
        this.items.push(this.createItem(item));
        this.applyFilter();
    }
    createItem(item?) {
        return this.fb.group({
            id: new FormControl(item ? item.id : null),
            libelle: new FormControl(item ? item.libelle : '', [Validators.required]),
            code: new FormControl(item ? item.code : '', [Validators.required]),
            calculated: new FormControl(item ? item.calculated : false),
            formula: new FormControl(item ? item.formula : null),
            authorizedRoles: new FormControl(item ? item.authorizedRoles : null),
            toolTipText: new FormControl(item ? item.toolTipText : null),
            type: new FormControl(this.selectedType.code),
            coefficientId: new FormControl(item ? item.coefficientId : 1)
        });
    }

    changeRoleName = input => {
        return this.adminService.changeRoleName(input);
    };

    applyFilter() {
        this.formToDisplay['controls'] = this.formGroup.get('items')['controls'].filter(d => {
            return (
                d.value.libelle
                    .toLowerCase()
                    .trim()
                    .includes(this.filterValue.toLowerCase().trim()) ||
                d.value.code
                    .toLowerCase()
                    .trim()
                    .includes(this.filterValue.toLowerCase().trim()) ||
                (d.value.toolTipText &&
                    d.value.toolTipText
                        .toLowerCase()
                        .trim()
                        .includes(this.filterValue.toLowerCase().trim())) ||
                (d.value.formula &&
                    d.value.formula
                        .toLowerCase()
                        .trim()
                        .includes(this.filterValue.toLowerCase().trim())) ||
                (d.value.authorizedRoles &&
                    d.value.authorizedRoles.find(role => {
                        return (
                            this.changeRoleName(role) &&
                            this.changeRoleName(role)
                                .toLowerCase()
                                .trim()
                                .includes(this.filterValue.toLowerCase().trim())
                        );
                    }))
            );
        });
    }
    applyFilterRole() {
        this.filteredRoles = this.roles.filter(r => {
            const displayedName = this.adminService.changeRoleName(r);
            if (displayedName) {
                return displayedName.trim().includes(this.filterValueRole.toUpperCase().trim());
            }
        });
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 32) {
            // do not propagate spaces to MatSelect, as this would select the currently active option
            event.stopPropagation();
        }
    }

    resetFilteredRole(opened: boolean) {
        if (!opened) {
            this.filterValueRole = '';
            this.filteredRoles = this.roles;
        }
    }

    handleValidatorRequiredFormula(event, i) {
        setTimeout(() => {
            const control = this.items.at(i)['controls']['formula'];
            if (event.checked) {
                control.setValidators([Validators.required]);
            } else {
                control.value = '';
                control.clearValidators();
            }
            control.updateValueAndValidity();
        });
    }
    openModal(designation, i) {
        const modal = this.modal.open(ModalConfirmationComponent, {
            autoFocus: false,
            width: '70%',
            minHeight: '200px',
            data: {
                action: 'Supprimer',
                question: `Êtes vous sûr de vouloir supprimer la rubrique <strong>${designation.libelle}</strong> ?`,
            },
        });
        modal.afterClosed().subscribe(res => {
            if (res) {
                this.deleteDesignation(designation, i);
            }
        });
    }

    resetForm() {
        this.formGroup = new FormGroup({
            items: new FormArray([], [this.codeUniqueValidateur, this.formulaValidateur]),
        });
        this.items = this.formGroup.get('items') as FormArray;
        this.formToDisplay = this.formGroup.get('items').value;
        this.applyFilter();
    }

    cancelAdding() {
        this.resetForm();
        this.allDesignations.forEach(d => {
            this.addRow(d);
        });
        this.toastService.success('Annulation des modifications terminée avec succès', 'Annulation');
    }

    deleteDesignation(designation, i) {
        this.rubriqueService.delete(designation.id).subscribe(
            data => {
                const index = this.allDesignations.findIndex(d => d.id === designation.id);
                this.allDesignations.splice(index, 1);
                this.items.removeAt(i);
                this.applyFilter();
                this.toastService.success('Suppression de la rubrique terminée', 'Suppression');
            },
            () => {
                this.toastService.error('Une erreur est survenue', 'Erreur');
            }
        );
    }

    addDesignation() {
        const designationsToAdd = [...this.formGroup.value.items];

        designationsToAdd.map(d => {
            if (d.authorizedRoles) {
                d.authorizedRoles = d.authorizedRoles.toString();
            }
        });
        this.rubriqueService.upsertAll(designationsToAdd).subscribe(
            data => {
                this.resetForm();
                this.getDesignations();

                this.formGroup.markAsUntouched();
                this.toastService.success('Les données ont été modifiées avec succès', 'Rubrique');
            },
            () => {
                this.toastService.error('Une erreur est survenue', 'Erreur');
            }
        );
    }

    getErrorMessage(i) {
        const controlCode = this.items.at(i)['controls'].code;
        const controlFormula = this.items.at(i)['controls'].formula;
        return controlCode.hasError('alreadyExist')
            ? `${controlCode.value} existe déjà`
            : controlFormula.hasError('formulaError')
                ? controlFormula.errors.formulaError
                : '';
    }

    formulaValidateur = (formArray: FormArray): any => {
        const res = [];
        this.parser = math.parser();
        if (formArray.controls.length > 0) {
            formArray.value.forEach(element => {
                if (element.calculated) {
                    res.push(`${element.code} = ${element.formula}`);
                } else {
                    res.push(`${element.code} = 1`);
                }
            });
            const evaluate = this.evaluate(res, []);

            //Unset all errors on form
            formArray.controls.forEach(c => {
                c['controls'].formula.setErrors(null);
            });

            if (evaluate.errors.length > 0) {
                evaluate.errors.forEach(element => {
                    const code = element.formula.split('=')[0].trim();

                    const index = formArray.value.findIndex(f => f.code === code);
                    const elem = formArray.value.find(f => f.code === code);
                    if (elem.calculated) {
                        formArray.controls[index]['controls'].formula.setErrors({
                            formulaError: element.error,
                        });
                    }
                });
            }
        }
    };

    evaluate(formulas: string[], errorMsg: any[]) {
        let resteAfaire = [];
        let errors = errorMsg;

        formulas.forEach(formula => {
            try {
                this.parser.evaluate(formula);
            } catch (error) {
                errors.push({ error: error.message, formula: formula });
                resteAfaire.push(formula);
            }
        });
        if (formulas.length === resteAfaire.length) {
            return { status: 'error', errors: errors };
        }
        if (resteAfaire.length === 0) {
            return { status: 'ok', errors: [] };
        }

        return this.evaluate(resteAfaire, errors);
    }

    codeUniqueValidateur(formArray: FormArray): any {
        if (formArray.controls.length > 0) {
            const codes = formArray.value.map(f => f.code.toLocaleLowerCase().trim());
            const duplicates = codes.reduce(function (acc, el, i, arr) {
                if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
                return acc;
            }, []);

            let indexArray = [];
            if (duplicates.length > 0) {
                // Get all index of duplicate items
                duplicates.forEach(d => {
                    const index = formArray.value.findIndex(f => f.code.toLocaleLowerCase().trim() === d);
                    indexArray.push(index);
                });
            }

            //Unset all errors on form
            formArray.controls.forEach(c => {
                c['controls'].code.setErrors(null);
            });

            //Set errors on duplicate items
            indexArray.forEach(i => {
                formArray.controls[i]['controls'].code.setErrors({ alreadyExist: true });
            });
        }
    }
    scrollToElement(el): void {
        this.myScrollContainer.nativeElement.scroll({
            top: this.myScrollContainer.nativeElement.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    getCoefficients() {
        this.coefficientService.getAll().subscribe(
            data => {
                this.coefficients = data;
            });
    }
}
