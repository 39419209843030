import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CalendarDay } from '../../_models/calendar-day';
import { Designation } from '../../_models/designation';
import { StoreService } from '../../_services/store/store.service';
import { STEP1TO2, STEP2TO3, STEP_1, STEP_2, STEP_3, STEP_4 } from '../../constants';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { Rubrique } from 'src/app/_models/rubrique';
import { Pattern } from 'src/app/_models/pattern';

@Component({
    selector: 'app-designations-controller',
    templateUrl: './designations-controller.component.html',
    styleUrls: ['./designations-controller.component.css'],
})
export class DesignationsControllerComponent implements OnInit {
    /** Sélecteur permettant d'afficher les tableaux des périodes journalières, mensuelles ou annuelles */
    @Input() tabSelected: string;
    /** Type de compte : (ex: HYP, ESS, RAY)*/
    @Input() accountType: string;
    /** La liste de désignations étendues */
    // @Input() patternsGroups: Pattern[][];
    @Input() motif: Pattern[][];
    /** La ligne selectionnée */
    @Input() lineSelected: string;
    /** Le jour selectionné */
    @Input() daySelected: number;
    /** Les jours pour le journalier */
    @Input() calendarDays: CalendarDay[];
    /** Année pour le type PILOT */
    @Input() pilotYear: number;

    @Input() MV_PILOT_RUBRIQUE: Rubrique;
    @Input() VA_PILOT_RUBRIQUE: Rubrique;



    /** Event pour indiquer la ligne selectionnée */
    @Output() newLineSelected: EventEmitter<string> = new EventEmitter();
    /** Event pour indiquer le jour selectionné */
    @Output() newDaySelected: EventEmitter<string> = new EventEmitter();
    /** Event pour indiquer que le sélecteur à changé */
    @Output() selectorChangeEvent: EventEmitter<string> = new EventEmitter();
    /** Event pour indiquer le changement de step du store */
    @Output() changeStoreStep: EventEmitter<number> = new EventEmitter();
    @Output() hiddenList: EventEmitter<string[]> = new EventEmitter();
    hidden: string[];
    @Output() pilotRepartition: EventEmitter<boolean> = new EventEmitter();

    constructor(public dialog: MatDialog, protected storeService: StoreService) { }

    ngOnInit() {
        this.hidden = [];
        this.lineSelected = '';
    }

    isHidden(pattern: Pattern): boolean {
        if (pattern.parentCode === (null || '0') || this.hidden.length <= 0) {
            return false;
        }
        if (this.hidden.find(code => code === pattern.parentCode)) {
            return true;
        }
    }

    isParentHide(code: string): boolean {
        return this.hidden.findIndex(cd => cd === code) > -1;
    }

    setHide(code: string) {
        if (this.isParentHide(code)) {
            const i = this.hidden.findIndex(cd => cd === code);
            this.hidden.splice(i, 1);
        } else {
            this.hidden.push(code);
        }
        this.hiddenList.emit(this.hidden);
    }

    setAllHidden(isTrue: boolean) {
        if (isTrue) {
            this.hidden = [];
            this.motif.forEach(group => {
                group.forEach(pattern => {
                    if (pattern.parentCode === null) {
                        this.hidden.push(pattern.rubrique.code);
                    }
                });
            });
            this.hiddenList.emit(this.hidden);
        } else if (!isTrue) {
            this.hidden = [];
            this.hiddenList.emit(this.hidden);
        } else {
            return;
        }
    }

    fireNewLineSelected(designationCode) {
        this.lineSelected = this.lineSelected !== designationCode ? designationCode : '';
        this.newLineSelected.emit(designationCode);
    }

    fireNewDaySelected(idDay) {
        this.daySelected = this.daySelected !== idDay ? idDay : null;
        this.newDaySelected.emit(idDay);
    }

    isRubriqueEditable = (rubrique: Rubrique): boolean => {
        if (rubrique.calculated) {
            return false;
        }
        return true;
    };

    hasChildren = (code: string): boolean => {
        return this.motif.some(group =>
            group.some(pattern => pattern.parentCode === code)
        );
    }

    /**
     * Emet le changement du selecteur de période mensuelle / annuelle
     * @param {boolean} selector
     */
    changeSelector(selector: string): void {
        this.pilotRepartition.emit(false);
        if (this.tabSelected === selector) {
            // Pas de changement d'état
            return;
        }
        // Si on est en Step 1 et qu'on passe à l'annuel on affiche un message à l'utilisateur.
        if (
            this.storeService.getStoreSelected().editable &&
            this.storeService.getStoreSelected().step === STEP_1 &&
            selector === 'ANNUEL'
        ) {
            const dialogRef = this.showMessage(STEP1TO2);
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.storeService
                        .updateStoreStep(this.storeService.getStoreSelected().id, STEP_2)
                        .subscribe(res => {
                            this.storeService.setStoreSelectedStep(STEP_2);
                            this.tabSelected = 'ANNUEL';
                            this.selectorChangeEvent.emit(this.tabSelected);
                            this.changeStoreStep.emit(STEP_2);
                        });
                }
            });
        } else if (
            this.storeService.getStoreSelected().editable &&
            this.storeService.getStoreSelected().step === STEP_2 &&
            selector === 'MENSUEL'
        ) {
            const dialogRef = this.showMessage(STEP2TO3);
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.storeService
                        .updateStoreStep(this.storeService.getStoreSelected().id, STEP_3)
                        .subscribe(res => {
                            this.storeService.setStoreSelectedStep(STEP_3);
                            this.tabSelected = 'MENSUEL';
                            this.selectorChangeEvent.emit(this.tabSelected);
                            this.changeStoreStep.emit(STEP_3);
                        });
                }
            });
        } else if (
            (this.storeService.getStoreSelected().step === STEP_3 ||
                this.storeService.getStoreSelected().step === STEP_4) &&
            selector === 'ANNUEL'
        ) {
            // const message = this.storeService.getStoreSelected().step === STEP_4 ? MENTOANNDELTA : MENTOANN;
            // const dialogRef = this.showMessage(message);
            // dialogRef.afterClosed().subscribe(result => {
            //     if (result) {
            this.tabSelected = 'ANNUEL';
            this.selectorChangeEvent.emit(this.tabSelected);
            // }
            // });
        } else if (
            (this.storeService.getStoreSelected().step === STEP_3 ||
                this.storeService.getStoreSelected().step === STEP_4) &&
            selector === 'MENSUEL'
        ) {
            // const message = this.storeService.getStoreSelected().step === STEP_4 ? ANNTOMENDELTA : ANNTOMEN;
            // const dialogRef = this.showMessage(message);
            // dialogRef.afterClosed().subscribe(result => {
            //     if (result) {
            this.tabSelected = 'MENSUEL';
            this.selectorChangeEvent.emit(this.tabSelected);
            //     }
            // });
        } else if (selector === 'JOURNA') {
            this.tabSelected = 'JOURNA';
            this.selectorChangeEvent.emit(this.tabSelected);
        }
    }

    private showMessage(message) {
        return this.dialog.open(MessageDialogComponent, {
            data: message,
        });
    }

    isAccentLine(code) {
        return (code === 'ECO056' && this.accountType !== 'RAY') || (code === 'ECOX19' && this.accountType === 'RAY');
    }

    checkRepartition($event) {
        this.pilotRepartition.emit($event.target.checked);
    }

    addTabToRubrique(code) {
        return ( code === 'P6211_01' || code === 'P6212_01' || code === 'P6213_01' || code === 'AUTREMOE')

    }
}
