import { animate, style, transition, trigger } from '@angular/animations';
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    OnDestroy,
    HostListener,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalLockingComponent } from '../home/modal-locking/modal-locking.component';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription, forkJoin } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { Account } from '../../_models/account';
import { Designation } from '../../_models/designation';
import { Entry } from '../../_models/entry';
import { EntryJourna } from '../../_models/entryJourna';
import { Period } from '../../_models/period';
import { Title } from '../../_models/title';
import { AccountService } from '../../_services/account/account.service';
import { DailyService } from '../../_services/daily/daily.service';
import { EntryService } from '../../_services/entry/entry.service';
import { LockService } from '../../_services/lock/lock.service';
import { StoreService } from '../../_services/store/store.service';
import { TitleService } from '../../_services/title/title.service';
import {
    MONTHS,
    PERIODS_CODE,
    PLAN_COLUMN_CODE,
    REALISER_COLUMN_CODE,
    ENTR,
    PERIODS,
    CONFIG_KEY, ROLES, TOTALMENSUEL,
} from '../../constants';
import { TITLE } from '../../constants'
import { DistributionService } from '../../_services/distribution/distribution.service';
//import { SocketService } from 'src/app/_services/socket/socket.service';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { PatternService } from 'src/app/_services/pattern/pattern.service';
import { Pattern } from 'src/app/_models/pattern';
import * as math from 'mathjs';
import { get, set, uniq } from 'lodash';
import { FormulaService } from 'src/app/_services/formula/formula.service';
import { RubriqueService } from 'src/app/_services/rubrique/rubrique.service';
import { Rubrique } from 'src/app/_models/rubrique';
import { TitleFilterComponent } from '../title-filter/title-filter.component';
import { ConfigService } from 'src/app/_services/config/config.service';
import { Config } from 'src/app/_models/config';
import { Coefficient } from '../../_models/coefficient';
import { CoefficientService } from '../../_services/coefficient/coefficient.service';
import { ExcelService } from 'src/app/_services/excel/excel.service';
import { MessageAccountDialogComponent } from './message-account-dialog/message-account-dialog.component';
import { ReferenceService } from '../../_services/anneeReference/reference.service';
import {AnneeReference} from "../../_models/anneeReference";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.css'],
    animations: [
        trigger('fadeInAnimation', [
            transition(':enter', [style({ opacity: 0 }), animate('400ms', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('0ms', style({ opacity: 0 }))]),
        ]),
        trigger('periodLocked', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('0.4s', style({ transform: 'translateX(0%)' })),
            ]),
            transition(':leave', [animate('0.4s', style({ transform: 'translateX(-100%)' }))]),
        ]),
    ],
})

// TODO: Refacto onEntryValueChanges : les cas d'initialisation n'existent plus ils sont déplacés, simplifier et clean la fn
export class AccountComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

    @HostListener('window:resize', ['$event'])

    onResize() {
        this.setTitlesGroups();
    }

    @Input('account') account: Account;
    @ViewChild('designationsComponent') designationsComponent: ElementRef;
    @ViewChild('arrowButton') arrowButton: ElementRef;
    @ViewChild('deltaComponent') deltaComponent: ElementRef;
    @ViewChild('lockedPeriodComponent') lockedPeriodComponent: ElementRef;
    @ViewChild('headerComponent') headerComponent: ElementRef;

    @Input() currentYear: number;
    @Input() pilotYear: number;

    currentStep: number;

    periodsToDisplay: Period[] = [];
    periodLocked: number;
    periodInEdition: number;
    periodsCode: string[];
    periodsNumberToDisplay: number[] = [];

    delta: Period;
    deltaEntrant: Period;
    periods: Period[];
    annuel: Period;

    parsers: {
        parser: math.Parser;
        periodCode: string;
        titleCode: string;
    }[];

    config: Config[];

    days: any[];
    months: string[];
    dailyPeriod: any;

    cum6Index: number;
    cum12Index: number;

    isNavigation: boolean;

    hiddenDesignations: string[];
    hiddenTitles: string[];
    titleWit: string;

    titles: Title[];
    allTitles: Title[];
    displayedTitles: Title[];
    titlesGroups: any[];
    monthTitles: Title[];
    annualTitles: Title[];

    motif: Pattern[][];
    calculationMatrix: Rubrique[];

    rhtDesignationCode: string;
    tabSelected: string;
    designationsExtended: Designation[];

    lineSelected: string;
    daySelected: number;

    periodLoader: boolean = false;

    dispatchFilter: string;

    defaultHiddenTitles = ['REAL', 'DELTA_REAL_REALN-1', 'PROG_REAL_REALN-1', 'RHT_REAL'];


    piloteRepartition: boolean = false;
    pilotRepartition: boolean = true;

    //connectionsSub: Subscription;
    connections: string;
    connectionsNumber: number;
    isLockingModalOpen: boolean = false;

    VA_PILOT_RUBRIQUE: Rubrique;
    MV_PILOT_RUBRIQUE: Rubrique;
    RHT_RUBRIQUE: Rubrique;

    allCoefficients: Coefficient[];
    referencesYears: AnneeReference[];

    constructor(
        private formulaService: FormulaService,
        private titleService: TitleService,
        public storeService: StoreService,
        private accountService: AccountService,
        private toastService: ToastrService,
        private entryService: EntryService,
        private cd: ChangeDetectorRef,
        private dailyService: DailyService,
        private distributionService: DistributionService,
        private toastrService: ToastrService,
        private lockService: LockService,
        //private socketService: SocketService,
        public modalLock: MatDialog,
        private authenticationService: AuthenticationService,
        private patternService: PatternService,
        private rubriqueService: RubriqueService,
        private matDialog: MatDialog,
        private configService: ConfigService,
        private coefficientService: CoefficientService,
        private excelService: ExcelService,
        private referenceService: ReferenceService,
    ) { }

    ngOnInit() {
       // localStorage.clear()
        /*this.connectionsSub = this.socketService.getObservable().subscribe(c => {
            //this.connectionsNumber = c.length;
            this.connectionsNumber = c;
            //this.connections = this.formatConnectionsForDisplay(c);
        });*/



        this.designationsExtended = [];
        this.tabSelected = 'ANNUEL';

        this.hiddenDesignations = [];
        this.hiddenTitles = JSON.parse(localStorage.getItem('Titles'));
        if (this.hiddenTitles == null) {this.hiddenTitles = this.defaultHiddenTitles }
        if(!this.hiddenTitles.includes("OBJ_X")) {
            this.hiddenTitles.push("OBJ_X");
        }
        localStorage.setItem('Titles', JSON.stringify(this.hiddenTitles));

        if(ROLES.LIST.HIDDEN_TITLES.includes(this.storeService.getStoreSelected().category))
        {
            console.log(' type '+this.storeService.getStoreSelected().category)
            this.hiddenTitles.push("ENTR");
            this.hiddenTitles.push("DELTA_PLAN_ENTR");
            this.hiddenTitles.push("DELTA_ENTR_EST");
            this.hiddenTitles.push("PROG_BDGT_EST");
            this.hiddenTitles.push("RHT_ENTR");
            localStorage.setItem('Titles', JSON.stringify(this.hiddenTitles));
        }


        this.motif = [];
        this.days = [];
        this.months = MONTHS;

        this.titleService.getHiddenTitlesObservable().subscribe(hiddenTitles => {
            setTimeout(() => {
                this.hiddenTitles = hiddenTitles;
                this.hiddenTitles.push("OBJ_X");
                localStorage.setItem('Titles', JSON.stringify(this.hiddenTitles));
                this.displayedTitles = this.titles.filter(t => !this.hiddenTitles.some(ht => ht === t.code))
                this.setTitlesGroups();
            }, 500);
        });

        this.coefficientService.getAll().subscribe(
            data => {
                setTimeout(() => {
                    this.allCoefficients = data;
                }, 500);
            }
        );

        // get referenceYear
        this.referenceService.getAll().subscribe(
            data => {
                setTimeout(() => {
                    this.referencesYears = data
                }, 500);

            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        this.hiddenTitles = JSON.parse(localStorage.getItem('Titles'));
        if (this.hiddenTitles == null) { this.hiddenTitles = this.defaultHiddenTitles; }

        if (changes.account) {
            this.account = changes.account.currentValue;

            // Reset variables
            this.tabSelected = 'ANNUEL';
            this.periodsToDisplay = [];
            this.periodInEdition = null;
            this.periodLocked = null;
            this.motif = [];
            this.calculationMatrix = [];
            this.getAccountData();
        }
    }

    ngAfterViewChecked() {
        this.cd.detectChanges();
    }

    protected openFilters = () => {
        const dialogRef  = this.matDialog.open(TitleFilterComponent, {
            data: {
                titles: this.titles,
                currentYear: this.currentYear,
                hiddenTitles: this.hiddenTitles,
                category: this.storeService.getStoreSelected().category,
            }
        });
    }

    /**
     * Emits warning if compulsory config element is not found
     */
    private checkConfig = (configs: Config[]) => {
        const compulsory_config_key = [CONFIG_KEY.PILOT_RUBRIQUE_MSV, CONFIG_KEY.PILOT_RUBRIQUE_VASE, CONFIG_KEY.RHT_RUBRIQUE_REFERENCE];
        compulsory_config_key.forEach(key => {
            const config = configs.find(c => c.key === key);
            if (config == null || config.value == null) {
                this.toastService.warning(`Veuillez compléter l'administration 'Divers' pour assurer le bon fonctionement du CEX`, `Avertissement`)
                return;
            }
        })
    }

    /**
     * Set MV_PILOT_RUBRIQUE.code and VA_PILOT_RUBRIQUE.code according to config
     */
    private setPilotDesignations = (configs: Config[]) => {
        const mv_conf = configs.find(c => c.key === CONFIG_KEY.PILOT_RUBRIQUE_MSV);
        const va_conf = configs.find(c => c.key === CONFIG_KEY.PILOT_RUBRIQUE_VASE);
        const rht_conf = configs.find(c => c.key === CONFIG_KEY.RHT_RUBRIQUE_REFERENCE);

        const mv_id = mv_conf ? mv_conf.value : null;
        const va_id = va_conf ? va_conf.value : null;
        const rht_id = rht_conf ? rht_conf.value : null;

        this.MV_PILOT_RUBRIQUE = this.calculationMatrix.find(r => r.code === mv_id)
        this.VA_PILOT_RUBRIQUE = this.calculationMatrix.find(r => r.code === va_id)
        this.RHT_RUBRIQUE = this.calculationMatrix.find(r => r.code === rht_id)
    }

    private setTitlesGroups = () => {
        let small = 0;
        let wide = 0;
        let media = window.matchMedia("screen and (min-width: 800px) and (max-width: 1440px)");
        if (media.matches) {
            small = 46
            wide = 70
        } else {
            small = 50
            wide = 90
        }
        if (this.titles == null) { return }
        const newGroups = []
        const groups = uniq(this.titles
            .filter(t => !this.hiddenTitles.some(ht => ht === t.code))
            .map(t => t.group)
            .filter(g => g != null));
        groups.forEach(group => {
            let width = 0;
            const titles = this.titles
                .filter(t => t.group === group)
                .filter(tg => !this.hiddenTitles.some(ht => ht === tg.code))
            titles.forEach(t => {
                width += t.type === 1 ? wide : small;
            })
            newGroups.push({
                name: group,
                width,
                value: this.getTitleGroupValue(group)
            })
        })
        this.titlesGroups = newGroups;
    }

    private getTitleGroupValue = (group): string => {
        let year = this.currentYear;
        switch (group) {
            case 'N-1':
                year = year - 1;
                break;
            case 'N+1':
                year += 1;
                break;
        }
        return `${year} (${group})`;
    }

    private getAccountData() {
        this.periodLoader = true;

        let patternType = this.account.type;

        if (['HYP', 'SUP', 'ULT'].includes(patternType)) {
            patternType = 'MAG';
        }
        if (['HYPCO', 'SUPCO'].includes(patternType)) {
            patternType = 'HYPCO';
        }

        let rubriqueType = this.account.type;
        if (['HYP', 'SUP', 'ULT','HYPCO', 'SUPCO','ESS','RAY'].includes(rubriqueType)) {
            rubriqueType = 'MAG';
        }
        if (['FRNCO','FRN_ESS'].includes(rubriqueType)) {
            rubriqueType = 'FRN';
        }
        forkJoin([
            this.titleService.getViewByPosition().pipe(take(1)),
            this.accountService.getAccountById(this.account.id).pipe(take(1)),
            this.patternService.getByCategory(patternType).pipe(take(1)),
            this.rubriqueService.getAllByType(rubriqueType).pipe(take(1)),
            this.configService.getAll().pipe(take(1)),
        ]).subscribe(data => {
            this.periodLoader = false;

            this.titles = data[0];
            this.allTitles = data[0];
            this.account = data[1];
            this.motif = data[2];
            this.calculationMatrix = this.formulaService.reorganiseByDegree(data[3]);

            this.config = data[4];
            this.checkConfig(this.config);
            this.setPilotDesignations(this.config);

            this.cum6Index = this.getPeriodIndex(8);
            this.cum12Index = this.getPeriodIndex(16);

            this.delta = JSON.parse(JSON.stringify(this.account.periods.find(period => period.number === 18)));
            this.delta.entries = this.delta.entries.filter(e => {
                return e.title === null;
            });
            this.deltaEntrant = JSON.parse(JSON.stringify(this.account.periods.find(period => period.number === 18)));
            this.deltaEntrant.entries = this.deltaEntrant.entries.filter(e => {
                return e.title !== null && e.title.code === 'ENTR';
            });

            //remove delta from basic periods
            const deltaIndex = this.account.periods.findIndex(period => period.number === 18);
            this.account.periods.splice(deltaIndex, 1);

            this.initAccount();
        });
    }

    private refreshTitles = (titles: Title[]) => {
        const monthTitles = titles.filter(t => t.view === 'ALWAYS' || t.view === 'MONTH');
        const annualTitles = titles.filter(t => t.view === 'ALWAYS' || t.view === 'ANNUAL');
        this.titles = this.tabSelected === 'MENSUEL' ? monthTitles : annualTitles;
        this.displayedTitles = this.hiddenTitles ?
            this.titles.filter(t => !this.hiddenTitles.some(ht => ht === t.code)) :
            this.titles.filter(() => true);

        this.setTitlesGroups();
    }

    private organizeTitles = (titles: Title[]): Title[] => {
        const DELTA = titles.filter(t => t.code.includes('DELTA'))
        const RHT = titles.filter(t => t.code.includes('RHT'))
        const PROG = titles.filter(t => t.code.includes('PROG'))
        const PDS = titles.filter(t => t.code.includes('PDS'))
        const RAW = titles.filter(
            t => !t.code.includes('DELTA')
                && !t.code.includes('PROG')
                && !t.code.includes('PDS')
                && !t.code.includes('RHT'))
        return RAW
            .concat(...DELTA)
            .concat(...RHT)
            .concat(...PROG)
            .concat(...PDS)
    }

    private initAccount(): void {
        ['HYP', 'SUP', 'ULT','DRIVE','MAR','ESS'].includes(this.account.type) ?  this.titleWit ='OBJ_X' :  this.titleWit ='OBJ';
        this.annuel = {
            code: 'ANNUEL',
            number: 0,
            entryMatrix: {},
            isRealLocked: this.account.isAnnualRealLocked,
            isLocked: this.account.isAnnualObjLocked,
            locks: this.delta.locks,
        };

        this.isNavigation = true;
        this.periodsNumberToDisplay = [1, 2, 3];
        this.periodsCode = PERIODS_CODE;

        this.generateParsers();
        this.initEntryMatrixes();
        this.generateEntries();

        this.getOffSet();

        this.refreshTitles(this.allTitles);

/*        this.referenceService.getAll().subscribe(
            data => {
                setTimeout(() => {
                    const accountCategory = ['HYP', 'SUP', 'ULT'].includes(this.account.type) ? 'MAG' : this.account.type;
                    if(typeof data.find(e => e.category === accountCategory) !== "undefined") {
                        this.titleWit = data.find(e => e.category === accountCategory).code;
                    }

                }, 500);

            }
        );*/


    }

    /**
     * Creates a parser for each period for each title
     */
    private generateParsers = () => {
        this.parsers = [];

        //Classic periods
        this.account.periods.forEach((p: Period) => {
            this.allTitles.forEach((t: Title) => {
                this.parsers.push({
                    parser: this.initParser(math.parser()),
                    periodCode: p.code,
                    titleCode: t.code,
                });
            });
        });

        //Annuel
        this.allTitles.forEach((t: Title) => {
            this.parsers.push({
                parser: this.initParser(math.parser()),
                periodCode: 'ANNUEL',
                titleCode: t.code,
            });
        });

        //Delta
        this.parsers.push({
            parser: this.initParser(math.parser()),
            periodCode: 'DELTA',
            titleCode: null,
        });

        //Delta Entrants
        this.parsers.push({
            parser: this.initParser(math.parser()),
            periodCode: 'DELTA',
            titleCode: 'ENTR',
        });
    };

    /**
     *  Initialise les valeurs des entryMatrixes à 0 pour les periodes, annuel et delta
     * @param done
     */
    private initEntryMatrixes = () => {
        this.account.periods.forEach((period, index) => {
            this.motif.forEach(group => {
                for (const pattern of group) {
                    set(this.delta, ['entryMatrix', pattern.rubrique.code], { id: null, value: 0, role: null });
                    set(this.deltaEntrant, ['entryMatrix', pattern.rubrique.code], {
                        id: null,
                        value: 0,
                        role: null,
                    });

                    this.allTitles.forEach(title => {
                        set(period, ['entryMatrix', pattern.rubrique.code, title.code], {
                            id: null,
                            value: 0,
                            role: null,
                        });
                        if (index === 0) {

                            set(this.annuel, ['entryMatrix', pattern.rubrique.code, title.code], {
                                id: null,
                                value: 0,
                                role: null,
                            });
                        }
                    });
                }
            });
        });
    };

    getPeriodIndex(periodNumber): number {
        return this.account.periods.findIndex(period => period.number === periodNumber);
    }

    /**
     * Fetch parser according to its periodCode and titleCode
     */
    private getParser = (periodCode: string, titleCode: string): math.Parser => {
        const found = this.parsers.find(pars => pars.periodCode === periodCode && pars.titleCode === titleCode);
        return found ? found.parser : null;
    };

    /**
     * Puts inital entryMatrixes raw values in the parser, for each period, title and desgination
     */
    private initParser = (parser: math.Parser) => {
        this.calculationMatrix.forEach((r: Rubrique) => {
            parser.set(r.code, 0);
        });
        return parser;
    };

    /**
     *
     */
    private generateEntries() {
        // TODO: Add role from entries
        // TODO: Remove Id

        const rubriquesToUpdate = this.calculationMatrix
            .filter(r => r.calculated);

        const titlesToUpdate = this.allTitles
            .filter(t => [TITLE.EST, TITLE.OBJ, TITLE.REALN_1, TITLE.REAL, TITLE.PLAN, TITLE.OBJ_X].includes(t.code))

        //Init delta with existing values
        const deltaParser = this.getParser('DELTA', null);
        this.delta.entries.forEach(entry => {
            if (this.calculationMatrix.some(r => r.code === entry.designation.code) && entry.title === null) {
                set(this.delta, ['entryMatrix', entry.designation.code],
                    {
                        id: entry.id,
                        value: Number(entry.value),
                    }
                );
                deltaParser.set(entry.designation.code, entry.value);
            }
        });
        rubriquesToUpdate.forEach(r => {
            const result = +this.getParser('DELTA', null).evaluate(`${r.code} = ${r.formula}`);
            set(this.delta, ['entryMatrix', r.code, 'value'], result);
        })

        //Init delta entrants with existing values
        const deltaEntrantParser = this.getParser('DELTA', ENTR);
        this.deltaEntrant.entries.forEach(entry => {
            if (this.calculationMatrix.some(r => r.code === entry.designation.code) && entry.title !== null) {
                set(this.deltaEntrant, ['entryMatrix', entry.designation.code],
                    {
                        id: entry.id,
                        value: Number(entry.value),
                    }
                );
                deltaEntrantParser.set(entry.designation.code, +entry.value);
                const rubrique = this.calculationMatrix.find(r => r.code === entry.designation.code);

                if (rubrique) {
                    const dependencies = this.getLinkedRubriques(rubrique.code);
                    dependencies.concat([rubrique]).forEach(d => {
                        this.calculateAnnuel([d], entry.title.code);
                    });
                }
            }
        });

        // init periods with existing values
        this.account.periods.forEach(async (period, periodIndex) => {
            if (period.number !== 8 && period.number !== 16) {
                period.entries.forEach(entry => {

                    if (this.calculationMatrix.some(r => r.code === entry.designation.code)) {
                        set(period, ['entryMatrix', entry.designation.code, entry.title.code, 'id'], entry.id); // TODO: useless ?
                        set(period, ['entryMatrix', entry.designation.code, entry.title.code, 'role'], entry.role);

                        set(period, ['entryMatrix', entry.designation.code, entry.title.code, 'value'], +entry.value);

                        const currentParser = this.getParser(period.code, entry.title.code);
                        currentParser.set(entry.designation.code, +entry.value);
                    }
                    return;
                });
                //this.ECO7072(period);
                // All raw set here + eco7072

                //TODO: Order calculus
                const dependencies = this.getLinkedRubriques(null);


                titlesToUpdate.forEach(t =>
                    rubriquesToUpdate.forEach(r => {
                        const result = +this.getParser(period.code, t.code).evaluate(`${r.code} = ${r.formula}`);

                        set(period, ['entryMatrix', r.code, t.code, 'value'], result);
                    })
                )
                //All sums set here
            }
            // delete period.entries;
        });

        titlesToUpdate.forEach(t => {
            this.calculateAnnuel(this.calculationMatrix, t.code);
            this.calculateCum(this.calculationMatrix, t.code);
        })
        // Raw + sums on annual set here + cum


        this.account.periods.forEach(async (period) => {
            this.calculateColumns(period);
        })
        this.calculateColumns(this.annuel);

        // All done here, calculated columns on month + annual
    }


    private onEntryValueChange = async (
        value: number,
        periodId: number,
        titleCode: string,
        code: string,
        needUpdate?: boolean
    ) => {
        try {
            const isInit = !needUpdate;
            // Object context reconstruction
            const period = periodId ? this.account.periods.find(p => p.id === periodId) : this.annuel;
            const title = this.allTitles.find(t => t.code === titleCode);
            const rubrique = this.calculationMatrix.find(r => r.code === code);
            if (!period || !title || !rubrique) {
                return;
            }
            if (titleCode === TITLE.RHT_PLAN) {
                console.log('VALUE : ',value)
                const RHTHE = get(period, ['entryMatrix', this.RHT_RUBRIQUE.code, TITLE.PLAN, 'value'], 0);
                console.log('RHTHE : ',RHTHE)
                const calculatedPlan = value * RHTHE / 100;
                console.log('calculatedPlan : ',calculatedPlan)
                this.onEntryValueChange(calculatedPlan, periodId, TITLE.PLAN, code, true);
                return;
            }
            if (titleCode === TITLE.PROG_PLAN_EST) {
                const est = get(period, ['entryMatrix', code, TITLE.EST, 'value'], 0);
                const calculatedPlan = Math.abs(est) * (value / 100) + est;
                this.onEntryValueChange(calculatedPlan, periodId, TITLE.PLAN, code, true);
                return;
            }

            const currentValue = +get(period, ['entryMatrix', code, titleCode, 'value']);
            const currentRole = get(period, ['entryMatrix', code, titleCode, 'role'], null);
            const currentUser = get(period, ['entryMatrix', code, titleCode, 'byUser'], null);
            const currentId = get(period, ['entryMatrix', code, titleCode, 'id']);
            const diff = currentValue - value;

            const parser = this.getParser(period.code, title.code);
            const deltaParser = this.getParser('DELTA', null);
            const deltaEntrantParser = this.getParser('DELTA', ENTR);

            let entry = { id: currentId, value: value, role: currentRole, byUser: currentUser };
            // If !needUpdate pas d'action en base
            // cas 1: entree mensuelle
            if (period.code !== 'ANNUEL') {
                if (needUpdate) {
                    const entryToSend: Entry = {
                        id: entry.id,
                        periodId: period.id,
                        accountId: this.account.id,
                        titleId: title.id,
                        designationId: rubrique.id,
                        yearId: 3,
                        value: +entry.value,
                        role: this.authenticationService.getCurrentUser().role,
                        byUser: this.authenticationService.getCurrentUser().login,
                    };

                    await this.entryService.saveAll([entryToSend]).toPromise();
                    entry.value = +entryToSend.value;
                    entry.role = this.authenticationService.getCurrentUser().role;
                    entry.byUser = this.authenticationService.getCurrentUser().login;
                }
                // Mettre a jour la valeur non calculee dans le parser puis entrymatrix

                parser.set(code, +value);

                set(period, ['entryMatrix', code, titleCode, 'value'], Number(entry.value));
                set(period, ['entryMatrix', code, titleCode, 'id'], entry.id);
                set(period, ['entryMatrix', code, titleCode, 'role'], entry.role);
                set(period, ['entryMatrix', code, titleCode, 'byUser'], entry.byUser);
                // MAJ DELTA
                if (needUpdate && titleCode === PLAN_COLUMN_CODE) {
                    //Si on est pas dans une intialisation, on module delta selon DIFF
                    const newDeltaValue = +deltaParser.evaluate(`${code} = ${code} - ${diff}`);
                    const idDeltaActuel = get(this.delta, ['entryMatrix', code, 'id']);

                    const entryToSend: Entry = {
                        id: idDeltaActuel,
                        periodId: 15,
                        accountId: this.account.id,
                        titleId: null,
                        designationId: rubrique.id,
                        yearId: 3,
                        value: +newDeltaValue,
                        role: this.authenticationService.getCurrentUser().role,
                        byUser: this.authenticationService.getCurrentUser().login,
                    };

                    await this.entryService.saveAll([entryToSend]).toPromise();
                    entry.value = +entryToSend.value;
                    set(this.delta, ['entryMatrix', code, 'value'], Number(newDeltaValue));
                }

                // Mettre à jour les formules liées récursivement dans le parser
                // Mettre a jour les valeurs dans l'entrymatrix associée
                const dependencies = this.getLinkedRubriques(code);
                dependencies.forEach(d => {
                    if (d.code === 'ECO7072_OLD') {
                        return;
                    }
                    // Update value
                    let newValue = +parser.evaluate(`${d.code} = ${d.formula}`);
                    set(period, ['entryMatrix', d.code, titleCode, 'value'], Number(newValue));
                    // Update delta value
                    if (titleCode === PLAN_COLUMN_CODE) {
                        const newDeltaValue = +deltaParser.evaluate(`${d.code} = ${d.formula}`);
                        set(this.delta, ['entryMatrix', d.code, 'value'], Number(newDeltaValue));
                    }
                });


                //this.ECO7072(period) //TODO: test if useful
                //this.ECO7072(this.annuel) //TODO: test if useful

                // MAJ Annuel CUM6 CUM 12
                this.organizeTitles(this.titles).forEach(t => {
                    this.calculateAnnuel(this.calculationMatrix, t.code);
                    this.calculateCum(this.calculationMatrix, t.code);
                })

                this.account.periods.forEach(async (period) => {
                    this.calculateColumns(period);
                })
                this.calculateColumns(this.annuel);

            } else {
                // 2 cas: entree  annuelle
                //si on est en initialisation pas besoin d'action
                if (isInit || (titleCode !== PLAN_COLUMN_CODE && titleCode !== ENTR)) {

                    return;
                }

                let titleId = null;
                let currentDelta = null;
                let parser = null;
                if (titleCode === ENTR) {

                    this.dispatchFilter = 'NO_REP';
                    titleId = 18;
                    currentDelta = this.deltaEntrant;
                    parser = deltaEntrantParser;
                } else if (titleCode === PLAN_COLUMN_CODE) {

                    currentDelta = this.delta;
                    parser = deltaParser;
                }
                switch (this.dispatchFilter) {
                    case 'NO_REP':

                        //si pas de rep on update delta
                        let newDeltaValue;

                        //inversion du sens des delta entre entrant et repere
                        if (titleCode === TITLE.ENTR) {

                            newDeltaValue = +parser.evaluate(`${code} = ${code} - ${diff}`);
                        } else {

                            newDeltaValue = +parser.evaluate(`${code} = ${code} + ${diff}`);
                        }
                        const idDeltaActuel = get(currentDelta, ['entryMatrix', code, 'id']);

                        const entryToSend: Entry = {
                            id: idDeltaActuel,
                            periodId: 15,
                            accountId: this.account.id,
                            titleId: titleId,
                            designationId: rubrique.id,
                            yearId: 3,
                            value: +newDeltaValue,
                            role: this.authenticationService.getCurrentUser().role,
                            byUser: this.authenticationService.getCurrentUser().login,
                        };

                        await this.entryService.saveAll([entryToSend]).toPromise();
                        entry.value = +entryToSend.value;
                        set(currentDelta, ['entryMatrix', code, 'value'], Number(entry.value));
                        set(currentDelta, ['entryMatrix', code, 'id'], entry.id);

                        //lancer un recalcul de annuel
                        let dependencies = this.getLinkedRubriques(code)


                        dependencies.forEach(d => {
                            if (d.code === 'ECO7072_OLD') {
                                return;
                            }
                            // Update value
                            let newValue = +parser.evaluate(`${d.code} = ${d.formula}`);

                            set(period, ['entryMatrix', d.code, titleCode, 'value'], Number(newValue));
                            // Update delta value
                            if (titleCode === PLAN_COLUMN_CODE) {

                                const newDeltaValue = +deltaParser.evaluate(`${d.code} = ${d.formula}`);
                                set(this.delta, ['entryMatrix', d.code, 'value'], Number(newDeltaValue));
                            }
                        });

                        this.calculateAnnuel([rubrique].concat(dependencies), titleCode); // met a jour la valeur changée en annuel
                        this.account.periods.forEach(p => {
                            if (p.code === 'CUM6' || p.code === 'CUM12') {
                                return;
                            }
                            this.calculateColumns(p);
                        });
                        this.calculateColumns(period);


                        break;
                    case 'REP_12':
                    // break;
                    case 'REP_POIDS':
                        //si pas de rep on update delta
                        const newDeltaValue12 = +parser.evaluate(`${code} = 0`);
                        const idDeltaActuel12 = get(currentDelta, ['entryMatrix', code, 'id']);

                        const entryToSend12: Entry = {
                            id: idDeltaActuel12,
                            periodId: 15,
                            accountId: this.account.id,
                            titleId: titleId,
                            designationId: rubrique.id,
                            yearId: 3,
                            value: newDeltaValue12,
                            role: this.authenticationService.getCurrentUser().role,
                            byUser: this.authenticationService.getCurrentUser().login,
                        };

                        await this.entryService.saveAll([entryToSend12]).toPromise();
                        entry.value = +entryToSend12.value;
                        set(currentDelta, ['entryMatrix', code, 'value'], Number(entry.value));
                        set(currentDelta, ['entryMatrix', code, 'id'], entry.id);
                    // break;
                }
                // Attention prendre en compte les mois verrouillés
            }

            if (![TITLE.EST,TITLE.ENTR].includes(titleCode)) {
                if (period.code === 'ANNUEL') {
                    const rest = await this.entryService.getCheckTotalYear(this.account.id, rubrique.id, title.id).toPromise();
                    if (rest) {
                        this.showMessage(TOTALMENSUEL);
                    }
                }
            }
            // commune
        } catch (e) {
        }
    };

    /**
     * MAJ du CUM6 CUM12 pour les rubriques entrées sur el titleCode donné
     */
    private calculateCum = (rubriques: Rubrique[], titleCode: string) => {
        const cum6period = this.account.periods.find(p => p.code === 'CUM6');
        const cum12period = this.account.periods.find(p => p.code === 'CUM12');

        rubriques.forEach(rubrique => {
            let totalValue = 0;
            const cum6periods = this.account.periods.filter(period => period.number < 8);
            cum6periods.forEach(p => {
                const periodValue = +get(p, ['entryMatrix', rubrique.code, titleCode, 'value']);
                totalValue += periodValue ? periodValue : 0;
            });
            set(cum6period, ['entryMatrix', rubrique.code, titleCode, 'value'], totalValue);
        });

        rubriques.forEach(rubrique => {
            let totalValue = 0;
            const cum12periods = this.account.periods.filter(period => period.number < 16 && period.code !== 'CUM6');
            cum12periods.forEach(p => {
                const periodValue = +get(p, ['entryMatrix', rubrique.code, titleCode, 'value']);
                totalValue += periodValue ? periodValue : 0;
            });
            set(cum12period, ['entryMatrix', rubrique.code, titleCode, 'value'], totalValue);
        });
    };

    /**
     * Va chercher les valeurs des 14 mois et set la valeur d'annuel en conséquence
     */
    private calculateAnnuel = (rubriques: Rubrique[], titleCode: string) => {
        rubriques.forEach(rubrique => {
            let totalValue = 0;
            this.account.periods.forEach(p => {
                if (['CUM6', 'CUM12'].includes(p.code)) {
                    return;
                }
                const periodValue = +get(p, ['entryMatrix', rubrique.code, titleCode, 'value'], 0);
                totalValue += periodValue ? periodValue : 0;
            });
            // On prend en compte DELTA si c'est un PLAN
            if (titleCode === PLAN_COLUMN_CODE) {
                totalValue -= +get(this.delta, ['entryMatrix', rubrique.code, 'value'], 0);
            }
            // On prend en compte uniquement DELTA si c'est un entrant
            if (titleCode === ENTR) {
                if (rubrique.calculated) {
                    totalValue = +this.getParser('DELTA', ENTR).evaluate(`${rubrique.code} = ${rubrique.formula}`);
                } else {
                    totalValue = +this.getParser('DELTA', ENTR).get(rubrique.code);
                }
            }
            set(this.annuel, ['entryMatrix', rubrique.code, titleCode, 'value'], totalValue);

        });
    };

    /**
     *
     */
    private getLinkedRubriques = (code: string): Rubrique[] => {
        const calculatedRubriques = this.calculationMatrix.filter(r => r.calculated);
        const linkedFormulas = this.formulaService.getLinkedRubriques(calculatedRubriques, code);

        return linkedFormulas;
    };

    /**
     * @param p {Period} period
     * @param done {callback}
     * */
    private calculateColumns = (p: Period) => {

        const rhtTitles = this.allTitles.filter(t => t.code.includes('RHT_')).map(t => t.code)
        const progTitles = this.allTitles.filter(t => t.code.includes('PROG_')).map(t => t.code)
        const deltaTitles = this.allTitles.filter(t => t.code.includes('DELTA_')).map(t => t.code)
        const poidsTitles = this.allTitles.filter(t => t.code.includes('PDS_')).map(t => t.code)

        this.motif.forEach(group => {
            for (const pattern of group) {
                const d = pattern.rubrique.code;

                let planValue = +get(p, ['entryMatrix', d, TITLE.PLAN, 'value'], 0);

                //BDGT
                set(p, ['entryMatrix', d, TITLE.BDGT, 'value'], planValue);

                // RHT
                const config_RHT = this.config.find(c => c.key === CONFIG_KEY.RHT_RUBRIQUE_REFERENCE);
                const rubrique_RHT = this.calculationMatrix.find(r => config_RHT ? r.code === config_RHT.value : null);
                if (rubrique_RHT) {
                    rhtTitles.forEach(rhtCode => {
                        let refTitleCode = rhtCode.split('_')[1]; // RHT_REALN-1 => REALN-1
                        if (refTitleCode === 'BDGT') { refTitleCode = 'PLAN' } //TODO: remove or change title
                        const refValue = +get(p, ['entryMatrix', rubrique_RHT.code, refTitleCode, 'value'], 0);
                        const rhtValue = +get(p, ['entryMatrix', d, refTitleCode, 'value'], 0);
                        const result = refValue !== 0 ? (rhtValue / refValue) * 100 : 0;
                        set(p, ['entryMatrix', d, rhtCode, 'value'], result);
                    })
                }

                // PROGS
                progTitles.forEach(progCode => {
                    let refTitleCodes = progCode.split('_'); // PROG_OBJ_REALN-1 => [PROG, OBJ, REALN-1]
                    if (refTitleCodes[1] === 'BDGT') { refTitleCodes[1] = 'PLAN' } //TODO: remove or change title
                    const refValue = +get(p, ['entryMatrix', d, refTitleCodes[1], 'value'], 0);
                    const progValue = +get(p, ['entryMatrix', d, refTitleCodes[2], 'value'], 0);
                    const result = this.calcProgression(progValue, refValue);
                    set(p, ['entryMatrix', d, progCode, 'value'], result);

                    if (p.id===1 && d==='CG0011' && progCode ==='PROG_BDGT_EST' ) {
                        console.log('--------- CALCUL PROGRESSION ------');
                        console.log('- IN ------');
                        console.log('> Period    : ',p.id);
                        console.log('> Rubrique  : ',d);
                        console.log('> refValue  : ',refValue);
                        console.log('> progValue : ',progValue);
                        console.log('> refTitleCodes[1] : ',refTitleCodes[1]);
                        console.log('> refTitleCodes[2] : ',refTitleCodes[2]);
                        console.log('- OUT ------');
                        console.log('> PROG % = ',result);
                    }

                })

                // DELTAS
                deltaTitles.forEach(deltaCode => {
                    let refTitleCodes = deltaCode.split('_'); // DELTA_OBJ_REALN-1 => [DELTA, OBJ, REALN-1]
                    if (refTitleCodes[1] === 'BDGT') { refTitleCodes[1] = 'PLAN' } //TODO: remove or change title
                    const refValue = +get(p, ['entryMatrix', d, refTitleCodes[1], 'value'], 0);
                    const deltaValue = +get(p, ['entryMatrix', d, refTitleCodes[2], 'value'], 0);
                    const result = refValue - deltaValue;
                    set(p, ['entryMatrix', d, deltaCode, 'value'], result);
                })

                // POIDS%
                poidsTitles.forEach(poidsCode => {
                    let refTitleCodes = poidsCode.split('_'); // PDS_REAL => [PDS, REAL]
                    if (refTitleCodes[1] === 'BDGT') { refTitleCodes[1] = 'PLAN' } //TODO: remove or change title
                    const refValue = +get(this.annuel, ['entryMatrix', d, refTitleCodes[1], 'value'], 0);
                    const deltaValue = +get(p, ['entryMatrix', d, refTitleCodes[1], 'value'], 0);
                    const result = refValue !== 0 ? deltaValue / refValue : 0;
                    set(p, ['entryMatrix', d, poidsCode, 'value'], result);
                })

            }
        })
    }

    private ECO7072_OLD = (p: Period) => {
        const d = 'ECO7072_OLD';
        const realValue = +get(p, ['entryMatrix', d, TITLE.REAL, 'value'], 0);
        // RHT
        const config_RHT = this.config.find(c => c.key === CONFIG_KEY.RHT_RUBRIQUE_REFERENCE);
        const rubrique_RHT = this.calculationMatrix.find(r => config_RHT ? r.id === +config_RHT.value : null);
        if (!rubrique_RHT) {
            return
        }
        const ECO21EST = +get(p, ['entryMatrix', rubrique_RHT.code, TITLE.EST, 'value'], 0);
        const ECO21PLAN = +get(p, ['entryMatrix', rubrique_RHT.code, TITLE.PLAN, 'value'], 0);
        const CC7071EST = +get(p, ['entryMatrix', 'CC7071', TITLE.EST, 'value'], 0);
        const CC7071PLAN = +get(p, ['entryMatrix', 'CC7071', TITLE.PLAN, 'value'], 0);
        let coef = (ECO21PLAN + CC7071PLAN - (ECO21EST + CC7071EST)) / Math.abs(ECO21EST + CC7071EST);
        if (isNaN(coef) || !isFinite(coef)) {
            coef = 0;
        }

        const parser = this.getParser(p.code, PLAN_COLUMN_CODE);
        parser.set(d, realValue * (1 + coef));

        set(
            p,
            ['entryMatrix', d, TITLE.PLAN, 'value'],
            isNaN(realValue * (1 + coef)) ? 0 : realValue * (1 + coef)
        );
        set(
            this.annuel,
            ['entryMatrix', d, TITLE.ENTR, 'value'],
            +get(this.annuel, ['entryMatrix', d, TITLE.PLAN, 'value'])
        );
    }


    private calcProgression(startValue: number, endValue: number): number {
        const prog = ((endValue - startValue) / Math.abs(startValue)) * 100;
        return isNaN(prog) ? 0 : Math.round(prog * 100) / 100;
    }


    /**
     * TODO: Clean
     * Envoie une entrée saisie dans un composant Period au service puis dispatch la valeur reçue
     * @param periodId {number} Id de la période
     * @param $event {Entry} Entrée à mettre à jour
     * */
    public handleEntry(periodId: number, $event: Entry): void {

        const entry: Entry = $event;
        this.onEntryValueChange(+entry.value, periodId, entry.title.code, entry.designation.code, true);

        if (entry.designation.code === this.VA_PILOT_RUBRIQUE.code || entry.designation.code === this.MV_PILOT_RUBRIQUE.code) {
            const year = entry.title.code === PLAN_COLUMN_CODE ? PLAN_COLUMN_CODE : REALISER_COLUMN_CODE;
            const deltaDailyEntry: EntryJourna = {
                annee: entry.title.code === PLAN_COLUMN_CODE ? this.pilotYear + 1 : this.pilotYear,
                mois: this.getMonthOfPeriodById(periodId),
                jour: 0,
                accountId: this.account.id,
            };
            this.dailyService
                .getAllByAccountAndPeriod(this.account.id, this.getMonthOfPeriodById(periodId), this.pilotYear + 1)
                .pipe(take(1))
                .subscribe(response => {
                    const calendarDays = response as any[];
                    let sum = 0;
                    calendarDays.forEach(calendarDay => {
                        if (calendarDay.presentYearEntry) {
                            if (entry.designation.code === this.VA_PILOT_RUBRIQUE.code) {
                                sum += calendarDay.presentYearEntry.volumeAffaire;
                            } else {
                                sum += calendarDay.presentYearEntry.margeVente;
                            }
                        }
                    });
                    if (entry.designation.code === this.VA_PILOT_RUBRIQUE.code) {
                        if (this.pilotRepartition) {
                            deltaDailyEntry.volumeAffaire = 0;
                        } else {
                            deltaDailyEntry.volumeAffaire = +entry.value - sum;
                        }
                    } else {
                        if (this.pilotRepartition) {
                            deltaDailyEntry.margeVente = 0;
                        } else {
                            deltaDailyEntry.margeVente = +entry.value - sum;
                        }
                    }
                    const periodIndex = this.account.periods.findIndex(period => period.id === periodId);
                    this.dailyService.save(deltaDailyEntry).pipe(take(1)).subscribe(res => {
                        this.account.periods[periodIndex].entryMatrix[entry.designation.code][year].value = +entry.value;
                        this.account.periods[periodIndex].entryMatrix[entry.designation.code][year].id = res.id;
                    });
                });
            return;
        }
        return;
    }

    handleDeltaEntry(codeDesignation, diff, isAnnuel, titleCode, repartition?) {
        const delta = titleCode === ENTR ? this.deltaEntrant : this.delta;
        // On regarde si on est sur le delta normal ou le delta annuel
        const deltaEntry: Entry = {
            periodId: delta.id,
            accountId: this.account.id,
            designation: { code: codeDesignation },
            designationId: this.calculationMatrix.find(r => r.code === codeDesignation).id,
            title: titleCode === ENTR ? { code: ENTR } : null,
            titleId: titleCode === ENTR ? 18 : null,
            value: 0,
            yearId: 3,
            role: isAnnuel ? this.authenticationService.getCurrentUser().role : null,
            byUser: this.authenticationService.getCurrentUser().login,
        };

        const i = delta.entries.findIndex(entry => entry.designation.code === codeDesignation);
        if (i > -1) {
            deltaEntry.id = delta.entries[i].id;
            deltaEntry.value = isAnnuel ? Number(delta.entries[i].value) + diff : Number(delta.entries[i].value) - diff;
        } else {
            deltaEntry.id = null;
            deltaEntry.value = isAnnuel ? diff : -diff;
        }

        if (repartition) {
            deltaEntry.value = 0;
        }
        this.entryService.saveAll([deltaEntry]).pipe(take(1)).subscribe(() => {
            deltaEntry.designation.code = codeDesignation;
            if (i > -1) {
                delta.entries[i] = deltaEntry;
            } else {
                delta.entries.push(deltaEntry);
            }
        });
    }

    public handleAnnuelEntry($event) {

        const entry: Entry = $event;

        this.onEntryValueChange(entry.value, null, entry.title.code, entry.designation.code, true);

       // return;
        //location.reload();
    }

    public handleTabSelector($event) {
        // Mensuel selectionné
        if ((this.tabSelected === 'JOURNA') && ($event === 'MENSUEL' || $event === 'ANNUEL')) {
            this.accountService.getAccountById(this.account.id).subscribe(result => {
                this.account = result;
                this.cum6Index = this.getPeriodIndex(8);
                this.cum12Index = this.getPeriodIndex(16);
                this.delta = this.account.periods.find(period => period.number === 18);
                const deltaIndex = this.account.periods.findIndex(period => period.number === 18);
                this.account.periods.splice(deltaIndex, 1);
                // Initialisation de l'account
                this.initAccount();
            });
        }
        this.tabSelected = $event;
        this.refreshTitles(this.allTitles);


        // Journa
        if ($event === 'JOURNA') {
            this.goToDailyPeriod(1);
        }
    }

    protected handleNewPeriodInEdition($event) {
        this.periodInEdition = $event;
    }

    /**
     * Appelé lorsqu'on reçoit la nouvelle période lockée.
     */
    handlePeriodLocked($event) {
        this.periodLocked = $event;
    }

    /**
     * Appelé lorque une ligne est selectionnée.
     */
    handleNewLineSelected($event) {
        if (this.lineSelected === $event) {
            this.lineSelected = null;
        } else {
            this.lineSelected = $event;
        }
    }

    /**
     * Appelé lorque un jour est selectionnée.
     */
    handleNewDaySelected($event) {
        if (this.daySelected === $event) {
            this.daySelected = null;
        } else {
            this.daySelected = $event;
        }
    }

    /**************/
    /* NAVIGATION */

    /**************/

    isPeriodDisplay(periodNumber) {
        return this.periodsNumberToDisplay.indexOf(periodNumber) !== -1;
    }

    /**
     * Affiche la période suivante.
     */
    displayNextPeriods() {
        if (this.periodsNumberToDisplay[2] === this.periodsCode.length) {
            return;
        }
        for (let i = 0; i < 3; i++) {
            this.periodsNumberToDisplay[i] += 1;
        }
    }

    /**
     * Affiche la période précédente.
     */
    displayPreviousPeriods() {
        if (this.periodsNumberToDisplay[0] === 1) {
            return;
        }
        for (let i = 0; i < 3; i++) {
            this.periodsNumberToDisplay[i] -= 1;
        }
    }

    goToDailyPeriod(periodNumber: number) {
        this.dailyService
            .getAllByAccountAndPeriod(this.account.id, periodNumber, this.pilotYear + 1)
            .subscribe(response => {
                const period = this.getPeriodByNumber(periodNumber - 1);
                this.dailyPeriod = {
                    number: periodNumber,
                    VA: get(period, ['entryMatrix', this.VA_PILOT_RUBRIQUE.code, PLAN_COLUMN_CODE, 'value'], 0),
                    MV: get(period, ['entryMatrix', this.MV_PILOT_RUBRIQUE.code, PLAN_COLUMN_CODE, 'value'], 0),
                    isRealLocked: period.isRealLocked,
                    isLocked: period.isLocked,
                };
                this.days = response as any[];
            });
    }

    /**
     * Indique si une période est affichée pour le sommaire de navigation.
     */
    isPeriodCodeDisplaying(periodNumber) {
        return this.periodsNumberToDisplay.findIndex(period => period === periodNumber) > -1;
    }

    getPeriodNumberByCode(periodCode) {
        return this.periodLocked === PERIODS_CODE.indexOf(periodCode) + 1;
    }

    /**
     * Navigue vers la période cliquée dans le sommaire avec ses deux voisines.
     */
    navigateToPeriod(periodCode) {
        const index = this.periodsCode.indexOf(periodCode);
        const number = index + 1;
        if (index === this.periodsCode.length - 2) {
            this.periodsNumberToDisplay[0] = number - 1;
            this.periodsNumberToDisplay[1] = number;
            this.periodsNumberToDisplay[2] = number + 1;
        } else if (index === this.periodsCode.length - 1) {
            this.periodsNumberToDisplay[0] = number - 2;
            this.periodsNumberToDisplay[1] = number - 1;
            this.periodsNumberToDisplay[2] = number;
        } else {
            this.periodsNumberToDisplay[0] = number;
            this.periodsNumberToDisplay[1] = number + 1;
            this.periodsNumberToDisplay[2] = number + 2;
        }
    }

    lockPeriod($event) {
        this.periodLocked = $event;
        setTimeout(() => {
            this.getOffSet();
        });
    }

    hiddeTitle($event) {
        this.hiddenTitles = $event;
        setTimeout(() => {
            this.getOffSet();
        });
    }

    getOffSet() {
        let done = false;
        interval(100)
            .pipe(takeWhile(() => !done))
            .subscribe(() => {
                if (this.designationsComponent !== undefined) {
                    let offset = this.designationsComponent.nativeElement.offsetWidth;
                    if (this.deltaComponent !== undefined) {
                        this.deltaComponent.nativeElement.style.left = offset + 'px';
                        offset += this.deltaComponent.nativeElement.offsetWidth;
                    }
                    if (this.lockedPeriodComponent !== undefined && this.arrowButton !== undefined) {
                        this.lockedPeriodComponent.nativeElement.style.left = offset + 'px';
                        this.arrowButton.nativeElement.style.left =
                            offset + this.lockedPeriodComponent.nativeElement.offsetWidth + 'px';
                    } else if (this.arrowButton !== undefined) {
                        this.arrowButton.nativeElement.style.left = offset + 'px';
                    }
                }
                done = true;
            });
    }

    getMainHeight() {
        if (this.headerComponent !== undefined) {
            return 46 + this.headerComponent.nativeElement.offsetHeight + 'px';
        }
    }


    /**
     * Retourne le numéro du mois actuel (date réelle).
     * @returns {number}
     */
    getCurrentPeriodNumber(): number {
        const currentDate: Date = new Date();
        const periods = this.account.periods.filter(period => period.code.startsWith('M'));
        return periods[currentDate.getUTCMonth()].number;
    }

    getCurrentMonth(): number {
        return new Date().getUTCMonth();
    }

    getPeriodByNumber(number: number): Period {
        const periods = this.account.periods.filter(period => period.code.startsWith('M'));
        return periods[number];
    }

    getMonthOfPeriodById(periodId): number {
        const periods = this.account.periods.filter(period => period.code.startsWith('M'));
        return periods.findIndex(period => period.id === periodId) + 1;
    }

    annualDistribution(entry: Entry) {
        // Object context reconstruction

        const period = entry.periodId ? this.account.periods.find(p => p.id === entry.periodId) : this.annuel;

        if (entry.title.code === TITLE.RHT_PLAN) {
            const RHTHE = get(period, ['entryMatrix', this.RHT_RUBRIQUE.code, TITLE.PLAN, 'value'], 0);
            const calculatedPLan = entry.value * RHTHE / 100;
            entry.title.code = TITLE.PLAN;
            entry.value = calculatedPLan;
            this.annualDistribution(entry);
            return;
        }

        if (entry.title.code === TITLE.PROG_PLAN_EST) {
            const est = get(period, ['entryMatrix', entry.designation.code, TITLE.EST, 'value'], 0);
            const calculatedPlan = Math.abs(est)*(entry.value/100) + est;
            entry.title.code = TITLE.PLAN;
            entry.value = calculatedPlan;
            this.annualDistribution(entry);            
            return;
        }

        let distribution = null;

        switch (this.dispatchFilter) {

            case 'NO_REP':
                return;
            case 'REP_12':

                if (entry.designation.code === this.VA_PILOT_RUBRIQUE.code || entry.designation.code === this.MV_PILOT_RUBRIQUE.code) {
                    distribution = 'dispatchAnnualEntryPilot';
                } else {
                    distribution = 'dispatchAnnualEntry12';
                }
                break;
            case 'REP_14':
                if (entry.designation.code === this.VA_PILOT_RUBRIQUE.code || entry.designation.code === this.MV_PILOT_RUBRIQUE.code) {
                    distribution = 'dispatchAnnualEntryPilot';
                } else {
                    distribution = 'dispatchAnnualEntry14';
                }
                break;
            case 'REP_POIDS':
                if (entry.designation.code === this.VA_PILOT_RUBRIQUE.code || entry.designation.code === this.MV_PILOT_RUBRIQUE.code) {
                    distribution = 'dispatchAnnualEntryLastWeightPilot';
                } else {
                    distribution = 'dispatchAnnualEntryLastWeight';
                }
                break;
        }

  let type_rubrique =  ['HYP', 'SUP', 'ULT','HYPCO', 'SUPCO','ESS','RAY'].includes(this.account.type) ? 'MAG':this.account.type ;

       // this.rubriqueService.getAllByType(this.account.type === 'DRIVE' ? this.account.type : 'MAG').pipe(take(1)).subscribe(rubriques => {
        this.rubriqueService.getAllByType(type_rubrique).pipe(take(1)).subscribe(rubriques => {
            this.titleService.getAllTitles().pipe(take(1)).subscribe(titles => {
                this.distributionService[distribution](
                    entry,
                    this.account,
                    this.account.periods,
                    this.account.id,
                    titles,
                    rubriques,
                    this.allCoefficients,
                    this.referencesYears,
                    this.pilotYear,
                    this.config
                ).subscribe(result => {
                    if (!result) {
                        this.toastrService.error('Une erreur est survenue lors de la répartition');
                    } else {
                        result.forEach(entry => {

                            const rubrique = this.calculationMatrix.find(r => r.id === entry.designationId);
                            const code = rubrique ? rubrique.code : null;

                            const title = this.allTitles.find(t => t.id === entry.titleId);
                            const titleCode = title ? title.code : null;

                            this.onEntryValueChange(entry.value, entry.periodId, titleCode, code);

                        });

                        this.toastrService.success(`Répartition ${this.dispatchFilter} effectuée`);
                    }
                });
            });
        });
    }

    pilotDistribution(entry) {
        if (this.pilotRepartition) {
            const designationLabel = entry.designation.code === this.VA_PILOT_RUBRIQUE.code ? 'volumeAffaire' : 'margeVente';
            const designationLabelOther = entry.designation.code === this.VA_PILOT_RUBRIQUE.code ? 'margeVente' : 'volumeAffaire';
            this.dailyService
                .getAllByAccountAndPeriod(
                    this.account.id,
                    entry.periodNumber > 6 ? entry.periodNumber - 2 : entry.periodNumber,
                    this.pilotYear + 1
                )
                .pipe(take(1))
                .subscribe(data => {
                    let total = 0;
                    for (let datum of data) {
                        if (datum.lastYearEntry && datum.lastYearEntry[designationLabel]) {
                            total += datum.lastYearEntry[designationLabel];
                        }
                    }
                    let dailyEntriesToSave = [];
                    for (let datum of data) {
                        if (datum.lastYearEntry && datum.lastYearEntry[designationLabel]) {
                            console.log('Test 0 lastYearEntry before>' + datum.lastYearEntry[designationLabel]);
                            const weight = (datum.lastYearEntry[designationLabel] / total) * 100;
                            const value = (weight * (+entry.value)) / 100;
                            console.log('Test 0 lastYearEntry weight value>' + value);
                            if (datum.presentYearEntry) {
                                console.log('Test 1 PresentYearEntry before>' + datum.presentYearEntry[designationLabel]);
                                const entryJourna: EntryJourna = datum.presentYearEntry;
                                entryJourna[designationLabel] = +value;
                                console.log('Test 1 PresentYearEntry after >' + datum.presentYearEntry[designationLabel]);
                                this.dailyService.save(entryJourna).subscribe();
                            } else {
                                console.log('If 2--------> CREATION NEW ENTRY' );
                                const entryJourna: EntryJourna = {
                                    accountId: this.account.id,
                                    annee: this.pilotYear + 1,
                                    jour: datum.day.numObjectif,
                                    mois: datum.day.moisObjectif,
                                };
                                entryJourna[designationLabel] = +value;
                                entryJourna[designationLabelOther] = null;

                                dailyEntriesToSave.push(entryJourna);
                            }
                        } else if (datum.presentYearEntry && datum.presentYearEntry[designationLabel]) {
                            console.log('If 3-------->' + datum.presentYearEntry + '-----' + datum.presentYearEntry[designationLabel]);
                            const entryJourna: EntryJourna = datum.presentYearEntry;
                            entryJourna[designationLabel] = 0;
                            dailyEntriesToSave.push(entryJourna);
                        }
                    }
                    this.dailyService.saveAll(dailyEntriesToSave).subscribe(entries => {
                        if (!entries) {
                            this.toastrService.error('Une erreur est survenue lors de la répartition pilot');
                        } else {
                            this.toastrService.success(`Répartition pilote effectuée`);
                        }
                    });
                });
        }
    }

    formatConnectionsForDisplay = (connections: Array<any>): string => {
        return connections
            .map(
                c =>
                    `${c.user.firstName ? c.user.firstName : ''} ${c.user.lastName ? c.user.lastName : ''} ${c.user.firstName ? '(' + c.user.login + ')' : c.user.login
                    }`
            )
            .join('\n');
    };

    openLockingModal(period?) {
        if (period == undefined) {
            this.isLockingModalOpen = true;
        }
        let zero = true;
        for (let prop in this.delta.entryMatrix) {
            if (Math.trunc(this.delta.entryMatrix[prop].value) > 10 && !isNaN(this.delta.entryMatrix[prop].value)) {
                zero = false;
            }
        }

        const lockingModal = this.modalLock.open(ModalLockingComponent, {
            disableClose: true,
            width: '500px',
            height: '400px',
            data: {
                account: this.account,
                periodName: period ? PERIODS[period.number] : null,
                isDeltaZero: zero,
            },
        });
        lockingModal.afterClosed().subscribe(result => {
            if (result === 'OUI') {
                const periodId = period ? period.id : null;
                this.lockService.lock([this.account.id], [4], periodId).subscribe(() => {
                    this.toastService.success('CEX verrouillé', 'Succès');
                });
                this.ngOnInit();
                this.getAccountData();
            }
            this.isLockingModalOpen = false;
        });
    }

    ngOnDestroy() {
        //this.connectionsSub.unsubscribe();
    }

    handleLockPeriod = (period: any) => {
        this.openLockingModal(period);
    };

    print(): void {
        window.print();
    }

    getCoefficients() {
        this.coefficientService.getAll().subscribe(
            data => {
                this.allCoefficients = data;
            }
        );
    }

    public exportAccountData = (): void => {
        const data = { account: this.account, titles: this.allTitles, periods: [] };
        [this.annuel].concat(...this.account.periods).forEach(period => {
            const periodData = { period, rows: [] };

            this.motif.forEach(group => {
                group.forEach(pattern => {
                    let row: any = { code: pattern.rubrique.code };
                    set(row, 'code', pattern.rubrique.libelle);
                    this.allTitles.forEach(title => {
                        const value = get(period, ['entryMatrix', pattern.rubrique.code, title.code, 'value']);
                        set(row, title.code, !isNaN(value) ? value : 0);
                    });
                    periodData.rows.push(row);
                });
            });
            data.periods.push(periodData);
        });

        return this.excelService.generateAccountExcel(data);
    };

    private showMessage(message) {
        return this.matDialog.open(MessageAccountDialogComponent, {
            data: message,
        });
    }
}
