import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PeriodLogService {
    private periodsName: string[] = [
        'JANVIER',
        'FEVRIER',
        'MARS',
        'AVRIL',
        'MAI',
        'JUIN',
        'SUPP 06',
        'JUILLET',
        'AOUT',
        'SEPTEMBRE',
        'OCTOBRE',
        'NOVEMBRE',
        'DECEMBRE',
        'SUPP 12',
        'ANNUEL',
    ];

    constructor() {}

    public getPeriodNameByPeriodNumber(periodNumber: number) {
        return this.periodsName[periodNumber - 1];
    }
}
