import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';

import { Coefficient } from 'src/app/_models/coefficient';

@Injectable({
  providedIn: 'root',
})
export class CoefficientService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<Coefficient[]> {
    return this.http.get<Coefficient[]>(`${environment.OP_API_URI}/coefficients`);
  }
  upsertAll(coefficients: Coefficient[]): Observable<any> {
    return this.http.post(environment.OP_API_URI + '/coefficients', { coefficients });
  }

  delete(coefficientId: number): Observable<any> {
    return this.http.delete<any>(environment.OP_API_URI + `/coefficients/${coefficientId}`);
  }
}
