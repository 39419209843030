import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Title } from '../../_models/title';
import { TitleItem } from '../../_models/title-item';
import { StoreService } from '../../_services/store/store.service';

@Component({
    selector: 'app-titles-controller',
    templateUrl: './titles-controller.component.html',
    styleUrls: ['./titles-controller.component.css'],
})
export class TitlesControllerComponent implements OnInit, OnChanges {
    @Input('titlesItems') titlesItems: Title[];
    @Input('tabSelector') tabSelector: boolean;
    @Input('forbiddenTitles') forbiddenTitles: string[];

    @Output() hiddenList: EventEmitter<any> = new EventEmitter();

    hidden: string[];

    constructor(public storeService: StoreService) {}

    ngOnInit() {
        this.titlesItems = this.titlesItems.filter(t => t.code !== 'RHTEST');
        if (isNullOrUndefined(localStorage.getItem('Titles'))) {
            this.hidden = [];
        } else {
            this.hidden = JSON.parse(localStorage.getItem('Titles'));
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (isNullOrUndefined(localStorage.getItem('Titles'))) {
            this.hidden = [];
        } else {
            this.hidden = JSON.parse(localStorage.getItem('Titles'));
        }
        /* if (changes.account !== null) {
             if (!isNullOrUndefined(changes.tabSelected)) {
                 this.tabSelected = changes.tabSelected.currentValue;
             }
             if (this.tabSelected) {
                 const titleCodesToRemove = ['PDSO', 'PDSR'];
                 this.titlesItems.forEach(titleItem => {
                     if (titleCodesToRemove.indexOf(titleItem.title.code) !== -1) {
                         titleItem.enable = false;
                     } else {
                         titleItem.enable = true;
                     }
                 });
             }
         }*/
    }

    /**
     * Appelé lorsqu'on clique sur un title.
     * @param {TitleItem} title le title cliqué.
     */
    private isHidden(titleCode: string): boolean {
        return this.hidden.findIndex(code => code === titleCode) > -1;
    }

    private isForbidden(titleCode: string): boolean {
        return this.forbiddenTitles.findIndex(code => code === titleCode) > -1;
    }

    private fireTitlesChanges(titleCode: string): void {
        if (this.isForbidden(titleCode)) {
            return;
        }
        if (this.isHidden(titleCode)) {
            const hiddenTitle = this.hidden.findIndex(code => code === titleCode);
            this.hidden.splice(hiddenTitle, 1);
        } else {
            this.hidden.push(titleCode);
        }
        localStorage.setItem('Titles', JSON.stringify(this.hidden));
        this.hiddenList.emit(this.hidden);
    }

    isRealTitle(title) {
        return title.code === 'REAL' || title.code === 'RHTR';
    }

    isPoidsTitle(title) {
        return title.code === 'PDSR' || title.code === 'PDSO';
    }

    isRightBorderVisible(titleCode): boolean {
        return (
            titleCode === 'PDSR' ||
            titleCode === 'PDSO' ||
            titleCode === 'OBJ' ||
            titleCode === 'RAF' ||
            titleCode === 'RHTEST' ||
            titleCode === 'REALN-1'
        );
    }
}
