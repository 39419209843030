import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Account } from '../../_models/account';
import { User } from '../../_models/user';
import {
    ROLE_SUP_NATIONAL,
    ROLE_SUP_REG_ARA,
    ROLE_SUP_REG_CLA,
    ROLE_SUP_REG_GE,
    ROLE_SUP_REG_HALLES,
    ROLE_SUP_REG_NPL,
    ROLE_SUP_REG_NC,
    ROLE_SUP_REG_NPDC,
    ROLE_SUP_REG_OM,
    ROLE_SUP_REG_PARIS,
    ROLE_SUP_REG_GC,
} from 'src/app/constants';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    constructor(private http: HttpClient) { }

    getUsers(login, category): Observable<any> {
        return this.http.get<any>(environment.OP_API_URI + '/admin/users/' + login + '/category/' + category);
    }

    getUsersManaged(managerId): Observable<User[]> {
        return this.http.get<User[]>(environment.OP_API_URI + '/admin/users/' + managerId + '/users');
    }

    updateUser(user: any): Observable<User> {
        return this.http.put<User>(environment.OP_API_URI + '/admin/users/' + user.id, user);
    }

    createUser(user: any): Observable<any> {
        return this.http.post(environment.OP_API_URI + '/admin/users', user);
    }

    getRoles(login): Observable<any> {
        return this.http.get<any>(environment.OP_API_URI + '/admin/roles/' + login);
    }

    getStores(login): Observable<any> {
        return this.http.get<any>(environment.OP_API_URI + '/admin/stores/' + login);
    }

    getAccountByUserAndStore(userId, storeId): Observable<Account[]> {
        return this.http.get<Account[]>(`${environment.OP_API_URI}/admin/users/${userId}/stores/${storeId}/accounts`);
    }

    getUser(login): Observable<User> {
        return this.http.get<User>(environment.OP_API_URI + '/admin/user/' + login);
    }

    changeRoleName = input => {
        switch (input) {
            case 'HRPRORAYON':
                return 'MANAGER COMMERCE';
            case 'HYP':
                return 'SUPADMIN';
            case 'HRPROSIT':
                return 'CDG MAGASIN HYPER';
            case 'HRPROSITULT':
                return 'CDG MAGASIN ULTRA';
            case 'HRPROSITSUP':
                return 'CDG MAGASIN SUPER';
            case 'HRPRONAT':
                return 'CDG NATIONAL MAG';
            case 'HRPROARA':
                return 'CDG Auvergne Rhône Alpes';
            case 'HRPROCLA':
                return 'CDG CLA';
            case 'HRPROGE':
                return 'CDG Grand Est';
            case 'HRPRONPL':
                return 'CDG Normandie-Picardie-Littoral';
            case 'HRPRONC':
                return 'CDG Nouveau Concept';
            case 'HRPRONPDC':
                return 'CDG NPDC';
            case 'HRPROOM':
                return 'CDG Occitanie Mediterrannée';
            case 'HRPROHALLES':
                return 'CDG Halles';
            case 'HRPROPARIS':
                return 'CDG Paris';
            case 'HRPROGC':
                return 'CDG Grande Couronne';
            case 'CDGLOGHYPERNORD':
                return 'CDG LOG HYPER NORD';
            case 'CDGLOGSUPERNORD':
                return 'CDG LOG SUPER NORD';
            case 'CDGLOGGRANDEST':
                return 'CDG LOG GRAND EST';
            case 'CDGLOGOUEST':
                return 'CDG LOG OUEST';
            case 'CDGLOGHYPERSUD':
                return 'CDG LOG HYPER SUD';
            case 'CDGLOGSUPERSUD':
                return 'CDG LOG SUPER SUD';
            case 'CDGLOGCENTRE':
                return 'CDG LOG CENTRE';
            case 'CDGLOGHYPERIDF':
                return 'CDG LOG HYPER IDF';
            case 'CDGLOGSUPERIDF':
                return 'CDG LOG SUPER IDF';
            case 'CDGLOGNAT':
                return 'CDG NATIONAL LOG';
            case 'USERLOG':
                return 'USER LOG';
            default:
                return input;
            // case ROLE_SUP_NATIONAL:
            //     return 'CDG SUPER NATIONAL';
            // case ROLE_SUP_REG_ARA:
            //     return 'CDG SUPER ARA';
            // case ROLE_SUP_REG_CLA:
            //     return 'CDG SUPER CLA';
            // case ROLE_SUP_REG_GE:
            //     return 'CDG SUPER GE';
            // case ROLE_SUP_REG_HALLES:
            //     return 'CDG SUPER HALLES';
            // case ROLE_SUP_REG_NPL:
            //     return 'CDG SUPER NPL';
            // case ROLE_SUP_REG_NC:
            //     return 'CDG SUPER NC';
            // case ROLE_SUP_REG_NPDC:
            //     return 'CDG SUPER NPDC';
            // case ROLE_SUP_REG_OM:
            //     return 'CDG SUPER OM';
            // case ROLE_SUP_REG_PARIS:
            //     return 'CDG SUPER PARIS';
            // case ROLE_SUP_REG_GC:
            //     return 'CDG SUPER GC';
        }
    };

    private _listenrs = new Subject<any>();
    listen(): Observable<any> {
        return this._listenrs.asObservable();
    }
    filter(filterBy: string) {
        this._listenrs.next(filterBy);
    }

    getAccountsByTypes(): Observable<Account[]> {
        return this.http.get<Account[]>(environment.OP_API_URI + '/admin/accounts');
    }
}
