import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { Title } from '../../_models/title';
import { TITLE_URI } from '../../constants';

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    constructor(private http: HttpClient) { }

    private hiddenTitlesSubject = new Subject<string[]>();

    getAllTitles(): Observable<Title[]> {
        return this.http.get<Title[]>(environment.OP_API_URI + TITLE_URI);
    }

    getViewByPosition(): Observable<Title[]> {
        return this.http.get<Title[]>(`${environment.OP_API_URI}${TITLE_URI}/orderBy/position`);
    }

    public getHiddenTitlesObservable = (): Observable<string[]> => {
        return this.hiddenTitlesSubject.asObservable();
    }

    public emitNewHiddenTitles = (titles: string[]) => {
        this.hiddenTitlesSubject.next(titles);
    }


}
