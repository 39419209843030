import { Component, Inject, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Title } from 'src/app/_models/title';
import { FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { TitleService } from 'src/app/_services/title/title.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ROLES } from '../../constants';

@Component({
    moduleId: module.id,
    selector: 'title-filter',
    templateUrl: 'title-filter.component.html',
    styleUrls: ['title-filter.component.css']
})

/**
 * Technical notes: To best limit event spamming, the group checked control is irrelevant at the time is it sent,
 * But we don't need that value for the current use
 */

export class TitleFilterComponent implements OnDestroy {

    titlesForm: FormArray;
    groups = ['N-1', 'N', 'N+1'];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { titles: Title[], currentYear: number, hiddenTitles: string[],  category:string},
        @Inject(MatDialogRef) public ref: MatDialogRef<TitleFilterComponent>,
        protected fb: FormBuilder,
        private titleService: TitleService,
    ) {
        this.titlesForm = this.fb.array([])

        let shift = -1;

        this.groups.forEach(group => {
            this.titlesForm.push(this.fb.group({
                name: this.fb.control(`Groupe ${this.data.currentYear + shift} (${group})`),
                checked: this.fb.control(null),
                titles: this.fb.array(this.data.titles
                    .filter(t => t.group === group)
                    .map(t => this.fb.group({
                        code: this.fb.control(t.code),
                        name: this.fb.control(t.name),
                        checked: this.fb.control(this.data.hiddenTitles ? !this.data.hiddenTitles.some(code => t.code === code) : true),
                    }))),
            }))
            shift += 1;
        })


        this.titlesForm.valueChanges.subscribe(value => {
            //Update Group states
            this.titlesForm.controls.forEach((c, index) => {
                c.get('checked').setValue(this.getGroupState(index), { emitEvent: false })
            })
        })

        this.titlesForm.controls.forEach((c, index) => {
            c.get('checked').setValue(this.getGroupState(index), { emitEvent: false })
        })

    }


    protected getGroupState = (index: number) => {
       // console.log("set getGroupState")
        const filter: any[] = this.titlesForm.value[index].titles;

       // const filter2 =  filter.filter(s=> (s.code!=="ENTR" && s.code!=="DELTA_PLAN_ENTR"));
        //console.log(" filter")
       // console.log(filter2)
        const predicates: boolean[] = filter.map(t => t.checked);

        const isAllTrue = predicates.every(p => p === true);
        const isAllFalse = predicates.every(p => p === false);

        return isAllTrue ? true : isAllFalse ? false : null;
    }

    protected setSubCheckBox = (index) => {
        const yearGroup = this.titlesForm.controls[index] as FormGroup;
        const newValue = yearGroup.get('checked').value === null ? true : !yearGroup.get('checked').value;

        yearGroup.get('titles')['controls'].forEach(control => {
            control.get('checked').setValue(newValue, { emitEvent: false }); //no event to prevent spamming
        });
    }

    ngOnDestroy() {
        const hiddenTitles = this.titlesForm.value
            .map((v: any) => v.titles)
            .flat()
            .filter(t => !t.checked)
            .map((t: any) => t.code)
        if(ROLES.LIST.HIDDEN_TITLES.includes(this.data.category))
        {
            hiddenTitles.push("ENTR");
            hiddenTitles.push("DELTA_PLAN_ENTR");
            hiddenTitles.push("DELTA_ENTR_EST");
            hiddenTitles.push("PROG_ENTR_EST");
            hiddenTitles.push("RHT_ENTR");
        }
        this.titleService.emitNewHiddenTitles(hiddenTitles);
    }

    close = () => {
        this.ref.close();
    }

    disabledTitles = (catogory:string) => {

        if(ROLES.LIST.HIDDEN_TITLES.includes(this.data.category)&& ((catogory=="Entrant") || (catogory=="Ecart /Entrant")|| (catogory=="Ecart /Estimé")|| (catogory=="Prog% /Estimé")|| (catogory=="Tx/ RHT HE"))) {
            console.log('disabledTitles '+catogory)
            return true;
        } else {
            return  false;
        }

    }
    showTitles = (catogory:string) => {
        if(ROLES.LIST.HIDDEN_TITLES.includes(this.data.category)&& ((catogory=="Entrant") || (catogory=="Ecart /Entrant")|| (catogory=="Ecart /Estimé")|| (catogory=="Prog% /Estimé")|| (catogory=="Tx/ RHT HE"))) {
            console.log('showTitles '+catogory)
            return false;
        } else {
            return  true;
        }

    }

}
