export const PERIODS = [
    '2022 - 2023',
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'SUPP 6',
    'CUMUL 6',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
    'SUPP 12',
    'CUMUL 12',
];
export const PERIODS_CODE = [
    'Jan',
    'Fev',
    'Mar',
    'Avr',
    'Mai',
    'Jui',
    'Sup6',
    'Cumul6',
    'Jul',
    'Aou',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Sup12',
    'Cumul12',
];
export const MONTHS = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
];


/**
 * Titles
 */

export const TITLE = {
    REALN_1: 'REALN-1',
    RHT_REALN_1: 'RHT_REALN-1',
    OBJ: 'OBJ',
    PROG_OBJ_REALN_1: 'PROG_OBJ_REALN-1',
    REAL: 'REAL',
    DELTA_REAL_REALN_1: 'DELTA_REAL_REALN-1',
    PROG_REAL_REALN_1: 'PROG_REAL_REALN-1',
    RHT_REAL: 'RHT_REAL',
    EST: 'EST',
    DELTA_EST_REALN_1: 'DELTA_EST_REALN-1',
    PROG_EST_REALN_1: 'PROG_EST_REALN-1',
    RHT_EST: 'RHT_EST',
    ENTR: 'ENTR',
    DELTA_ENTR_EST: 'DELTA_ENTR_EST',
    PROG_ENTR_EST: 'PROG_ENTR_EST',
    PLAN: 'PLAN',
    RHT_PLAN: 'RHT_PLAN',
    DELTA_PLAN_ENTR: 'DELTA_PLAN_ENTR',
    BDGT: 'BDGT',
    DELTA_BDGT_EST: 'DELTA_BDGT_EST',
    PROG_PLAN_EST: 'PROG_PLAN_EST',
    RHT_BDGT: 'RHT_BDGT',
    PDS_REAL: 'PDS_EST',
    OBJ_X: 'OBJ_X',
}

export const CONFIG_KEY = {
    RHT_RUBRIQUE_REFERENCE: 'RHT_RUBRIQUE_REFERENCE',
    PILOT_RUBRIQUE_MSV: 'PILOT_RUBRIQUE_MSV',
    PILOT_RUBRIQUE_VASE: 'PILOT_RUBRIQUE_VASE',
    SUPP6_SUPP12_RUBRIQUES_ALLOWED: 'SUPP6_SUPP12_RUBRIQUES_ALLOWED',
    EXCEPTION_CALCUL_TOTAL_CA_NET_TTC: 'EXCEPTION_CALCUL_TOTAL_CA_NET_TTC',
    MAX_RUBRIQUES:'MAX_RUBRIQUES_'

}
/**
 * Les codes des titres.
 */
export const ENTR = 'ENTR';
export const ENTRN_1 = 'ENTRN-1';
export const PREFIXE_RHT_COLUMN = 'RHT';
export const PREFIXE_PROGRESSION_COLUMN = 'PGS';
export const PROGRESSION_COLUMN_CODE = 'PGS€';
export const REALISER_COLUMN_CODE = 'REAL';
export const PLAN_COLUMN_CODE = 'PLAN';
export const POIDS_REAL_COLUMN_CODE = 'PDSR';
export const POIDS_PLAN_COLUMN_CODE = 'PDSO';
export const PROGRESSION_POURCENT_COLUMN_CODE = 'PGS%';
export const PREFIXE_POIDS_COLUMN = 'PDS';
export const ESTIME = 'EST';
export const LAST_REAL = 'REALN-1';
export const RAF = 'RAF';
export const OBJECTIF_N = 'OBJ';
export const ECART_OBJ_EST = 'EAOEST';
export const PROGRESSION_EST = 'PGS%EST';
export const RHT_EST = 'RHTEST';
export const ECART_OBJ_REAL = 'EAOREAL';

// TODO Ajoutez les codes des titles log

export const TYPE_TILE_WIDE = 1;

export const STEP_1 = 1;
export const STEP_2 = 2;
export const STEP_3 = 3;
export const STEP_4 = 4;

export const LOC_STO_USER = 'userEntityOp';
export const LOC_STO_JWT_TOKEN = 'jwtTokenOp';
export const LOC_STO_STORE_SELECTED = 'currentStore';
export const LOC_STO_USER_STORES = 'userStores';

// Routes Front Angular
export const HOME_CDG_ROUTE = 'accueil';
export const AUTHENTICATION_ROUTE = 'connexion';
export const ADMIN_ROUTE = 'admin';
export const LOGGER_ROUTE = 'logger';

// Routes API
export const AUTHENTICATION_URI = '/login';
export const AUTHENTICATION_URI2 = '/login2';
export const ACCOUNT_URI = '/accounts';
export const STORE_URI = '/stores';
export const STORE_UNIQUE_URI = '/store';
export const TITLE_URI = '/titles';
export const DESIGNATION_URI = '/designations';
export const PERIOD_URI = '/periods';
export const PERIOD_EDITION_URI = '/periodsLock';
export const DAYLI_ENTRY_URI = '/daily';
export const ENTRIES_URI = '/entries';
export const LOCK_URI = '/lock';

// Messages
export const ERR_INTERNAL_SERVEUR_ERROR_TITLE = 'Erreur critique';
export const ERR_INTERNAL_SERVEUR_ERROR_MESSAGE =
    'Le serveur est a rencontré une erreur interne.' +
    " Veuillez contacter le service d'administration si le problème persiste.";
export const ERR_UNAUTHORIZED_ACTION_MESSAGE = 'Accès refusé';
export const LOGOUT_MESSAGE = 'Vous avez été déconnecté.';
export const LOGOUT_EXPIRE_MESSAGE = 'Votre session a expiré.';

export const STEP1TO2 = 'Vous allez passer en étape 2, vérifiez vos entrées de 2018.';
export const STEP2TO3 = 'Vous allez passer en étape 3, vérifiez vos objectifs annuel.';
export const MENTOANN = "Attention, toutes modifications dans l'annuel entraînera des répercutions dans le mensuel.";
export const ANNTOMEN = "Attention, toutes modifications dans le mensuel entraînera des répercutions dans l'annuel.";
export const MENTOANNDELTA = "Attention, toutes modifications dans l'annuel impactera le Delta.";
export const ANNTOMENDELTA = 'Attention, toutes modifications dans le mensuel impactera le Delta.';
export const TOTALMENSUEL =  "Attention, votre annuel est différent de la somme des mois"

export const HYPER_TYPE = 'HYP';
export const ESSENCE_TYPE = 'ESS';
export const RAYON_TYPE = 'RAY';

// Role users
export const ROLES = {
    SUPERADMIN: 'HYP',
    NATIONAL_MAG: 'HRPRONAT',
    NATIONAL_LOG: 'CDGLOGNAT',
    REG_ARA: 'HRPROARA',
    REG_CLA: 'HRPROCLA',
    REG_GE: 'HRPROGE',
    REG_HALLES: 'HRPROHALLES',
    REG_NPL: 'HRPRONPL',
    REG_NC: 'HRPRONC',
    REG_NPDC: 'HRPRONPDC',
    REG_OM: 'HRPROOM',
    REG_PARIS: 'HRPROPARIS',
    CLASSIQUE: 'HRPROSIT',
    SUP_NATIONAL: 'SUPNAT',
    SUP_REG_ARA: 'SUPARA',
    SUP_REG_CLA: 'SUPCLA',
    SUP_REG_GE: 'SUPGE',
    SUP_REG_HALLES: 'SUPHALLES',
    SUP_REG_NPL: 'SUPNPL',
    SUP_REG_NC: 'SUPNC',
    SUP_REG_NPDC: 'SUPNPDC',
    SUP_REG_OM: 'SUPOM',
    SUP_REG_PARIS: 'SUPPARIS',
    SUP_REG_GC: 'SUPGC',
    LIST: {
        ADMINS: ['HYP', 'HRPRONAT', 'CDGLOGNAT'],
        ADMINS_EXPORT: ['HYP', 'HRPRONAT', 'CDGLOGNAT', 'DIR','EXP'],
        ADMINS_REPERE_MENSUEL: ['HYP', 'HRPRONAT', 'CDGLOGNAT', 'DIR','EXP','ARA','FRN','SAV','AUCHFR','DRIVE','ENER'],
        ADMINS_IMPORT: ['HYP', 'HRPRONAT', 'CDGLOGNAT','DIR','ARA','ENER','FRN','SAV','AUCHFR','DRIVE'
        ],
        RAYON: ['HYP','SUP','ULT'],
        CONSO: ['ARA','ENER','SAV'],
        WITH_CHILD: ['SAV'],
        HIDDEN_TITLES: ['APP', 'DIR','EXP','ARA','ENER','FRN', 'SAV','AUCHFR','FRN','DRIVE'],
        NON_ADMINS: ['APP', 'DIR','ARA','ENER', 'SAV','AUCHFR','FRN'],
        CONNECT_NON_ADMINS: ['APP', 'DIR','ARA','ENER', 'SAV','AUCHFR','FRN','HRPRORAYON', 'HRPROSIT','HRPROSITSUP', 'HRPROSITULT'],
        ADD_USERS_ADMIN: ['HRPRORAYON', 'HRPROSIT','HRPROSITSUP', 'HRPROSITULT','DRIVE'],
        ADMINS_Estime_MENSUEL: ['HYP', 'HRPRONAT', 'CDGLOGNAT', 'SAV','DRIVE'],
    }
}

export const ROLE_SUPERADMIN = 'HYP';
export const ROLE_NATIONAL = 'HRPRONAT';
export const ROLE_REG_ARA = 'HRPROARA';
export const ROLE_REG_CLA = 'HRPROCLA';
export const ROLE_REG_GE = 'HRPROGE';
export const ROLE_REG_HALLES = 'HRPROHALLES';
export const ROLE_REG_NPL = 'HRPRONPL';
export const ROLE_REG_NC = 'HRPRONC';
export const ROLE_REG_NPDC = 'HRPRONPDC';
export const ROLE_REG_OM = 'HRPROOM';
export const ROLE_REG_PARIS = 'HRPROPARIS';
export const ROLE_CLASSIQUE = 'HRPROSIT';

export const ROLE_SUP_NATIONAL = 'SUPNAT';
export const ROLE_SUP_REG_ARA = 'SUPARA';
export const ROLE_SUP_REG_CLA = 'SUPCLA';
export const ROLE_SUP_REG_GE = 'SUPGE';
export const ROLE_SUP_REG_HALLES = 'SUPHALLES';
export const ROLE_SUP_REG_NPL = 'SUPNPL';
export const ROLE_SUP_REG_NC = 'SUPNC';
export const ROLE_SUP_REG_NPDC = 'SUPNPDC';
export const ROLE_SUP_REG_OM = 'SUPOM';
export const ROLE_SUP_REG_PARIS = 'SUPPARIS';
export const ROLE_SUP_REG_GC = 'SUPGC';

export const APPLICATION_IN_MAINTENANCE = false;

