import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Designation } from 'src/app/_models/designation';
import { isNullOrUndefined } from 'util';
import { DispatcherService } from '../../../_services/logistic/dispatcher.service';
import { EntryLogService } from '../../../_services/logistic/entry-log.service';
import { PeriodLogService } from '../../../_services/logistic/period-log.service';
import { TitlesLogService } from '../../../_services/logistic/titles-log.service';
import { EntryLog } from '../_models/entry-log';
import { PeriodLog } from '../_models/period-log';
import { TitleLog } from '../_models/title-log';
import { UndoService } from '../../../_services/logistic/undo.service';
import { MatDialog } from '@angular/material';
import { HistoryComponent } from '../history/history.component';
import { Account } from '../../../_models/account';
import { Pattern } from 'src/app/_models/pattern';
import { get } from 'lodash';

@Component({
    selector: 'app-period-logistic',
    templateUrl: './period-logistic.component.html',
    styleUrls: ['./period-logistic.component.css'],
})

export class PeriodLogisticComponent implements OnInit, OnChanges {
    @Input() account: Account;
    @Input() period: PeriodLog;

    @Input() titles: TitleLog[];
    @Input() motif: Pattern[][];

    @Input() hiddenDesignations: string[];
    @Input() lineSelected: string;
    @Input() hiddenTitles: string[];
    @Input() accountId: number;

    // @Input('calculDispatcher') calculDispatcher: any;
    @Output() annualEntry: EventEmitter<EntryLog> = new EventEmitter();
    @Output() filter: EventEmitter<String> = new EventEmitter();

    @Output() updateEntryEvent: EventEmitter<any> = new EventEmitter();

    cellOnEdition: EntryLog;
    isCellOnEditionDirty: boolean;
    /**
     * La valeur de la cellule en édition.
     */
    currentValue: string;
    /**
     * La valeur de la cellule avant son édition (pour rollback en cas d'erreur serveur).
     */
    private lastValue: string;
    /**
     * Liste des boutons de filtre pour la répartition
     */
    dispatchFilters: string[] = ['REP_12', 'REP_WEIGHT', 'REP_LAST_WEIGHT'];
    dispatchFilterSelected: string;

    // private rollbar;

    constructor(
        private periodService: PeriodLogService,
        private titlesLogService: TitlesLogService,
        private entryLogService: EntryLogService,
        private dispatcherLogService: DispatcherService,
        private toastrService: ToastrService,
        private undoService: UndoService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.isCellOnEditionDirty = false;
        this.dispatchFilterSelected = this.dispatchFilters[0];
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.cellOnEdition = null;
    }

    /**
     * Retourne l'entrée de la période en paramètre en fonction de la désignation
     * et du titre passé en paramètre.
     * @param period
     * @param designation
     * @param title
     */
    getEntryByDesinationAndTitle(period: PeriodLog, pattern: Pattern, title: TitleLog): any {
        return get(period, ['entryMatrix', pattern.rubrique.code, title.code], { value: 0, designation: pattern, title: title })
    }

    getPeriodNameByPeriodNumber(periodNumber: number): string {
        return this.periodService.getPeriodNameByPeriodNumber(periodNumber);
    }

    getTitleLogTypeById(id: number): number {
        return this.titlesLogService.getTitleLogTypeById(id);
    }

    /**
     * Indique si la colonne doit avoir des bordures en pointillées la droite.
     * @param idTitle
     */
    isBorderRightDotted(idTitle: number): boolean {
        return [1, 2, 5, 8, 11, 12, 14, 16, 24, 21, 23].includes(idTitle);
    }

    /**
     * Appelé lorsque l'on clique sur une cellule.
     * @param entry
     */
    editCell(entry: EntryLog) {
        this.cellOnEdition = entry;
        this.currentValue = this.cellOnEdition.value + '';
        this.lastValue = entry.value + '';
    }

    handleKeyPressed($event) {
        let newEntry;
        if ($event.key !== 'Enter' && $event.code !== 'Enter') {
            return;
        }
        if (isNaN(Number(this.currentValue))) {
            return;
        }
        if (this.currentValue === this.lastValue && this.period.id !== 15) {
            this.editCell(this.getNextCell(this.cellOnEdition))
            return;
        }
        this.cellOnEdition.value = Number(this.currentValue);
        // On arrondie à deux décimale
        this.cellOnEdition.value = Math.round(this.cellOnEdition.value * 100) / 100;
        this.currentValue = '' + this.cellOnEdition.value;

        // annuel
        if (this.period.code === 'ANNUAL' && this.cellOnEdition.title.code !== 'PGS%ON+1E') {
            this.filter.emit(this.dispatchFilterSelected);
            this.annualEntry.emit(this.cellOnEdition);
            // this.dispatchValueYear(this.cellOnEdition);
            this.historicize(this.cellOnEdition);
            this.editCell(this.getNextCell(this.cellOnEdition))

            // this.cellOnEdition = this.getNextCell(this.cellOnEdition);
            return;
            // Progression ?
        } else if (this.cellOnEdition.title.code === 'PGS%ON+1E') {
            this.cellOnEdition.value /= 100;
            const entryEst = this.period.entries.find(entry => {
                return entry.designation.code === this.cellOnEdition.designation.code && entry.title.code === 'EST';
            });
            newEntry = this.period.entries.find(entry => {
                return entry.designation.code === this.cellOnEdition.designation.code && entry.title.code === 'OBJN+1';
            });
            this.lastValue = newEntry.value;
            newEntry.value = (this.cellOnEdition.value + 1) * Number(entryEst.value);
            this.filter.emit(this.dispatchFilterSelected);
            this.annualEntry.emit(newEntry);
            // this.dispatchValueYear(newEntry);
            this.cellOnEdition = this.getNextCell(this.cellOnEdition);
            return;
        }
        // Progression editable mensuelle
        if (this.cellOnEdition.title.code === 'PGS%OE') {
            this.cellOnEdition.value /= 100;

            const entryEst = get(this.period, ['entryMatrix', this.cellOnEdition.designation.code, 'EST'])
            newEntry = get(this.period, ['entryMatrix', this.cellOnEdition.designation.code, 'OBJN+1'])

            this.lastValue = newEntry.value;
            newEntry.value = Math.ceil((this.cellOnEdition.value + 1) * Number(entryEst.value));
            newEntry.periodLogId = this.period.id;
            newEntry.accountId = this.accountId;
        } else {
            //classique
            // this.cellOnEdition.value = Number(this.currentValue);

            newEntry = {
                id: this.cellOnEdition.id,
                value: this.cellOnEdition.value,
                title: this.cellOnEdition.title,
                designation: this.cellOnEdition.designation,
                periodLogId: this.period.id,
                accountId: this.accountId,
            };
        }

        this.entryLogService.createOrUpdateEntryLogV2([newEntry]).subscribe(
            () => {
                this.historicize(newEntry);
                this.updateEntryEvent.emit(newEntry)
                this.editCell(this.getNextCell(this.cellOnEdition))
            },
            () => {
                this.toastrService.error('Une erreur est survenue, merci de réessayer plus tard');
            }
        );
        return;
    }

    // dispatchValue(newEntry: EntryLog) {
    //     const diff = Number(newEntry.value) - Number(this.lastValue);
    //     // this.dispatcherLogService.dispatchCalcul(newEntry, this.period, this.calculDispatcher, diff);
    //     this.dispatcherLogService.dispatchLine(newEntry.designation, this.period);
    // }

    // dispatchValueYear(newEntry: EntryLog) {
    // const diff = Number(newEntry.value) - Number(this.lastValue);
    // this.dispatcherLogService.dispatchCalculYear(
    //     newEntry,
    //     this.period,
    //     this.calculDispatcher,
    //     diff,
    //     this.account.periods
    // );
    // this.dispatcherLogService.dispatchLineYear(newEntry.designation, this.period, this.account.periods);
    // }

    handleKeyUp($event) {
        this.isCellOnEditionDirty = isNaN(Number(this.currentValue));
    }

    /**
     * Indique si la cellule en paramètre est celle qui est en cours d'édition.
     * @param entry
     */
    isCellOnEdition(entry: EntryLog) {

        this.handleKeyUp(null);
        if (isNullOrUndefined(this.cellOnEdition)) {
            return;
        }
        return (
            this.cellOnEdition.designation.code === entry.designation.code &&
            this.cellOnEdition.title.code === entry.title.code
        );
    }

    isHiddenDesignation(designation: Designation): boolean {
        if (designation.parent === (null || '0') || this.hiddenDesignations.length <= 0) {
            return false;
        }
        if (this.hiddenDesignations.find(code => code === designation.parent)) {
            return true;
        }
    }

    isHiddenTitle(titleCode: string): boolean {
        if (isNullOrUndefined(this.hiddenTitles)) {
            return false;
        }
        if (this.hiddenTitles.length <= 0) {
            return false;
        } else {
            return this.hiddenTitles.findIndex(code => code === titleCode) > -1;
        }
    }

    isInfinity(value) {
        return value === Infinity || value === -Infinity;
    }

    isCC(designation: Designation): boolean {
        return designation.code === 'CC';
    }
    /**
     * Chande la méthode de répartition en annuel.
     */
    changeDispatchFilter(dispatchFilter: string) {
        this.dispatchFilterSelected = dispatchFilter;
    }

    /**
     * Ouvre la modale avec l'historique des saisies.
     */
    openHistoryDialog() {
        const dialogRef = this.dialog.open(HistoryComponent, {
            width: '500px',
            data: {
                accountId: this.accountId,
                periodCode: this.period.code,
            },
        });
        dialogRef.afterClosed().subscribe(edition => {
            if (edition) {
                this.cellOnEdition = this.period.entries.find(
                    e => e.designation.code === edition.designation.code && e.title.code === edition.title.code
                );
                this.currentValue = edition.previousValue;
                this.lastValue = edition.currentValue;
            }
        });
    }

    historicize(newEntry: EntryLog) {
        this.undoService.addNewEdition({
            account: { id: this.accountId },
            period: { code: this.period.code },
            designation: { code: newEntry.designation.code, name: newEntry.designation.name },
            title: { code: newEntry.title.code, name: newEntry.title.name },
            previousValue: this.lastValue,
            currentValue: this.currentValue,
            date: new Date(),
        });
    }

    getNextCell(currentCell) {

        const groupIndex = this.motif.findIndex(group => group.some(pattern => pattern.rubrique.code === currentCell.designation.code))
        if (groupIndex < 0) { return null }
        const patternIndex = this.motif[groupIndex].findIndex(pattern => pattern.rubrique.code === currentCell.designation.code);

        const nextPattern = get(this.motif, [groupIndex, patternIndex + 1]);
        const nextGroupPattern = get(this.motif, [groupIndex + 1, '0']);

        if (nextPattern != null) {
            const nextCell = this.getEntryByDesinationAndTitle(this.period, nextPattern, currentCell.title);
            if (!nextPattern.rubrique.calculated) {
                return nextCell;
            } else {
                return this.getNextCell(nextCell)
            }
        } else if (nextGroupPattern != null) {
            const nextCell = this.getEntryByDesinationAndTitle(this.period, nextGroupPattern, currentCell.title);
            if (!nextGroupPattern.rubrique.calculated) {
                return nextCell;
            } else {
                return this.getNextCell(nextCell);
            }
        } else {
            return null;
        }
    }

    isEditable(period, pattern, title): boolean {
        return (
            !pattern.rubrique.calculated &&
            title.editable &&
            (!period.isLocked || (title.code === 'OBJN+1' || title.code === 'PGS%OE'))
        );
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        this.cellOnEdition = null;
    }
}
