import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../_services/authentication/authentication.service';
import { AUTHENTICATION_ROUTE, ERR_UNAUTHORIZED_ACTION_MESSAGE } from '../constants';

/**
 * Sécurité qui redirige l'utilisateur vers la page de connexion si il n'est pas CDG.
 */
@Injectable()
export class CanActivateCdg implements CanActivate {
    constructor(
        private authentificationService: AuthenticationService,
        private toastService: ToastrService,
        private router: Router
    ) {}

    canActivate(): boolean {
        if (!this.authentificationService.isAuthenticate()) {
            this.toastService.error(ERR_UNAUTHORIZED_ACTION_MESSAGE);
            this.router.navigate([AUTHENTICATION_ROUTE]);
            return false;
        } else {
            return true;
        }
    }
}
