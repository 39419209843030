import { Injectable } from '@angular/core';
import * as math from 'mathjs';
import * as _ from 'lodash';
import { Rubrique } from 'src/app/_models/rubrique';

@Injectable({
    providedIn: 'root',
})
export class FormulaService {
    // parser = math.parser();

    /**
     * 
     */
    evaluate = (parser: any, formulas: string[]) => {
        const resteAfaire = [];
        formulas.forEach(formula => {
            try {
                parser.evaluate(formula);
            } catch (error) {
                resteAfaire.push(formula);
            }
        });
        if (formulas.length === resteAfaire.length) {
            return { status: 'error', error: resteAfaire.length };
        }
        if (resteAfaire.length === 0) {
            return { status: 'ok', errors: [] };
        }

        return this.evaluate(parser, resteAfaire);
    };

    /**
     * 
     */
    dispatch(formulas, code) {
        const index = formulas.findIndex(f => f.split('=')[0].trim() === code.trim());
        formulas.splice(index, 1);
        return this.organize(formulas, code);
    }

    /**
     * 
     */
    organize(formulas, code) {
        let array = [];
        let etages = formulas.filter(f => f.includes(code));
        const formulasUpdated = formulas.filter(f => !etages.includes(f));
        let start = [];
        etages.forEach(f => {
            const code = f.split('=')[0].trim();
            start.push(f);
            const recursive = this.organize(formulasUpdated, code);
            array = [...array, ...recursive];
        });
        return [...start, ...array];
    }

    /**
     * 
     */
    getLinkedRubriques = (rubriques: Rubrique[], code: string, previousResults?: Rubrique[]): Rubrique[] => {

        // entree
        const result = previousResults ? previousResults : [];
        const rubrique = rubriques.find(r => r.code === code);

        if (rubrique ) {
            result.push(rubrique)
        }
       // const relatedRubriques = rubriques.filter(r =>  r.formula.includes(code));
      //  console.log('code '+code)
        const relatedRubriques = rubriques.filter(r => r.formula.replace(/ /g, "").replace(/-/g, "+")
                                                       .replace("*", "+").split("+").map(String).includes(code) )

        // sortie
        if (relatedRubriques.length === 0) {
            return result;
        }

         relatedRubriques.forEach(r => {
           //  console.log('code relatedRubriques '+r.code)
           this.getLinkedRubriques(rubriques, r.code, result)

        })

        return result;
    }

    public reorganiseByDegree = (rubriques: Rubrique[]): Rubrique[] => {
        const parser = math.parser();
        let OK = false;
        let intent = 0;

        while (!OK && intent < 150) {
            try {
                intent += 1;
                rubriques.forEach((rubrique, index) => {
                    if (rubrique.calculated) {
                        try {
                            parser.evaluate(`${rubrique.code} = ${rubrique.formula}`);
                        } catch (e) {
                            throw { rubrique, index }
                        }
                    } else {
                        parser.evaluate(` ${rubrique.code} = 1`)
                    }
                    OK = true;
                })
            } catch (e) {
                OK = false;
                rubriques.splice(e.index, 1);
                rubriques.push(e.rubrique);
            }
        }

        return rubriques;
    }

}


