import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { User } from '../../_models/user';
import { AUTHENTICATION_URI2, LOC_STO_JWT_TOKEN, LOC_STO_USER } from '../../constants';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private user: User;
    private jwtToken: string;

    constructor(private http: HttpClient) {
        if (sessionStorage.getItem(LOC_STO_USER)) {
            this.user = JSON.parse(sessionStorage.getItem(LOC_STO_USER)) as User;
        } else {
            this.user = null;
        }
        this.jwtToken = sessionStorage.getItem(LOC_STO_JWT_TOKEN);
    }

    authenticate(login: string, password: string) {
        const body = new HttpParams().append('login', login).append('password', password);
        return this.http.post<any>(environment.OP_API_URI + AUTHENTICATION_URI2, body.toString(), {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        });
    }

    getCasData(): Observable<any> {
        return this.http.get<any>(environment.OP_API_CAS);
    }

    getCurrentUser(): User {
        return this.user;
    }

    setCurrentUser(user: User): void {
        this.user = user;
    }

    getJwtToken(): string {
        if (this.isAuthenticate()) {
            return this.jwtToken;
        }
        return null;
    }

    setJwtToken(jwtToken: string): void {
        this.jwtToken = jwtToken;
    }

    isAuthenticate(): boolean {
        return this.user != null;
    }

    disconnect(): void {
        this.jwtToken = null;
        this.user = null;
        sessionStorage.clear();
    }
}
