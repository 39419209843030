import { Pipe, PipeTransform } from '@angular/core';

/**
 * Permet d'afficher l'opérateur devant un nombre
 */
@Pipe({ name: 'showOperator' })
export class ShowOperatorPipe implements PipeTransform {
    transform(value: number): string {
        let str = '' + value;
        if (value > 0) {
            str = '+ ' + str;
        } else if (value < 0) {
            str = '- ' + str;
        }
        return str;
    }
}
