import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../_services/authentication/authentication.service';
import { AUTHENTICATION_ROUTE, LOC_STO_USER, APPLICATION_IN_MAINTENANCE } from '../../constants';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.css'],
})
export class AuthenticationComponent implements OnInit {
    form: FormGroup;
    formBuilder: FormBuilder;
    errorLogin: boolean;
    unauthorizedAccess: boolean;
    inMaintenance: boolean;
    loading: boolean;

    constructor(public authenticationService: AuthenticationService, private router: Router) {
        this.formBuilder = new FormBuilder();
        this.errorLogin = false;
        this.unauthorizedAccess = false;
        this.inMaintenance = APPLICATION_IN_MAINTENANCE;
        this.form = this.formBuilder.group({
            login: ['', Validators.required],
            password: ['', Validators.required],
        });
        this.loading = false;
    }

    ngOnInit() {
        // Si l'utilisateur est déjà connecté, il est redirigé
        if (this.authenticationService.isAuthenticate()) {
            this.router.navigate([AUTHENTICATION_ROUTE]);
        }

        // On récupère l'utilisateur connecté avec le CAS
        if (!this.isDevMode()) {
            this.authenticationService.getCasData().subscribe(
                users => {
                    const user = users[0];
                    if (isNullOrUndefined(user) && !this.inMaintenance) {
                        this.unauthorizedAccess = true;
                        return;
                    }
                    this.authenticationService.setCurrentUser(user);
                    sessionStorage.setItem(LOC_STO_USER, JSON.stringify(user));
                    this.loading = false;
                },
                error => {
                    this.errorLogin = true;
                    this.loading = false;
                }
            );
        }
    }

    signIn(): void {
        if (this.form.status === 'VALID') {
            this.loading = true;
            const login: string = this.form.get('login').value;
            const password: string = this.form.get('password').value;
            this.authenticationService.authenticate(login, password).subscribe(
                result => {
                    this.errorLogin = false;
                    // this.authenticationService.setJwtToken(result.token);
                    this.authenticationService.setCurrentUser(result.user);
                    sessionStorage.setItem(LOC_STO_USER, JSON.stringify(result.user));
                    // sessionStorage.setItem(LOC_STO_JWT_TOKEN, result.token);
                    this.loading = false;
                },
                _ => {
                    this.errorLogin = true;
                    this.loading = false;
                }
            );
        }
    }

    signInWithCAS() {
        window.location.href = environment.OP_API_URI + '/login';
    }

    isDevMode() {
        return environment.OP_API_URI.includes('localhost') || environment.OP_API_URI.includes('preprod') || environment.OP_API_URI.includes('develop') || environment.OP_API_URI.includes('qualif');
    }
}
