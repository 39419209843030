import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Designation } from '../../_models/designation';
import { Entry } from '../../_models/entry';
import { Period } from '../../_models/period';
import { AuthenticationService } from '../../_services/authentication/authentication.service';
import { StoreService } from '../../_services/store/store.service';
import { PERIODS, ENTR, ROLES, CONFIG_KEY, TITLE } from '../../constants';
import { AccountComponent } from '../account/account.component';
import { Pattern } from 'src/app/_models/pattern';
import { Title } from 'src/app/_models/title';
import { Rubrique } from 'src/app/_models/rubrique';
import { Config } from 'src/app/_models/config';
import { MatDialog } from '@angular/material';
import { CopyEntrantDialogComponent } from './copy-entrant-dialog/copy-entrant-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
interface Repartition {
    value: string;
    viewValue: string;
}
@Component({
    selector: 'app-period',
    templateUrl: './period.component.html',
    styleUrls: ['./period.component.css'],
})
export class PeriodComponent implements OnInit {
    @Input() period: Period;
    @Input() motif: Pattern[][];
    @Input() titles: Title[];
    @Input() titlesGroups: any[];

    @Input() calculationMatrix: Rubrique[];
    @Input() config: Config[];

    @Input() hiddenDesignations: string[];
    @Input() hiddenTitles: string[];
    @Input() periodLocked: number;
    @Input() periodInEdition: number;
    @Input() lineSelected: string;
    @Input() accountType: string;
    @Input() titleWit: string;

    @Output() setPeriodLocked: EventEmitter<any> = new EventEmitter();
    @Output() fireEntry: EventEmitter<Entry> = new EventEmitter();
    @Output() firePeriodInEdition: EventEmitter<number> = new EventEmitter();

    @Output() annualEntry: EventEmitter<any> = new EventEmitter();
    @Output() filter: EventEmitter<String> = new EventEmitter();

    @Output() pilotEntry: EventEmitter<any> = new EventEmitter();

    @Output() lockPeriod = new EventEmitter<any>();



    PERIODS: string[];
    /** La cellule qu'on édite. */
    cellOnEdit: any;
    isCellOnEditDirty: boolean;
    /** Valeurs de la celulle au format string et number. */
    valueStr: string;
    currentStep: number;

    dispatchFilters: Repartition[] = [
        { value: 'NO_REP', viewValue: 'Pas de répartition' },
        { value: 'REP_POIDS', viewValue: 'REP_POIDS - Répartition selon le poids du mois' },
        { value: 'REP_12', viewValue: 'REP_12 linéaire - Répartition sur 12 mois' },
        // { value: 'REP_14', viewValue: 'REP_14 - Répartition sur 14 mois' }
    ];

    dispatchFilterSelected: string;

    colorTitlesCode = ['PLAN', 'OBJ', 'ENTR', 'ENTRN-1'];
    /** Directive afin de focus l'input au click de la cellule */

    VA_PILOT_RUBRIQUE: Rubrique;
    MV_PILOT_RUBRIQUE: Rubrique;

    protected colspans = [2, 6, 7];

    constructor(
        private authenticationService: AuthenticationService,
        private storeService: StoreService,
        private modalService: NgbModal,
        protected dialog: MatDialog,
        @Inject(AccountComponent) private parent: AccountComponent
    ) { }

    ngOnInit() {
        this.PERIODS = PERIODS;
        this.currentStep = this.storeService.getStoreSelected().step;
        this.cellOnEdit = { designation: null, title: null };
        this.isCellOnEditDirty = false;
        this.dispatchFilterSelected = this.dispatchFilters[0].value;
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(CopyEntrantDialogComponent, {
            width: '350px',
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    /** NEW HERE */
    editCell(designationCode, titleCode) {
        this.valueStr = this.period.entryMatrix[designationCode][titleCode].value;

        if (!Number.isInteger(Number(this.valueStr))) {
            this.valueStr = Number(this.valueStr).toFixed(2);
        }

        if (!this.isCellEditable(designationCode, titleCode)) {
            return;
        }
        this.cellOnEdit = {
            designation: designationCode,
            title: titleCode,
        };
        if (this.periodInEdition !== this.period.number) {
            this.firePeriodInEdition.emit(this.period.number);
        }
    }

    isCellOnEdit(patternRubriqueCode, titleCode): boolean {
        return (
            patternRubriqueCode.toString() === this.cellOnEdit.designation &&
            titleCode.toString() === this.cellOnEdit.title &&
            (this.periodInEdition === this.period.number || this.period.number === 0)
        );
    }

    /**
     * Indique si la cellule peut être éditée ou non.
     */
    isCellEditable(patternRubriqueCode: string, titleCode: string) {

        //TODO: improve: if RHT_PLAN, same rules as PLAN
        if (titleCode === TITLE.RHT_PLAN) { titleCode = TITLE.PLAN }
        if (titleCode === TITLE.PROG_PLAN_EST) { titleCode = TITLE.PLAN }


        const role = this.authenticationService.getCurrentUser().role
        const rubrique = this.calculationMatrix.find(r => r.code === patternRubriqueCode);
        const title = this.titles.find(t => t.code === titleCode);
        const roles = rubrique.authorizedRoles ? rubrique.authorizedRoles : 'test';

        const supp_config = this.config.find(c => c.key === CONFIG_KEY.SUPP6_SUPP12_RUBRIQUES_ALLOWED);
        const allowed_rubriques_ids = JSON.parse(supp_config ? supp_config.value : "[]");

        const mv_conf = this.config.find(c => c.key === CONFIG_KEY.PILOT_RUBRIQUE_MSV);
        const va_conf = this.config.find(c => c.key === CONFIG_KEY.PILOT_RUBRIQUE_VASE);

        const mv_id = mv_conf ? mv_conf.value : null;
        const va_id = va_conf ? va_conf.value : null;

        this.MV_PILOT_RUBRIQUE = this.calculationMatrix.find(r => r.code === mv_id)
        this.VA_PILOT_RUBRIQUE = this.calculationMatrix.find(r => r.code === va_id)


        if (['HYPCO', 'SUPCO', 'FRNCO', 'RAYCO', 'AUCHFR'].includes(this.accountType)) {
            return false
        }
        if (rubrique == null || title == null) {
            return false
        }
        // Temporary for daily
        if (titleCode === 'PLAN' && (rubrique.code === this.MV_PILOT_RUBRIQUE.code || rubrique.code === this.VA_PILOT_RUBRIQUE.code) && (this.period.number != 0 && this.period.number != 8 && this.period.number != 16)) {
            return true;
        }
        if (rubrique.calculated || !title.seizable) {
            return false
        }
        if (
            (titleCode === 'EST' || titleCode === 'PLAN' || titleCode.includes('PGS')) &&
            ((this.period.number === 7 || this.period.number === 15) &&
                (rubrique.code === this.MV_PILOT_RUBRIQUE.code || rubrique.code === this.VA_PILOT_RUBRIQUE.code))
        ) {
            return false;
        }
        if (this.period.number === 8 || this.period.number === 16) {
            return false;
        }

        if (
            (titleCode === 'PLAN' || titleCode.includes('PGS')) &&
            this.period.isLocked &&
            !(rubrique.code === this.MV_PILOT_RUBRIQUE.code || rubrique.code === this.VA_PILOT_RUBRIQUE.code)
        ) {
            return false;
        }
        if (
            (titleCode === 'PLAN' || titleCode.includes('PGS')) &&
            this.period.isPilotLocked &&
            (rubrique.code === this.MV_PILOT_RUBRIQUE.code || rubrique.code === this.VA_PILOT_RUBRIQUE.code)
        ) {
            return false;
        }
        if (titleCode === 'EST' && this.period.isRealLocked) {
            return false;
        }
        if (titleCode === 'EST' && this.period.number === 0) {
            return false;
        }
        if (titleCode === 'REAL' && this.period.number === 0) {
            return false;
        }
        if (titleCode === 'EST' && (rubrique.code === this.MV_PILOT_RUBRIQUE.code || rubrique.code === this.VA_PILOT_RUBRIQUE.code)) {
            return false;
        }
        if (this.isLockedByLocks(titleCode)) {
            return false;
        }
        if (!this.isRealObjEntrTitles(titleCode)) {
            return false;
        }
        if (titleCode === ENTR) {
            if (this.isRubriqueCalculated(patternRubriqueCode)) { return false }
            return ROLES.LIST.ADMINS.includes(role);
        }
        if (this.period.code.includes('SUPP')) {
            if (!allowed_rubriques_ids.some(id => id === rubrique.id)) {
                return false;
            }
        }
        if (!ROLES.LIST.ADMINS.concat(roles.split(',')).includes(role)) { return false }

        return true;
    }

    protected isRealObjEntrTitles = (titleCode: string): boolean => {
        return [TITLE.EST, TITLE.PLAN, TITLE.ENTR].includes(titleCode);
    }

    isDottedBorderCell = (titleCode: string): boolean => {

        const maxPosition = Math.max(...this.titles.map(t => t.position))
        const title = this.titles.find(t => t.code === titleCode);

        if (title == null) { return false }
        if (title.position === maxPosition) { return true; }

        let position = title.position;

        while (position <= maxPosition) {
            const nextTitle = this.titles.find(t => t.position === position + 1);
            if (nextTitle != null) {
                return !nextTitle.code.includes('_'); // trick pour savoir si on change de sous groupe
            } else {
                position += 1;
            }
        }
        return false;
    }

    isDottedBorderCellLeft = (titleCode: string): boolean => {
        const title = this.titles.find(t => t.code === titleCode);
        if (title == null) { return false }
        if (title.code.includes('_')) {
            const ref_title_code = title.code.split('_')[1];
            return this.hiddenTitles.includes(ref_title_code)
        } else {
            return true
        };
    }

    isHiddenDesignation(designation: Designation): boolean {
        if (designation.parent === (null || '0') || this.hiddenDesignations.length <= 0) {
            return false;
        }
        if (this.hiddenDesignations.find(code => code === designation.parentCode)) {
            return true;
        }
    }

    isHiddenTitle(titleCode: string): boolean {
        if (this.hiddenTitles == null) {
            return false;
        }
        return this.hiddenTitles.some(t => t === titleCode);
    }

    handleEntry($event, content): void {

        if (isNaN(Number(this.valueStr))) {
            return;
        }
        if ($event.key !== 'Enter' && $event.code !== 'Enter') {
            return;
        }
        if ((this.period.entryMatrix[this.cellOnEdit.designation][this.titleWit]=== undefined || this.period.entryMatrix[this.cellOnEdit.designation][this.titleWit].value.toFixed(0) === '0')
                  && this.dispatchFilterSelected === 'REP_POIDS' && (this.cellOnEdit.title === 'PLAN' || this.cellOnEdit.title === 'RHT_PLAN')) {
            this.modalService.open(content, {
                centered: true,
                beforeDismiss: () => {
                    this.executeRepartition('REP_12');
                    return true;
                }
            });
        } else {
            this.executeRepartition(this.dispatchFilterSelected);
        }

    }

    handleKeyUp($event) {

        if (isNaN(Number(this.valueStr))) {
            this.isCellOnEditDirty = true;
        } else {
            this.isCellOnEditDirty = false;
        }
    }

    firePeriodLockedEvent() {
        if (this.periodLocked !== this.period.number) {
            this.setPeriodLocked.emit(this.period.number);
        } else {
            this.setPeriodLocked.emit(null);
        }
    }

    isAccentLine(code) {
        return (code === 'ECO056' && this.accountType !== 'RAY') || (code === 'ECOX19' && this.accountType === 'RAY');
    }

    goToJourna(periodNumber: number) {
        const nbStr = this.period.code.substring(1);
        const monthNumber = Number(nbStr);
        this.parent.goToDailyPeriod(Number(monthNumber));
        this.parent.tabSelected = 'JOURNA';
    }

    /**
     * Chande la méthode de répartition en annuel.
     */
    changeDispatchFilter(dispatchFilter: string) {
        this.dispatchFilterSelected = dispatchFilter;
    }

    goToNextCell(designationCode, titleCode) {
        let currentDesignationFound = false;
        for (let group of this.motif) {
            for (let pattern of group) {
                if (currentDesignationFound) {
                    if (this.isCellEditable(pattern.rubrique.code, titleCode)) {
                        return { designation: pattern.rubrique.code, title: titleCode };
                    }
                }
                if (pattern.rubrique.code === designationCode) {
                    currentDesignationFound = true;
                }
            }
        }
        return { designation: null, title: null };
    }

    isLockedByLocks(titleCode): boolean {
        if (this.period.locks.find(l => (l.title.code === titleCode && l.isLocked === true && l.periodId !== 18 ) )) {
            return true;
        }
    }

    sendLockNotif = () => {
        this.lockPeriod.emit(this.period);
    };

    isRubriqueCalculated(code: string): boolean {
        for (let group of this.motif) {
            for (let pattern of group) {
                if (pattern.rubrique.code === code) {
                    return pattern.rubrique.calculated;
                }
            }
        }
    }

    isPlanUpdatedByMag(titleCode, period, designationCode) {
        return (
            titleCode === 'PLAN' &&
            period.entryMatrix[designationCode][titleCode].role &&
            (period.entryMatrix[designationCode][titleCode].role === 'HRPROSIT' ||
                period.entryMatrix[designationCode][titleCode].role === 'HRPROSITULT' ||
                period.entryMatrix[designationCode][titleCode].role === 'HRPROSITSUP')
        );
    }

    executeRepartition(dispatchFilter) {

        const mv_conf = this.config.find(c => c.key === CONFIG_KEY.PILOT_RUBRIQUE_MSV);
        const va_conf = this.config.find(c => c.key === CONFIG_KEY.PILOT_RUBRIQUE_VASE);

        const mv_id = mv_conf ? mv_conf.value : null;
        const va_id = va_conf ? va_conf.value : null;

        const MV_PILOT_RUBRIQUE = this.calculationMatrix.find(r => r.code === mv_id)
        const VA_PILOT_RUBRIQUE = this.calculationMatrix.find(r => r.code === va_id)

        this.filter.emit(dispatchFilter);
        const entry: Entry = {
            designation: { code: this.cellOnEdit.designation },
            title: { code: this.cellOnEdit.title },
            value: +Number(this.valueStr).toFixed(2),
        };
        this.fireEntry.emit(entry);
        if (this.period.number === 0 && dispatchFilter !== 'NO_REP') {
            this.annualEntry.emit(entry);
        }
        if (
            (this.period.number !== 0 && entry.designation.code === VA_PILOT_RUBRIQUE.code) ||
            entry.designation.code === MV_PILOT_RUBRIQUE.code
        ) {
            let newEntry = Object.assign({ periodNumber: this.period.number }, entry);
            this.pilotEntry.emit(newEntry);
        }
        const nextCell = this.goToNextCell(this.cellOnEdit.designation, this.cellOnEdit.title);
        this.cellOnEdit = { designation: null, title: null };
        if (nextCell.designation && nextCell.title) {
            this.editCell(nextCell.designation, nextCell.title);
        }
    }
}
