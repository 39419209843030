import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appDesignationsGroup]',
})
export class DesignationsGroupDirective {
    constructor() { }

    @HostListener('click', ['$event.target']) onClick(group) {
    }
}
