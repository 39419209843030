import { Component, DoCheck, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Store as StoreNGRX } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from 'src/app/_services/logistic/export.service';
import { Account } from '../../_models/account';
import { Store } from '../../_models/store';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication/authentication.service';
import { DesignationService } from '../../_services/designation/designation.service';
import { StoreService } from '../../_services/store/store.service';
import {
    AUTHENTICATION_ROUTE,
    ERR_INTERNAL_SERVEUR_ERROR_MESSAGE,
    ERR_INTERNAL_SERVEUR_ERROR_TITLE,
    HOME_CDG_ROUTE,
    LOGOUT_MESSAGE,
    ROLES,
} from '../../constants';
import * as ApplicationActions from './../../_utils/state/application/actions';
import * as fromRoot from './../../_utils/state/reducers';

import { saveAs } from 'file-saver';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../environments/environment';
import { ExcelService } from '../../_services/excel/excel.service';
import { YearSettingsService } from '../../_services/yearSettings/year-settings.service';
//import { SocketService } from 'src/app/_services/socket/socket.service';
import {  Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalImportationComponent } from './modal-importation/modal-importation.component';
import { ModalExportationComponent } from './modal-exportation/modal-exportation.component';
import { MatDialog } from '@angular/material';
import { AccountComponent } from '../account/account.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

/**
 * Food data with nested structure.
 * Each node has a name and an optiona list of children.
 */
interface RayonNode {
    name: string;
    id: string;
    children?: RayonNode[];
}

let TREE_DATA: RayonNode[] = []

/** Flat node with expandable and level information */
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    animations: [
        trigger('scroll', [
            state('on', style({right: '0px'})),
            transition('* => *', [
                style({right: '-900px'}),
                animate(29000, style({right: '100%'}))
            ])
        ])
    ]
})
export class HomeComponent implements OnInit, OnDestroy, DoCheck {
    @ViewChild(AccountComponent)
    private accountComponent: AccountComponent;

    public version: string = environment.VERSION;

    private transformer = (node: RayonNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
            id: node.id
        };
    }

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this.transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);




    userConnected: any;
    accountSelected: Account;
    accounts: Account[];
    user: User;
    state = 0;
    newMessage: string;

    _messageSub: Subscription;

    /** Variable de déploiement */
    isMenuDeployed: boolean;
    isRayonsDeployed: boolean;
    isStoresDeployed: boolean;
    isDrivesDeployed: boolean;
    isSAVDeployed: boolean;

    /** Variable des inputs de recherche */
    searchStoreInput: string;
    searchRayonInput: string;
    searchDriveInput: string;
    searchSAVInput: string;

    /** Liste des stores disponibles pour l'utilisateur */
    filteredUserStoresList: Store[];
    filteredRayonsList: Account[];
    filteredDrivesList: Account[];
    filteredSavList: Account[];

    periodsToExport: any[];
    isExporting: boolean = false;

    modalVisible = false;

    currentYear: number;
    pilotYear: number;
    yearSetting: number;
    public isExportOpen = false;
    public isMessagingOpen = false;

    sortingOrder = ['HYPCO', 'SUPCO', 'HYP', 'SUP','FRNCO','FRN', 'FRN_ESS', 'ESS', 'RAY'];

    connectionsSub: Subscription;
    connections;
    currentMessage ='';
    constructor(
        private authenticationService: AuthenticationService,
        public storeService: StoreService,
        private router: Router,
        private toastService: ToastrService,
        private store: StoreNGRX<fromRoot.State>,
        private designationService: DesignationService,
        private exportService: ExportService,
        private excelService: ExcelService,
        private yearSettingsService: YearSettingsService,
        //private socketService: SocketService,
        public modalExport: MatDialog,
        public modalImport: NgbModal,
        public modalMessaging: NgbModal,
    ) {
        this.isMenuDeployed = false;
        this.isStoresDeployed = false;
        this.user = this.authenticationService.getCurrentUser();
        this.filteredUserStoresList = [];
        this.dataSource.data = TREE_DATA;
    }
    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
    ngOnInit() {
        TREE_DATA = this.getRayons()

        /*this.connectionsSub = this.socketService.getObservable().subscribe(connections => {
            this.connections = connections;
            console.log('connections : ', connections);
            this.currentMessage = connections;
        });*/
        this.storeService
            .getStoreSelected()
            .accounts.sort(
                (a, b) =>
                    this.sortingOrder.findIndex(x => x === a.type) - this.sortingOrder.findIndex(x => x === b.type)
            );
        this.userConnected = this.authenticationService.getCurrentUser();
        this.filteredUserStoresList = this.storeService.getUserStores();
        this.filteredRayonsList = this.storeService.getStoreSelected().rayons;
        this.filteredDrivesList = this.storeService.getStoreSelected().drives;
        this.filteredSavList = this.storeService.getStoreSelected().sav;
        this.accounts = this.storeService.getStoreSelected().accounts;
        this.setAccount(this.accounts[0] ? this.accounts[0].type : null);
        this.designationService
            .getAllDesignation()
            .subscribe(designations => (this.designationService.designations = designations));
        this.yearSettingsService.getYearByType('PILOT').subscribe(year => (this.pilotYear = year));
        this.yearSettingsService.getYearByType('CURRENT').subscribe(year => (this.currentYear = year));
        this.yearSettingsService.getYearByType('LOG').subscribe(year => (this.yearSetting = year));

        /*this.socketService
            .getMessage()
            .subscribe(msg => {
                console.log('Incoming msg : ', msg);
                this.currentMessage = msg;
            });

        this._messageSub = this.socketService.getMessageObservable().subscribe(message => {
            console.log('Incoming msg : ', message);
            this.currentMessage = message;
        });*/
    }

    getRayons() {
        var rayons = new Array();
        for(let key in this.storeService.getStoreSelected().rayons) {
            let child = this.storeService.getStoreSelected().rayons[key];
            let listCild = this.storeService.getStoreSelected().rayons.filter(rayon => rayon.parentRayondId  != null && (rayon.parentRayondId  === child.rayonId) );
            if(child.type!='MAR' && child.type!='MAR_FR' ){
                let rayon =  {
                    name: child.name,
                    id: child.id,
                    children: listCild
                }
                rayons.push(rayon)
            }
        }
       return rayons
    }
    ngDoCheck() {
        this.store.dispatch(new ApplicationActions.ExtendLogoutTimer());
    }

    refreshStoreFilter(): void {
        this.searchStoreInput = '';
        this.filteredUserStoresList = this.storeService.getUserStores();
    }

    refreshRayonFilter(): void {
        this.searchRayonInput = null
        this.dataSource.data = this.getRayons().sort((a, b) => a.id - b.id);
/*        this.searchRayonInput = '';
        this.filteredRayonsList = this.storeService.getStoreSelected().rayons.sort((a, b) => a.id - b.id);*/
    }

    refreshDriveFilter(): void {
        this.searchDriveInput = '';
        this.filteredDrivesList = this.storeService.getStoreSelected().drives.sort((a, b) => a.id - b.id);
    }

    refreshSavFilter(): void {
        this.searchSAVInput = '';
        this.filteredSavList = this.storeService.getStoreSelected().sav.sort((a, b) => a.id - b.id);
    }

    selectStore(store: Store): void {
        this.storeService.selectStoreById(store.id, this.authenticationService.getCurrentUser().login).subscribe(_ => {
            // vidage du filtre
            this.refreshStoreFilter();
            this.storeService
                .getStoreSelected()
                .accounts.sort(
                    (a, b) =>
                        this.sortingOrder.findIndex(x => x === a.type) - this.sortingOrder.findIndex(x => x === b.type)
                );
            this.accounts = this.storeService.getStoreSelected().accounts;
            this.setAccount(this.accounts[0] ? this.accounts[0].type : null);

            this.isMenuDeployed = false;
            this.isStoresDeployed = false;
            this.isRayonsDeployed = false;
            this.isDrivesDeployed = false;
            this.isSAVDeployed = false;
            this.router.navigate([HOME_CDG_ROUTE]);
        });
    }

    filterStore(event: any): void {
        if (!event.target.value || event.target.value.length === 0) {
            this.filteredUserStoresList = [...[], ...this.storeService.getUserStores()];
        } else {
            this.filteredUserStoresList = [...[], ...this.storeService.getUserStores()].filter(
                store =>
                    store.hyperionId.slice(-4).indexOf(event.target.value) > -1 ||
                    store.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
            );
        }
    }

    filterRayon(event: any): void {
        if (!event.target.value || event.target.value.length === 0) {
            this.dataSource.data = this.getRayons()
/*
            this.filteredRayonsList = this.storeService.getStoreSelected().rayons;*/
        } else {
            let filterRayons = this.getRayons()
            this.dataSource.data = filterRayons.filter(rayon => { return (rayon.name.toLowerCase().includes(event.target.value.toLowerCase()) || rayon.children.some(c => c.name.includes(event.target.value))); });
            var rayons = new Array();

            for(let key in this.dataSource.data)
            {
                let child = this.dataSource.data[key];
                console.log(child)
               let listCild = this.storeService.getStoreSelected().rayons.filter(rayon =>  (rayon.name.toLowerCase().includes(event.target.value.toLowerCase()) &&
                   child.name.includes(rayon.parentRayondId)) );
                if(listCild.length ===0) {
                    rayons.push(child)
                }  else
                {
                    let rayon =
                      {
                        name: child.name,
                        id: child.id,
                        children: listCild
                      }
                    rayons.push(rayon)
                }
            }
            this.dataSource.data = rayons
/*            this.filteredRayonsList = this.storeService
                .getStoreSelected()
                .rayons.filter(rayon => rayon.name.toLowerCase().includes(event.target.value.toLowerCase()));*/
        }
    }
    filterDrive(event: any): void {
        if (!event.target.value || event.target.value.length === 0) {
            this.filteredDrivesList = this.storeService.getStoreSelected().drives;
        } else {
            this.filteredDrivesList = this.storeService
                .getStoreSelected()
                .drives.filter(drive => drive.name.toLowerCase().includes(event.target.value.toLowerCase()));
        }
    }

    filterSav(event: any): void {
        if (!event.target.value || event.target.value.length === 0) {
            this.filteredSavList = this.storeService.getStoreSelected().sav;
        } else {
            this.filteredSavList = this.storeService
                .getStoreSelected()
                .sav.filter(sav => sav.name.toLowerCase().includes(event.target.value.toLowerCase()));
        }
    }

    setAccount(accountType) {
        // Aucun compte disponible donc l'utilisateur connecté est un chef de rayon ou logistic
        if (this.accounts.length === 0 && this.storeService.getStoreSelected().rayons.length !== 0) {
            if (!isNullOrUndefined(this.accountSelected)) {
                let newAccount = this.storeService
                    .getStoreSelected()
                    .rayons.find(r => r.name === this.accountSelected.name);
                if (!isNullOrUndefined(newAccount)) {
                    this.accountSelected = newAccount;
                } else {
                    this.accountSelected = this.storeService.getStoreSelected().rayons[0];
                }
            } else {
                this.accountSelected = this.storeService.getStoreSelected().rayons[0];
            }
        } else if (this.accounts.length !== 0) {
            this.accountSelected = this.accounts.find(account => account.type === accountType);
        } else {
            this.toastService.error(ERR_INTERNAL_SERVEUR_ERROR_MESSAGE, ERR_INTERNAL_SERVEUR_ERROR_TITLE);
        }
        this.isStoresDeployed = false;
        this.isRayonsDeployed = false;
        this.isMenuDeployed = false;
        this.isDrivesDeployed = false;
        this.isSAVDeployed = false;

        //this.socketService.enterAccount(this.accountSelected);
        //this.socketService.getMessage();
    }

    setRayon(id) {
        this.accountSelected = this.storeService.getStoreSelected().rayons.find(rayon => rayon.id === id);
        this.isMenuDeployed = false;
        this.isRayonsDeployed = false;
        this.isStoresDeployed = false;
        this.isDrivesDeployed = false;
        this.isSAVDeployed = false;

        //this.socketService.enterAccount(this.accountSelected);
        //this.socketService.getMessage();
    }

    setDrive(id) {
        this.accountSelected = this.storeService.getStoreSelected().drives.find(drive => drive.id === id);
        this.isMenuDeployed = false;
        this.isRayonsDeployed = false;
        this.isStoresDeployed = false;
        this.isDrivesDeployed = false;
        this.isSAVDeployed = false;

        //this.socketService.enterAccount(this.accountSelected);
       //this.socketService.getMessage();
    }

    setSav(id) {
        this.accountSelected = this.storeService.getStoreSelected().sav.find(sav => sav.id === id);
        this.isMenuDeployed = false;
        this.isRayonsDeployed = false;
        this.isStoresDeployed = false;
        this.isDrivesDeployed = false;
        this.isSAVDeployed = false;

        //this.socketService.enterAccount(this.accountSelected);
        //this.socketService.getMessage();
    }

    goToAdmin() {
        this.router.navigate(['admin']);
    }

    goToLogger() {
        this.router.navigate(['logger']);
    }

    adminVisible() {
        return this.user.role !== 'HRPRORAYON' && this.user.role !== 'USERLOG';
    }

    isAdmin = () => {
        return ROLES.LIST.ADMINS.includes(this.user.role);
    }

    disconnect(): void {
        this.authenticationService.disconnect();
        this.toastService.info(LOGOUT_MESSAGE);
        this.router.navigate([AUTHENTICATION_ROUTE]);
    }

    isLogistic(): boolean {
        return this.storeService.getStoreSelected().category === 'LOG';
    }

    exportHyperionEst() {
        this.isExporting = true;
        this.exportService.export('EST').subscribe(
            data => {
                this.isExporting = false;
                this.toastService.success('Export hyperion terminé', 'Export Estimé');
                let blob = new Blob(data, { type: 'text/plain;charset=utf-8' });
                const date = new Date();
                saveAs(blob, `SUPP12@SO@ESTIME@LOG@SUPP12-${this.yearSetting}@RR.csv`);
            },
            error => {
                this.isExporting = false;
                this.toastService.error('Une erreur est survenue', 'Erreur');
            }
        );
        this.modalVisible = false;
    }
    exportHyperionObj() {
        this.isExporting = true;
        this.exportService.export('OBJN+1').subscribe(
            data => {
                this.isExporting = false;
                this.toastService.success('Export hyperion terminé', 'Export Objectif');
                let blob = new Blob(data, { type: 'text/plain;charset=utf-8' });
                const date = new Date();
                saveAs(blob, `SUPP12@SO@OBJECTIFS@LOG@SUPP12-${this.yearSetting}@RR.csv`);
            },
            error => {
                this.isExporting = false;
                this.toastService.error('Une erreur est survenue', 'Erreur');
            }
        );
        this.modalVisible = false;
    }
    isModalVisible() {
        this.modalVisible = true;
    }
    closeExportModal() {
        this.modalVisible = false;
    }

    openExportationModal() {
        this.isExportOpen = true;
        const exportationModal = this.modalExport.open(ModalExportationComponent, {
            disableClose: true,
            minWidth: '450px',
            data: {
                accountSelected: this.accountSelected,
                store: this.storeService.getStoreSelected()
            },
        });
        exportationModal.afterClosed().subscribe((isRayonExport) => {
            if (isRayonExport) {
                this.accountComponent.exportAccountData();
            }
            this.isExportOpen = false;
        });
    }

    openImportModal() {
        this.modalImport.open(ModalImportationComponent, {
            size: 'lg',
            backdrop: 'static',
            centered: true,
            keyboard: false,
        });
    }

    ngOnDestroy() {
        //this.connectionsSub.unsubscribe();
        //this.socketService.leaveAccount();
    }

    scrollDone() {
        this.state++;
    }

    open(content) {
        this.isMessagingOpen = true;
        this.modalMessaging.open(content, {
            size: 'lg',
            //backdrop: 'static',
            centered: true,
            //keyboard: false,
            beforeDismiss: () => {
                this.isMessagingOpen = false;
                return true;
            }
        });
    }

    sendMessage() {
        //this.connections = this.currentMessage
        //this.socketService.sendMessage(this.connections);
    }

    destroyMessage() {
        //this.socketService.destroyMessage();
    }
    isAuchanDirect(): boolean {
        return this.storeService.getStoreSelected().category === 'AUCHD';
    }
    isAdminImport = () => {
        return ROLES.LIST.ADMINS_IMPORT.includes(this.user.role);
    }
}
