import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { AuthenticationService } from './_services/authentication/authentication.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authenticationService.isAuthenticate()) {
            request = request.clone({
                headers: request.headers.set('x-access-token', sessionStorage.getItem('userEntityOp')),
            });
        }
        return next.handle(request);
    }
}
