import { Component } from '@angular/core';

@Component({
  selector: 'app-copy-entrant-dialog',
  templateUrl: './copy-entrant-dialog.component.html',
  styleUrls: ['./copy-entrant-dialog.component.css']
})
export class CopyEntrantDialogComponent {

  constructor() { }

}
