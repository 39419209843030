import { Component, Inject, OnInit } from '@angular/core';
import { EntryEdition, UndoService } from '../../../_services/logistic/undo.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { filter, flatMap } from 'rxjs/operators';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.css'],
})
export class HistoryComponent implements OnInit {
    history: EntryEdition[];

    constructor(
        public dialogRef: MatDialogRef<HistoryComponent>,
        private undoService: UndoService,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit() {
        this.history = [];
        // On récupère l'historique de la période et on tri par date
        this.undoService.history$
            .pipe(
                flatMap(edition => edition),
                filter(
                    edition =>
                        edition.account.id === this.data.accountId && edition.period.code === this.data.periodCode
                )
            )
            .subscribe(edition => {
                this.history.push(edition);
                this.history = this.history.sort((a, b) => {
                    return a.date > b.date ? -1 : 1;
                });
            });
    }

    closeDialog(edition: EntryEdition) {
        this.dialogRef.close(edition);
    }
}
