import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormArray } from '@angular/forms';

import { RubriqueService } from 'src/app/_services/rubrique/rubrique.service';
import { ConfigService } from 'src/app/_services/config/config.service';
import { ToastrService } from 'ngx-toastr';

import { Rubrique } from 'src/app/_models/rubrique';
import { Config } from 'src/app/_models/config';
import { map } from 'rxjs/operators';
import { User } from '../../../_models/user';
import { Store } from '../../../_models/store';

// @ts-ignore
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from '../../../_services/admin/admin.service';
import { AuthenticationService } from '../../../_services/authentication/authentication.service';
import { isNullOrUndefined } from "util";
import { ModalUserComponent } from '../modal-user/modal-user.component';
import { ModalUserEditionComponent } from '../modal-user-edition/modal-user-edition.component';
import { Account } from '../../../_models/account';


@Component({
  selector: 'app-manage-drive',
  templateUrl: './manage-drive.component.html',
  styleUrls: ['./manage-drive.component.css']
})
export class manageDriveComponent implements OnInit {

  @ViewChild('filterIdentifiant') filterIdentifiant: ElementRef;
  @ViewChild('filterRole') filterRole: ElementRef;
  @ViewChild('filterMagasin') filterMagasin: ElementRef;

  @Input() roles: any[];

  allUsers: User[];

  usersToDisplay: User[] = [];

  accountsToDisplay: Account[] = []

  stores: Store[];

  accounts: Account[];

  roleName: String;

  loading = false;

  idRayonFilter: string = '';

  nameFilter: string = '';

  typeFilter: string = '';

  isFiltered: boolean = false;

  constructor(
      private modalUser: NgbModal,
      private adminService: AdminService,
      private authenticationService: AuthenticationService,
  ) {
    this.adminService.listen().subscribe((m: any) => {
      this.loading = true;
      this.resetResearch();
      this.getAccounts();
    })
  }

  ngOnInit() {
    this.loading = true;
    this.isFiltered = false;
    // TODO Adapter les noms des fonctions -> + explicit
    this.getUsers();
    this.getStores();
    this.getAccounts();
  }

  getUsers() {
    this.adminService.getUsersManaged(this.authenticationService.getCurrentUser().id).subscribe(
        users => {
          this.allUsers = users;
          this.usersToDisplay = this.allUsers;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
    );
  }

  getStores() {
    this.adminService.getStores(this.authenticationService.getCurrentUser().login).subscribe(
        stores => {
          this.stores = stores;
        },
        () => {
        }
    );
  }

  getAccounts() {
    this.adminService.getAccountsByTypes().subscribe(
        accounts => {
          this.accounts = accounts;
          this.accountsToDisplay = this.accounts;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
    );
  }

  // TODO On peut sans doute refactorer ça
  setIdentifiantFilter(event) {
    this.idRayonFilter = event.target.value;
    this.applyFilter();
  }

  setRoleFilter(event) {
    this.nameFilter = event.target.value;
    this.applyFilter();
  }

  setStoreFilter(event) {
    this.typeFilter = event.target.value;
    this.applyFilter();
  }

  /**
   * Applique les trois filtres sur les users, ca permet de les combiner.
   */
  // TODO Ajouter un loader (des fois le filtre peut prendre + de 2s)
  /*applyFilter() {
    this.usersToDisplay = this.allUsers.filter(user => {
      if (!user.login.toLowerCase().includes(this.identifiantFilter.toLowerCase())) {
        return false;
      } else if (
          !isNullOrUndefined(this.changeRoleName(user.role)) &&
          !this.changeRoleName(user.role)
              .toLowerCase()
              .includes(this.roleFilter.toLowerCase())
      ) {
        return false;
      } else if (
          !user.stores.find(store => store.name.toLowerCase().includes(this.storeFilter.toLowerCase())) &&
          user.stores.length !== 0
      ) {
        return false;
      }
      return true;
    });
  }*/

  applyFilter() {
    this.accountsToDisplay = this.accounts.filter(account => {
      if (!account.rayonId.toLowerCase().includes(this.idRayonFilter.toLowerCase())) {
        return false;
      } else if (
          !account.name
              .toLowerCase()
              .includes(this.nameFilter.toLowerCase())
      ) {
        return false;
      } else if (
          !account.type
              .toLowerCase()
              .includes(this.nameFilter.toLowerCase())
      ) {
        return false;
      }
      return true;
    });
  }

  changeRoleName = input => {
    return this.adminService.changeRoleName(input);
  };

  resetResearch() {
    this.filterIdentifiant.nativeElement.value = '';
    this.filterRole.nativeElement.value = '';
    this.filterMagasin.nativeElement.value = '';
    this.idRayonFilter = '';
    this.nameFilter = '';
    this.typeFilter = '';
    this.applyFilter();
  }

  openCreateUserModal() {
    const userModal = this.modalUser.open(ModalUserComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    userModal.componentInstance.stores = this.stores;
    userModal.componentInstance.roles = this.roles;
    userModal.result.then(res => {
      if (res && res.login) {
        this.allUsers.push(res);
      }
    });
  }

  openEditUserModal(user) {
    const userEditModal = this.modalUser.open(ModalUserEditionComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    userEditModal.componentInstance.user = user;
    userEditModal.componentInstance.roles = this.roles;
    userEditModal.componentInstance.stores = this.stores;
    userEditModal.result.then(res => {
      if (res && res.login) {
        let previousUser = this.allUsers.find(u => u.id === res.id);
        this.allUsers.splice(this.allUsers.indexOf(previousUser), 1);
        this.allUsers.push(res);
      }
    });
  }
}


