import { Component, Input, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Designation } from '../../_models/designation';
import { Period } from '../../_models/period';
import { Pattern } from 'src/app/_models/pattern';
import * as _ from 'lodash';
import { Rubrique } from 'src/app/_models/rubrique';

const DESIGNATIONS_CALC = ['CG0011', 'ECO021', 'ECO020', 'ECO540', 'ECOX19', 'ECO560', 'ECO531', 'ECO052', 'ECO056'];

@Component({
    selector: 'app-delta',
    templateUrl: './delta.component.html',
    styleUrls: ['./delta.component.css'],
})
export class DeltaComponent implements OnInit {
    @Input('patternDesignation') pattern: any[];
    @Input() calculationMatrix: any[];
    @Input() lineSelected: string;
    @Input() delta: Period;
    @Input() hiddenDesignations: string[];
    @Input() accountType: string;
    @Input() headerName: string;

    @Input() MV_PILOT_RUBRIQUE: Rubrique;
    @Input() VA_PILOT_RUBRIQUE: Rubrique;

    constructor() { }

    ngOnInit() { }

    isHidden(pattern: Pattern): boolean {
        if (pattern.parentCode === (null || '0') || this.hiddenDesignations.length <= 0) {
            return false;
        }
        if (this.hiddenDesignations.find(code => code === pattern.parentCode)) {
            return true;
        }
    }

    getDeltaValue(pattern: Pattern) {
        const v = _.get(this.delta, ['entryMatrix', pattern.rubrique.code, 'value'])
        return v ? v : 0;
    }

    ABS(value : any){
        return Math.abs(value);
    }

}
