import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/index';
import { catchError, map } from 'rxjs/internal/operators';
import { environment } from '../../../environments/environment';
import { Account } from '../../_models/account';
import { Store } from '../../_models/store';
import {
    ACCOUNT_URI,
    AUTHENTICATION_ROUTE,
    ERR_INTERNAL_SERVEUR_ERROR_MESSAGE,
    ERR_INTERNAL_SERVEUR_ERROR_TITLE,
    ERR_UNAUTHORIZED_ACTION_MESSAGE,
    LOC_STO_STORE_SELECTED,
    LOC_STO_USER_STORES,
    STORE_UNIQUE_URI,
    STORE_URI,
} from '../../constants';
import { AuthenticationService } from '../authentication/authentication.service';
//import { SocketService } from 'src/app/_services/socket/socket.service';

@Injectable({
    providedIn: 'root',
})
export class StoreService {
    private storeSelected: Store;
    // Liste des magasins de l'utilisateur connecté
    private userStores: Store[];

    constructor(
        private http: HttpClient,
        private toastService: ToastrService,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
        if (sessionStorage.getItem(LOC_STO_STORE_SELECTED)) {
            this.storeSelected = JSON.parse(sessionStorage.getItem(LOC_STO_STORE_SELECTED)) as Store;
        } else {
            this.storeSelected = null;
        }
        if (sessionStorage.getItem(LOC_STO_USER_STORES)) {
            this.userStores = JSON.parse(sessionStorage.getItem(LOC_STO_USER_STORES)) as Store[];
        } else {
            this.userStores = null;
        }
    }

    getAllStoresByUserConnected(login): Observable<Store[]> {
        return this.http.get<Store[]>(environment.OP_API_URI + '/login/' + login).pipe(
            map(result => {
                this.userStores = result as Store[];
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    //this.toastService.error(ERR_UNAUTHORIZED_ACTION_MESSAGE);
                    this.router.navigate([AUTHENTICATION_ROUTE]);
                    this.authenticationService.disconnect();
                } else {
                    this.toastService.error(ERR_INTERNAL_SERVEUR_ERROR_MESSAGE, ERR_INTERNAL_SERVEUR_ERROR_TITLE);
                }
                return [];
            })
        );
    }

    selectStoreById(storeId, userLogin): Observable<Store> {
        return this.http
            .get<Store>(environment.OP_API_URI + '/stores/' + storeId.toString() + '/users/' + userLogin)
            .pipe(
                map(result => {
                    this.setStoreSelected(result as Store);
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        //this.toastService.error(ERR_UNAUTHORIZED_ACTION_MESSAGE);
                        this.router.navigate([AUTHENTICATION_ROUTE]);
                        this.authenticationService.disconnect();
                    } else {
                        this.toastService.error(ERR_INTERNAL_SERVEUR_ERROR_MESSAGE, ERR_INTERNAL_SERVEUR_ERROR_TITLE);
                    }
                    return [];
                })
            );
    }

    getStoreSelected(): Store {
        return this.storeSelected;
    }

    setStoreSelected(store: Store): void {
        this.storeSelected = store;
        sessionStorage.setItem(LOC_STO_STORE_SELECTED, JSON.stringify(this.storeSelected));
    }

    setStoreSelectedStep(stepNumber: number) {
        this.storeSelected.step = stepNumber;
        sessionStorage.setItem(LOC_STO_STORE_SELECTED, JSON.stringify(this.storeSelected));
    }

    getUserStores(): Store[] {
        return this.userStores;
    }

    hasOnlyOneStore(): boolean {
        return this.userStores && this.userStores.length === 1;
    }

    /**
     * Met à jour l'étape d'un magasin.
     */
    updateStoreStep(storeId: number, newStep: number): Observable<Store> {
        return this.http
            .put<Store>(environment.OP_API_URI + STORE_URI + '/' + storeId, {
                step: newStep,
            })
            .pipe(
                map(_ => _),
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        //this.toastService.error(ERR_UNAUTHORIZED_ACTION_MESSAGE);
                        this.router.navigate([AUTHENTICATION_ROUTE]);
                        this.authenticationService.disconnect();
                    } else {
                        this.toastService.error(ERR_INTERNAL_SERVEUR_ERROR_MESSAGE, ERR_INTERNAL_SERVEUR_ERROR_TITLE);
                    }
                    return [];
                })
            );
    }

    toStep2(storeId: number): Observable<string> {
        return this.http.get<string>(environment.OP_API_URI + STORE_URI + '/step2/' + storeId);
    }

    updateToStep2(data: { storeId: number; accountType: string }): Observable<string> {
        return this.http.put<string>(environment.OP_API_URI + '/toStep2', data);
    }

    getStoreById(id: number): Observable<Store> {
        return this.http.get<Store>(`${environment.OP_API_URI}${STORE_URI}/${id}`);
    }

    getAll(): Observable<Store[]> {
        return this.http.get<Store[]>(`${environment.OP_API_URI}${STORE_URI}`);
    }

}
