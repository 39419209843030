import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntryLog } from 'src/app/components/logistic/_models/entry-log';
import { isNullOrUndefined } from 'util';
import { Designation } from '../../_models/designation';
import { Period } from '../../_models/period';
import { EntryLogService } from './entry-log.service';
import { PeriodLog } from '../../components/logistic/_models/period-log';
import { stringify } from 'querystring';

@Injectable({
    providedIn: 'root',
})
export class DispatcherService {
    constructor(private entryLogService: EntryLogService) { }

    /**
     * Dispatch la ligne correspondante à la désignation en paramètre de la période
     * Effectue les mises a jour horizontales de calcul par rapport a l'entrée modifiée -- vue mensuelle
     * en paramètre -> on calcule les progressions.
     * @param designation
     * @param period
     */
    dispatchLine(designation: Designation, period: Period) {
        // On récupère toutes les entrées de la designation
        const entriesToDispatch = period.entries.filter(e => e.designation.code === designation.code);

        const lastYearReal = entriesToDispatch.find(e => e.title.code === 'REALN-1');
        const lastYearObj = entriesToDispatch.find(e => e.title.code === 'OBJ');
        // Calcul des progressions de l'estimé
        const est = entriesToDispatch.find(e => e.title.code === 'EST');
        const progEstReal = entriesToDispatch.find(e => e.title.code === 'PGS%ER');
        const progEstObj = entriesToDispatch.find(e => e.title.code === 'PGS%EO');
        progEstReal.value = DispatcherService.calcProgression(lastYearReal.value, est.value);
        progEstObj.value = DispatcherService.calcProgression(lastYearObj.value, est.value);
        // Calcul des progressions du réalisé
        const currentReal = entriesToDispatch.find(e => e.title.code === 'REALN');
        const progReals = entriesToDispatch.find(e => e.title.code === 'PGS%RR');
        const progRealObj = entriesToDispatch.find(e => e.title.code === 'PGS%RO');
        progReals.value = DispatcherService.calcProgression(lastYearReal.value, currentReal.value);
        progRealObj.value = DispatcherService.calcProgression(lastYearObj.value, currentReal.value);
        // Calcul des progressions de l'objectif
        const currentObj = entriesToDispatch.find(e => e.title.code === 'OBJN+1');
        const progCurrentObjEst = entriesToDispatch.find(e => e.title.code === 'PGS%OE');
        progCurrentObjEst.value = DispatcherService.calcProgression(est.value, currentObj.value);
    }

    /**
     * 
     * Effectue les mises a jour horizontales de calcul par rapport a l'entrée modifiée -- vue mensuelle
     */
    dispatchLineYear(designation: Designation, year: Period, periods: any[]) {
        const lockedPeriods = periods.filter(p => p.isLocked && p.entryLogs);
        const unlockedPeriods = periods.filter(p => !p.isLocked && p.entryLogs);
        const reducerLastYearReal = (acc, p) => {
            const entry = p.entryLogs
                .filter(e => e.designation.code === designation.code)
                .find(e => e.titleLog.code === 'REALN-1');
            return entry ? acc + Number(entry.value) : acc;
        };
        const reducerObj = (acc, p) => {
            const entry = p.entryLogs
                .filter(e => e.designation.code === designation.code)
                .find(e => e.titleLog.code === 'OBJ');
            return entry ? acc + Number(entry.value) : acc;
        };
        const lastYearRealLockedValue = lockedPeriods.reduce(reducerLastYearReal, 0);
        const lastYearRealUnlockedValue = unlockedPeriods.reduce(reducerLastYearReal, 0);
        const objUnlockedValue = unlockedPeriods.reduce(reducerObj, 0);
        // On récupère toutes les entrées de la designation
        const entriesToDispatch = year.entries.filter(e => e.designation.code === designation.code);
        const lastYearReal = entriesToDispatch.find(e => e.title.code === 'REALN-1');
        // Objectifs N
        const lastYearObj = entriesToDispatch.find(e => e.title.code === 'OBJ');
        const progObjReal = entriesToDispatch.find(e => e.title.code === 'PGS%OR');
        progObjReal.value = DispatcherService.calcProgression(lastYearReal.value, lastYearObj.value);
        // Réalisé N a date
        const currentReal = entriesToDispatch.find(e => e.title.code === 'REALN');
        const progReals = entriesToDispatch.find(e => e.title.code === 'PGS%RR');
        progReals.value = DispatcherService.calcProgression(lastYearRealLockedValue, currentReal.value);
        // Estimé N
        const est = entriesToDispatch.find(e => e.title.code === 'EST');
        const progEstReal = entriesToDispatch.find(e => e.title.code === 'PGS%ER');
        const progEstObj = entriesToDispatch.find(e => e.title.code === 'PGS%EO');
        progEstReal.value = DispatcherService.calcProgression(lastYearReal.value, est.value);
        progEstObj.value = DispatcherService.calcProgression(lastYearObj.value, est.value);
        // Estimé reste à faire N
        const estRaf = entriesToDispatch.find(e => e.title.code === 'ESTRAF');
        estRaf.value = est.value - currentReal.value;
        const progEstRafReal = entriesToDispatch.find(e => e.title.code === 'PGS%ERAFR');
        const progEstRafObj = entriesToDispatch.find(e => e.title.code === 'PGS%ERAFO');
        progEstRafReal.value = DispatcherService.calcProgression(lastYearRealUnlockedValue, estRaf.value);
        progEstRafObj.value = DispatcherService.calcProgression(objUnlockedValue, estRaf.value);
        // Objectifs N+1
        const obj = entriesToDispatch.find(e => e.title.code === 'OBJN+1');
        const progObjEst = entriesToDispatch.find(e => e.title.code === 'PGS%ON+1E');
        progObjEst.value = DispatcherService.calcProgression(est.value, obj.value);
    }

    /**
     * Applique les changements en vertical
     */
    dispatchCalcul(newEntry, period: Period, calculDispatcher, diff: number) {
        if (newEntry.designation.code === 'PNLSTAT01' || newEntry.designation.code === 'ECOX18') {
            const coutColis = period.entries.find(
                e => e.designation.code === 'CC' && e.title.code === newEntry.title.code
            );
            const firstPart = period.entries.find(
                e => e.designation.code === 'ECOX18' && e.title.code === newEntry.title.code
            ).value;
            const secondPart = period.entries.find(
                e => e.designation.code === 'PNLSTAT01' && e.title.code === newEntry.title.code
            ).value;
            coutColis.value = Number(firstPart) / Number(secondPart);
            coutColis.value = Math.round(coutColis.value * 1000) / 1000;
            this.dispatchLine(coutColis.designation, period);
        }

        const stop = isNullOrUndefined(calculDispatcher[newEntry.designation.code]);
        if (stop) {
            return;
        }

        // Calcul des Sommes
        calculDispatcher[newEntry.designation.code].sum.forEach(entriesToSumCode => {
            const entriesToGetSum = period.entries.filter(
                e => e.designation.code == entriesToSumCode && e.title.code === newEntry.title.code
            );
            entriesToGetSum.forEach(entryToGetSum => {
                const lastValue = entryToGetSum.value;
                entryToGetSum.value = Number(entryToGetSum.value) + diff;
                const newDiff = entryToGetSum.value - lastValue;
                // On dispatche la ligne calculée
                this.dispatchLine(entryToGetSum.designation, period);
                // On relance le calcul de manière récursif
                this.dispatchCalcul(entryToGetSum, period, calculDispatcher, newDiff);
            });
        });
        // Calcul des Soustractions
        calculDispatcher[newEntry.designation.code].sub.forEach(entriesToSubCode => {
            const entriesToGetSub = period.entries.filter(
                e => e.designation.code == entriesToSubCode && e.title.code === newEntry.title.code
            );
            entriesToGetSub.forEach(entryToGetSub => {
                const lastValue = entryToGetSub.value;
                entryToGetSub.value = Number(entryToGetSub.value) - diff;
                const newDiff = entryToGetSub.value - lastValue;
                // On dispatche la ligne calculée
                this.dispatchLine(entryToGetSub.designation, period);
                // On relance le calcul de manière récursif
                this.dispatchCalcul(entryToGetSub, period, calculDispatcher, newDiff);
            });
        });
    }

    /**
     * Applique les changements en vertical pour la réparition par année
     * @param calculDispatcher matrice pattern retournée
     * a = b - c (Ex matrice) -> modification b -> ajout à 'a'
     */
    dispatchCalculYear(newEntry, period: Period, calculDispatcher, diff: number, periods: any[]) {
        if (newEntry.designation.code === 'PNLSTAT01' || newEntry.designation.code === 'ECOX18') {
            const coutColis = period.entries.find(
                e => e.designation.code === 'CC' && e.title.code === newEntry.title.code
            );
            const firstPart = period.entries.find(
                e => e.designation.code === 'ECOX18' && e.title.code === newEntry.title.code
            ).value;
            const secondPart = period.entries.find(
                e => e.designation.code === 'PNLSTAT01' && e.title.code === newEntry.title.code
            ).value;
            coutColis.value = Number(firstPart) / Number(secondPart);
            this.dispatchLineYear(coutColis.designation, period, periods);
        }

        const stop = isNullOrUndefined(calculDispatcher[newEntry.designation.code]);
        if (stop) {
            return;
        }

        // Calcul des Sommes
        calculDispatcher[newEntry.designation.code].sum.forEach(entriesToSumCode => {
            const entriesToGetSum = period.entries.filter(
                e => e.designation.code == entriesToSumCode && e.title.code === newEntry.title.code
            );
            entriesToGetSum.forEach(entryToGetSum => {
                const lastValue = entryToGetSum.value;
                entryToGetSum.value = Number(entryToGetSum.value) + diff;
                const newDiff = entryToGetSum.value - lastValue;
                // On dispatche la ligne calculée
                this.dispatchLineYear(entryToGetSum.designation, period, periods);
                // On relance le calcul de manière récursif
                this.dispatchCalculYear(entryToGetSum, period, calculDispatcher, newDiff, periods);
            });
        });
        // Calcul des Soustractions
        calculDispatcher[newEntry.designation.code].sub.forEach(entriesToSubCode => {
            const entriesToGetSub = period.entries.filter(
                e => e.designation.code == entriesToSubCode && e.title.code === newEntry.title.code
            );
            entriesToGetSub.forEach(entryToGetSub => {
                const lastValue = entryToGetSub.value;
                entryToGetSub.value = Number(entryToGetSub.value) - diff;
                const newDiff = entryToGetSub.value - lastValue;
                // On dispatche la ligne calculée
                this.dispatchLineYear(entryToGetSub.designation, period, periods);
                // On relance le calcul de manière récursif
                this.dispatchCalculYear(entryToGetSub, period, calculDispatcher, newDiff, periods);
            });
        });
    }


    static calcProgression(startValue: number, endValue: number): number {
        const prog = ((endValue - startValue) / Math.abs(startValue)) * 100;
        return isNaN(prog) ? 0 : Math.round(prog * 100) / 100;
    }

    /**
     * Applique le changement annuel vers les mois selon al repatition selectionnée
     * @param entry 
     * @param periods 
     * @param accountId 
     */
    private dispatchAnnualEntry(entry: EntryLog, periods: any[], accountId: number, resetSupp?: boolean): Observable<any> {
        const both = [];
        const periodValue = Math.round((entry.value / periods.length) * 100) / 100;
        periods.forEach(period => {

            const newEntry = {
                title: entry.title,
                periodLogId: period.id,
                accountId,
                designation: entry.designation,
                value: periodValue,
                yearId: 3,
            };

            both.push(newEntry);
        });

        if (resetSupp) {
            const newEntry = {
                title: entry.title,
                periodLogId: 7,
                accountId,
                designation: entry.designation,
                value: 0,
                yearId: 3,
            };

            const newEntry2 = {
                title: entry.title,
                periodLogId: 14,
                accountId,
                designation: entry.designation,
                value: 0,
                yearId: 3,
            };

            both.push(newEntry);
            both.push(newEntry2);

        }
        return this.entryLogService.createOrUpdateEntryLogV2(both);
    }

    dispatchAnnualEntry14(entry: EntryLog, periods: any[], accountId: number): Observable<any> {
        // remove locked periods and their result
        const entryCopy = JSON.parse(JSON.stringify(entry));
        if (entry.title.code !== 'OBJN+1') {
            const lockedPeriods = periods.filter(period => period.isLocked);
            const lockedValue = this.calculateSumEntries(entry.title.code, entry.designation.code, lockedPeriods);
            entryCopy.value = entry.value - lockedValue;
            periods = periods.filter(period => !period.isLocked);
        }
        return this.dispatchAnnualEntry(entryCopy, periods, accountId);
    }

    dispatchAnnualEntry12(entry: EntryLog, periods: any[], accountId: number): Observable<any> {
        periods = periods.filter(period => period.code !== 'SUPP06' && period.code !== 'SUPP12');
        // remove locked periods and their result
        const entryCopy = JSON.parse(JSON.stringify(entry));
        if (entry.title.code !== 'OBJN+1') {
            const lockedPeriods = periods.filter(period => period.isLocked);
            const lockedValue = this.calculateSumEntries(entry.title.code, entry.designation.code, lockedPeriods);
            entryCopy.value = entry.value - lockedValue;
            periods = periods.filter(period => !period.isLocked);
        }
        return this.dispatchAnnualEntry(entryCopy, periods, accountId, true);
    }

    dispatchAnnualEntryWeight(entry: EntryLog, periods: any[], accountId: number): Observable<any> {
        const both = [];
        // remove locked periods and their result
        let entryValue = entry.value;
        if (entry.title.code !== 'OBJN+1') {
            const lockedPeriods = periods.filter(period => period.isLocked);
            const lockedValue = this.calculateSumEntries(entry.title.code, entry.designation.code, lockedPeriods);
            entryValue = entry.value - lockedValue;
            periods = periods.filter(period => !period.isLocked);
        }
        const sumEntries = this.calculateSumEntries(entry.title.code, entry.designation.code, periods);
        periods.forEach(period => {
            let periodEntry = period.entryLogs.find(
                e => e.titleLog.code === entry.title.code && e.designation.code === entry.designation.code
            );
            let weightEntry = period.entryLogs.find(
                e => e.titleLog.code === entry.title.code && e.designation.code === entry.designation.code
            );

            periodEntry = JSON.parse(JSON.stringify(entry))
            periodEntry.periodLogId = period.id;
            periodEntry.accountId = accountId;
            periodEntry.yearId = 3;

            periodEntry.value = weightEntry ? +((Number(weightEntry.value) / sumEntries) * Number(entryValue)).toFixed(2) : 0;
            both.push(periodEntry);

        });
        return this.entryLogService.createOrUpdateEntryLogV2(both);
    }

    dispatchAnnualEntryLastWeight(entry: EntryLog, periods: any[], accountId: number): Observable<any> {
        const both = []
        let titleWitness;
        // remove locked periods and their result
        let entryValue = entry.value;
        if (entry.title.code !== 'OBJN+1') {
            const lockedPeriods = periods.filter(period => period.isLocked);
            const lockedValue = this.calculateSumEntries(entry.title.code, entry.designation.code, lockedPeriods);
            entryValue = entry.value - lockedValue;
            periods = periods.filter(period => !period.isLocked);
            titleWitness = 'REALN-1';
        } else {
            titleWitness = 'EST';
        }
        const sumEntries = this.calculateSumEntries(titleWitness, entry.designation.code, periods);
        periods.forEach(period => {
            let periodEntry = period.entryLogs.find(
                e => e.titleLog.code === entry.title.code && e.designation.code === entry.designation.code
            );
            let weightEntry = period.entryLogs.find(
                e => e.titleLog.code === titleWitness && e.designation.code === entry.designation.code
            );

            periodEntry = JSON.parse(JSON.stringify(entry))
            periodEntry.periodLogId = period.id;
            periodEntry.accountId = accountId;
            periodEntry.yearId = 3;
            periodEntry.value = weightEntry ? +((Number(weightEntry.value) / sumEntries) * Number(entryValue)).toFixed(2) : 0;
            both.push(periodEntry);

        });
        return this.entryLogService.createOrUpdateEntryLogV2(both);
    }

    private calculateSumEntries(titleCode, designationCode, periods: any[]): number {
        let sumEntries = 0;
        periods.forEach(period => {
            const periodEntry = period.entryLogs.find(
                e => e.titleLog.code === titleCode && e.designation.code === designationCode
            );
            if (!isNullOrUndefined(periodEntry)) {
                sumEntries += Number(periodEntry.value);
            }
        });
        return sumEntries;
    }
}
