import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/_services/store/store.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-locking',
    templateUrl: './modal-locking.component.html',
    styleUrls: ['./modal-locking.component.css'],
})
export class ModalLockingComponent implements OnInit {
    public subscription = new Subscription();
    public value: string = 'NON';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalLockingComponent>
    ) { }

    ngOnInit() {
    }

    onSubmit = () => {
        this.value = 'OUI';
        this.dialogRef.close(this.value);
    };

    closeModal = () => {
        this.dialogRef.close(this.value);
    };
}
