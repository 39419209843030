import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-account-dialog.component.html',
    styleUrls: ['./message-account-dialog.component.css'],
})
export class MessageAccountDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<MessageAccountDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public message: string
    ) {}

    ngOnInit() {}

    closeMessage(confirm: boolean) {
        this.dialogRef.close(confirm);
    }
}
