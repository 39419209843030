import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '../../../_models/store';
import { User } from '../../../_models/user';
import { AuthenticationService } from '../../../_services/authentication/authentication.service';
import { StoreService } from '../../../_services/store/store.service';
import { AUTHENTICATION_ROUTE, HOME_CDG_ROUTE, LOC_STO_USER, LOC_STO_USER_STORES } from '../../../constants';

@Component({
    selector: 'app-store-selection-card',
    templateUrl: './store-selection-card.component.html',
    styleUrls: ['./store-selection-card.component.css'],
    animations: [
        trigger('fadeInAnimation', [
            transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class StoreSelectionCardComponent implements OnInit {
    stores: Store[];
    loader: boolean;

    constructor(
        private storeService: StoreService,
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
        this.stores = new Array<Store>();
        this.loader = false;
    }

    ngOnInit() {
        const user: User = JSON.parse(sessionStorage.getItem(LOC_STO_USER));
        this.loader = true;
        this.storeService.getAllStoresByUserConnected(user.login).subscribe(
            _ => {
                this.stores = this.storeService.getUserStores().sort((a, b) => {
                    if (a.hyperionId.slice(-4) < b.hyperionId.slice(-4)) { return -1; }
                    if (a.hyperionId.slice(-4) > b.hyperionId.slice(-4)) { return 1; }
                    return 0;
                }
                );
                if (this.storeService.hasOnlyOneStore()) {
                    this.selectStore(this.stores[0]);
                } else {
                    sessionStorage.setItem(LOC_STO_USER_STORES, JSON.stringify(this.stores));
                }
                this.loader = false;
            },
            _ => {
                this.loader = false;
            }
        );
    }

    selectStore(store: Store): void {
        this.storeService.selectStoreById(store.id, this.authenticationService.getCurrentUser().login).subscribe(_ => {
            this.router.navigate([HOME_CDG_ROUTE]);
        });
    }
}
