import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EntryJourna } from '../../_models/entryJourna';
import { DAYLI_ENTRY_URI } from '../../constants';

@Injectable({
    providedIn: 'root',
})
export class DailyService {
    constructor(private http: HttpClient) {}

    getAllByAccountAndPeriod(accountId: number, periodNumber: number, year: number): Observable<any[]> {
        return this.http.get<any[]>(
            `${environment.OP_API_URI}/accounts/${accountId}/year/${year}/periods/${periodNumber}/daily`
        );
    }

    save(entry: EntryJourna): Observable<EntryJourna> {
        return this.http.post<EntryJourna>(environment.OP_API_URI + DAYLI_ENTRY_URI, entry);
    }

    saveAll(entries: EntryJourna[]): Observable<EntryJourna[]> {
        return this.http.post<EntryJourna[]>(environment.OP_API_URI + DAYLI_ENTRY_URI + '/all', entries);
    }

    /**
     * Copy entries linked to there stores and rubriquesIds + apply repartition
     * @param data{ storesIds, rubriqueIds, repartition}
     */
      repartitionMonthlyToDaily = (data): Observable<any> => {
      //return this.http.post<any>(`${environment.OP_API_URI + DAYLI_ENTRY_URI}/repartitionMonthlyToDaily`, data, { headers: new HttpHeaders( { timeout: `${9}`}) })
          return this.http.post<any>(`${environment.OP_API_URI + DAYLI_ENTRY_URI}/repartitionMonthlyToDaily`, data)

      }
    getAllByAccountAndYear(accountId: number) {
        return this.http.get<any[]>(
            `${environment.OP_API_URI}/accounts/${accountId}/piloteYear`
        );
    }

}
