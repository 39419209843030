import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { ExcelService } from 'src/app/_services/excel/excel.service';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from 'src/app/_services/logistic/export.service';
import { StoreService } from 'src/app/_services/store/store.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AUTHENTICATION_ROUTE, CONFIG_KEY, LOGOUT_MESSAGE, ROLES } from '../../../constants';
import { User } from '../../../_models/user';
import { AuthenticationService } from '../../../_services/authentication/authentication.service';
import { RubriqueService } from 'src/app/_services/rubrique/rubrique.service';
import { TitleService } from 'src/app/_services/title/title.service';
import { formatDate, getLocaleDateFormat } from '@angular/common';
import { PatternService } from '../../../_services/pattern/pattern.service';
import { Rubrique } from '../../../_models/rubrique';
import { Pattern } from '../../../_models/pattern';
import { take } from 'rxjs/operators';
import { ConfigService } from '../../../_services/config/config.service';
import { Config } from '../../../_models/config';

@Component({
    selector: 'app-modal-exportation',
    templateUrl: './modal-exportation.component.html',
    styleUrls: ['./modal-exportation.component.css'],
})
export class ModalExportationComponent implements OnInit {
    public clickEvent = false;
    public exportForm: FormGroup;
    public subscription = new Subscription();
    public blocks = [];
    user: User;
    public data2= [];
    public load = true;
    public rubriqueDispalyed = [];
    entrantForm: FormGroup;
    public rubriques : Rubrique[];
    public configs: Config[];
    public limitRubriques;
    public storeCategorySelected;
    public storeType = [{ name: 'Hyper',   code: 'HYP' },
        { name: 'Super',   code: 'SUP' },
        { name: 'Ultra', code: 'ULT'} ,
        { name: 'Essence', code: 'ESS'} ,
        { name: 'Drive', code: 'DRIVE'}
    ];

    get rubrique(): FormArray {
        return this.entrantForm.get('rubriqueList') as FormArray;
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalExportationComponent>,
        private formBuilder: FormBuilder,
        private excelService: ExcelService,
        private toastService: ToastrService,
        private exportService: ExportService,
        private storeService: StoreService,
        private authenticationService: AuthenticationService,
        private rubriqueService: RubriqueService,
        private titleService: TitleService,
        private patternService: PatternService,
        private toastrService: ToastrService,
        private fb: FormBuilder,
        private configService: ConfigService
    ) {
        this.user = this.authenticationService.getCurrentUser();
        this.storeCategorySelected = this.storeService.getStoreSelected().category;
    }

    ngOnInit() {
        this.entrantForm = this.fb.group({
            rubrique: this.fb.control(false),
            rubriqueList: this.fb.array([]),
        })

            this.exportService.getNbBlock().subscribe(data => {
            for (let i = 1; i <= data.hypers; i++) {
                this.blocks.push({ type: 'HYP', name: 'Hyper', block: i });
            }
            for (let i = 1; i <= data.supers; i++) {
                this.blocks.push({ type: 'SUP', name: 'Super', block: i });
            }
            for (let i = 1; i <= data.ultras; i++) {
                this.blocks.push({ type: 'ULT', name: 'Ultra', block: i });
            }
        });
        this.subscription.unsubscribe();
        this.exportForm = this.formBuilder.group({
            exportSelected: new FormControl(null, [Validators.required]),
            blockSelected: new FormControl(null),
            typeSelected: new FormControl(null),
        });
        forkJoin([
            this.rubriqueService.getAllByType(this.storeCategorySelected),
            this.configService.getAll().pipe(take(1)),
        ]).subscribe(
            results => {
                this.rubriques = results[0].sort((a) => { return a.defaultExport  ? -1 : 1 });
                this.configs = results[1];
                this.limitRubriques = this.configs.find(c => c.key === CONFIG_KEY.MAX_RUBRIQUES + this.storeCategorySelected).value;
                const rubControls = this.rubriques.map(() => this.fb.control(false));
                rubControls.forEach(c => this.rubrique.push(c));
            },
            () => {
                this.toastrService.error(
                    `Une erreur s'est produite lors de la récupération du compte, veuillez réessayer ultérieurement.`
                );
            })
    }

    updateFormControlsRequired = () => {
        if (this.exportForm.value.exportSelected === 'RAYONS') {
            this.exportForm.get('blockSelected').setValidators([Validators.required]);
        } else {
            this.exportForm.get('blockSelected').clearValidators();
        }
        this.exportForm.get('blockSelected').updateValueAndValidity();

        if ( ['HYP', 'SUP', 'ULT'].includes(this.storeCategorySelected) && (this.exportForm.value.exportSelected === 'MAGASINS_MENSUEL' || this.exportForm.value.exportSelected === 'ESTIME_MENSUEL' )) {
            this.exportForm.get('typeSelected').setValidators([Validators.required]);
        } else {
            this.exportForm.get('typeSelected').clearValidators();
        }
        this.exportForm.get('typeSelected').updateValueAndValidity();
    };

    onSubmit = () => {
        switch (this.exportForm.value.exportSelected) {
            case 'CURRENT_RAYON':
                console.log('CURRENT_RAYON')
                this.toastService.success('Export rayon terminé, le téléchargement va démarrer', 'Export');
                this.dialogRef.close(true);
                break;
            case 'CURRENT_STORE':
                this.load = false;
                this.subscription = forkJoin([
                    this.exportService.exportRay(this.data.store.id, [9,18,11,330,3,23,102,101,340,928,929]), //TODO: Dynamic rubriquesIds
                    this.titleService.getAllTitles(),
                    this.rubriqueService.getAllByType('MAG')
                ]).subscribe(
                        async data => {
                            this.excelService.generateOneStoreExcel(data[0], data[1], data[2], this.data.store.name);
                            this.toastService.success('Export marchés terminé', 'Export');
                            this.load = true;
                        },
                        error => {
                            this.toastService.error('Une erreur est survenue', 'Erreur');
                            this.load = true;
                        }
                    );
                break;
            case 'CONSO_STORE':
                this.load = false;
                this.subscription = forkJoin([
                    this.exportService.exportConso(this.storeCategorySelected, this.asChild() ? this.data.store.id : null , this.recapSelected()), //TODO: Dynamic rubriquesIds
                    this.titleService.getAllTitles(),
                    this.rubriqueService.getAllByType(this.storeCategorySelected)
                ]).subscribe(
                    async data => {
                        this.excelService.generateOneStoreExcel(data[0], data[1], data[2], this.data.store.name);
                        this.toastService.success('Export rayons terminé', 'Export');
                        this.load = true;
                    },
                    error => {
                        this.toastService.error('Une erreur est survenue', 'Erreur');
                        this.load = true;
                    }
                );
                break;
            case 'RAYONS':
                this.load = false;
                console.log(" this.load = true;")
                this.subscription = this.exportService
                    .exportRayons(this.exportForm.value.blockSelected.type, this.exportForm.value.blockSelected.block)
                    .subscribe(
                        async data => {
                            const file = await this.excelService.generateStoreExcelData(this.getData(data), 'RAYONS');
                            this.toastService.success('Export marchés terminé', 'Export');
                            this.load = true;
                        },
                        error => {
                            this.toastService.error('Une erreur est survenue', 'Erreur');
                            this.load = true;
                        }
                    );
                break;
            case 'MAGASINS':
                this.load = false;
               // this.subscription = this.exportService.exportStores().subscribe(
                    this.subscription = this.exportService.exportStoresByCategory(this.storeCategorySelected).subscribe(
                    async data => {
                        this.toastService.success('Export magasins terminé', 'Export');
                        const file = await this.excelService.generateStoreExcelData(this.getData(data),'',this.storeCategorySelected);
                        this.load = true;
                    },
                    error => {
                        this.toastService.error('Une erreur est survenue', 'Erreur');
                        this.load = true;
                    }
                );
                break;
            case 'MAGASINS_MENSUEL':
                console.log('MAGASINS_MENSUEL')
                this.load = false;
                const categoryStore = [];
                const blockStore = [];
                let category =  this.storeCategorySelected
                if(this.authenticationService.getCurrentUser().role==='DRIVE') {
                    category = 'DRIVE';
                }
                ['HYP', 'SUP', 'ULT'].includes(category) ? categoryStore.push(this.exportForm.value.typeSelected) : categoryStore.push(category);

                //if(['SAV', 'AUCHFR'].includes(category)) {
                if([ 'AUCHFR'].includes(category)) {
                   category === 'SAV' ? blockStore.push( '1','2','3','4','5','6','7','8','9','10','11'):blockStore.push( '1','2','3','4','5','6','7','8','9','10');

                    blockStore.forEach(block => {
                        this.subscription = this.exportService.exportStoresByMonth(category,block).subscribe(

                        async data => {

                            if(block >10) {
                                const file = await this.excelService.generateStoreExcelData(data, 'MAGASINS_MENSUEL','AUCHPRO');
                                this.toastService.success('Export repère terminé', 'Export');}
                            if(block <11) {
                                this.data2.push(data);
                            }
                          if(this.data2.length===5) {
                              const file = await this.excelService.generateStoreExcelData2(this.data2, 'MAGASINS_MENSUEL',category);
                              this.toastService.success('Export repère terminé', 'Export');
                              this.data2= [];
                          }
                            if( block==='10') {
                                this.load = true;
                            }

                        },
                        error => {
                            this.toastService.error('Une erreur est survenue', 'Erreur');
                        }
                       );   }

                    );

                } else {
                    console.log(category)
                    categoryStore.forEach(catStore => {  this.subscription = this.exportService.exportStoresByMonth(catStore,0).subscribe(
                        async data => {
                            this.toastService.success('Export repère terminé', 'Export');
                            const file = await this.excelService.generateStoreExcelData(data, 'MAGASINS_MENSUEL',catStore);
                            this.data2.push(data);
                            this.load = true;
                        },
                        error => {
                            this.toastService.error('Une erreur est survenue', 'Erreur');
                            this.load = true;
                        }
                        );}
                    );
                }
                break;

            case 'ESTIME_MENSUEL':
                this.load = false;
                const categoryStoreEstimer = [];
                const blockStoreEstimer = [];
                let categoryEstimer = this.storeCategorySelected;
                if(this.authenticationService.getCurrentUser().role==='DRIVE') {
                    categoryEstimer = 'DRIVE';
                }
                ['HYP', 'SUP', 'ULT'].includes(categoryEstimer) ? categoryStoreEstimer.push(this.exportForm.value.typeSelected):categoryStoreEstimer.push( categoryEstimer);
                if(['SAV', 'AUCHFR'].includes(categoryEstimer)) {
                    categoryEstimer === 'SAV' ? blockStoreEstimer.push( '1','2','3','4','5','6','7','8','9','10','11'):blockStoreEstimer.push( '1','2','3','4','5','6','7','8','9','10');

                    blockStoreEstimer.forEach(block => {

                        this.subscription = this.exportService.exportEstimerByMonth(categoryEstimer,block).subscribe(
                            async data => {

                                if(block >10) {
                                    const file = await this.excelService.generateStoreExcelData(data, 'ESTIME_MENSUEL','AUCHPRO');
                                    this.toastService.success('Export estimé terminé', 'Export');}
                                if(block <11) {
                                    this.data2.push(data);
                                }

                                if(this.data2.length===5) {
                                    const file = await this.excelService.generateStoreExcelData2(this.data2, 'ESTIME_MENSUEL',categoryEstimer);
                                    this.toastService.success('Export estimé terminé', 'Export');
                                    this.data2= [];}

                                if( block==='10') {
                                    this.load = true;
                                }

                            },
                            error => {
                                this.toastService.error('Une erreur est survenue', 'Erreur');
                            }
                        );}

                    );
                } else {
                    categoryStoreEstimer.forEach(catStore => {  this.subscription = this.exportService.exportEstimerByMonth(catStore).subscribe(
                        async data => {
                            this.toastService.success('Export estimé terminé', 'Export');
                            const file = await this.excelService.generateStoreExcelData(data, 'ESTIME_MENSUEL',catStore);
                            this.data2.push(data);
                            if(  ['HYP', 'SUP', 'ULT'].includes(categoryEstimer)) {
                                this.load = true;
                            } else if(!['HYP', 'SUP', 'ULT'].includes(categoryEstimer) ) {
                                this.load = true;
                            }
                        },
                        error => {
                            this.toastService.error('Une erreur est survenue', 'Erreur');
                        }
                        );}
                    );
                }
                break;
        }
    };


    closeModal = () => {
        this.subscription.unsubscribe();
        this.dialogRef.close();
    };

    isAdmin = () => {
        return ROLES.LIST.ADMINS.includes(this.user.role);
    }

    isLogistic(): boolean {
        return this.storeCategorySelected === 'LOG';
    }

    isAdminExport = () => {
        return ROLES.LIST.ADMINS_EXPORT.includes(this.user.role);
    }
    isRayon = () => {
        return ROLES.LIST.RAYON.includes(this.storeCategorySelected);
    }
    isConso = () => {
        return ROLES.LIST.CONSO.includes(this.storeCategorySelected);
    }
    asChild = () => {
        return ROLES.LIST.WITH_CHILD.includes(this.storeCategorySelected);
    }
    isAdminRepereMensuel = () => {
        return ROLES.LIST.ADMINS_REPERE_MENSUEL.includes(this.user.role);
    }
    isAdminEstimMensuel = () => {
        return ROLES.LIST.ADMINS_Estime_MENSUEL.includes(this.user.role);
    }
    rubriqueType = [{ name: 'Magasin',   code: 'MAG' },
        { name: 'Logistique',   code: 'LOG' },
        { name: 'Drive', code: 'DRIVE'} ,
        { name: 'Service après-vente', code: 'SAV'},
        //{ name: 'Service après-vente PRO', code: 'SAV_PRO'},
        { name: 'Service Appui', code: 'APP'},
        { name: 'Auchan Direct', code: 'DIR'},
        { name: 'ARA', code: 'ARA'},
        { name: 'Energie', code: 'ENER'},
        { name: 'Export', code: 'EXP'},
        { name: 'Auchan.fr', code: 'AUCHFR'},
        { name: 'Franchisé / Partenaire',   code: 'FRN' },
        { name: 'Auchan Pro',   code: 'AUCHPRO' },
    ];

    getNameRubrique= () => {
       let category =  this.storeCategorySelected;
       if(this.authenticationService.getCurrentUser().role==='DRIVE') {
           category = 'DRIVE';
       }
        const accountCategory = ['HYP', 'SUP', 'ULT'].includes(category) ? 'MAG' : category;
        return this.rubriqueType.filter(r => r.code === accountCategory).map(e=>e.name);
    }

    isExportCalled(tagValue) {
       return  this.exportForm.value.exportSelected === tagValue && this.clickEvent
    }

    changeItem(){
        this.clickEvent = !this.clickEvent
        if (this.clickEvent ===  false) {
            this.exportForm.reset()
        }
    }
     recapSelected(){
         return this.rubrique.value
             .map((h, index) => {
                 if (h === true) {
                     return this.rubriques[index].id
                 } else {
                     return null;
                 }
             })
             .filter(id => id != null)
     }

    getData = (data) => {
        const res = [];
        data[0].account.forEach(acc => {

            let entriesByAccount = data[0].storeWithAccounts.filter(e => e.accountid === acc.id);
            let filteredRubriques
                 if(['HYP', 'SUP', 'ULT', 'ESS', 'DRIVE'].includes(acc.type)) {
                     filteredRubriques =  ['HYP', 'SUP', 'ULT'].includes(acc.type) ?  data[0].filteredRubriquesMag: acc.type ==='ESS' ? data[0].filteredRubriquesESS : data[0].filteredRubriquesDrive
                 } else {
                     filteredRubriques = data[0].filteredRubriques
                 }


            filteredRubriques.forEach(rubrique => {
                let sumReal = 0;
                let sumEst = 0;
                let sumEntr = 0;
                let sumRepere = 0;
                let annualRepere = 0;
                let deltaRepere = 0;

                //const entries = storeWithAccounts.filter(e => e.designationId === rubrique.id && e.accountid === acc.id);
                console.log()
                const entries = entriesByAccount.filter(e => e.designationId === rubrique.id);
                const realEntries = entries.filter(e => e.titleId === 17); // REALN-1
                const estEntries = entries.filter(e => e.titleId === 10); // EST
                const entrantEntries = entries.filter(e => e.titleId === 18); // ENTR
                const repereEntries = entries.filter(e => e.titleId === 4); // repere 2020
                const deltaReperesEntries = entries.filter(e => e.titleId === null && e.periodId === 15); // titleID repere & DELTA

                deltaRepere = deltaReperesEntries[0] ? +deltaReperesEntries[0].value : 0;
                sumReal = realEntries.reduce((acc, current) => acc + Number(current.value), 0);
                sumEst = estEntries.reduce((acc, current) => acc + Number(current.value), 0);
                sumEntr =
                    entrantEntries.length > 0
                        ? entrantEntries.reduce((acc, current) => acc + Number(current.value), 0)
                        : null;

                sumRepere = repereEntries.reduce((acc, current) => acc + Number(current.value), 0);
                annualRepere = sumRepere + (deltaRepere * -1);


                res.push({
                    cex: acc.type,
                    rubrique: rubrique.code,
                    libelle: rubrique.libelle,
                    format: acc.category,
                    repere: Math.round(sumRepere),
                    annualRepere: Math.round(annualRepere),
                    real: Math.round(sumReal),
                    est: Math.round(sumEst),
                    entr: sumEntr != null ? Math.round(sumEntr) : null,
                    code: acc.hyperionId,
                    storeName:acc.storename,
                    accountId: acc.id,
                    designationId: rubrique.id,
                    codeRayon: acc.rayonId,
                    nameRayon: acc.name,
                    parentRayondId: acc.parentRayondId,
                });
            });



        });
        return res
    }
}
