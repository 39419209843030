import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from 'src/app/_services/logistic/export.service';
import { ExcelService } from 'src/app/_services/excel/excel.service';
import { take } from 'rxjs/operators';
import { AUTHENTICATION_ROUTE, LOGOUT_MESSAGE, ROLES } from '../../../constants';
import { Store } from 'src/app/_models/store';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { StoreService } from '../../../_services/store/store.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-modal-importation',
    templateUrl: './modal-importation.component.html',
    styleUrls: ['./modal-importation.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalImportationComponent implements OnInit {
    selectedTab = 'newImport';
    selectedTabMonth = 'newImportMonth';
    selectedTabEstimeMonth = 'newImportEstime';
    acceptedFormats = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    errorType: string;
    titleError: string;
    errorMsg: string;
    errorMonthlyType: string;
    titleMonthlyError: string;
    errorMonthlyMsg: string;
    file: File;
    fileByMonth: File;
    fileImport: File;
    fileEstimeByMonth: File;
    subscription = new Subscription();
    step = null;
    locked = false;
    user: User;

    //Todo: replace by real data when back is ready of coursee
    historic = [
        { user: { firstName: 'Jean', lastName: 'Dubois' }, date: '10/10/2020', updated: '64 entrants sur 4 magasins' },
        { user: { firstName: 'Marc', lastName: 'Durand' }, date: '10/10/2020', updated: '64 entrants sur 4 magasins' },
        { user: { firstName: 'Jean', lastName: 'Dubois' }, date: '10/10/2020', updated: '64 entrants sur 4 magasins' },
        { user: { firstName: 'Jean', lastName: 'Dubois' }, date: '10/10/2020', updated: '64 entrants sur 4 magasins' },
        { user: { firstName: 'Jean', lastName: 'Dubois' }, date: '10/10/2020', updated: '64 entrants sur 4 magasins' },
    ];
    constructor(
        private authenticationService: AuthenticationService,
        public storeService: StoreService,
        public activeModal: NgbActiveModal,
        private excelService: ExcelService,
        private toastService: ToastrService,
        private exportService: ExportService
    ) {this.user = this.authenticationService.getCurrentUser(); }

    ngOnInit() {
        this.subscription.unsubscribe();
    }

    changeTabSelected(tab: string) {
        this.selectedTab = tab;
        this.file = null;
        this.fileByMonth = null;
        this.locked = false;
        this.errorType = null;
        this.titleError = null;
        this.errorMsg = null;
    }

    handleFile(file): void {
        this.file = file;
        this.titleError = null;
        if (file) {
            if (!this.acceptedFormats.includes(file.type)) {
                this.errorType = 'Erreurs';
                this.titleError = 'Erreur de type fichier';
                this.errorMsg = 'Seuls les fichiers .xlsx sont autorisés';
            } else {
                if (!this.verifyVersionFile(file.name)) {
                    this.errorType = 'Erreurs';
                    this.titleError = 'Erreur de version du fichier';
                    this.errorMsg = 'Merci d exporter la derniére version de fichier';
                }
            }
        }
    }

    import() {
        this.file ? this.fileImport= this.file : this.fileByMonth ? this.fileImport= this.fileByMonth:this.fileImport= this.fileEstimeByMonth;
        this.step = 'Validation du fichier en cours';
        this.subscription.unsubscribe();
        if(this.file){
            this.subscription = this.exportService.validateStoreEntriesImport(this.fileImport).subscribe(
                data => {
                    this.updateErrorMessageOnValidationFile(data);
                },
                error => {
                    this.toastService.error('Une erreur est survenue', 'Erreur');
                }
            );
        } else if(this.fileByMonth) {

            this.subscription = this.exportService.validateStoreReperesImport(this.fileImport).subscribe(
                data => {
                    this.updateErrorMessageOnValidationFile(data);
                },
                error => {
                    this.toastService.error('Une erreur est survenue', 'Erreur');
                }
            );
        } else {
            console.log(' estime')
            this.subscription = this.exportService.validateStoreEstimeImport(this.fileImport).subscribe(
                data => {
                    this.updateErrorMessageOnValidationFile(data);
                },
                error => {
                    this.toastService.error('Une erreur est survenue', 'Erreur');
                }
            );
        }

    }

    updateErrorMessageOnValidationFile(data) {
        if (data.errors.length > 0) {
            this.errorType = 'Erreurs';
            this.titleError = 'Erreur de validation de fichier';
            this.errorMsg = 'Veuillez consulter le fichier .txt en téléchargement pour plus de détails';
            this.excelService.generateTxtErrorImport(data);
        } else {
            this.updateEntry();
        }
    }

    updateEntry() {
        this.file ? this.fileImport= this.file : this.fileByMonth ? this.fileImport= this.fileByMonth:this.fileImport= this.fileEstimeByMonth;
        this.step = 'Programmation des imports';
        this.subscription.unsubscribe();
        this.file ? this.fileImport= this.file : this.fileImport= this.fileByMonth;
        if(this.file) {
            this.subscription = this.exportService.updateStoreEntriesImport(this.file).pipe(take(1)).subscribe(
                data => {
                    this.toastService.success('Importation lancée, les entrants seront visibles dans les prochaines minutes', 'Import');
                    this.locked = true;
                },
                error => {
                    this.toastService.error('Une erreur est survenue', 'Erreur');
                }
            );
        } else if(this.fileByMonth) {
            this.subscription = this.exportService.updateStoreReperesImport(this.fileByMonth).pipe(take(1)).subscribe(
                data => {
                    this.toastService.success('Importation lancée, les repères seront visibles dans les prochaines minutes', 'Import');
                    this.locked = true;
                },
                error => {
                    this.toastService.error('Une erreur est survenue', 'Erreur');
                }
            );
        } else  {
            this.subscription = this.exportService.updateStoreEstimeImport(this.fileEstimeByMonth).pipe(take(1)).subscribe(
                data => {
                    this.toastService.success('Importation lancée, les estimés seront visibles dans les prochaines minutes', 'Import');
                    this.locked = true;
                },
                error => {
                    this.toastService.error('Une erreur est survenue', 'Erreur');
                }
            );
        }
    }
    handleFileMonth(file): void {
        this.fileByMonth = file;
        this.titleError = null;
        if (this.fileByMonth) {
            if (!this.acceptedFormats.includes(file.type)) {
                this.errorType = 'Erreurs';
                this.titleError = 'Erreur de type fichier';
                this.errorMsg = 'Seuls les fichiers .xlsx sont autorisés';
            } else {
                if (!this.verifyVersionFile(file.name)) {
                    this.errorType = 'Erreurs';
                    this.titleError = 'Erreur de version du fichier';
                    this.errorMsg = 'Merci d exporter la derniére version de fichier';
                }
            }
        }
    }

    handleFileEstimeMonth(file): void {
        this.fileEstimeByMonth = file;
        this.titleError = null;
        if (this.fileEstimeByMonth) {
            if (!this.acceptedFormats.includes(file.type)) {
                this.errorType = 'Erreurs';
                this.titleError = 'Erreur de type fichier';
                this.errorMsg = 'Seuls les fichiers .xlsx sont autorisés';
            } else {
                if (!this.verifyVersionFile(file.name)) {
                    this.errorType = 'Erreurs';
                    this.titleError = 'Erreur de version du fichier';
                    this.errorMsg = 'Merci d exporter la derniére version de fichier';
                }
            }
        }
    }
    closeModal = () => {
        this.file = null;
        this.titleError = null;
        this.subscription.unsubscribe();
        this.activeModal.close();
    };

    isAdmin = () => {
        return ROLES.LIST.ADMINS.includes(this.user.role);
    }

    isLogistic(): boolean {
        return this.storeService.getStoreSelected().category === 'LOG';
    }

    isAdminImport = () => {
        return ROLES.LIST.ADMINS_IMPORT.includes(this.user.role);
    }
    isAdminRepereMensuel = () => {
        return ROLES.LIST.ADMINS_REPERE_MENSUEL.includes(this.user.role);
    }
    isAdminEstimeMensuel = () => {
        return ROLES.LIST.ADMINS_Estime_MENSUEL.includes(this.user.role);
    }
    verifyVersionFile(file){

       let  versionFile = file.substring(file.indexOf('-v')+2,file.toLowerCase().indexOf('.xls'));

      // return (versionFile === environment.VERSION);
        return (versionFile.includes(environment.VERSION));
    }
}
