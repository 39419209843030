import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { PatternLog } from '../_models/pattern-log';
import { Pattern } from 'src/app/_models/pattern';
import { TitleLog } from '../_models/title-log';

@Component({
    selector: 'app-title-logistic-controller',
    templateUrl: './title-logistic-controller.component.html',
    styleUrls: ['./title-logistic-controller.component.css'],
})
export class TitleLogisticControllerComponent implements OnInit {
    @Input('tabSelector') tabSelector: boolean;
    @Input('forbiddenTitles') forbiddenTitles: string[];
    @Input() titles: TitleLog[];

    @Output() hiddenList: EventEmitter<any> = new EventEmitter();

    hidden: string[];
    constructor() { }

    ngOnInit() {
        if (isNullOrUndefined(localStorage.getItem('TitlesLog'))) {
            this.hidden = [];
        } else {
            this.hidden = JSON.parse(localStorage.getItem('TitlesLog'));
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (isNullOrUndefined(localStorage.getItem('TitlesLog'))) {
            this.hidden = [];
        } else {
            this.hidden = JSON.parse(localStorage.getItem('TitlesLog'));
        }
    }

    /**
     * Appelé lorsqu'on clique sur un title.
     * @param titleCode
     */
    protected isHidden(titleCode: string): boolean {
        return this.hidden.findIndex(code => code === titleCode) > -1;
    }

    protected isForbidden(titleCode: string): boolean {
        return this.forbiddenTitles.findIndex(code => code === titleCode) > -1;
    }

    protected fireTitlesChanges(titleCode: string): void {
        if (this.isForbidden(titleCode)) {
            return;
        }
        if (this.isHidden(titleCode)) {
            const hiddenTitle = this.hidden.findIndex(code => code === titleCode);
            this.hidden.splice(hiddenTitle, 1);
        } else {
            this.hidden.push(titleCode);
        }
        localStorage.setItem('TitlesLog', JSON.stringify(this.hidden));
        this.hiddenList.emit(this.hidden);
    }

    isRightBorderVisible(titleCode): boolean {
        return titleCode === 'PDSR' || titleCode === 'PDSO';
    }
}
