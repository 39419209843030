import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { Entry } from '../../_models/entry';
import { ENTRIES_URI } from '../../constants';
import { Account } from '../../_models/account';

@Injectable({
    providedIn: 'root',
})
export class EntryService {
    constructor(private http: HttpClient) { }

    /**
     * Deprecated
     */
    // save(entry: Entry): Observable<Entry> {
    //     if (entry.id != null) {
    //         return this.http.put<Entry>(environment.OP_API_URI + ENTRY_URI + '/' + entry.id, { entry: entry });
    //     } else {
    //         return this.http.post<Entry>(environment.OP_API_URI + ENTRY_URI, entry);
    //     }
    // }

    saveAll(entries: Entry[]): Observable<Entry[]> {
        return this.http.post<Entry[]>(environment.OP_API_URI + ENTRIES_URI, entries);
    }

    /**
     * Copy entries linked to there stores and rubriquesIds + apply repartition
     * @param data{ storesIds, rubriqueIds, repartition}
     */
    copyEntrToPlan = (data): Observable<any> => {
        return this.http.post<any>(`${environment.OP_API_URI}/copyEntrantToRepere`, data)
    }

    lockCEX = (data): Observable<any> => {
        return this.http.post<any>(`${environment.OP_API_URI}/lockCex`, data)
    }

      getCheckTotalYear(account, rubrique, title): Observable<boolean> {
        return this.http.get<boolean>(`${environment.OP_API_URI}/totalYear/`+account+'/'+rubrique+'/'+title);
    }
}
