import 'hammerjs';

import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatChipsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatStepperModule
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ANIMATION_TYPES, LoadingModule } from 'ngx-loading';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { DesignationsGroupDirective } from './_directives/designations-group.directive';
import { AutofocusDirective } from './_directives/focus.directive';
import { ShowOperatorPipe } from './_pipes/show-operator.pipe';
import { AppComponent } from './app.component';
import { CanActivateCdg } from './can-activate-teams/can-activate-cdg';
import { FooterComponent } from './components/_footer/footer.component';
import { HeaderComponent } from './components/_header/header.component';
import { AccountComponent } from './components/account/account.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { StoreSelectionCardComponent } from './components/authentication/store-selection-card/store-selection-card.component';
import { DesignationsControllerComponent } from './components/designations-controller/designations-controller.component';
import { HomeComponent } from './components/home/home.component';
import { PeriodComponent } from './components/period/period.component';
import { TitlesControllerComponent } from './components/titles-controller/titles-controller.component';
import { ADMIN_ROUTE, AUTHENTICATION_ROUTE, HOME_CDG_ROUTE, LOGGER_ROUTE } from './constants';

import { HttpErrorInterceptor } from './_utils/http-error.interceptor';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './_utils/rollbar.error-handler';
import { ApplicationEffects } from './_utils/state/application/effects';
import { reducers } from './_utils/state/reducers';
import { ModalUserComponent } from './components/admin/modal-user/modal-user.component';
import { ViewUsersComponent } from './components/admin/view-users/view-users.component';
import { UnauthorizedAccessComponent } from './components/authentication/unauthorized-access/unauthorized-access.component';
import { DeltaComponent } from './components/delta/delta.component';
import { JournaComponent } from './components/journa/journa.component';
import { AccountLogisticComponent } from './components/logistic/account-logistic/account-logistic.component';
import { DesignationsControllerLogisticComponent } from './components/logistic/designations-controller-logistic/designations-controller-logistic.component';
import { PeriodLogisticComponent } from './components/logistic/period-logistic/period-logistic.component';
import { TitleLogisticControllerComponent } from './components/logistic/title-logistic-controller/title-logistic-controller.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { HistoryComponent } from './components/logistic/history/history.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ModalUserEditionComponent } from './components/admin/modal-user-edition/modal-user-edition.component';
import { LoggerComponent } from './components/logger/logger.component';
import { RequestInterceptor } from './request-interceptor';
//import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { ModalImportationComponent } from './components/home/modal-importation/modal-importation.component';
import { ModalExportationComponent } from './components/home/modal-exportation/modal-exportation.component';
import { AdminPageComponent } from './components/admin/admin-page/admin-page.component';
import { AffectationCompteMagasinComponent } from './components/admin/affectation-compte-magasin/affectation-compte-magasin.component';
import { EditionLibelleDesignationComponent } from './components/admin/edition-libelle-designation/edition-libelle-designation.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { ModalLockingComponent } from './components/home/modal-locking/modal-locking.component';
import { DesignationPipe } from './_pipes/filter-admin-designation';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TitleFilterComponent } from './components/title-filter/title-filter.component';
import { DiversConfigComponent } from './components/admin/divers-config/divers-config.component';
import { CopyEntrantDialogComponent } from './components/period/copy-entrant-dialog/copy-entrant-dialog.component';
import {
    RecopieEntrantsComponent,
    DialogContentComponent,
    DialogDataInfoDialog,
} from './components/admin/recopie-entrants/recopie-entrants.component';
import { CoefficientCalendaireComponent } from './components/admin/coefficient-calendaire/coefficient-calendaire.component';
import { DragScrollModule } from 'cdk-drag-scroll';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { manageDriveComponent } from './components/admin/manage-drive/manage-drive.component';
import { MessageAccountDialogComponent } from './components/account/message-account-dialog/message-account-dialog.component';
import {
    RepartDailyComponent,
    DialogContentDailyComponent,
    DialogDataInfoDailyDialog,
} from './components/admin/repart-daily/repart-daily.component';
import {
    DialogContentLockComponent,
    DialogDataInfoLockDialog,
    LockCexComponent,
} from './components/admin/lock-cex/lock-cex.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';


//const config: SocketIoConfig = { url: environment.OP_SOCKET_URI, options: {} };

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

registerLocaleData(localeFr, 'fr');

const routes: Routes = [
    {
        path: ADMIN_ROUTE,
        component: AdminPageComponent,
        canActivate: [CanActivateCdg],
    },
    {
        path: HOME_CDG_ROUTE,
        component: HomeComponent,
        canActivate: [CanActivateCdg],
    },
    {
        path: LOGGER_ROUTE,
        component: LoggerComponent,
        canActivate: [CanActivateCdg],
    },
    {
        path: AUTHENTICATION_ROUTE,
        component: AuthenticationComponent,
    },
    {
        path: '**',
        redirectTo: AUTHENTICATION_ROUTE,
    },
];

@NgModule({
    declarations: [
        AppComponent,
        AuthenticationComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        StoreSelectionCardComponent,
        AccountComponent,
        DesignationsControllerComponent,
        PeriodComponent,
        TitlesControllerComponent,
        DesignationsGroupDirective,
        AutofocusDirective,
        ShowOperatorPipe,
        MessageDialogComponent,
        MessageAccountDialogComponent,
        DeltaComponent,
        UnauthorizedAccessComponent,
        ModalUserComponent,
        ViewUsersComponent,
        JournaComponent,
        AccountLogisticComponent,
        PeriodLogisticComponent,
        DesignationsControllerLogisticComponent,
        TitleLogisticControllerComponent,
        HistoryComponent,
        ModalUserEditionComponent,
        LoggerComponent,
        ModalImportationComponent,
        ModalExportationComponent,
        AdminPageComponent,
        AffectationCompteMagasinComponent,
        EditionLibelleDesignationComponent,
        ModalConfirmationComponent,
        ModalLockingComponent,
        DesignationPipe,
        TitleFilterComponent,
        DiversConfigComponent,
        CopyEntrantDialogComponent,
        RecopieEntrantsComponent,
        CoefficientCalendaireComponent,
        DialogContentComponent,
        manageDriveComponent,
        RepartDailyComponent,
        DialogContentDailyComponent,
        LockCexComponent,
        DialogContentLockComponent,
        DialogDataInfoDialog,
        DialogDataInfoDailyDialog,
        DialogDataInfoLockDialog
    ],
    imports: [
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([ApplicationEffects]),
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatCardModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatChipsModule,
        MatSelectModule,
        DragDropModule,
        MatSlideToggleModule,
        MatBottomSheetModule,
        MatStepperModule,
        ToastrModule.forRoot(),
        NgbModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        LoadingModule.forRoot({
            animationType: ANIMATION_TYPES.cubeGrid,
            backdropBackgroundColour: 'rgba(0,0,0,0.5)',
            backdropBorderRadius: '4px',
            primaryColour: 'rgb(239, 34, 49)',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff',
        }),
        PerfectScrollbarModule,
        //SocketIoModule.forRoot(config),
        DragScrollModule,
        MatMenuModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatTreeModule,
    ],
    entryComponents: [
        MessageDialogComponent,
        MessageAccountDialogComponent,
        ModalUserComponent,
        ModalUserEditionComponent,
        HistoryComponent,
        ModalImportationComponent,
        ModalExportationComponent,
        ModalConfirmationComponent,
        ModalLockingComponent,
        TitleFilterComponent,
        CopyEntrantDialogComponent,
        DialogContentComponent,
        DialogContentDailyComponent,
        DialogContentLockComponent,
        DialogDataInfoDialog,
        DialogDataInfoDailyDialog,
        DialogDataInfoLockDialog
    ],
    providers: [
        CanActivateCdg,
        NgbActiveModal,
        ToastrService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        { provide: RollbarService, useFactory: rollbarFactory },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },

    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
