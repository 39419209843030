import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-modal-confirmation',
    templateUrl: './modal-confirmation.component.html',
    styleUrls: ['./modal-confirmation.component.css'],
})
export class ModalConfirmationComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ModalConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { action: string; question: string }
    ) {}

    ngOnInit() {}

    cancel(): void {
        this.dialogRef.close();
    }
}
