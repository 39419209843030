import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';

import { Rubrique } from 'src/app/_models/rubrique';

@Injectable({
    providedIn: 'root',
})
export class RubriqueService {
    constructor(private http: HttpClient) {}

    getAllByType(type: string): Observable<Rubrique[]> {
        return this.http.get<Rubrique[]>(`${environment.OP_API_URI}/rubrique/${type}`);
    }
    upsertAll(rubriques: Rubrique[]): Observable<any> {
        return this.http.post(environment.OP_API_URI + '/rubrique', { rubriques });
    }

    delete(rubriqueId: number): Observable<any> {
        return this.http.delete<any>(environment.OP_API_URI + `/rubrique/${rubriqueId}`);
    }
}
