import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/_models/config';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  CONFIG_URI = `/configs`

  constructor(private http: HttpClient) { }

  getAll(): Observable<Config[]> {
    return this.http.get<Config[]>(`${environment.OP_API_URI}${this.CONFIG_URI}`);
  }

  saveAll(configs: Config[]): Observable<Config[]> {
    return this.http.post<Config[]>(`${environment.OP_API_URI}${this.CONFIG_URI}`, configs);
  }
}
