import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { timer } from 'rxjs';

import { map, switchAll, switchMap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../_services/authentication/authentication.service';
import { AUTHENTICATION_ROUTE, LOGOUT_EXPIRE_MESSAGE, LOGOUT_MESSAGE } from '../../../constants';
import * as ApplicationActions from './actions';

@Injectable()
export class ApplicationEffects {
    APPLICATION_TIMEOUT_TIME = 60000 * 60 * 10; // 20 * 1min

    @Effect()
    extendApplicationTimeout$ = this.actions$.pipe(
        switchMap((action: Action) => {
            return timer(this.APPLICATION_TIMEOUT_TIME);
        }),
        map(() => {
            if (this.authenticateService.isAuthenticate()) {
                this.authenticateService.disconnect();
                this.toastService.info(LOGOUT_EXPIRE_MESSAGE);
                this.router.navigate([AUTHENTICATION_ROUTE]);
                return new ApplicationActions.LogOut();
            } else {
                return new ApplicationActions.LogIn();
            }
        })
    );

    constructor(
        private actions$: Actions,
        private authenticateService: AuthenticationService,
        private router: Router,
        private toastService: ToastrService
    ) { }
}
