import { animate, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';
import { Account } from '../../../_models/account';
import { Period } from '../../../_models/period';
import { AccountService } from '../../../_services/account/account.service';
import { DispatcherService } from '../../../_services/logistic/dispatcher.service';
import { TitlesLogService } from '../../../_services/logistic/titles-log.service';
import { StoreService } from '../../../_services/store/store.service';
import { DistributionMode } from '../_models/distribution-mode.enum';
import { EntryLog } from '../_models/entry-log';
import { PatternLog } from '../_models/pattern-log';
import { Pattern } from 'src/app/_models/pattern';

import { PeriodLog } from '../_models/period-log';
import { TitleLog } from '../_models/title-log';
import { Subscription, forkJoin } from 'rxjs';
//import { SocketService } from 'src/app/_services/socket/socket.service';
import { RubriqueService } from 'src/app/_services/rubrique/rubrique.service';
import { PatternService } from 'src/app/_services/pattern/pattern.service';
import { Rubrique } from 'src/app/_models/rubrique';

import * as math from 'mathjs';
import { get, set } from 'lodash';
import { FormulaService } from 'src/app/_services/formula/formula.service';

@Component({
    selector: 'app-account-logistic',
    templateUrl: './account-logistic.component.html',
    styleUrls: ['./account-logistic.component.css'],
    animations: [
        trigger('fadeInAnimation', [
            transition(':enter', [style({ opacity: 0 }), animate('400ms', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('0ms', style({ opacity: 0 }))]),
        ]),
    ],
})

export class AccountLogisticComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input('account') account: Account;
    @Output() periodsToExport: EventEmitter<any> = new EventEmitter(); //probably unsued

    motif: Pattern[][];
    periodDisplayed: Period;
    annual: Period;
    periodsDisplayed: Period[];
    titlesLog: TitleLog[];
    calculationMatrix: Rubrique[];
    hiddenDesignations: string[];
    hiddenTitles: string[];
    forbiddenTitles: string[] = [];
    tabSelected: string;
    lineSelected: string;
    periodLoader: boolean;
    period: Period[];
    dispatchFilter: string;
    headerHeight: number;
    parsers: {
        parser: math.Parser,
        periodCode: string,
        titleCode: string,
    }[];
    periodsCode: any[] = [
        { name: 'J', code: 'M01' },
        { name: 'F', code: 'M02' },
        { name: 'Mr', code: 'M03' },
        { name: 'Av', code: 'M04' },
        { name: 'Mi', code: 'M05' },
        { name: 'Jn', code: 'M06' },
        { name: 'S6', code: 'SUPP06' },
        { name: 'Ju', code: 'M07' },
        { name: 'Ao', code: 'M08' },
        { name: 'S', code: 'M09' },
        { name: 'O', code: 'M10' },
        { name: 'N', code: 'M11' },
        { name: 'D', code: 'M12' },
        { name: 'S12', code: 'SUPP12' },
    ];

    connectionsSub: Subscription;
    connections: string;
    connectionsNumber: number;

    @ViewChild('headerComponent') headerComponent: ElementRef;

    constructor(
        public storeService: StoreService,
        private accountService: AccountService,
        private rubriqueService: RubriqueService,
        private titlesLogService: TitlesLogService,
        private patternService: PatternService,
        private dispatcherService: DispatcherService,
        private toastrService: ToastrService,
        private cdRef: ChangeDetectorRef,
        //private socketService: SocketService,
        private formulaService: FormulaService
    ) { }

    ngOnInit() {
        /*this.connectionsSub = this.socketService.getObservable().subscribe(c => {
            this.connectionsNumber = c.length;
            this.connections = this.formatConnectionsForDisplay(c);
        });*/
        this.periodLoader = true;
        this.titlesLog = [];
        this.hiddenDesignations = [];
        this.tabSelected = 'MENSUEL';
        this.hiddenTitles = JSON.parse(localStorage.getItem('TitlesLog'));
        this.generateAccount(this.tabSelected);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.account.firstChange) {
            this.hiddenTitles = JSON.parse(localStorage.getItem('TitlesLog'));
            this.generateAccount(this.tabSelected);
        }
    }

    ngAfterViewInit() {
        this.headerHeight = this.headerComponent.nativeElement.offsetHeight;
        this.cdRef.detectChanges();
    }

    generateAccount(tabSelected: string) {
        const type = tabSelected === 'MENSUEL' ? 1 : 2;
        const monthlyTitlesOrder = tabSelected === 'MENSUEL' ?
            ['REALN-1', 'OBJ', 'EST', 'PGS%ER', 'PGS%EO', 'REALN', 'PGS%RR', 'PGS%RO', 'OBJN+1', 'PGS%OE'] :
            ['REALN-1', 'OBJ', 'PGS%OR', 'REALN', 'PGS%RR', 'ESTRAF', 'PGS%ERAFR', 'PGS%ERAFO', 'EST', 'PGS%ER', 'PGS%EO', 'OBJN+1', 'PGS%ON+1E'];
        this.tabSelected = tabSelected;
        this.periodDisplayed = undefined;
        this.periodsDisplayed = undefined;

        forkJoin([
            this.accountService.getAccountById(this.account.id),
            this.titlesLogService.getAllTitlesLogByType(type),
            this.patternService.getByCategory('LOG'),
            this.rubriqueService.getAllByType('LOG')
        ]).subscribe(
            results => {
                this.account = results[0];
                this.period = this.account.periods;
                this.titlesLog = monthlyTitlesOrder.map(t => results[1].find(title => title.code === t))
                this.motif = results[2]
                this.calculationMatrix = results[3];

                this.annual = this.account.periods.find(p => p.code === 'ANNUAL');
                const indexOfAnnual = this.account.periods.indexOf(this.annual);
                this.account.periods.splice(indexOfAnnual, 1);

                this.initAccount();

                // if (type === 1) {
                //     // On génère les entrées pour tous les mois
                //     // this.account.periods.forEach((period, index) => {
                //         // this.account.periods[index] = this.generateEntries(period, this.pattern);
                //     // });
                //     this.periodsToExport.emit(this.account.periods); // TODO: check, probably unused
                // } else {
                //     // On génère les entrées pour l'année
                //     // this.account.periods = [this.generateAnnualEntries(annual, this.account.periods, this.pattern)];
                // }
                // On génère le calcul dispatcher
                // this.account.calculDispatcher = this.generateCalculDispatcher(this.account.calculationMatrix);
                this.periodLoader = false;
                this.periodDisplayed = this.account.periods[0];

                this.periodsDisplayed =
                    type === 1
                        ? [
                            this.account.periods.find(p => p.number === 1),
                            this.account.periods.find(p => p.number === 2),
                            this.account.periods.find(p => p.number === 3),
                        ]
                        : [this.annual];

            },
            () => {
                this.toastrService.error(
                    `Une erreur s'est produite lors de la récupération du compte, veuillez réessayer ultérieurement.`
                );
            })
        // D'abord on récupère les données du compte
        // this.accountService.getAccountById(this.account.id).subscribe(
        //     account => {
        //         this.account = account;
        //         this.period = this.account.periods;
        //         // On doit récupérer les titres afin de pouvoir générer le pattern.
        //         this.titlesLogService.getAllTitlesLogByType(type).subscribe(titlesLog => {
        //             this.titlesLog = titlesLog;
        //             // On génère le pattern
        //             // TODO: Replace by new pattern
        //             // const tmp1 = 
        //             this.pattern = this.patternService.generatePattern(this.account, this.titlesLog, type);
        //             // TODO: Fetch calculationMatrix
        //             this.designations = this.pattern.designations;
        //             const annual = this.account.periods.find(p => p.code === 'ANNUAL');
        //             const indexOfAnnual = this.account.periods.indexOf(annual);
        //             this.account.periods.splice(indexOfAnnual, 1);
        //             if (type === 1) {
        //                 // On génère les entrées pour tous les mois
        //                 this.account.periods.forEach((period, index) => {
        //                     this.account.periods[index] = this.generateEntries(period, this.pattern);
        //                 });
        //                 this.periodsToExport.emit(this.account.periods);
        //             } else {
        //                 // On génère les entrées pour l'année
        //                 this.account.periods = [this.generateAnnualEntries(annual, this.account.periods, this.pattern)];
        //             }
        //             // On génère le calcul dispatcher
        //             this.account.calculDispatcher = this.generateCalculDispatcher(this.account.calculationMatrix);
        //             this.periodLoader = false;
        //             this.periodDisplayed = this.account.periods[0];
        //             this.periodsDisplayed =
        //                 type === 1
        //                     ? [
        //                         this.account.periods.find(p => p.number === 1),
        //                         this.account.periods.find(p => p.number === 2),
        //                         this.account.periods.find(p => p.number === 3),
        //                     ]
        //                     : [this.account.periods.find(p => p.number === 15)];
        //         });
        //     },
        //     () => {
        //         this.toastrService.error(
        //             `Une erreur s'est produite lors de la récupération du compte, veuillez réessayer ultérieurement.`
        //         );
        //     }
        // );
    }

    private initAccount = () => {
        this.parsers = [];


        if (this.tabSelected === 'MENSUEL') {


            //Monthly periods
            this.account.periods.forEach((p: PeriodLog) => {
                this.titlesLog.forEach((t: TitleLog) => {
                    // month parser
                    const newParser =
                    {
                        parser: this.initParser(math.parser()),
                        periodCode: p.code,
                        titleCode: t.code,
                    };
                    this.parsers.push(newParser);

                    // Parcours des entrées pour chercher les existantes
                    this.calculationMatrix.forEach(r => {
                        const found = p.entryLogs.find(e =>
                            e.designation.code === r.code &&
                            e.titleLog.code === t.code
                        )

                        const value = found && found.value ? Number(found.value) : 0;

                        //MAJ Mensuel
                        set(p, ['entryMatrix', r.code, t.code], {
                            value,
                            designation: r,
                            title: t

                        });

                        this.updateEntryEvent({
                            value,
                            designation: r,
                            title: t,
                            periodLogId: p.id
                        })

                    })
                });
            });
        } else {
            //Annuel parsers
            this.titlesLog.forEach((t: TitleLog) => {

                const newParser = {
                    parser: this.initParser(math.parser()),
                    periodCode: this.annual.code,
                    titleCode: t.code,
                }

                this.parsers.push(newParser);

                // Parcours des entrées pour chercher les existantes
                this.calculationMatrix.forEach(r => {

                    //TODO found should be sum of entries of all periods
                    let sum = 0;
                    // "REDUCE"
                    this.account.periods.forEach(p => {

                        const found = p.entryLogs.find((e: any) =>
                            e.designation.code === r.code &&
                            e.titleLog.code === t.code
                        )

                        const value = found && found.value ? Number(found.value) : 0;
                        sum = sum + value
                    })


                    set(this.annual, ['entryMatrix', r.code, t.code], {
                        value: sum,
                        designation: r,
                        title: t
                    });
                    this.updateEntryEvent({
                        value: sum,
                        designation: r,
                        title: t,
                        periodLogId: 15
                    })


                })

            });

        }





    }

    /**
     * Fetch parser according to its periodCode and titleCode
     */
    private getParser = (periodCode: string, titleCode: string): math.Parser => {
        const found = this.parsers.find(pars => pars.periodCode === periodCode && pars.titleCode === titleCode);
        return found ? found.parser : null;
    };

    /**
     * Puts inital entryMatrixes raw values in the parser, for each period, title and desgination
     */
    private initParser = (parser: math.Parser) => {
        this.calculationMatrix.forEach((r: Rubrique) => {
            parser.set(r.code, 0);
        });
        return parser;
    };


    updateEntryEvent(entry: any) {
        const rubrique = this.calculationMatrix.find(r => r.code === entry.designation.code);
        const title = this.titlesLog.find(t => t.code === entry.title.code);
        const period = entry.periodLogId === 15 ? this.annual : this.account.periods.find(p => p.id === entry.periodLogId);

        const linkedRubriques = this.getLinkedRubriques(entry.designation.code);
        const dependencies = [rubrique].concat(linkedRubriques);

        // Mettre à jour le parser pour entry (entryMatrix MAJ directement dans period)
        const parser = this.getParser(period.code, title.code);
        parser.set(rubrique.code, entry.value);

        //Mettre à jour parser + entrymatrix sur les linked
        linkedRubriques.forEach(rubrique => {
            const result = parser.evaluate(`${rubrique.code} = ${rubrique.formula}`);
            set(period, ['entryMatrix', rubrique.code, title.code, 'value'], result)
        })

        //Mettre à jour les colonnes sur toutes les lignes touchées
        dependencies.forEach(d => {
            if (entry.periodLogId !== 15) {
                const lastYearReal = get(period, ['entryMatrix', d.code, 'REALN-1', 'value'], 0);
                const lastYearObj = get(period, ['entryMatrix', d.code, 'OBJ', 'value'], 0);

                const est = get(period, ['entryMatrix', d.code, 'EST', 'value'], 0);
                // const progEstReal = get(period, ['entryMatrix', d.code, 'PGS%ER', 'value'], 0); //anciennes prog
                // const progEstObj = get(period, ['entryMatrix', d.code, 'PGS%EO', 'value'], 0);

                const newProgEstReal = DispatcherService.calcProgression(lastYearReal, est);
                set(period, ['entryMatrix', d.code, 'PGS%ER', 'value'], newProgEstReal);
                const newProgEstObj = DispatcherService.calcProgression(lastYearObj, est);
                set(period, ['entryMatrix', d.code, 'PGS%EO', 'value'], newProgEstObj);

                const currentReal = get(period, ['entryMatrix', d.code, 'REALN', 'value'], 0);
                // const progReals = get(period, ['entryMatrix', d.code, 'PGS%RR', 'value'], 0);
                // const progRealObj = get(period, ['entryMatrix', d.code, 'PGS%RO', 'value'], 0);

                const newProgReals = DispatcherService.calcProgression(lastYearReal, currentReal);
                set(period, ['entryMatrix', d.code, 'PGS%RR', 'value'], newProgReals);

                const newProgRealObj = DispatcherService.calcProgression(lastYearObj, currentReal);
                set(period, ['entryMatrix', d.code, 'PGS%RO', 'value'], newProgRealObj);

                const currentObj = get(period, ['entryMatrix', d.code, 'OBJN+1', 'value'], 0);
                // const progCurrentObjEst = get(period, ['entryMatrix', d.code, 'PGS%OE', 'value'], 0);

                const newProgCurrentObjEst = DispatcherService.calcProgression(est, currentObj);
                set(period, ['entryMatrix', d.code, 'PGS%OE', 'value'], newProgCurrentObjEst);
            } else {
                const lockedPeriods = this.account.periods.filter(p => p.isLocked);
                const unlockedPeriods = this.account.periods.filter(p => !p.isLocked);

                const reducerLastYearReal = (acc, p) => {
                    const entry = p.entryLogs
                        .filter(e => e.designation.code === d.code)
                        .find(e => e.titleLog.code === 'REALN-1');
                    return entry ? acc + Number(entry.value) : acc;
                };
                const reducerObj = (acc, p) => {
                    const entry = p.entryLogs
                        .filter(e => e.designation.code === d.code)
                        .find(e => e.titleLog.code === 'OBJ');
                    return entry ? acc + Number(entry.value) : acc;
                };

                const lastYearRealLockedValue = lockedPeriods.reduce(reducerLastYearReal, 0);
                const lastYearRealUnlockedValue = unlockedPeriods.reduce(reducerLastYearReal, 0);
                const objUnlockedValue = unlockedPeriods.reduce(reducerObj, 0);

                const lastYearReal = get(period, ['entryMatrix', d.code, 'REALN-1', 'value'], 0)

                // Objectifs N
                const lastYearObj = get(period, ['entryMatrix', d.code, 'OBJ', 'value'], 0)

                // const progObjReal = get(period, ['entryMatrix', d.code, 'PGS%OR', 'value'], 0)

                const newProgObjReal = DispatcherService.calcProgression(lastYearReal, lastYearObj);
                set(period, ['entryMatrix', d.code, 'PGS%OR', 'value'], newProgObjReal);

                // Réalisé N a date
                const currentReal = get(period, ['entryMatrix', d.code, 'REALN', 'value'], 0)

                // const progReals = get(period, ['entryMatrix', d.code, 'PGS%RR', 'value'], 0)

                const newProgReals = DispatcherService.calcProgression(lastYearRealLockedValue, currentReal);
                set(period, ['entryMatrix', d.code, 'PGS%RR', 'value'], newProgReals);

                // Estimé N
                const est = get(period, ['entryMatrix', d.code, 'EST', 'value'], 0)

                // const progEstReal = get(period, ['entryMatrix', d.code, 'PGS%ER', 'value'], 0)
                // const progEstObj = get(period, ['entryMatrix', d.code, 'PGS%EO', 'value'], 0)

                const newProgEstReal = DispatcherService.calcProgression(lastYearReal, est);
                set(period, ['entryMatrix', d.code, 'PGS%ER', 'value'], newProgEstReal);

                const neWProgEstObj = DispatcherService.calcProgression(lastYearObj, est);
                set(period, ['entryMatrix', d.code, 'PGS%EO', 'value'], neWProgEstObj);

                // Estimé reste à faire N
                const estRaf = get(period, ['entryMatrix', d.code, 'ESTRAF', 'value'], 0)

                const newEstRaf = est - currentReal;
                set(period, ['entryMatrix', d.code, 'ESTRAF', 'value'], newEstRaf);

                // const progEstRafReal = get(period, ['entryMatrix', d.code, 'PGS%ERAFR', 'value'], 0)
                // const progEstRafObj = get(period, ['entryMatrix', d.code, 'PGS%ERAFO', 'value'], 0)

                const newProgEstRafReal = DispatcherService.calcProgression(lastYearRealUnlockedValue, estRaf);
                set(period, ['entryMatrix', d.code, 'PGS%ERAFR', 'value'], newProgEstRafReal);

                const newProgEstRafObj = DispatcherService.calcProgression(objUnlockedValue, estRaf);
                set(period, ['entryMatrix', d.code, 'PGS%ERAFO', 'value'], newProgEstRafObj);


                // Objectifs N+1
                const obj = get(period, ['entryMatrix', d.code, 'OBJN+1', 'value'], 0)

                // const progObjEst = get(period, ['entryMatrix', d.code, 'PGS%ON+1E', 'value'], 0)

                const newProgObjEst = DispatcherService.calcProgression(est, obj);
                set(period, ['entryMatrix', d.code, 'PGS%ON+1E', 'value'], newProgObjEst);

            }
        })

    }

    /**
    *
    */
    private getLinkedRubriques = (code: string): Rubrique[] => {
        const calculatedRubriques = this.calculationMatrix.filter(r => r.calculated);
        const linkedFormulas = this.formulaService.getLinkedRubriques(calculatedRubriques, code);
        return linkedFormulas;
    };


    /**
     * Génére les entrées de la période passée en paramètre puis en retourne une
     * copie avec celles-ci.
     */
    private generateEntries(period, pattern: PatternLog): PeriodLog {
        const newPeriodLog: PeriodLog = {
            id: period.id,
            code: period.code,
            number: period.number,
            entries: [],
            isLocked: period.isLocked,
        };
        const newEntries: EntryLog[] = [];

        pattern.designations.forEach(group => {
            group.forEach(designation => {
                pattern.titles.forEach(title => {
                    // Si il n'existe pas d'entrée on en crée une avec pour valeur 0.
                    let existingEntry = null;
                    if (period.entryLogs.length > 0) {
                        existingEntry = period.entryLogs.find(
                            e => e.designation.code === designation.code && e.titleLog.code === title.code
                        );
                    }

                    let newEntry: EntryLog;
                    if (!isNullOrUndefined(existingEntry)) {
                        newEntry = {
                            id: existingEntry.id,
                            designation,
                            title,
                            value: existingEntry.value,
                        };
                    } else {
                        newEntry = {
                            designation,
                            title,
                            value: 0,
                        };
                    }

                    newEntries.push(newEntry);
                });
            });
        });

        newPeriodLog.entries = newEntries;

        // On génère les entrées calculées à partir de la matrice de calcul
        this.generateCalculatedEntries(newPeriodLog, this.account.calculationMatrix);

        // On dispatch toutes les lignes
        pattern.designations.forEach(group => {
            group.forEach(designation => {
                if (newPeriodLog.code !== 'ANNUAL') {
                    this.dispatcherService.dispatchLine(designation, newPeriodLog);
                } else {
                    this.dispatcherService.dispatchLineYear(designation, newPeriodLog, this.account.periods);
                }
            });
        });

        return newPeriodLog;
    }

    /**
     * Génére les entrées de la période annuelle passée en paramètre puis en retourne une
     * copie avec celles-ci.
     * @param annualPeriod
     * @param periods les periodes à cumuler
     * @param pattern
     */
    private generateAnnualEntries(annualPeriod, periods: any[], motif: Pattern[][]): PeriodLog {
        const annualPeriodLog: PeriodLog = {
            id: annualPeriod.id,
            code: annualPeriod.code,
            number: annualPeriod.number,
            entries: [],
            isLocked: annualPeriod.isLocked,
        };
        const annualEntries: EntryLog[] = [];
        motif.forEach(group => {
            group.forEach(pattern => {
                this.titlesLog.forEach(title => {
                    let annualEntry: any;
                    let value = 0;
                    if (title.code === 'REALN') {
                        let estTitle = this.titlesLog.find(t => t.code === 'EST');
                        let filteredPeriods = periods.filter(p => p.isLocked);
                        filteredPeriods.forEach(period => {
                            value += Number(this.getValueFromPeriodDesignationAndTitle(period, pattern, estTitle));
                        });
                    } else if (title.code === 'ESTRAF') {
                        let estTitle = this.titlesLog.find(t => t.code === 'EST');
                        let filteredPeriods = periods.filter(p => !p.isLocked);
                        filteredPeriods.forEach(period => {
                            value += Number(this.getValueFromPeriodDesignationAndTitle(period, pattern, estTitle));
                        });
                    } else {
                        periods.forEach(period => {
                            value += Number(this.getValueFromPeriodDesignationAndTitle(period, pattern, title));
                        });
                    }

                    annualEntry = {
                        designation: pattern,
                        title,
                        value: Math.round(value),
                    };

                    annualEntries.push(annualEntry);
                });
            });
        });

        annualPeriodLog.entries = annualEntries;

        // On génère les entrées calculées à partir de la matrice de calcul
        // this.generateCalculatedEntries(annualPeriodLog, this.account.calculationMatrix);

        // On dispatch toutes les lignes
        // motif.forEach(group => {
        //     group.forEach(pattern => {
        //         if (annualPeriodLog.code !== 'ANNUAL') {
        //             this.dispatcherService.dispatchLine(pattern, annualPeriodLog);
        //         } else {
        //             this.dispatcherService.dispatchLineYear(pattern, annualPeriodLog, this.account.periods);
        //         }
        //     });
        // });

        return annualPeriodLog;
    }

    private generateCalculatedEntries(period: Period, calculationMatrix) {
        calculationMatrix.forEach(calcul => {
            // On récupère les entrées concernées par le calcul
            const calculatedEntries = period.entries.filter(
                e =>
                    e.designation.code === calcul.codeDesignation &&
                    ['REALN-1', 'OBJ', 'EST', 'REALN', 'OBJN+1'].includes(e.title.code)
            );

            calculatedEntries.forEach(calculatedEntry => {
                // Cas spécial COUT COLIS
                if (calculatedEntry.designation.code === 'CC') {
                    const firstPart = period.entries.find(
                        e => e.designation.code === 'ECOX18' && e.title.code === calculatedEntry.title.code
                    ).value;
                    const secondPart = period.entries.find(
                        e => e.designation.code === 'PNLSTAT01' && e.title.code === calculatedEntry.title.code
                    ).value;
                    calculatedEntry.value = Number(firstPart) / Number(secondPart);
                    calculatedEntry.value = Math.round(calculatedEntry.value * 1000) / 1000;
                    if (isNaN(calculatedEntry.value)) {
                        calculatedEntry.value = 0;
                    }
                }

                // TODO Les calculs de calculs peuvent être mal initialisés si ils sont mal ordonnées dans le pattern
                // de calcul. Il faut donc mettre les calculs de calculs en fin du fichier.
                // Donc il serait bien d'adapter le code ci-dessous pour qu'il gère les calculs de calculs.

                // On ajoute les sum
                calcul.sum.forEach(sum => {
                    const entrySum = period.entries.find(
                        e => e.designation.code === sum && e.title.code === calculatedEntry.title.code
                    );

                    calculatedEntry.value += Number(entrySum.value);
                });
                // On soustrait les sub
                calcul.sub.forEach(sub => {
                    const entrySub = period.entries.find(
                        e => e.designation.code === sub && e.title.code === calculatedEntry.title.code
                    );
                    calculatedEntry.value -= Number(entrySub.value);
                });
            });
        });
    }

    /**
     * Génère le caclul dispatcher à partir de la matrice de calcul.
     * Celui-ci permet d'aisément rediriger les calculs lorsque une entrée est modifiée ou créée.
     */
    private generateCalculDispatcher(
        calculationMatrix: Array<{ codeDesignation: string, sum: Array<string>, sub: Array<string> }>
    ): any {
        const calculDispatcher: any = {};
        calculationMatrix.forEach(calculation => {
            calculation.sum.forEach((code: string) => {
                if (isNullOrUndefined(calculDispatcher[code])) {
                    calculDispatcher[code] = { sum: [], sub: [] };
                }
                calculDispatcher[code].sum.push(calculation.codeDesignation);
            });
            calculation.sub.forEach((code: string) => {
                if (isNullOrUndefined(calculDispatcher[code])) {
                    calculDispatcher[code] = { sum: [], sub: [] };
                }
                calculDispatcher[code].sub.push(calculation.codeDesignation);
            });
        });
        return calculDispatcher;
    }

    /**
     * Appelle la répartition annuel lorsque l'annuel est modifié.
     */
    annualDistribution(entry: any) {
        const method = DistributionMode[this.dispatchFilter];
        let entryCopy = JSON.parse(JSON.stringify(entry));
        this.titlesLogService.getAllTitlesLogByType(1).subscribe(titlesLog => {
            entryCopy.title = titlesLog.find(t => t.code === entry.title.code);
            this.dispatcherService[method](entryCopy, this.period, this.account.id).subscribe(result => {
                if (!result) {
                    this.toastrService.error('Une erreur est survenue lors de la répartition');
                } else {
                    entry.periodLogId = 15;
                    this.updateEntryEvent(entry);
                    this.toastrService.success(`Répartition ${this.dispatchFilter} effectuée`);
                }
            });
        });
    }

    private getValueFromPeriodDesignationAndTitle(period, pattern: Pattern, title: TitleLog): number {
        let existingEntry = null;
        if (period.entryLogs.length > 0) {
            existingEntry = period.entryLogs.find(
                e => e.rubrique.code === pattern.rubrique.code && e.titleLog.code === title.code
            );
        }
        if (!isNullOrUndefined(existingEntry)) {
            return existingEntry.value;
        } else {
            return 0;
        }
    }

    /**
     * Appelé lorque une ligne est selectionnée.
     */
    handleNewLineSelected($event) {
        if (this.lineSelected === $event) {
            this.lineSelected = null;
        } else {
            this.lineSelected = $event;
        }
    }

    hiddeTitle($event) {
        this.hiddenTitles = $event;
    }

    displayNextPeriod() {
        this.periodDisplayed =
            this.periodDisplayed.number === 14
                ? this.account.periods.find(p => p.number === 1)
                : this.account.periods.find(p => p.number === this.periodDisplayed.number + 1);

        if (this.periodsDisplayed[2].number === 14) {
            return;
        }
        for (let i = 0; i < 3; i++) {
            this.periodsDisplayed[i] = this.account.periods.find(p => p.number === this.periodsDisplayed[i].number + 1);
        }
    }

    displayPreviousPeriod() {
        this.periodDisplayed =
            this.periodDisplayed.number === 1
                ? this.account.periods.find(p => p.number === 14)
                : this.account.periods.find(p => p.number === this.periodDisplayed.number - 1);
        if (this.periodsDisplayed[0].number === 1) {
            return;
        }
        for (let i = 0; i < 3; i++) {
            this.periodsDisplayed[i] = this.account.periods.find(p => p.number === this.periodsDisplayed[i].number - 1);
        }
    }

    getMainHeight() {
        return 46 + this.headerHeight + 69 + 'px';
    }

    navigateToPeriod(code) {
        this.periodDisplayed = this.account.periods.find(p => p.code === code);
        const number = this.account.periods.find(p => p.code === code).number;
        const nextPeriods: Period[] = [];
        if (number === 14) {
            nextPeriods.push(this.account.periods.find(p => p.number === number - 2));
            nextPeriods.push(this.account.periods.find(p => p.number === number - 1));
            nextPeriods.push(this.account.periods.find(p => p.number === number));
        } else if (number === 13) {
            nextPeriods.push(this.account.periods.find(p => p.number === number - 1));
            nextPeriods.push(this.account.periods.find(p => p.number === number));
            nextPeriods.push(this.account.periods.find(p => p.number === number + 1));
        } else {
            nextPeriods.push(this.account.periods.find(p => p.number === number));
            nextPeriods.push(this.account.periods.find(p => p.number === number + 1));
            nextPeriods.push(this.account.periods.find(p => p.number === number + 2));
        }
        this.periodsDisplayed = nextPeriods;
    }

    isPeriodCodeDisplaying(code) {
        return (
            this.account.periods &&
            this.periodsDisplayed &&
            this.periodsDisplayed.includes(this.account.periods.find(p => p.code === code))
        );
    }

    formatConnectionsForDisplay = (connections: Array<any>): string => {
        return connections.map(c => `${c.user.firstName ? c.user.firstName : ''} ${c.user.lastName ? c.user.lastName : ''} ${c.user.firstName ? '(' + c.user.login + ')' : c.user.login}`).join('\n')
    }

    ngOnDestroy() {
        /*this.connectionsSub.unsubscribe();*/
    }
}
