import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { CalendarDay } from '../../_models/calendar-day';
import * as ExcelJS from 'exceljs/dist/exceljs';
import { Title } from 'src/app/_models/title';
import { Workbook, Worksheet } from 'exceljs';
import { Rubrique } from 'src/app/_models/rubrique';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root',
})
export class ExcelService {
    constructor(
    ) { }

    generateExcel(data) {
        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'Pilotage Budgétaire';
        data.forEach(datum => {
            workbook.addWorksheet(datum.period, { properties: { tabColor: { argb: 'EE432F' } } });
            const worksheet = workbook.getWorksheet(datum.period);
            let first = true;
            //const header = worksheet.addRow(['', 'Obj 2020', 'Prog%']);
            //header.font = { bold: true };
            datum.designations.forEach((d, indexDesignation) => {
                // DESIGNATION TITLE
                let row = worksheet.getRow(1);
                worksheet.mergeCells(1, indexDesignation * 3 + 2, 1, indexDesignation * 3 + 4);
                let cell = row.getCell(indexDesignation * 3 + 2);
                cell.value = d.designation;
                cell.font = { bold: true };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffff99' },
                };
                cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
                // COLUMN TITLE
                row = worksheet.getRow(2);
                cell = row.getCell(indexDesignation * 3 + 2);
                cell.value = 'REAL';
                cell.font = { bold: true };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffff99' },
                };
                cell.border = { bottom: { style: 'thin' } };
                cell = row.getCell(indexDesignation * 3 + 3);
                cell.value = 'OBJ';
                cell.font = { bold: true };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffff99' },
                };
                cell.border = { bottom: { style: 'thin' } };
                cell = row.getCell(indexDesignation * 3 + 4);
                cell.value = 'PROG. %';
                cell.font = { bold: true };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffff99' },
                };
                cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
                // Index of the first row with data
                let start = 3;
                d.entries.forEach((e, indexEntry) => {
                    const row = worksheet.getRow(indexEntry + start);
                    if (first) {
                        const cell = row.getCell(indexDesignation * 3 + 1);
                        cell.value = e.account;
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: e.color },
                        };
                        cell.border = { right: { style: 'thin' } };
                        if (indexEntry < 4) {
                            cell.font = { bold: true };
                        }
                    }
                    let real = row.getCell(indexDesignation * 3 + 2);
                    real.value = Math.floor(e.real);
                    real.numFmt = '### ### ### ###;-### ### ### ###;-;';
                    let obj = row.getCell(indexDesignation * 3 + 3);
                    obj.value = Math.floor(e.obj);
                    obj.numFmt = '### ### ### ###;-### ### ### ###;-;';
                    let cell = row.getCell(indexDesignation * 3 + 4);
                    isNaN(Number(e.prog)) ? (cell.value = '/') : (cell.value = Math.floor(Number(e.prog)));
                    cell.border = { right: { style: 'thin' } };
                    if (Number(e.prog) === 0) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'EE432F' },
                        };
                    }
                    // Adding HYP - Total Rayon
                    if (indexEntry === 3) {
                        start += 1;
                        const row = worksheet.getRow(indexEntry + start);
                        if (first) {
                            const cell = row.getCell(indexDesignation * 3 + 1);
                            cell.value = 'HYP - Total Rayon';
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: e.color },
                            };
                            cell.border = { right: { style: 'thin' } };
                            cell.font = { bold: true };
                        }
                        let real = row.getCell(indexDesignation * 3 + 2);
                        real.value =
                            Number(worksheet.getCell(4, indexDesignation * 3 + 2).value) -
                            Number(worksheet.getCell(6, indexDesignation * 3 + 2).value);
                        real.numFmt = '### ### ### ###;-### ### ### ###;-;';
                        let obj = row.getCell(indexDesignation * 3 + 3);
                        obj.value =
                            Number(worksheet.getCell(4, indexDesignation * 3 + 3).value) -
                            Number(worksheet.getCell(6, indexDesignation * 3 + 3).value);
                        obj.numFmt = '### ### ### ###;-### ### ### ###;-;';
                        cell = row.getCell(indexDesignation * 3 + 4);
                        let value = Number(this.progression(Number(real), Number(obj)));
                        cell.value = isNaN(value) ? '/' : value;
                        cell.border = { right: { style: 'thin' } };
                        if (Number(cell.value) === 0) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'EE432F' },
                            };
                        }
                    }
                });
                first = false;
            });
        });
        workbook.xlsx.writeBuffer().then(file => {
            let blob = new Blob([file], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const date = new Date();
            saveAs(blob, `RAYONS-${date.toLocaleString().replace(/\s/g, '')}.xlsx`);
        });
    }

    private progression(vd, va) {
        const prog = ((va - vd) / vd) * 100;
        return prog.toFixed(2);
    }

    generateExcelDay(calendarDays: CalendarDay[], matrix: any[], month: string, year: number) {
        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'Pilotage Budgétaire';
        workbook.addWorksheet(month, { properties: { tabColor: { argb: 'EE432F' } } });
        const worksheet = workbook.getWorksheet(month);
        // HEADERS
        // FIRST ROW
        worksheet.mergeCells(1, 1, 2, 2);
        worksheet.mergeCells(1, 3, 2, 4);
        worksheet.mergeCells(1, 5, 1, 8);
        worksheet.mergeCells(1, 9, 1, 12);
        let row = worksheet.getRow(1);
        let cell = row.getCell(1);
        cell.value = year;
        cell.font = { bold: true };
        cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        cell = row.getCell(3);
        cell.value = year + 1;
        cell.font = { bold: true };
        cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        cell = row.getCell(5);
        cell.value = "Volume d'affaire TTC";
        cell.font = { bold: true };
        cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        cell = row.getCell(9);
        cell.value = 'Marge sur vente';
        cell.font = { bold: true };
        cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        cell = row.getCell(13);
        cell.value = 'Marge/VA 2018';
        cell.font = { bold: true };
        cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        cell = row.getCell(14);
        cell.value = 'Marge/VA 2019';
        cell.font = { bold: true };
        cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        // SECOND ROW
        row = worksheet.getRow(2);
        cell = row.getCell(5);
        cell.value = year;
        cell.font = { bold: true };
        cell.border = { bottom: { style: 'thin' } };
        cell = row.getCell(6);
        cell.value = year + 1;
        cell.font = { bold: true };
        cell.border = { bottom: { style: 'thin' } };
        cell = row.getCell(7);
        cell.value = 'Prog. %';
        cell.font = { bold: true };
        cell.border = { bottom: { style: 'thin' } };
        cell = row.getCell(8);
        cell.value = 'Prog. €';
        cell.font = { bold: true };
        cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        cell = row.getCell(9);
        cell.value = year;
        cell.font = { bold: true };
        cell.border = { bottom: { style: 'thin' } };
        cell = row.getCell(10);
        cell.value = year + 1;
        cell.font = { bold: true };
        cell.border = { bottom: { style: 'thin' } };
        cell = row.getCell(11);
        cell.value = 'Prog. %';
        cell.font = { bold: true };
        cell.border = { bottom: { style: 'thin' } };
        cell = row.getCell(12);
        cell.value = 'Prog. €';
        cell.font = { bold: true };
        cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        // THRID ROW
        worksheet.mergeCells(3, 1, 3, 4);
        row = worksheet.getRow(3);
        cell = row.getCell(1);
        cell.value = 'Total du mois';
        cell.font = { bold: true };
        cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        cell = row.getCell(5);
        cell.value = this.calcSumOfCol(calendarDays, matrix, 'LASTVA');
        cell.font = { bold: true };
        cell = row.getCell(6);
        cell.value = this.calcSumOfCol(calendarDays, matrix, 'PRESVA');
        cell.font = { bold: true };
        cell = row.getCell(9);
        cell.value = this.calcSumOfCol(calendarDays, matrix, 'LASTMV');
        cell.font = { bold: true };
        cell = row.getCell(10);
        cell.value = this.calcSumOfCol(calendarDays, matrix, 'PRESMV');
        cell.font = { bold: true };
        // FOURTH ROW
        worksheet.mergeCells(4, 1, 4, 4);
        row = worksheet.getRow(4);
        cell = row.getCell(1);
        cell.value = 'Ecart entre somme des jours et mensuel';
        cell.font = { bold: true, color: { argb: 'FF0000' } };
        cell.border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
        cell = row.getCell(6);
        cell.value = -matrix['delta']['PRESVA'];
        cell.font = { bold: true, color: { argb: 'FF0000' } };
        cell = row.getCell(10);
        cell.value = -matrix['delta']['PRESMV'];
        cell.font = { bold: true, color: { argb: 'FF0000' } };
        for (let i = 5; i <= 14; i++) {
            cell = row.getCell(i);
            cell.border = { bottom: { style: 'thin' } };
        }
        calendarDays.forEach(calendarDay => {
            let rowData = matrix[calendarDay.day.id];
            let row = worksheet.addRow([
                calendarDay.day.jourReal,
                calendarDay.day.numReal,
                calendarDay.day.jourObjectif,
                calendarDay.day.numObjectif,
                rowData['LASTVA'],
                rowData['PRESVA'],
                rowData['PROGVA%'],
                rowData['PROGVA'],
                rowData['LASTMV'],
                rowData['PRESMV'],
                rowData['PROGMV%'],
                rowData['PROGMV'],
                rowData['LASTMVVA'],
                rowData['PRESMVVA'],
            ]);
            let cell = row.getCell(1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'f0f0f0' },
            };
            cell.border = {
                right: { style: 'thin', color: { argb: 'c0c0c0' } },
                bottom: { style: 'thin', color: { argb: 'c0c0c0' } },
            };
            cell = row.getCell(2);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'f0f0f0' },
            };
            cell.border = {
                right: { style: 'thin', color: { argb: 'c0c0c0' } },
                bottom: { style: 'thin', color: { argb: 'c0c0c0' } },
            };
            cell = row.getCell(3);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'f0f0f0' },
            };
            cell.border = {
                right: { style: 'thin', color: { argb: 'c0c0c0' } },
                bottom: { style: 'thin', color: { argb: 'c0c0c0' } },
            };
            cell = row.getCell(4);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'f0f0f0' },
            };
            cell.border = { right: { style: 'thin' }, bottom: { style: 'thin', color: { argb: 'c0c0c0' } } };
        });
        console.log('done')
        workbook.xlsx.writeBuffer().then(file => {
            try {

                console.log('done2')

                let blob = new Blob([file], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const date = new Date();
                saveAs(blob, `${month}-${date.toLocaleString().replace(/\s/g, '')}.xlsx`);
                console.log('done2')
            } catch (e) {
                console.log("ExcelService -> generateExcelDay -> e", e)

            }

        });
    }

    private calcSumOfCol(calendarDays, matrix, col): number {
        let sum = 0;
        calendarDays.forEach(day => {
            sum += Number(matrix[day.day.id][col]);
        });
        return sum;
    }

    generateTxtErrorImport(data) {
        let text = '';

        data.errors.forEach(element => {
            text += `${element.level} -> Colonne ${element.type} : ${element.message} \r\n`;
        });

        let blob = new Blob([text], {
            type: 'application/pdf',
        });
        const date = new Date();
        saveAs(blob, `error-import-${date.toLocaleString().replace(/\s/g, '')}.txt`);
    }

    generateStoreExcelData = async (data, type?, catStore?) => {
        const headers =
            type === 'RAYONS'
                ? [
                    'accountId',
                    'designationId',
                    'Code marché',
                    'Libellé rayon',
                    'Rubrique de gestion',
                    'Libellé de la rubrique de gestion',
                    'Format du magasin',
                    'Somme repères annuels N+1',
                    'Repere annuel N+1',
                    'Réalisé N-1',
                    'Estimé',
                    'Code magasin',
                    'Libellé magasin',
                    'Entrant',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                ]
                :
                type === 'MAGASINS_MENSUEL'
                    ? [
                        'accountId',
                        'designationId',
                        'périodeId',
                        'CEX',
                        'Rubrique de gestion',
                        'Libellé de la rubrique de gestion',
                        'Format du magasin',
                        //'Somme repères mensuels N+1',
                        //'Repere annuel N+1',
                        'Réalisé N-1',
                        'Estimé',
                        'Code magasin',
                        'Libellé magasin',
                        'Période',
                        'Repere',

                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                    ]
                    :
                    type === 'ESTIME_MENSUEL'
                        ? [
                            'accountId',
                            'designationId',
                            'périodeId',
                            'CEX',
                            'Rubrique de gestion',
                            'Libellé de la rubrique de gestion',
                            'Format du magasin',
                            //'Somme repères mensuels N+1',
                            //'Repere annuel N+1',
                            'Réalisé N-1',
                            //'Estimé',
                            'Code magasin',
                            'Libellé magasin',
                            'Période',
                            'Estime',

                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                        ]
                    :[
                    'accountId',
                    'designationId',
                    'CEX',
                    'Rubrique de gestion',
                    'Libellé de la rubrique de gestion',
                    'Format du magasin',
                    'Somme repères mensuels N+1',
                    'Repere annuel N+1',
                    'Réalisé N-1',
                    'Estimé',
                    'Code magasin',
                    'Libellé magasin',
                    'Entrant',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                    'libre',
                ];

        const largeColumnsIndexes = ['Libellé de la rubrique de gestion', 'Libellé rayon','Libellé magasin'];
        const hiddenColumnsIndexes = [];
        const unlockedColumnsIndexes = [];

        headers.forEach((h, i) => {
            if (h.includes('periodId') || h === 'accountId' || h === 'designationId') {
                // hiddenColumnsIndexes.push(i + 1);
            }
            if (h === 'libre' || h === 'Entrant') {
                // unlockedColumnsIndexes.push(i + 1);
            }
        });

        // Create workbook and worksheet
        let workbook = new ExcelJS.Workbook();
        let worksheetName;
        type === 'MAGASINS_MENSUEL' ? worksheetName = 'REPERES_MAGASINS': type === 'ESTIME_MENSUEL'? worksheetName = 'ESTIME_MAGASINS': worksheetName = 'ENTRANTS_MAGASINS';
        let worksheet = workbook.addWorksheet(worksheetName);
        const password = 'Admin@Export';

        // await worksheet.protect(password, {
        //     formatCells: true,
        //     formatColumns: true,
        //     formatRows: true,
        //     insertRows: true,
        //     insertColumns: true,
        //     insertHyperlinks: false,
        //     deleteRows: false,
        //     deleteColumns: false,
        //     sort: true,
        //     autoFilter: true,
        //     pivotTables: false,
        // });
        // Add Header Row
        let titleRow = worksheet.addRow(headers);
        titleRow.font = { bold: true };

        // Columns width, locking and hiding
        for (let i = 1; i <= headers.length; i++) {
            worksheet.getColumn(i).width = largeColumnsIndexes.includes(worksheet.getColumn(i).values[1] as string)
                ? 60
                : 20;
            // worksheet.getColumn(i).hidden = hiddenColumnsIndexes.includes(i) ? true : false;
            // worksheet.getColumn(i).protection = {
            //     locked: unlockedColumnsIndexes.includes(i) ? false : true,
            // };
        }

        for (let i = headers.length; i <= headers.length + 25; i++) {
            // worksheet.getColumn(i).protection = {
            //     locked: false,
            // };
        }

        // Header cell Style
        titleRow.eachCell(async (cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '9d0000' },
            };
            cell.font = {
                color: { argb: 'ffffff' },
            };
            cell.protection = {
                locked: true,
            };
        });


        let currentRow;
        // Add Data
        data.forEach(async (d, index) => {
            currentRow =
                type === 'RAYONS'
                    ? worksheet.addRow([

                        d.accountId,
                        d.designationId,
                        d.codeRayon,
                        d.nameRayon,
                        d.rubrique,
                        d.libelle,
                        d.format,
                        d.repere,
                        d.annualRepere,
                        d.real,
                        d.est,
                        d.code,
                        d.storeName,
                        d.entr,
                    ]) :
                    type === 'MAGASINS_MENSUEL'
                        ? worksheet.addRow([

                            d.accountId,
                            d.designationId,
                            d.periodId,
                            d.cex,
                            d.rubrique,
                            d.libelle,
                            d.format,
                            d.real,
                            d.est,
                            d.code,
                            d.storeName,
                            d.period,
                            d.repere,

                        ])
                        :
                        type === 'ESTIME_MENSUEL'
                            ? worksheet.addRow([

                                d.accountId,
                                d.designationId,
                                d.periodId,
                                d.cex,
                                d.rubrique,
                                d.libelle,
                                d.format,
                                d.real,
                                //d.est,
                                d.code,
                                d.storeName,
                                d.period,
                                d.est,

                            ])
                    : worksheet.addRow([

                        d.accountId,
                        d.designationId,
                        d.cex,
                        d.rubrique,
                        d.libelle,
                        d.format,
                        d.repere,
                        d.annualRepere,
                        d.real,
                        d.est,
                        d.code,
                        d.storeName,
                        d.entr,
                    ]);
        });

        // Styling Sheet
        let columnIndex = (type === 'RAYONS') ? 14 :13;

        worksheet.eachRow(function(row, rowNumber) {
            row.eachCell((cell, colIndex) => {

                if(colIndex === columnIndex && rowNumber>1){
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'CCFFCC'
                        },
                        bgColor: {
                            argb: '00000000'
                        }
                    }
                }
            });
        });
        // Generate Excel File with given name

        workbook.xlsx.writeBuffer().then(data => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const date = new Date();
            let filename = type === 'RAYONS' ? 'MARCHES' : type === 'ESTIME_MENSUEL' ? 'ESTIMES' : 'ENTRANTS';
            if(type === 'MAGASINS_MENSUEL') {
                filename = 'REPERES';
            }
            catStore = (filename === 'MARCHES') ? 'AllStore' :  catStore
            saveAs(blob, `${filename}-${catStore}-${date.toLocaleString().replace(/\s/g, '')}-v${environment.VERSION}.xlsx`);
        });
    };

    public generateAccountExcel = (input: {
        account: any,
        titles: Title[]
        periods: any[],
    }) => {
        const workbook = new ExcelJS.Workbook();
        input.periods.forEach(periodData => {
            const workSheet: Worksheet = workbook.addWorksheet(periodData.period.code);

            //Headers
            workSheet.columns = [{ header: 'Rubrique', key: 'code', width: 40 }].concat(input.titles.map(t => { return { header: t.name, key: t.code, width: t.type === 1 ? 15 : 5 } }));

            // Rows
            periodData.rows.forEach(row => {
                workSheet.addRow(row);
            });
        })

        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then(data => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const date = new Date();
            const filename = `export_magasin`;

            saveAs(blob, `${filename}-${date.toLocaleString().replace(/\s/g, '')}.xlsx`);
        });
    }

    public generateOneStoreExcel = (input: any[], titles: Title[], rubriques: Rubrique[], storeName: any) => {

        const workbook: Workbook = new ExcelJS.Workbook();
        input.forEach(periodData => {
            const workSheet: Worksheet = workbook.addWorksheet(periodData.period);

            const headers = Object
                .keys(periodData.rows[0])
                .filter(k => !['account', 'name', 'type','rayon'].includes(k))
                .map(code => {
                    const rubrique = rubriques.find( r => r.id === +code.split('_')[0])
                    const title = titles.find( t => t.id === +code.split('_')[1])
                    return { header: `${rubrique ? rubrique.libelle : code } - ${title ? title.name : code}`, key: code, width: 19 }
                })

            //Headers
            workSheet.columns = [{ header: 'Compte exploitation', key: 'name', width: 75 },{ header: 'Categorie', key: 'type', width: 12 },{ header: 'Rayon', key: 'rayon', width: 12 }].concat(...headers);

            for (let i = 3; i <= workSheet.columnCount; i += 1) {
                if (i % 3 === 0) {
                    workSheet.getColumn(i).numFmt = '0.00';
                    workSheet.getColumn(i+1).numFmt = '#,##0 €;[Red]-#,##0 €';
                    workSheet.getColumn(i+2).numFmt = '#,##0 €;[Red]-#,##0 €';
                }
            }
            /*workSheet.getColumn(3).numFmt = '#,##0 €;[Red]-#,##0 €';
            workSheet.getColumn(4).numFmt = '#,##0 €;[Red]-#,##0 €';
            workSheet.getColumn(5).numFmt = '0.00';
            workSheet.getColumn(6).numFmt = '#,##0 €;[Red]-#,##0 €';
            workSheet.getColumn(7).numFmt = '#,##0 €;[Red]-#,##0 €';
            workSheet.getColumn(8).numFmt = '0.00';*/

            // Rows
            periodData.rows.forEach(row => {
                workSheet.addRow(row);
            });

            const headerRow = workSheet.getRow(1);
            headerRow.height = 45;
            headerRow.eachCell((cell, colIndex) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center',  wrapText: true }
                cell.border = {
                    top: {style:'thin'},
                    left: {style:'thin'},
                    bottom: {style:'thin'},
                    right: {style:'thin'}
                  };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '64F4FCA1'
                    },
                    bgColor: {
                        argb: '00000000'
                    }
                };
                cell.font = {
                    //name: 'Arial',
                    //family: 4,
                    //size: 16,
                    bold: true
                };
            } );

            const lastRow = workSheet.lastRow;
            lastRow.eachCell((cell, colIndex) => {
                cell.font = {
                    //name: 'Arial',
                    //family: 4,
                    //size: 16,
                    bold: true
                };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '64F4FCA1'
                    },
                    bgColor: {
                        argb: '00000000'
                    }
                };
            } );

            // Styling Sheet
            workSheet.eachRow(function(row, rowNumber) {
                row.eachCell((cell, colIndex) => {
                    if (rowNumber % 2 === 0) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: {
                                argb: '87E8E1E1'
                            },
                            bgColor: {
                                argb: '00000000'
                            }
                        }
                    }
                    if(colIndex === 1){
                        cell.font = {
                            //name: 'Arial',
                            //family: 4,
                            //size: 16,
                            bold: true
                        };
                    }
                });
            });
        });

        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then(data => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const date = new Date();
            const filename = `export_Marchés_`+storeName;
            saveAs(blob, `${filename}-${date.toLocaleString().replace(/\s/g, '')}.xlsx`);
        });
    }

    generateStoreExcelData2 = async (data2, type?, catStore?) => {
        const headers =

                type === 'MAGASINS_MENSUEL'
                    ? [
                        'accountId',
                        'designationId',
                        'périodeId',
                        'CEX',
                        'Rubrique de gestion',
                        'Libellé de la rubrique de gestion',
                        'Format du magasin',
                        //'Somme repères mensuels N+1',
                        //'Repere annuel N+1',
                        'Réalisé N-1',
                        'Estimé',
                        'Code magasin',
                        'Libellé magasin',
                        'Période',
                        'Repere',

                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                        'libre',
                    ]
                    :
                    type === 'ESTIME_MENSUEL'
                        ? [
                            'accountId',
                            'designationId',
                            'périodeId',
                            'CEX',
                            'Rubrique de gestion',
                            'Libellé de la rubrique de gestion',
                            'Format du magasin',
                            //'Somme repères mensuels N+1',
                            //'Repere annuel N+1',
                            'Réalisé N-1',
                            //'Estimé',
                            'Code magasin',
                            'Libellé magasin',
                            'Période',
                            'Estime',

                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                        ]
                        :[
                            'accountId',
                            'designationId',
                            'CEX',
                            'Rubrique de gestion',
                            'Libellé de la rubrique de gestion',
                            'Format du magasin',
                            'Somme repères mensuels N+1',
                            'Repere annuel N+1',
                            'Réalisé N-1',
                            'Estimé',
                            'Code magasin',
                            'Libellé magasin',
                            'Entrant',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                            'libre',
                        ];

        const largeColumnsIndexes = ['Libellé de la rubrique de gestion', 'Libellé rayon','Libellé magasin'];
        const hiddenColumnsIndexes = [];
        const unlockedColumnsIndexes = [];

        headers.forEach((h, i) => {
            if (h.includes('periodId') || h === 'accountId' || h === 'designationId') {
                // hiddenColumnsIndexes.push(i + 1);
            }
            if (h === 'libre' || h === 'Entrant') {
                // unlockedColumnsIndexes.push(i + 1);
            }
        });

        // Create workbook and worksheet
        let workbook = new ExcelJS.Workbook();
        let worksheetName;
        type === 'MAGASINS_MENSUEL' ? worksheetName = 'REPERES_MAGASINS': type === 'ESTIME_MENSUEL'? worksheetName = 'ESTIME_MAGASINS': worksheetName = 'ENTRANTS_MAGASINS';
        let worksheet = workbook.addWorksheet(worksheetName);
        const password = 'Admin@Export';

        // await worksheet.protect(password, {
        //     formatCells: true,
        //     formatColumns: true,
        //     formatRows: true,
        //     insertRows: true,
        //     insertColumns: true,
        //     insertHyperlinks: false,
        //     deleteRows: false,
        //     deleteColumns: false,
        //     sort: true,
        //     autoFilter: true,
        //     pivotTables: false,
        // });
        // Add Header Row
        let titleRow = worksheet.addRow(headers);
        titleRow.font = { bold: true };

        // Columns width, locking and hiding
        for (let i = 1; i <= headers.length; i++) {
            worksheet.getColumn(i).width = largeColumnsIndexes.includes(worksheet.getColumn(i).values[1] as string)
                ? 60
                : 20;
            // worksheet.getColumn(i).hidden = hiddenColumnsIndexes.includes(i) ? true : false;
            // worksheet.getColumn(i).protection = {
            //     locked: unlockedColumnsIndexes.includes(i) ? false : true,
            // };
        }

        for (let i = headers.length; i <= headers.length + 25; i++) {
            // worksheet.getColumn(i).protection = {
            //     locked: false,
            // };
        }

        // Header cell Style
        titleRow.eachCell(async (cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '9d0000' },
            };
            cell.font = {
                color: { argb: 'ffffff' },
            };
            cell.protection = {
                locked: true,
            };
        });


        let currentRow;
        // Add Data
        console.log(' data2 excell '+data2.length)
        data2.forEach( da=>   {
            da.forEach(async (d, index) => {

                currentRow =
                    type === 'RAYONS'
                        ? worksheet.addRow([

                            d.accountId,
                            d.designationId,
                            d.codeRayon,
                            d.nameRayon,
                            d.rubrique,
                            d.libelle,
                            d.format,
                            d.repere,
                            d.annualRepere,
                            d.real,
                            d.est,
                            d.code,
                            d.storeName,
                            d.entr,
                        ]) :
                        type === 'MAGASINS_MENSUEL'
                            ? worksheet.addRow([

                                d.accountId,
                                d.designationId,
                                d.periodId,
                                d.cex,
                                d.rubrique,
                                d.libelle,
                                d.format,
                                d.real,
                                d.est,
                                d.code,
                                d.storeName,
                                d.period,
                                d.repere,

                            ])
                            :
                            type === 'ESTIME_MENSUEL'
                                ? worksheet.addRow([

                                    d.accountId,
                                    d.designationId,
                                    d.periodId,
                                    d.cex,
                                    d.rubrique,
                                    d.libelle,
                                    d.format,
                                    d.real,
                                    //d.est,
                                    d.code,
                                    d.storeName,
                                    d.period,
                                    d.est,

                                ])
                                : worksheet.addRow([

                                    d.accountId,
                                    d.designationId,
                                    d.cex,
                                    d.rubrique,
                                    d.libelle,
                                    d.format,
                                    d.repere,
                                    d.annualRepere,
                                    d.real,
                                    d.est,
                                    d.code,
                                    d.storeName,
                                    d.entr,
                                ]);
            });


        }      )

        // Styling Sheet
        let columnIndex = (type === 'RAYONS') ? 14 :13;

        worksheet.eachRow(function(row, rowNumber) {
            row.eachCell((cell, colIndex) => {

                if(colIndex === columnIndex && rowNumber>1){
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'CCFFCC'
                        },
                        bgColor: {
                            argb: '00000000'
                        }
                    }
                }
            });
        });
        // Generate Excel File with given name
       // console.log(' data '+data)
        workbook.xlsx.writeBuffer().then(data => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const date = new Date();
            let filename = type === 'RAYONS' ? 'RAYONS' : 'STORE';
            if(type === 'ESTIME_MENSUEL') {
                filename = 'ESTIME';
            }
            catStore = (filename === 'RAYONS') ? 'AllStore' :  catStore
            saveAs(blob, `${filename}-${catStore}-${date.toLocaleString().replace(/\s/g, '')}-v${environment.VERSION}.xlsx`);
        });
    };
}


