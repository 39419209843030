import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CoefficientService } from '../../../_services/coefficient/coefficient.service';
import { Coefficient } from '../../../_models/coefficient';
import { MatDialog } from '@angular/material';
import { Rubrique } from '../../../_models/rubrique';
import * as math from 'mathjs';
import { RubriqueService } from '../../../_services/rubrique/rubrique.service';
import { AdminService } from '../../../_services/admin/admin.service';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmationComponent } from '../../modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-coefficient-calendaire',
  templateUrl: './coefficient-calendaire.component.html',
  styleUrls: ['./coefficient-calendaire.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CoefficientCalendaireComponent implements OnInit {

  @ViewChild('overFlowContent') private myScrollContainer: ElementRef;
  @Input() roles: any[];
  rubriqueType = [{ name: 'Mag', code: 'MAG' }, { name: 'Log', code: 'LOG' }];
  selectedType = this.rubriqueType[0];

  filterValue = '';
  filterValueRole = '';

  filteredRoles;
  allCoefficients: Coefficient[];

  error = null;
  loading = false;

  formGroup: FormGroup;
  items: FormArray;
  formToDisplay: FormArray;

  parser: math.Parser;

  constructor(
      private coefficientService: CoefficientService,
      private adminService: AdminService,
      public modal: MatDialog,
      private fb: FormBuilder,
      private toastService: ToastrService
  ) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      items: new FormArray([]),
    });
    this.items = this.formGroup.get('items') as FormArray;
    this.formToDisplay = this.formGroup.get('items').value;
    this.getCoefficients();
  }

  getCoefficients() {
    this.loading = true;
    this.coefficientService.getAll().subscribe(
        data => {
          this.allCoefficients = data;
          this.loading = false;
          this.allCoefficients.forEach(d => {
            this.addRow(d);
          });
        },
        () => {
          this.loading = false;
        }
    );
  }

  addRow(item?) {
    this.items.push(this.createItem(item));
    this.applyFilter();

  }

  createItem(item?) {
    return this.fb.group({
      id: new FormControl(item ? item.id : null),
      libelle: new FormControl(item ? item.libelle : '', [Validators.required]),
      jan: new FormControl(item ? item.jan : ''),
      fev: new FormControl(item ? item.fev : ''),
      mar: new FormControl(item ? item.mar : ''),
      avr: new FormControl(item ? item.avr : ''),
      mai: new FormControl(item ? item.mai : ''),
      jui: new FormControl(item ? item.jui : ''),
      jul: new FormControl(item ? item.jul : ''),
      aou: new FormControl(item ? item.aou : ''),
      sep: new FormControl(item ? item.sep : ''),
      oct: new FormControl(item ? item.oct : ''),
      nov: new FormControl(item ? item.nov : ''),
      dec: new FormControl(item ? item.dec : ''),
    });
  }

  applyFilter() {
    this.formToDisplay['controls'] = this.formGroup.get('items')['controls'].filter(d => {
      return (
          d.value.libelle
              .toLowerCase()
              .trim()
              .includes(this.filterValue.toLowerCase().trim())
      );
    });
  }

  scrollToElement(el): void {
    this.myScrollContainer.nativeElement.scroll({
      top: this.myScrollContainer.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  openModal(coefficient, i) {
    const modal = this.modal.open(ModalConfirmationComponent, {
      autoFocus: false,
      width: '50%',
      minHeight: '200px',
      data: {
        action: 'Supprimer',
        question: `Êtes vous sûr de vouloir supprimer le coefficient <strong>${coefficient.libelle}</strong> ?`,
      },
    });
    modal.afterClosed().subscribe(res => {
      if (res) {
        this.deleteCoefficient(coefficient, i);
      }
    });
  }

  deleteCoefficient(coefficient, i) {
    this.coefficientService.delete(coefficient.id).subscribe(
        data => {
          const index = this.allCoefficients.findIndex(d => d.id === coefficient.id);
          this.allCoefficients.splice(index, 1);
          this.items.removeAt(i);
          this.applyFilter();
          this.toastService.success('Suppression de coefficient terminée', 'Suppression');
        },
        () => {
          this.toastService.error('Une erreur est survenue', 'Erreur');
        }
    );
  }

  remove (coefficient,index){
    if (coefficient.id) {
      this.openModal(coefficient, index);
    } else {
      this.items.removeAt(index);
      this.applyFilter();
    }
  }

  addCoefficient() {
    const coefficientsToAdd = [...this.formGroup.value.items];
    this.coefficientService.upsertAll(coefficientsToAdd).subscribe(
        data => {
          this.resetForm();
          this.formGroup.markAsUntouched();
          this.toastService.success('Les données ont été modifiées avec succès', 'Coefficient');
        },
        () => {
          this.toastService.error('Une erreur est survenue', 'Erreur');
        }
    );
  }

  resetForm() {
    this.items = this.formGroup.get('items') as FormArray;
    this.formToDisplay = this.formGroup.get('items').value;
    this.applyFilter();
  }

}
