import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { environment } from '../../../environments/environment';
import { Period } from '../../_models/period';
import { PeriodEdition } from '../../_models/period-edition';
import { PERIOD_EDITION_URI, PERIOD_URI } from '../../constants';
import { Pattern } from 'src/app/_models/pattern';
import { Title } from 'src/app/_models/title';

@Injectable({
    providedIn: 'root',
})
export class PeriodService {
    constructor(private http: HttpClient) { }

    getAllPeriodsByYearNumber(yearNumber: number): Observable<Period[]> {
        return this.http.get<Period[]>(environment.OP_API_URI + PERIOD_URI + '/year/' + yearNumber);
    }

    getEditablePeriodsByYearNumber(yearNumber: number): Observable<PeriodEdition[]> {
        return this.http.get<PeriodEdition[]>(environment.OP_API_URI + PERIOD_EDITION_URI + '/year/' + yearNumber);
    }

    getAllEntryPlanOfLastYear(accountId: number, yearNumber: number, periodNumber: number): Observable<Period> {
        return this.http.get<Period>(
            environment.OP_API_URI + `/accounts/${accountId}/year/${yearNumber}/period/${periodNumber}`
        );
    }

    // TODO: Check if used
    // isCellEditable(code: string, title: Title, accountType: string, period: Period, motif: Pattern[][]) {
    //     if (accountType === 'HYPCO' || accountType === 'SUPCO') {
    //         return false;
    //     } else if (
    //         (title.code === 'EST' || title.code === 'PLAN' || title.code.includes('PGS')) &&
    //         ((period.number === 7 || period.number === 15) && (code === 'ECO5162' || code === 'CG00112'))
    //     ) {
    //         return false;
    //     } else if (period.number === 8 || period.number === 16) {
    //         return false;
    //     } else if (
    //         (title.code === 'PLAN' || title.code.includes('PGS')) &&
    //         period.isLocked &&
    //         !(code === 'ECO5162' || code === 'CG00112')
    //     ) {
    //         return false;
    //     } else if (
    //         (title.code === 'PLAN' || title.code.includes('PGS')) &&
    //         period.isPilotLocked &&
    //         (code === 'ECO5162' || code === 'CG00112')
    //     ) {
    //         return false;
    //     } else if (title.code === 'EST' && period.isRealLocked) {
    //         return false;
    //     } else if (title.code === 'EST' && period.number === 0) {
    //         return false;
    //     } else if (title.code === 'REAL' && period.number === 0) {
    //         return false;
    //     } else if (title.code === 'EST' && (code === 'ECO5162' || code === 'CG00112')) {
    //         return false;
    //     } else if (this.isLockedByLocks(title.code, period)) {
    //         return false;
    //     } else if (!this.isRealObjEntrTitles(title.code)) {
    //         return false;
    //     } else {
    //         return !this.isRubriqueCalculated(title.code, motif);
    //         // && title.find(t => t.code === title.code).seizable;
    //     }
    // }

    isRealObjEntrTitles(titleCode): boolean {
        return titleCode === 'EST' || titleCode === 'PLAN' || titleCode === 'ENTR' ? true : false;
    }

    isLockedByLocks(titleCode, period: Period): boolean {
        if (period.locks.find(l => l.title.code === titleCode)) {
            return true;
        }
    }

    isRubriqueCalculated(code: string, motif: Pattern[][]): boolean {
        for (let group of motif) {
            for (let pattern of group) {
                if (pattern.rubrique.code === code) {
                    return pattern.rubrique.calculated;
                }
                return false;
            }
        }
    }

    isRubriqueAuthorized(code: string, motif: Pattern[][], userRole: string): boolean {
        const role = 'test'; //mettre le service qui va bien
        // if role === HYP return true
        for (let group of motif) {
            for (let pattern of group) {
                if (pattern.rubrique.code === code) {
                    // split rubrique.authorizedRole (HYP,HPPROSIT,AUTREROLE)
                    // return rubrique.authorizedRoles.contains(userRole)
                }
                return false;
            }
        }
    }
}
