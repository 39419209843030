import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RubriqueService } from 'src/app/_services/rubrique/rubrique.service';
import { Rubrique } from 'src/app/_models/rubrique';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PatternService } from 'src/app/_services/pattern/pattern.service';
import { Pattern } from 'src/app/_models/pattern';
import { forkJoin } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication/authentication.service';
import { User } from 'src/app/_models/user';
import { ROLES } from 'src/app/constants';

@Component({
    selector: 'app-affectation-compte-magasin',
    templateUrl: './affectation-compte-magasin.component.html',
    styleUrls: ['./affectation-compte-magasin.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class AffectationCompteMagasinComponent implements OnInit {

    @Input() userRole: string;

    userConnected: User;

    accountType = [
        { name: 'Magsin + Essence', code: 'HYPCO' },
        { name: 'Magasin', code: 'MAG' },
        { name: 'Essence', code: 'ESS' },
        { name: 'Franchisé + Essence', code: 'FRNCO' },
        { name: 'Franchisé / Partenaire',   code: 'FRN' },
        { name: 'Essence Franchisé ',   code: 'FRN_ESS' },
        { name: 'Auchan.fr', code: 'AUCHFR' },
        { name: 'Drive', code: 'DRIVE' },
        { name: 'Rayon', code: 'RAY' },
        { name: 'Marché', code: 'MAR' },
        { name: 'Logistique', code: 'LOG' },
        { name: 'Service après-vente', code: 'SAV'},
        //{ name: 'Service après-vente PRO', code: 'SAV_PRO'},
        { name: 'Service Appui', code: 'APP'},
        { name: 'Auchan Direct', code: 'DIR'},
        { name: 'ARA', code: 'ARA'},
        { name: 'Energie', code: 'ENER'},
        //{ name: 'Export', code: 'EXP'}

    ];
    selectedCategory = this.accountType[0];
    filterValue = '';
    filterValueRubrique = '';

    allDesignations: Rubrique[];
    designations: Rubrique[];
    filteredDesignations: Rubrique[];

    allPatterns: Pattern[];
    patterns: Pattern[];

    msgError = null;
    loading = false;
    formGroup: FormGroup;
    items: FormArray;
    formToDisplay: FormArray;

    constructor(
        public modal: MatDialog,
        private fb: FormBuilder,
        private toastService: ToastrService,
        private rubriqueService: RubriqueService,
        private patternService: PatternService,
        private authenticationService: AuthenticationService,
    ) {
        this.userConnected = this.authenticationService.getCurrentUser();

        if (this.userConnected.role === ROLES.NATIONAL_MAG) {
            this.accountType = [
                { name: 'Mag + Essence', code: 'HYPCO' },
                { name: 'Magasin', code: 'MAG' },
                { name: 'Essence', code: 'ESS' },
                { name: 'Rayon', code: 'RAY' },
            ];
        }
        if (this.userConnected.role === ROLES.NATIONAL_LOG) {
            this.accountType = [
                { name: 'Logistique', code: 'LOG' },
            ];
            this.selectedCategory = this.accountType[0];
        }


    }

    ngOnInit() {
        this.formGroup = new FormGroup({
            items: new FormArray([]),
        });
        this.items = this.formGroup.get('items') as FormArray;
        this.formToDisplay = this.formGroup.get('items').value;
        this.getPatterns();
    }

    getPatterns() {
        let listMagRubriques = ['HYP','SUP','ULT','ESS','RAY','HYPCO','SUPCO','MAR'];
        let listFRNRubriques = ['FRNCO','FRN_ESS']

        const type = listMagRubriques.includes(this.selectedCategory.code) ? 'MAG' : listFRNRubriques.includes(this.selectedCategory.code) ? 'FRN' : this.selectedCategory.code;
        this.msgError = null;
        this.loading = true;
        forkJoin([this.patternService.getAll(), this.rubriqueService.getAllByType(type)]).subscribe(
            ([patterns, rubriques]) => {
                this.allDesignations = rubriques;
                this.allPatterns = patterns;
                this.patterns = this.allPatterns.filter(p => p.category === this.selectedCategory.code);
                this.designations = rubriques.filter(r => !this.patterns.find(p => p.rubriqueId === r.id));
                this.filteredDesignations = [...this.designations];

                this.loading = false;
                this.patterns.forEach(p => {
                    this.addRow(p);
                });
                this.addSpace();
            },
            () => {
                this.loading = false;
            }
        );
    }

    applyFilter() {
        this.formToDisplay['controls'] = this.formGroup.get('items')['controls'].filter(d => {
            return (
                d.value.libelle
                    .toLowerCase()
                    .trim()
                    .includes(this.filterValue.toLowerCase().trim()) ||
                d.value.code
                    .toLowerCase()
                    .trim()
                    .includes(this.filterValue.toLowerCase().trim())
            );
        });
    }

    applyFilterRubrique() {
        this.filteredDesignations = this.designations.filter(d => {
            return (
                d.libelle
                    .toLowerCase()
                    .trim()
                    .includes(this.filterValueRubrique.toLowerCase().trim()) ||
                d.code
                    .toLowerCase()
                    .trim()
                    .includes(this.filterValueRubrique.toLowerCase().trim())
            );
        });
        // this.designations = this.designations.filter(r => !this.allPatterns.find(p => p.rubriqueId === r.id));
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.formToDisplay['controls'], event.previousIndex, event.currentIndex);
        moveItemInArray(this.formGroup.get('items')['controls'], event.previousIndex, event.currentIndex);
        moveItemInArray(this.formGroup.get('items').value, event.previousIndex, event.currentIndex);

        this.formGroup.get('items').markAsDirty();
    }

    updateSelectedTab(tab) {
        this.selectedCategory = tab;
        this.resetForm();
        this.getPatterns();
        this.addSpace();
    }

    addRow(item) {
        this.items = this.formGroup.get('items') as FormArray;
        this.items.push(this.createItem(item));
        this.applyFilter();
    }

    createItem(item) {
        return this.fb.group({
            id: new FormControl(item.id ? item.id : null),
            libelle: new FormControl(item.rubrique ? item.rubrique.libelle : item.libelle ? item.libelle : ''),
            code: new FormControl(item.rubrique ? item.rubrique.code : item.code ? item.code : ''),
            isHead: new FormControl(item.isHead ? item.isHead : false),
            isExpanded: new FormControl(item.isExpanded ? item.isExpanded : false),
            groupNumber: new FormControl(item.groupNumber ? item.groupNumber : 0),
            toggleGroupNumber: new FormControl(false),
            isTopPosition: new FormControl(item.isTopPosition ? item.isTopPosition : false),
            category: new FormControl(this.selectedCategory.code),
            rubriqueId: new FormControl(item.rubriqueId ? item.rubriqueId : item.tmpId),
            parentCode: new FormControl(item.parentCode && !item.isHead ? item.parentCode : null),
            styleApplied: new FormControl(item.styleApplied ? item.styleApplied : false),
        });
    }

    addSpace() {
        const mySet = new Set();
        this.items.value.forEach(p => {
            mySet.add(p.groupNumber);
        });

        const groupNumbers = Array.from(mySet);
        const result = [];
        groupNumbers.forEach(n => {
            const filter = this.items.value.filter(p => p.groupNumber === n);
            result.push(filter);
        });
        result.forEach(r => {
            if (r.length === 1) {
                const index = this.items.value.findIndex(p => p.id === r[0].id);
                this.items.at(index)['controls']['toggleGroupNumber'].patchValue(true);
            } else {
                const lastElementHead = r.reverse().find(x => x.isHead);
                r.forEach(rr => {
                    const index = this.items.value.findIndex(p => p.id === rr.id);
                    if (rr === lastElementHead) {
                        this.items.at(index)['controls']['toggleGroupNumber'].patchValue(true);
                    } else {
                        this.items.at(index)['controls']['toggleGroupNumber'].patchValue(false);
                    }
                });
            }
        });
    }

    onChangeToggle(event, i) {
        if (!event.checked) {
            this.items.at(i)['controls']['isExpanded'].patchValue(false);
            this.items.at(i)['controls']['toggleGroupNumber'].patchValue(false);
            this.items.at(i)['controls']['isTopPosition'].patchValue(false);
            this.items.at(i)['controls']['styleApplied'].patchValue(false);
        }
    }

    resetForm() {
        this.formGroup = new FormGroup({
            items: new FormArray([]),
        });
        this.items = this.formGroup.get('items') as FormArray;
        this.formToDisplay = this.formGroup.get('items').value;
        this.applyFilter();
    }

    openRubriqueModal(dialogRef) {
        const modal = this.modal.open(dialogRef, {
            disableClose: false,
            width: '50%',
            panelClass: 'custom-modal',
        });
    }

    selectRubrique(rubrique) {
        const rub = { ...rubrique };
        rub.tmpId = rubrique.id;
        delete rub.id;
        const index = this.designations.findIndex(d => d.id === rubrique.id);
        this.designations.splice(index, 1);
        this.filteredDesignations.splice(index, 1);
        this.modal.closeAll();
        this.addRow(rub);
        this.formGroup.get('items').markAsDirty();
    }

    cancelAdding() {
        this.resetForm();
        this.patterns.forEach(p => {
            this.addRow(p);
        });
        this.addSpace();
        this.toastService.success('Annulation des modifications terminée avec succès', 'Annulation');
    }

    delete(value, i) {
        this.items.removeAt(i);
        const rubrique = this.allDesignations.find(d => d.code === value.code);
        this.designations.push(rubrique);
        this.filteredDesignations.push(rubrique);

        this.formGroup.get('items').markAsDirty();
        this.applyFilter();
    }

    onSubmit() {
        this.msgError = null;
        let patterns = [...this.formGroup.value.items];

        patterns.forEach((p, i) => {
            p.index = i + 1;
        });
        patterns = this.associateChildToparent();
        if (!patterns) {
            this.formGroup.markAsUntouched();
            this.msgError = "Le formulaire contient des erreurs d'association parent/enfant.";
            return;
        }

        patterns = this.dispachGroupNumber();

        patterns.forEach(p => {
            delete p.code;
            delete p.libelle;
            delete p.toggleGroupNumber;
        });

        this.patternService.deleteCreateOrUpdatePatterns(this.selectedCategory.code, patterns).subscribe(
            data => {
                // this.ngOnInit();
                this.resetForm();
                this.getPatterns();

                this.formGroup.markAsUntouched();
                this.toastService.success('Les données ont été modifiées avec succès', 'Rubrique');
            },
            () => {
                this.toastService.error('Une erreur est survenue', 'Erreur');
            }
        );
    }


    associateChildToparent() {
        const patterns = [...this.formGroup.value.items];
        let lastParent;
        for (let p of patterns) {
            if (p.isHead) {
                lastParent = p;
            } else {
                if (lastParent) {
                    p.parentCode = lastParent.code;
                } else {
                    return null;
                }
            }
        }
        return patterns;
    }

    dispachGroupNumber() {
        const patterns = [...this.formGroup.value.items];
        let lastGroupNumber = 1;
        let index;
        for (let i = 0; i < patterns.length; i++) {
            if (i !== 0 && patterns[i].isHead && patterns[index].toggleGroupNumber) {
                lastGroupNumber += 1;
            }
            if (patterns[i].isHead) {
                index = i;
            }
            patterns[i].groupNumber = lastGroupNumber;
        }
        return patterns;
    }
}
