import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LOCK_URI } from 'src/app/constants';

@Injectable({
    providedIn: 'root',
})
export class LockService {
    constructor(private http: HttpClient) {}

    lock(accountIds: number[], titleIds: number[], periodId?: number): Observable<any[]> {
        let body = {
            accountsIdsToLock: accountIds,
            titleIdsToLock: titleIds,
        };
        if (periodId) {
            return this.http.post<any[]>(environment.OP_API_URI + LOCK_URI + `/${periodId}`, body);
        } else {
            return this.http.post<any[]>(environment.OP_API_URI + LOCK_URI + `/`, body);
        }
    }
}
